import { FunctionComponent, useContext } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { IsReferenceSelectedContext } from 'scenes/nuevo-collection/content-tab';

import Reference from '../base-reference';
import CollectionReference from '../reference';

import { useTopicGroup } from './use-topic-group';

const CardStack = styled('div')`
  /* Stacked card stack effect */
  /* Need position to allow stacking of pseudo-elements */
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  opacity: 1;

  /* 
    If the variants are shown, disable the shadow on the parent reference
    card on hover, because we'll show it on last card in the stack
  */
  &:not(.variants) > .reference:hover {
    box-shadow: ${({ theme }) => theme.shadows[1]} !important;
  }

  /* Disable the card stack effect if the variants are shown */
  &.variants::before,
  &.variants::after {
    opacity: 0;
  }

  &::before,
  &::after {
    content: '';
    border-radius: 6px;
    background-color: var(--color-neutral-one);
    /* Add shadow to distinguish sheets from one another */
    box-shadow: ${({ theme }) => theme.shadows[1]};
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.25s ease-in-out;
  }

  /* Second card in stack */
  &::before {
    left: 4px;
    width: calc(100% - 8px);
    top: 4px;
    z-index: 1;
  }

  /* Third card in stack */
  &::after {
    left: 8px;
    width: calc(100% - 16px);
    top: 8px;
    z-index: 0;
  }

  &:hover::after {
    box-shadow: ${({ theme }) => theme.shadows[4]};
  }
`;

type Props = {
  categoryId: number;
  collectionId: number;
  references: CategoryContentEntry[];
  onInfoClick: (contentEntryId: number) => void;
  onSelectChange: (referenceId: number, categoryId: number) => void;
};

const TopicGroup: FunctionComponent<Props> = ({
  categoryId,
  collectionId,
  references,
  onInfoClick,
  onSelectChange,
}) => {
  const isReferenceSelected = useContext(IsReferenceSelectedContext);

  const {
    showVariants,
    toggleShowVariants,
    allSelected,
    selectedCount,
    flagIncomplete,
    handleGroupSelectChange,
  } = useTopicGroup(
    references,
    categoryId,
    onSelectChange,
    isReferenceSelected
  );

  return (
    <CardStack className={showVariants ? 'variants' : ''}>
      <Reference
        className="reference"
        contentType="topicGroup"
        selected={allSelected}
        onSelectChange={handleGroupSelectChange}
        isIndeterminateSelection={!allSelected && selectedCount > 0}
        flagIncomplete={flagIncomplete}
        variants={
          showVariants
            ? references.map((ref) => (
                <CollectionReference
                  key={`tg-${ref.topicSlug}-${categoryId}-${ref.id}`}
                  asCard={false}
                  onInfoClick={onInfoClick}
                  onSelectChange={onSelectChange}
                  collectionId={collectionId}
                  categoryId={categoryId}
                  {...ref}
                />
              ))
            : undefined
        }
      >
        <Box
          position="absolute"
          left={3}
          top={16}
          bottom={16}
          width={0}
          padding="0px !important"
          borderRight="3px solid var(--color-content-topic-group)"
        />
        <Box display="block" minWidth="5rem" flex="10" alignSelf="center">
          <Typography fontWeight="bold" display="inline">
            {references[0].topic}
          </Typography>
          <Typography display="inline">{` (${references[0].topicSlug})`}</Typography>
          <Typography
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            color="var(--color-content-topic-group)"
            onClick={toggleShowVariants}
          >
            {`${showVariants ? 'Hide' : 'Show'} ${references.length} variants`}
          </Typography>
        </Box>
      </Reference>
    </CardStack>
  );
};

export default TopicGroup;
