import { useState } from 'react';
import { useAppToast } from 'contexts/app-toast-context';

export const useQuickAddResult = (
  id: number,
  isExistingReference: boolean,
  categoryName: string,
  onSelectResult: (id: number) => Promise<any>,
  onRemoveResult: (id: number) => Promise<any>
) => {
  const [addedSuccessfully, setAddedSuccessfully] = useState(false);
  const dispatchToast = useAppToast();

  const handleSelectResult = async () => {
    if (addedSuccessfully || isExistingReference) {
      return;
    }

    try {
      const result = await onSelectResult(id);
      result && setAddedSuccessfully(true);
      dispatchToast({
        level: 'success',
        buttonText: 'UNDO',
        onButtonClick: async () => {
          await onRemoveResult(id);
          setAddedSuccessfully(false);
          dispatchToast({ level: 'success', message: 'Adding answer undone.' });
        },
        message: (
          <>
            {'Success!  Answer added to '}
            <b>{categoryName}</b>
            {'. '}
          </>
        ),
      });
    } catch (e) {
      dispatchToast({
        level: 'error',
        message: 'We were unable to add your answer.  Please try again.',
      });
    }
  };

  return { addedSuccessfully, handleSelectResult };
};
