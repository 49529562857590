import { Autocomplete, TextField, Box, Skeleton } from '@mui/material';
import { FC, useMemo, SyntheticEvent } from 'react';
import styled from 'styled-components';

import { useTopicSelector } from './hooks';

const StyledTopicOption = styled.div`
  div {
    position: relative;
    font-size: 14px;
    color: var(--color-neutral-seven);
  }
  #slug {
    font-size: 13.3px;
    color: var(--color-neutral-six);
  }
`;

type Props = {
  label: string; // label for the dropdown
  onChange: (_e: SyntheticEvent<Element>, newTopic: TopicModel | null) => void;
  currentTopicId?: number;
  disabled?: boolean;
};

export const TopicSelector: FC<Props> = ({
  label,
  currentTopicId,
  onChange,
  disabled,
}) => {
  const { options, isLoading, filterOptions } = useTopicSelector();

  const currentTopic = useMemo(() => {
    return options.find((opt) => opt.id === currentTopicId);
  }, [options, currentTopicId]);

  if (isLoading) {
    return <Skeleton variant="rectangular" height="2rem" />;
  }

  return (
    <Autocomplete
      autoHighlight
      fullWidth
      disableClearable
      disabled={disabled}
      options={options}
      value={currentTopic}
      isOptionEqualToValue={(option: TopicModel, value: TopicModel) =>
        option.id === value.id
      }
      loading={isLoading}
      loadingText="Loading..."
      onChange={onChange}
      getOptionLabel={(option) => option.title}
      filterOptions={filterOptions}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.slug}>
          <StyledTopicOption>
            <div>{option.title}</div>
            <div id="slug">{option.slug}</div>
          </StyledTopicOption>
        </Box>
      )}
      renderInput={(params) => <TextField label={label} {...params} />}
      data-test-id="topic-selector"
    />
  );
};
