import { ContentTypes } from 'types/enums';

import ContentTypeWithIcon from './content-type-with-icon';
export { default } from './content-type-with-icon';

export const TutorialType = (
  <ContentTypeWithIcon contentType={ContentTypes.TUTORIAL} />
);
export const TourType = <ContentTypeWithIcon contentType={ContentTypes.TOUR} />;
export const CollectionType = (
  <ContentTypeWithIcon contentType={ContentTypes.COLLECTION} />
);
