import { FunctionComponent, useState, useCallback } from 'react';
import { Button, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import getPlural from 'services/utils/get-plural';
import usePreviousPage from 'services/utils/use-previous-page';
import styled from 'styled-components';

import DragAndDropArea from './drag-and-drop-area';
import ImagePreviewArea from './image-preview-area';

const StyledContainer = styled.div`
  height: calc(100vh - 260px);
  width: 100%;
  padding: 0px 48px;
  display: flex;
  & > div:first-child {
    margin-right: 24px;
  }
`;

const StyledDragAndDropContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledImagesContainer = styled.div`
  flex: 2;
  overflow-y: scroll;
`;

const StyledStickyFooter = styled.div`
  position: absolute;
  padding: 8px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  flex-direction: row-reverse;
  & > button {
    margin: 8px 16px;
  }
`;

const ShellCompiler: FunctionComponent = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [deviceShell, setDeviceShell] = useState<DeviceShellModel>();
  const [downloadLinks, setDownloadLinks] = useState<HTMLAnchorElement[]>([]);
  const { previousPageButton } = usePreviousPage();

  const hasBrokenFiles = files.length !== downloadLinks.length && deviceShell;
  const isDownloadReady =
    files.length === downloadLinks.length &&
    deviceShell &&
    downloadLinks.length > 0;

  const handleAddFiles = (files: File[]) => {
    setDownloadLinks([]);
    setFiles((f) => [...f, ...files]);
  };

  const handleSetDeviceShell = useCallback((s?: DeviceShellModel) => {
    // When the shell is changed, clear the download links to the old composites
    // because new ones will be created with the new shell
    setDownloadLinks([]);
    setDeviceShell(s);
  }, []);

  const handleCompositeComplete = useCallback((link: HTMLAnchorElement) => {
    setDownloadLinks((d) => [...d, link]);
  }, []);

  const handleClearClick = () => {
    // Clear the download links and files, keep the device and shell
    setDownloadLinks([]);
    setFiles([]);
  };

  const handleRemoveFile = useCallback((index: number) => {
    // When a file is removed, clear the download links because they will all be recreated
    setDownloadLinks([]);
    setFiles((files) => [...files.slice(0, index), ...files.slice(index + 1)]);
  }, []);

  const handleDownloadClick = () => {
    downloadLinks.forEach((link) => link.click());
  };

  const shellError =
    files.length && !deviceShell
      ? 'Please select a shell for for your images'
      : undefined;

  return (
    <>
      {previousPageButton}
      <Typography variant="h5" marginLeft={6}>
        {'Select a device and shell'}
      </Typography>
      <StyledContainer>
        <StyledDragAndDropContainer>
          <DragAndDropArea
            onAddFiles={handleAddFiles}
            onSelectDeviceShell={handleSetDeviceShell}
            error={shellError}
          />
        </StyledDragAndDropContainer>

        {files.length > 0 && (
          <StyledImagesContainer>
            <ImagePreviewArea
              onRemoveFile={handleRemoveFile}
              onCompositeComplete={handleCompositeComplete}
              deviceShell={deviceShell}
              files={files}
            />
          </StyledImagesContainer>
        )}
      </StyledContainer>
      <StyledStickyFooter>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={!isDownloadReady}
          onClick={handleDownloadClick}
        >
          {`Download ${downloadLinks.length} ${getPlural(
            downloadLinks.length,
            'image',
            'images'
          )}`}
        </Button>
        <Button
          size="small"
          onClick={handleClearClick}
          disabled={files.length === 0}
        >
          {'Clear all images'}
        </Button>
        {hasBrokenFiles && (
          <Typography
            color="error"
            alignSelf="center"
            display="flex"
            alignItems="center"
          >
            <ErrorIcon />
            &nbsp;
            {
              'Please delete all files with errors before downloading your images. '
            }
          </Typography>
        )}
      </StyledStickyFooter>
    </>
  );
};

export default ShellCompiler;
