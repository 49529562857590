import { useMemo, useCallback, FC } from 'react';
import { Grid, Skeleton, MenuItem, ListItemIcon } from '@mui/material';
import { Add, Description, Search } from '@mui/icons-material';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { ContentTypes } from 'types/enums';
import MenuButton from 'components/menu-button';
import { useModal } from 'components/modals';
import AddContent from 'scenes/add-content';
import { PermissionRequired } from 'components/permission-required';
import AddExistingContentModal from 'scenes/collection/localized-collection/add-existing-content';

type Props = {
  localizedCollection: LocalizedCollectionModel;
  categoryIndex?: number;
  addReferences: (
    referenceIds: number[],
    intoCategoryIndex?: number
  ) => Promise<boolean>;
};

const ReferenceListHeader: FC<Props> = ({
  localizedCollection,
  categoryIndex,
  addReferences,
}) => {
  const api = useOzmoApiService();
  const { open, openModal, handleClose } = useModal();

  const { data: contentEntry, isLoading } = api.ContentEntry.get({
    id: localizedCollection?.contentEntryId,
  });

  const collectionLanguages = useMemo(() => {
    return (
      contentEntry?.localizedContentEntries?.map((lce) => lce.languageId) ?? []
    );
  }, [contentEntry]);

  const collectionAttributes = useMemo(() => {
    return {
      Device: contentEntry?.devices?.map((d) => d.id) ?? [],
      DeviceType: contentEntry?.deviceTypes?.map((dt) => dt.id) ?? [],
      Manufacturer: contentEntry?.manufacturers?.map((m) => m.id) ?? [],
      OperatingSystem: contentEntry?.operatingSystems?.map((os) => os.id) ?? [],
      OperatingSystemRelease:
        contentEntry?.operatingSystemReleases?.map((osr) => osr.id) ?? [],
      OperatingSystemVersion:
        contentEntry?.operatingSystemVersions?.map((osv) => osv.id) ?? [],
    };
  }, [contentEntry]);

  const onSaveMultiple = useCallback(
    (newReferenceIds: number[]) =>
      // if categoryIndex is not defined; assume uncategorized
      addReferences(newReferenceIds, categoryIndex),
    [addReferences, categoryIndex]
  );

  const onSaveSingle = useCallback(
    (newReferenceId: number) => onSaveMultiple([newReferenceId]),
    [onSaveMultiple]
  );

  if (!contentEntry) {
    return <Skeleton />;
  }

  return (
    <>
      <Grid container justifyContent="space-between">
        <div /> {/* placeholder spacer for filter bar */}
        <PermissionRequired permission="edit_collection" disableElement>
          <MenuButton variant="text" startIcon={<Add />} label="Answer">
            <AddContent
              sourceName="collection"
              contentTypeBlocklist={[
                ContentTypes.COLLECTION,
                ContentTypes.LEGACY_TUTORIAL,
                ContentTypes.LEGACY_TOUR,
              ]}
              spaceId={contentEntry.spaceId}
              languageIds={collectionLanguages}
              attributes={collectionAttributes}
              onSave={onSaveSingle}
            >
              <MenuItem disabled={isLoading}>
                <ListItemIcon>
                  <Description />
                </ListItemIcon>
                {'New content'}
              </MenuItem>
            </AddContent>
            <MenuItem onClick={openModal}>
              <ListItemIcon>
                <Search />
              </ListItemIcon>
              {'Existing content'}
            </MenuItem>
          </MenuButton>
        </PermissionRequired>
      </Grid>
      <AddExistingContentModal
        open={open}
        handleClose={handleClose}
        onAdd={onSaveMultiple}
        collectionId={localizedCollection.contentEntryId}
        collectionName={localizedCollection.properties.title}
      />
    </>
  );
};

export default ReferenceListHeader;
