import { FunctionComponent, ReactNode } from 'react';

import { BlockOptions } from '../types';

import { useBlockButton } from './hooks';
import BaseButton from './base-button';

type Props = {
  format: BlockOptions;
  tooltip: string;
  children: ReactNode;
};

const BlockButton: FunctionComponent<Props> = ({
  format,
  tooltip,
  children,
}) => {
  const { color, backgroundColor, handleClick } = useBlockButton(format);

  return (
    <BaseButton
      color={color}
      backgroundColor={backgroundColor}
      label={format}
      tooltip={tooltip}
      onClick={handleClick}
    >
      {children}
    </BaseButton>
  );
};

export default BlockButton;
