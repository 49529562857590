import { createTheme } from '@mui/material/styles';
import { menuItemClasses } from '@mui/material/MenuItem';
import { tableRowClasses } from '@mui/material/TableRow';

// Add dynamic props to theme (colors, variants, etc)
// https://mui.com/blog/mui-core-v5/#dynamic-props
declare module '@mui/material/styles' {
  interface Palette {
    default: Palette['primary'];
    danger: Palette['primary'];
    draft: Palette['primary'];
    // eslint-disable-next-line camelcase
    published_with_draft: Palette['primary'];
    published: Palette['primary'];
    neutralSix: Palette['primary'];
    neutralEight: Palette['primary'];
    whitePrimary: Palette['primary'];
    filterChip: Palette['primary'];
    filterChipUsed: Palette['primary'];
    filterChipDisabled: Palette['primary'];
    contentTutorial: Palette['primary'];
    contentTutorialUsed: Palette['primary'];
    legacyContentChip: Palette['primary'];
    legacyContentChipUsed: Palette['primary'];
    statusDraft: Palette['primary'];
    statusDraftUsed: Palette['primary'];
    statusPublished: Palette['primary'];
    statusPublishedUsed: Palette['primary'];
  }
  // allow configuration using `createPalette`
  interface PaletteOptions {
    danger: PaletteOptions['primary'];
    draft: PaletteOptions['primary'];
    // eslint-disable-next-line camelcase
    published_with_draft: PaletteOptions['primary'];
    published: PaletteOptions['primary'];
    neutralSix: PaletteOptions['primary'];
    neutralEight: PaletteOptions['primary'];
    whitePrimary: PaletteOptions['primary'];
    filterChip: PaletteOptions['primary'];
    filterChipUsed: PaletteOptions['primary'];
    filterChipDisabled: PaletteOptions['primary'];
    legacyContentChip: Palette['primary'];
    legacyContentChipUsed: Palette['primary'];
    contentTutorial: PaletteOptions['primary'];
    contentTutorialUsed: PaletteOptions['primary'];
    statusDraft: PaletteOptions['primary'];
    statusDraftUsed: PaletteOptions['primary'];
    statusPublished: PaletteOptions['primary'];
    statusPublishedUsed: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    neutralSix: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    danger: true;
    neutralSix: true;
    neutralEight: true;
    whitePrimary: true;
  }
  interface ButtonPropsVariantOverrides {
    menu: true;
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    danger: true;
    neutralSix: true;
    neutralEight: true;
    whitePrimary: true;
  }
  interface ButtonGroupPropsVariantOverrides {
    menu: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    filterChip: true;
    filterChipUsed: true;
    filterChipDisabled: true;
    contentTutorial: true;
    contentTutorialUsed: true;
    legacyContentChip: true;
    legacyContentChipUsed: true;
    statusDraft: true;
    statusDraftUsed: true;
    statusPublished: true;
    statusPublishedUsed: true;
  }
}

declare module '@mui/material/FormControl' {
  interface FormControlPropsColorOverrides {
    // eslint-disable-next-line camelcase
    published_with_draft: true;
    draft: true;
    neutralSix: true;
    neutralEight: true;
    published: true;
  }
}

declare module '@mui/material/Select' {
  interface SelectPropsColorOverrides {
    neutralSix: true;
    neutralEight: true;
  }
}

const { palette } = createTheme();
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    background: {
      default: '#f2f2f2',
    },
    primary: {
      main: '#006dc7',
      light: '#007ee6',
      dark: '#005499',
    },
    secondary: {
      main: '#62d8c4',
    },
    success: {
      // this is --color-messaging-success from ozmo-theme.css
      // MUI does not support CSS variables here dispite being
      // able to use it within "text" below.
      main: '#106a20',
    },
    error: {
      // this is --color-messaging-error from ozmo-theme.css
      // MUI does not support CSS variables here dispite being
      // able to use it within "text" below.
      main: '#d10000',
    },
    text: {
      primary: '#1a242d',
      secondary: 'var(--color-neutral-seven)',
    },
    // Custom colors
    danger: palette.augmentColor({
      color: {
        main: '#d10000',
        dark: '#d10000',
      },
    }),
    draft: palette.augmentColor({ color: { main: '#AF6301' } }),
    // eslint-disable-next-line camelcase
    published_with_draft: palette.augmentColor({ color: { main: '#AF6301' } }),
    neutralSix: palette.augmentColor({
      color: { main: '#8b8b8d', contrastText: '#595f64' },
    }),
    neutralEight: palette.augmentColor({ color: { main: '#1a242d' } }),
    published: palette.augmentColor({ color: { main: '#106a20' } }),
    whitePrimary: palette.augmentColor({ color: { main: '#006dc7' } }),
    filterChip: palette.augmentColor({ color: { main: '#006dc7' } }),
    filterChipUsed: palette.augmentColor({
      color: { main: '#eff5fc', contrastText: '#006dc7' },
    }),
    filterChipDisabled: palette.augmentColor({
      color: { main: '#f2f2f2', contrastText: '#595f64' },
    }),
    contentTutorial: palette.augmentColor({ color: { main: '#c30ce1' } }),
    contentTutorialUsed: palette.augmentColor({
      color: { main: '#f6d8fb', contrastText: '#c30ce1' },
    }),
    legacyContentChip: palette.augmentColor({ color: { main: '#8b8b8d' } }),
    legacyContentChipUsed: palette.augmentColor({
      color: { main: '#dcdcdd', contrastText: '#595f64' },
    }),
    statusDraft: palette.augmentColor({
      color: { main: '#db8b03', contrastText: '#fff' },
    }),
    statusDraftUsed: palette.augmentColor({
      color: { main: '#fffcf2', contrastText: '#db8b03' },
    }),
    statusPublished: palette.augmentColor({ color: { main: '#106a20' } }),
    statusPublishedUsed: palette.augmentColor({
      color: { main: '#f1fff7', contrastText: '#106a20' },
    }),
  },
  typography: {
    h1: {
      fontSize: '2.99rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2.49rem',
      fontWeight: 'normal',
    },
    h3: {
      fontSize: '2.07rem',
      fontWeight: 'normal',
      color: 'var(--color-neutral-eight)',
      lineHeight: 1.5,
    },
    h4: {
      fontSize: '1.728rem',
      fontWeight: 'normal',
      color: 'var(--color-neutral-eight)',
    },
    h5: {
      fontSize: '1.44rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 'normal',
      color: 'var(--color-neutral-eight)',
    },
    body1: {
      fontSize: '1rem',
      color: 'var(--color-neutral-eight)',
    },
    body2: {
      fontSize: '0.875rem',
      color: 'var(--color-neutral-seven)',
    },
    subtitle1: {
      fontSize: '1.26rem',
      lineHeight: 1.5,
    },
    subtitle2: {
      fontWeight: 'bold',
      fontSize: '1.05rem',
      color: 'var(--color-neutral-seven)',
    },
    caption: {
      fontStyle: 'italic',
      fontSize: '0.833rem',
      color: 'var(--color-neutral-seven)',
    },
  },
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          transition: 'transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filled: {
          fontWeight: 400,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          top: 'calc(50% - 18px)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'none',
          ...(ownerState.color === 'neutralSix' && {
            color: theme.palette.neutralSix.contrastText,
          }),
          ...(ownerState.color === 'whitePrimary' && {
            backgroundColor: '#fff',
          }),
        }),
        sizeSmall: {
          height: 32,
          fontSize: '0.875rem',
        },
        sizeMedium: {
          height: 40,
          fontSize: '0.9375rem',
        },
        sizeLarge: {
          height: 48,
          fontSize: '1rem',
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--color-neutral-one)',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          float: 'right', // Move card actions to the right side of the card
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 8,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(139, 139, 141, 0.2)',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          border: 'solid 1px var(--color-neutral-five)',
          fontSize: '0.75rem',
          height: 24,
        },
        colorPrimary: ({ theme }) => ({
          border: 'none',
          backgroundColor: theme.palette.primary.light,
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: 300,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          '& > :not(:first-child)': {
            marginLeft: '24px',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        vertical: {
          backgroundColor: 'var(--color-neutral-three)',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontStyle: 'normal', // disable the italic on Typography variant "caption"
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          height: '30px',
          width: '30px',
          margin: 4,
          padding: '0px !important',
          transition:
            'box-shadow 200ms ease-in-out, background-color 200ms ease-in-out, color 200ms ease-in-out, transform 200ms ease-in-out',
          '&:hover': {
            backgroundColor: 'rgba(139, 139, 141, 0.2)',
          },
          '&:active:not(:disabled):not([disabled])': {
            backgroundColor: 'rgba(139, 139, 141, 0.4)',
          },
          '&:disabled': {
            backgroundColor: '#ffffff',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'var(--color-neutral-seven)',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: 'var(--color-neutral-seven)',
        },
        primary: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 6,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          color: 'var(--color-neutral-seven)',
          [`&.${menuItemClasses.selected}`]: {
            backgroundColor: 'var(--color-primary-background)',
          },
          [`&.${menuItemClasses.focusVisible}`]: {
            backgroundColor: 'var(--color-primary-background)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'var(--color-neutral-six)',
        },
        root: {
          borderRadius: 6,
          backgroundColor: '#fff',
          color: 'var(--color-neutral-seven)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 6,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            fontWeight: 'normal !important',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            fontWeight: 500,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          background: 'unset',
          padding: '24px 0px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          textTransform: 'none',
          minWidth: '0 !important',
          paddingBottom: '0.25rem',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowY: 'auto',
          borderRadius: '6px',
          border: 'solid 1px var(--color-neutral-five)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: undefined,
        },
        head: {
          fontSize: '0.875em',
          fontStyle: 'normal',
          textTransform: 'uppercase',
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'var(--color-neutral-four)',
        },
        body: {
          fontStyle: 'normal',
          color: 'var(--color-neutral-eight)',
          borderBottom: '2px solid var(--color-neutral-one)',
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          [`&.${tableRowClasses.selected}`]: {
            backgroundColor: 'var(--color-neutral-two)',
          },
          [`&.${tableRowClasses.selected}:hover`]: {
            backgroundColor: 'var(--color-neutral-three)',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '48px !important',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          boxShadow:
            '0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23)',
          backgroundColor: '#323e47',
          color: 'var(--color-neutral-one)',
          fontWeight: 500,
          lineHeight: 1.5,
          fontSize: '0.875rem',
        },
        tooltipPlacementBottom: {
          top: -4,
        },
        tooltipPlacementLeft: {
          left: 0,
        },
        arrow: {
          color: '#323e47',
        },
      },
    },
  },
});

export default theme;
