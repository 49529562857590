import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';

// Hi, are you here from the docs to implement a new model?
// If so, copy this folder and rename it to match your new model, then update the
// class name to YourModel and the type declarations in <> to match your types

interface ResourcePathVariables {
  id?: number;
}
class Topic extends OzmoApiBase<
  TopicModel,
  TopicUpdateModel,
  TopicCreateModel,
  ResourcePathVariables
>() {
  // If your model should be readonly (that is, it should never update the server)
  // set the readonly value to true.  Otherwise leave it as false or do not include it (it defaults to false)
  // This will disable the create(), update(), and delete() methods (and the async versions)
  // If someone tries to use one of the disabled methods it will throw()
  protected static readonly = false;

  // Next update the resource path and add any embed strings to the array
  // For example, if you are implementing a model that you get to at
  // https://api.ozmoapp.com/v1/authoring/fancy_thing?embed=topics,stuff you should enter
  // resourcePath = 'authoring/fancy_thing'
  // embedOptions = ['topic', 'stuff']
  protected static resourcePath = 'authoring/topics/:id';
  protected static embedOptions = [];

  // If you want override config values for react-query for all models
  // add them here- note that options passed to the instances will override these
  // For a full list of config options see the react-query docs: https://react-query.tanstack.com/reference/useQuery
  protected static defaultReactQueryConfig = {
    staleTime: 300000, // 5 minutes
  };
}

// Make sure to update this to match you class name
// And delete all these extra comments
export default Topic;
