import { FCWithChildren } from 'types/fc-with-children';
import { Grid, Typography, styled, Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';

type Props = {
  title: string;
  subTitle?: string;
  helperText?: string;
};

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 4px;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--color-neutral-seven);

  svg {
    font-size: 1em;
  }
`;

const DetailItem: FCWithChildren<Props> = ({
  title,
  helperText,
  subTitle,
  children,
}) => (
  <>
    <Grid item xs={6}>
      <StyledTypography>
        {title}
        {helperText && (
          <Tooltip title={helperText} arrow>
            <Info fontSize="small" />
          </Tooltip>
        )}
      </StyledTypography>
    </Grid>
    <Grid item xs={6}>
      {children}
      <Typography
        fontStyle="italic"
        color="var(--color-neutral-seven)"
        fontSize="0.85em"
      >
        {subTitle}
      </Typography>
    </Grid>
  </>
);

export default DetailItem;
