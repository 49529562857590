// Adapted from: https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
export const parseJWT = (token: string) => {
  try {
    const [, base64Url] = token.split('.');
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );

    return JSON.parse(jsonPayload) as Record<string, any>;
  } catch {
    return {};
  }
};

/**
 *
 * @param token A string JWT token
 * @returns A number that represents the expiration time in millisecond epoch time
 */
export const getTokenExpiry = (token?: Maybe<string>) => {
  if (!token) {
    return -1;
  }
  const { exp = -1 } = parseJWT(token) as DecodedJWTToken;
  return exp;
};
