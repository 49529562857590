import { useCallback, useState } from 'react';
import { useConfirmationModal } from 'components/modals';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { generateQueryKey } from 'services/ozmo-api/use-query-cache';

type State = {
  localizedContentEntryId: number;
  message: string;
};

export const useLanguages = (contentEntryId: number) => {
  const api = useOzmoApiService();
  const { data: contentEntry, ...rest } = api.ContentEntry.get({
    id: contentEntryId,
  });
  const [state, setState] = useState<State>({
    localizedContentEntryId: 0,
    message: '',
  });
  const { message, localizedContentEntryId } = state;
  const flagIncomplete = useCallback(
    (lce: LocalizedContentEntryModel) =>
      contentEntry?.contentType !== 'Collection' &&
      !lce.complete &&
      !lce.deletedAt,
    [contentEntry]
  );

  // Delete
  const {
    modal: deleteModal,
    openModal: openDeleteModal,
  } = useConfirmationModal({
    onConfirm: useCallback(() => {
      api.LocalizedContentEntry.deleteAsync({
        contentEntryId,
        id: localizedContentEntryId,
      });
      api.queryClient.invalidateQueries(
        generateQueryKey(contentEntryId, 'authoring/content_entries')
      );
    }, [
      api.LocalizedContentEntry,
      api.queryClient,
      contentEntryId,
      localizedContentEntryId,
    ]),
    modalProps: {
      confirmButtonColor: 'error',
      confirmButtonText: 'Delete',
      title: 'Confirm Delete',
      message,
    },
  });

  const handleDeleteClick = useCallback(
    (
      localizedContentEntryId: number,
      language: string,
      title: string
    ) => () => {
      const message = `Are you sure you want to delete the ${language} version of "${title}?"  This action cannot be undone`;
      setState({ localizedContentEntryId, message });
      openDeleteModal();
    },
    [openDeleteModal]
  );

  return {
    ...rest,
    contentEntry,
    deleteModal,
    flagIncomplete,
    handleDeleteClick,
  };
};

export const useContentEntrySidePanel = () => {
  const [panelItemId, setPanelItemId] = useState<number | null>(null);
  const isPanelOpen = panelItemId !== null;
  const sidePanelSelectedClass = `side-panel-selected-content-entry-${panelItemId}`;

  const showPanel = useCallback(
    (contentEntryId: number) => setPanelItemId(contentEntryId),
    []
  );
  const hidePanel = useCallback(() => setPanelItemId(null), []);

  const togglePanel = useCallback(
    (nextPanelItemId: number) => {
      if (nextPanelItemId === panelItemId) {
        hidePanel();
      } else {
        showPanel(nextPanelItemId);
      }
    },
    [panelItemId, hidePanel, showPanel]
  );

  return {
    sidePanelSelectedClass,
    showPanel,
    hidePanel,
    togglePanel,
    panelItemId,
    isPanelOpen,
  };
};
