import { useOzmoApiService } from 'contexts/ozmo-api-service-context';
import { useParams } from 'react-router-dom';

type RouteParams = {
  id: string;
  language: string;
  categoryId?: string;
};

export const useCurrentCollection = () => {
  const api = useOzmoApiService();
  const { id, language, categoryId } = useParams<RouteParams>();

  const {
    all: languages,
    isLoading: isLanguagesLoading,
  } = api.Language.getAll();
  const languageInUse =
    languages?.find((lang) => lang.shortCode === language) ?? languages[0];
  const languageId = languageInUse?.id ?? languages[0]?.id ?? 1;

  const {
    data: collection,
    isLoading,
    addCategory,
    updateCategory,
    ...otherProperties
  } = api.Collection.get(
    {
      id: isLanguagesLoading ? undefined : Number(id)!,
    },
    undefined,
    undefined,
    { languageId }
  );

  return {
    collection,
    isLoading: isLanguagesLoading || isLoading,
    language: languageInUse?.shortCode,
    languageDisplayName: languageInUse?.displayName,
    languageId,
    categoryId: Number(categoryId) || undefined,
    collectionId: Number(id)!,
    addCategory,
    updateCategory,
    ...otherProperties,
  };
};
