import { FunctionComponent } from 'react';
import { styled } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const StyledDragIcon = styled(DragIndicatorIcon)`
  color: var(--color-neutral-seven);
  transition: color 0.1s ease-in-out;
  cursor: grab;
  &:hover,
  &:active {
    color: var(--color-primary-base) !important;
  }

  &.disabled,
  &.disabled:hover {
    color: rgba(0, 0, 0, 0.16) !important;
    cursor: not-allowed;
  }
`;

type Props = {
  className?: string;
  disabled?: boolean;
};

const DragHandle: FunctionComponent<Props> = ({ className, disabled }) => (
  <StyledDragIcon className={`${className} ${disabled ? 'disabled' : ''}`} />
);

export default DragHandle;
