/**
 * Finds the best available LocalizedContentEntry, otherwise the first
 * @param contentEntry The localized content entries to choose from
 * @param preferredLanguageId The preferred language to find
 * @param fallbackLanguageId  If the preferred language does not exist, a fallback (defaults to 1 aka English)
 * @returns LocalizedContentEntry
 */
export const getBestLocalizedContentEntry = (
  localizedContentEntries: LocalizedContentEntryModel[],
  preferredLanguageId: number,
  fallbackLanguageId = 1
): LocalizedContentEntryModel | undefined => {
  const preferredLocalizedContentEntry = localizedContentEntries.find(
    (lce) => lce.languageId === preferredLanguageId
  );

  if (preferredLocalizedContentEntry) {
    return preferredLocalizedContentEntry;
  }

  const fallbackLocalizedContentEntry = localizedContentEntries.find(
    (lce) => lce.languageId === fallbackLanguageId
  );

  if (fallbackLocalizedContentEntry) {
    return fallbackLocalizedContentEntry;
  }

  return localizedContentEntries[0];
};
