import { useMemo } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

export const useLocaleFromLangauge = (languageShortCode: string) => {
  const api = useOzmoApiService();
  const { all } = api.Locale.getAll();

  const locale = useMemo(
    () => all.find((locale) => locale.name.split('-')[0] === languageShortCode),
    [all, languageShortCode]
  );

  return locale;
};
