import {
  useState,
  useEffect,
  useCallback,
  MouseEvent,
  ChangeEvent,
} from 'react';
import pushOrRemove from 'services/utils/push-or-remove';
import { getComparator, sortByCompartor } from 'services/utils/sorting-utils';
import { formatAttributeName } from 'services/ozmo-api/utils/format-attribute-name';
import { useDuplicateContentModal } from 'components/duplicate-content-entry-dialog';

import { headCells } from './head-cells';

const formatContentData = (
  content: ContentSearchResult[]
): ContentTableData[] =>
  content.map((ce) => {
    return {
      ...ce,
      attributes: [
        ...ce.devices.map((d) => ({ ...d, name: formatAttributeName(d.name) })),
        ...ce.deviceTypes,
        ...ce.manufacturers,
        ...ce.operatingSystems,
        ...ce.operatingSystemReleases,
        ...ce.operatingSystemVersions,
      ],
    };
  });

const sortData = (
  data: ContentTableData[],
  order: TableOrder,
  orderBy: keyof ContentTableData
) => {
  return sortByCompartor(data, getComparator(order, orderBy));
};

export const useContentTable = (
  content: ContentSearchResult[],
  defaultOrderBy: keyof ContentTableData,
  onSelectedChange: (selected: number[]) => void
) => {
  const [order, setOrder] = useState<TableOrder>('desc');
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [selected, setSelected] = useState<number[]>([]);

  const [tableData, setTableData] = useState<ContentTableData[]>(
    sortData(formatContentData(content), order, orderBy)
  );

  useEffect(() => {
    setTableData(sortData(formatContentData(content), order, orderBy));

    // this is intended, we don't want this to trigger when order or orderby change
    // since that is handled in another useEffect, this just triggers on content
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    setTableData((data) => sortData(data, order, orderBy));
  }, [order, orderBy]);

  useEffect(() => {
    onSelectedChange(selected);
  }, [selected, onSelectedChange]);

  const sortableData = headCells
    .filter((headCell) => headCell.isSortable)
    .map((headCell) => headCell.id);

  const handleSort = useCallback(
    (_: MouseEvent, property: keyof ContentTableData) => {
      if (!sortableData.includes(property)) return;

      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy, sortableData, setOrder, setOrderBy]
  );

  const handleSelectItem = useCallback((itemId: number) => {
    setSelected((currentSelected) =>
      pushOrRemove<number>(currentSelected, itemId)
    );
  }, []);

  const handleSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const itemIds = tableData.map((td) => td.id);
        setSelected(itemIds);
        return;
      }
      setSelected([]);
    },
    [tableData]
  );

  const isSelected = useCallback(
    (itemId: number) => selected.includes(itemId),
    [selected]
  );

  return {
    tableData,
    order,
    orderBy,
    selected,
    handleSelectItem,
    handleSelectAll,
    isSelected,
    handleSort,
  };
};

export const useContentActions = () => {
  const [itemId, handleSetItemId] = useState<number | null>(null);
  const {
    modal: duplicateModal,
    openModal: openDuplicateModal,
    // this is to prevent the modal from making space and LCE calls prior to opening the action menu.
  } = useDuplicateContentModal(itemId);

  return {
    duplicateModal,
    openDuplicateModal,
    handleSetItemId,
  };
};
