import { memo } from 'react';

import { SearchableAttribute } from '../types';

import AttributeResultItem from './attribute-result-item';

const FilterExample = () => {
  const exampleAttribute: SearchableAttribute = {
    // @ts-ignore - hardset the allowed keys; don't feel like adding this as one
    key: 'Example Attributes',
    selectedReturnKey: 'exampleAttributeIds',
    options: [
      {
        id: 0,
        name: 'iPhone 13 Pro',
        chipColor: 'filterChipDisabled',
        chipColorUsed: 'filterChipUsed',
      },
      {
        id: 1,
        name: 'iOS 13',
        chipColor: 'filterChipDisabled',
        chipColorUsed: 'filterChipUsed',
      },
      {
        id: 2,
        name: 'iOS 13.0.1',
        chipColor: 'filterChipDisabled',
        chipColorUsed: 'filterChipUsed',
      },
      {
        id: 3,
        name: 'Verizon',
        chipColor: 'filterChipDisabled',
        chipColorUsed: 'filterChipUsed',
      },
      {
        id: 4,
        name: 'English',
        chipColor: 'filterChipDisabled',
        chipColorUsed: 'filterChipUsed',
      },
      {
        id: 5,
        name: 'Smartphone',
        chipColor: 'filterChipDisabled',
        chipColorUsed: 'filterChipUsed',
      },
      {
        id: 6,
        name: 'iOS',
        chipColor: 'filterChipDisabled',
        chipColorUsed: 'filterChipUsed',
      },
      {
        id: 7,
        name: 'Apple',
        chipColor: 'filterChipDisabled',
        chipColorUsed: 'filterChipUsed',
      },
    ],
    score: 1,
  };

  return <AttributeResultItem attribute={exampleAttribute} />;
};

const memoizedFilterExample = memo(FilterExample);

export default memoizedFilterExample;
