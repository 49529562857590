import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { extractQueryAttributes } from 'components/attribute-selector';
import { useContentEntrySearch } from 'scenes/content-filter-table-sidebar/hooks';
import { useAppToast } from 'contexts/app-toast-context';
import { convertAttributeMapToSearchParams } from 'scenes/content-search/hooks';
import ozmoApi from 'services/ozmo-api';
import { Typography } from '@mui/material';

type ExportReadyAnswer = {
  id: number;
  slug: string;
  title: string;
  space: string;
  contentType: string;
  updatedAt: string;
  attributes: string[];
  status?: string;
  sourceUrl?: string;
};

type SearchResultLocalizedContentData = {
  languageShortCode: string;
  status: LocalizedContentEntryStatus;
  properties?: LocalizedContentEntryProperties;
};

type ContentEntryExportMetadata = {
  lceMetadata: SearchResultLocalizedContentData[];
};

const metadataRequest = async (
  answerId: number
): Promise<ContentEntryExportMetadata> => {
  const lceData: LocalizedContentEntryModel[] = await ozmoApi.LocalizedContentEntry.getAllAsync(
    {
      contentEntryId: answerId,
    }
  );
  // We need the SearchResultLocalizedContentData and list of links
  return {
    lceMetadata: lceData.map(({ languageShortCode, status, properties }) => ({
      languageShortCode,
      status,
      properties,
    })),
  };
};

// Requests and attaches LCE statuses to each search result
const withMetadata = async (
  searchResults: ContentSearchResult[]
): Promise<(ContentSearchResult & ContentEntryExportMetadata)[]> => {
  const CHUNK_SIZE = 25;
  const data: (ContentSearchResult & ContentEntryExportMetadata)[] = [];

  const processChunk = async (resultsChunk: ContentSearchResult[]) => {
    const metadata: ContentEntryExportMetadata[] = await Promise.all(
      resultsChunk.map(({ id }) => metadataRequest(id))
    );
    const d = resultsChunk.map((item, index) => ({
      ...item,
      ...metadata[index],
    }));
    return d;
  };

  // Split searchResults into chunks and process sequentially so we don't hit the concurrent connections limit
  for (let i = 0; i < searchResults.length; i += CHUNK_SIZE) {
    const chunk = searchResults.slice(i, i + CHUNK_SIZE);
    const processedChunk = await processChunk(chunk);
    data.push(...processedChunk);
  }

  return data;
};

export const filterData = (values: string[][]): string[][] | undefined => {
  // Return if there are no values to filter
  if (values.length <= 1) return;

  // Filter out rows that have no status, this means they do not exist in the language
  // Find the column index of the status
  const statusIndex = values[0].indexOf('Publish Status');

  // Filter out rows that have no status
  const filteredValues = values.filter((row) => row[statusIndex] !== '');

  return (filteredValues.length > 1 && filteredValues) || undefined;
};

export const getSourceUrl = (
  contentType: string,
  properties?: LocalizedContentEntryProperties
) => {
  if (contentType === 'externalWebpage') {
    return (properties as ExternalWebpageProperties)?.url;
  }
  return;
};

export const compileCsvContent = async (
  data: (ContentSearchResult & ContentEntryExportMetadata)[],
  language?: LanguageModel
) => {
  const answers: ExportReadyAnswer[] = data.map(
    ({
      id,
      topic,
      title,
      space,
      contentType,
      updatedAt,
      devices,
      manufacturers,
      operatingSystems,
      operatingSystemReleases,
      operatingSystemVersions,
      lceMetadata,
    }) => {
      const attributes = [
        ...devices.map(({ name }) => name),
        ...manufacturers.map(({ name }) => name),
        ...operatingSystems.map(({ name }) => name),
        ...operatingSystemReleases.map(({ name }) => name),
        ...operatingSystemVersions.map(({ name }) => name),
      ];
      const { properties, status } =
        lceMetadata.find(
          ({ languageShortCode }) => languageShortCode === language?.shortCode
        ) || {};
      return {
        id,
        slug: topic,
        title,
        space,
        contentType,
        updatedAt,
        attributes,
        status,
        sourceUrl:
          getSourceUrl(contentType, properties) ||
          `${window.location.origin}/edit/${id}`,
      };
    }
  );

  const header = [
    'ID',
    'Topic',
    'English title',
    'Space',
    'Content Type',
    'Last Updated Date',
    'Attributes',
    'Publish Status',
    'Source URL',
  ];

  const values = answers.map(
    ({
      id,
      slug,
      title,
      space,
      contentType,
      updatedAt,
      attributes,
      status,
      sourceUrl,
    }) => {
      return [
        `${id}`,
        slug,
        `"${title}"`, // Just in case the title has commas
        space,
        contentType,
        updatedAt,
        `"${attributes.join(',')}"`,
        status || '',
        sourceUrl || '',
      ];
    }
  );

  return filterData([header, ...values]);
};

export const useSearchResultsExport = () => {
  const [isExporting, setIsExporting] = useState(false);
  const [triggerExport, setTriggerExport] = useState(false);
  const [language, setLanguage] = useState<LanguageModel | undefined>();

  const location = useLocation();

  const { handleSearch, isSearching, searchResults } = useContentEntrySearch();
  const dispatchToast = useAppToast();

  const handleError = useCallback(
    (error: any) => {
      const errorMessage =
        (typeof error === 'string' && error) ||
        "Sorry! The export didn't work. Please try again.";
      dispatchToast({
        level: 'error',
        message: <Typography>{errorMessage}</Typography>,
      });
      console.error(error);
      setIsExporting(false);
    },
    [dispatchToast]
  );

  const downloadFile = (data: string[][], languageShortCode?: string) => {
    // Make the CSV string
    const csv = data.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.download = `Search results - ${languageShortCode} - ${Date.now()}.csv`;
    link.href = url;
    link.click();

    URL.revokeObjectURL(url);
  };

  const exportSearchResults = async (language: LanguageModel) => {
    try {
      setIsExporting(true);
      setLanguage(language);

      const attributes = extractQueryAttributes(location);
      const searchFilters = convertAttributeMapToSearchParams(attributes);

      await handleSearch(searchFilters, 1000);
      // This will trigger the useEffect below to process and download the CSV file
      setTriggerExport(true);
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        // This ensures that the script runs only when the export button is clicked.
        if (!isSearching && triggerExport) {
          setTriggerExport(false);

          const searchResultswithMetadata = await withMetadata(searchResults);

          const data = await compileCsvContent(
            searchResultswithMetadata,
            language
          );

          if (data) {
            downloadFile(data, language?.shortCode);
          } else {
            handleError(
              // if content entries in the search results have no status, they do not exist in the language and thus should not be exported
              'Sorry! There are no answers to export for the selected language.'
            );
          }

          setIsExporting(false);
        }
      } catch (e) {
        handleError(e);
      }
    })();
  }, [searchResults, isSearching, handleError, triggerExport, language]);

  return {
    exportSearchResults,
    isExporting,
  };
};
