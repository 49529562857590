import { TableBody, TableRow, TableCell, styled } from '@mui/material';
import { FCWithChildren } from 'types/fc-with-children';

const StyledTableRow = styled(TableRow)`
  /* provides height to center the row content within the table container */
  /* it is based on the height given to table container, which is 550px */
  height: 475px;
`;

type Props = {
  colSpan: number;
};

const PlaceholderTableBody: FCWithChildren<Props> = ({ colSpan, children }) => (
  <TableBody>
    <StyledTableRow>
      <TableCell colSpan={colSpan} align="center">
        {children}
      </TableCell>
    </StyledTableRow>
  </TableBody>
);

export default PlaceholderTableBody;
