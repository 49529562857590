import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';
interface ResourcePathVariables {
  id?: number;
}
class Locale extends OzmoApiBase<
  LocaleModel,
  void,
  void,
  ResourcePathVariables
>() {
  protected static resourcePath = 'config/locales/:id';
  protected static embedOptions = [];
  protected static defaultReactQueryConfig = {
    staleTime: 300000, // 5 minutes
  };

  // Make this model read only- don't allow mutating or creating anything on the server
  // This will disable the update/create/delete methods
  protected static readonly = true;

  public getFromLanguage = (languageId: number): LocaleModel | undefined => {
    if (this.isLoading || this.all.length > 0) {
      return this.all.find((l) => l.languageId === languageId);
    }
    throw new Error('You must call Locale.getAll() to use this method');
  };

  public getByName = (name: string): LocaleModel | undefined => {
    if (this.isLoading || this.all.length > 0) {
      return this.all.find((locale) => locale.name === name);
    }

    throw new Error('You must call Locale.getAll() to use this method');
  };

  static getByNameAsync = (name: string): Promise<LocaleModel> =>
    new Promise(async (resolve, reject) => {
      const locales = await Locale.getAllAsync();
      const locale = locales.find((l) => l.name === name);
      if (locale) resolve(locale);
      else reject(`No locale found with name "${name}"`);
    });
}

export default Locale;
