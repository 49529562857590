/* eslint-disable react/no-multi-comp */
import {
  PropsWithChildren,
  useRef,
  forwardRef,
  createContext,
  useContext,
  useEffect,
  cloneElement,
  ReactChild,
  ReactFragment,
  ReactPortal,
  Children,
  isValidElement,
} from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import { VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 16; // px

type Props = {
  data: any;
  index: number;
  style: Record<string, any>;
};

const renderRow = (props: Props) => {
  const { data, index, style } = props;
  return cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
};

const OuterElementContext = createContext({});
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

OuterElementType.displayName = 'OuterElementType';

const InnerElementType = forwardRef<HTMLUListElement, Props>(
  ({ style, ...rest }, ref) => (
    <ul style={{ ...style, width: 'calc(100% - 40px)' }} ref={ref} {...rest} />
  )
);
InnerElementType.displayName = 'InnerElementType';

function useResetCache(data: any) {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref?.current) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

/**
 * A virtualized Listbox container component for use in the Autocomplete
 *
 * This is _significantly_ more efficient for lists with more than ~100 options
 *
 * Under the covers this is using react-window for virtualization, and is based on
 * the example provided by Material UI's docs here: https://v4.mui.com/components/autocomplete/#virtualization
 */
const ListboxComponent = forwardRef<HTMLDivElement, PropsWithChildren<{}>>(
  (props, ref) => {
    const { children, ...other } = props;
    const itemData = Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 48;

    const getChildSize = (child: ReactChild | ReactFragment | ReactPortal) => {
      if (isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType={InnerElementType}
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);
ListboxComponent.displayName = 'ListboxComponent';
export default ListboxComponent;
