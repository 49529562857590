import { FC } from 'react';
import styled from 'styled-components';
import { bool, func, number } from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { ContentTypes } from 'types/enums';
import { ContentEntryTableSidebar } from 'scenes/content-filter-table-sidebar';
import LoadingButton from 'components/loading-button';

import { useExistingContent } from './hooks';

type Props = {
  open: boolean;
  handleClose: () => void;
  onAdd?: (addedContentEntryIds: number[]) => Promise<boolean>;
  collectionId: number;
  collectionName: string;
};

const StyledDialog = styled(Dialog)`
  // target the actual modal div
  .MuiPaper-root {
    max-width: 95%;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 25px;
  left: calc(100% - 4rem);
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding: 2rem;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 1rem 2rem;
  /**
       Browser window
   |--------------------|
   |    32px margin     |
   |  ________________  |
   | |  dialog title  | |
   | |----------------| |
   | |                | |
   | | dialog content | |
   | |                | |
   | |----------------| |
   | | dialog actions | |
   |  ----------------  |
   |     32px margin    |
    --------------------
    266 = dialog title + dialog actions + margin top + margin bottom
  */
  height: calc(100vh - 266px);
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 1rem 2rem 2rem 2rem;
`;

const PROHIBITED_FILTER_CONTENT_TYPES = [ContentTypes.COLLECTION];

const AddExistingContentModal: FC<Props> = ({
  open,
  handleClose,
  onAdd,
  collectionId,
  collectionName,
}) => {
  const {
    handleAddContent,
    handleSelectedContentChange,
    addContentButtonIsDisabled,
    isSaving,
  } = useExistingContent(collectionId, handleClose, onAdd);

  return (
    <StyledDialog
      fullWidth
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      aria-labelledby="add-existing-content-title"
    >
      <StyledIconButton onClick={handleClose} size="small">
        <Close />
      </StyledIconButton>
      <StyledDialogTitle id="add-existing-content-title">
        <Typography variant="h3" component="span">
          {'Add content to your collection'}
        </Typography>
      </StyledDialogTitle>
      <StyledDialogContent>
        <ContentEntryTableSidebar
          onSelectedContentChanged={handleSelectedContentChange}
          prohibitedFilterContentTypes={PROHIBITED_FILTER_CONTENT_TYPES}
          openEditInNewTab={true}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={handleClose} color="primary" variant="text">
          {'Cancel'}
        </Button>
        <LoadingButton
          onClick={handleAddContent}
          color="primary"
          variant="contained"
          disabled={addContentButtonIsDisabled || isSaving}
          isLoading={isSaving}
        >
          {isSaving ? 'Adding content' : 'Add content'}
        </LoadingButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

AddExistingContentModal.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  collectionId: number.isRequired,
};

export default AddExistingContentModal;
