import styled from 'styled-components';

// Constant sizes for Hotspots
const HS_SIZE = 30;
const HS_CENTER = Math.round(HS_SIZE / 2);
const HS_R1 = 15;
const HS_R2 = HS_R1 - 1.5;

const HotspotSVG = styled.svg`
  position: absolute;
  box-shadow: var(--shadow-z3);
  border-radius: 50%;

  & > circle:first-of-type {
    stroke: var(--color-neutral-one);
    stroke-width: 5px;
    fill: none;
  }

  & > circle:nth-of-type(2) {
    fill: var(--color-indicator-inner);
    opacity: 0.91;
  }

  @media print {
    opacity: 1 !important;
  }
` as any;

export const Hotspot = (props: HotspotIndicator) => {
  const { scale = 1 } = props;

  return (
    <HotspotSVG
      focusable={false}
      style={{ top: `${props.y}%`, left: `${props.x}%` }}
      height={HS_SIZE * scale}
      width={HS_SIZE * scale}
      viewBox={`0 0 ${HS_SIZE} ${HS_SIZE}`}
      {...props}
    >
      <circle cx={HS_CENTER} cy={HS_CENTER} r={HS_R1} />
      <circle cx={HS_CENTER} cy={HS_CENTER} r={HS_R2} />
    </HotspotSVG>
  );
};
