import { FCWithChildren } from 'types/fc-with-children';
import { styled, Box } from '@mui/material';
import ContentSearch from 'scenes/content-search';
import { TableWrapper } from 'components/content-entry-side-panel';
import { AllowedAttributeKeys } from 'components/attribute-selector';

const StyledTableWrapper = styled(TableWrapper)`
  margin-top: 1rem;
  /* 100% - height of the search bar container + margin-top */
  height: calc(100% - 136px);
`;

type Props = {
  isPanelOpen: boolean;
  panelItemId: number | null;
  probibitedAttributes?: AllowedAttributeKeys[];
  prohibitedFilterContentTypes?: ContentEntryContentType[];
  syncSearchAttributesWithQueryParams?: boolean;
  handleFilterResultsToggle: (searchOpen: boolean) => void;
  handleSearch: (
    searchParams: ContentEntrySearchParams,
    searchResultLimit?: number | undefined
  ) => Promise<void>;
};

const ContentFilterTableSidebar: FCWithChildren<Props> = ({
  isPanelOpen,
  panelItemId,
  probibitedAttributes,
  prohibitedFilterContentTypes,
  syncSearchAttributesWithQueryParams = false,
  handleFilterResultsToggle,
  handleSearch,
  children,
}) => (
  <Box height="100%">
    <ContentSearch
      prohibitedContentTypes={prohibitedFilterContentTypes}
      prohibitedAttributes={probibitedAttributes}
      onSearch={handleSearch}
      onAttributeResultsToggle={handleFilterResultsToggle}
      syncWithQueryParams={syncSearchAttributesWithQueryParams}
    />
    <StyledTableWrapper
      className="table-wrapper"
      isPanelOpen={isPanelOpen}
      contentEntryId={panelItemId}
    >
      {children}
    </StyledTableWrapper>
  </Box>
);

export default ContentFilterTableSidebar;
