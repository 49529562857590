import { useMemo } from 'react';
import Fuse from 'fuse.js';
import { FilterOptionsState } from '@mui/material';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

const MIN_MATCH_LENGTH = 3;

export const useTopicSelector = () => {
  const api = useOzmoApiService();
  const { all: options, isLoading } = api.Topic.getAll(undefined, undefined, {
    perPage: 500,
    allPages: true,
  });

  const fuse = useMemo(() => {
    const searchKeys = ['title', 'slug'];
    const fuseIndex = Fuse.createIndex(searchKeys, options);

    return new Fuse(
      options,
      {
        keys: searchKeys,
        minMatchCharLength: MIN_MATCH_LENGTH,
        shouldSort: true,
      },
      fuseIndex
    );
  }, [options]);

  const filterOptions = (
    options: TopicModel[],
    { inputValue }: FilterOptionsState<TopicModel>
  ) => {
    if (inputValue.length >= MIN_MATCH_LENGTH) {
      return fuse
        .search(inputValue)
        .slice(0, 50)
        .map((fuseResult) => fuseResult.item);
    }

    // return default sample list without any filter
    return options.slice(0, 50);
  };

  return {
    options,
    isLoading,
    filterOptions,
  };
};
