import { FC, memo, useCallback } from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { ContentCopy, Link } from '@mui/icons-material';
import { PermissionRequired } from 'components/permission-required';
import RowActionsMenu from 'components/content-table-row-action-menu';
import { ContentTypes } from 'types/enums';
import { useCopyLink } from 'services/hooks/use-copy-link';

import EditMenuItem from './edit-menu-item';

type Props = {
  rowId: number;
  contentType: ContentEntryContentType;
  handleSetItemId: (itemId: number) => void;
  openDuplicateModal: () => void;
};

const RowActions: FC<Props> = ({
  rowId,
  contentType,
  handleSetItemId,
  openDuplicateModal,
}) => {
  const isCollectionType = contentType === ContentTypes.COLLECTION;
  const stableSetItemId = useCallback(() => handleSetItemId(rowId), [
    rowId,
    handleSetItemId,
  ]);

  const [onClick] = useCopyLink(
    isCollectionType ? 'collection' : 'answer',
    rowId,
    undefined,
    true
  );

  return (
    <RowActionsMenu onMenuOpen={stableSetItemId}>
      {/* edit */}
      <EditMenuItem contentType={contentType} rowId={rowId} />
      {/* duplicate: only for collections */}
      {isCollectionType && (
        <PermissionRequired permission="edit_collection" disableElement>
          <MenuItem onClick={openDuplicateModal}>
            <ListItemIcon>{<ContentCopy fontSize="small" />}</ListItemIcon>
            <ListItemText>{'Duplicate'}</ListItemText>
          </MenuItem>
        </PermissionRequired>
      )}
      <MenuItem onClick={onClick as any}>
        <ListItemIcon>
          <Link fontSize="small" />
        </ListItemIcon>
        <ListItemText>{'Copy link'}</ListItemText>
      </MenuItem>
    </RowActionsMenu>
  );
};

const memoizedRowActions = memo(RowActions);

export default memoizedRowActions;
