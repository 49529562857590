export const getAttributeCount = (contentEntry: ContentEntryModel) => {
  return (
    (contentEntry?.devices?.length ?? 0) +
    (contentEntry?.deviceTypes?.length ?? 0) +
    (contentEntry?.manufacturers?.length ?? 0) +
    (contentEntry?.operatingSystems?.length ?? 0) +
    (contentEntry?.operatingSystemReleases?.length ?? 0) +
    (contentEntry?.operatingSystemVersions?.length ?? 0)
  );
};
