import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from '@mui/material';

interface IProps {
  message?: string | JSX.Element;
  isError?: boolean;
}

const StyledOverlay = styled.div<IProps>`
  border-radius: 6px;
  border: dashed 2px
    ${({ isError }) =>
      isError ? 'var(--color-messaging-error)' : 'var(--color-primary-base)'};
  background-color: ${({ isError }) =>
    isError ? 'rgba(248, 238, 238, 0.93)' : 'rgba(224, 237, 248, 0.85)'};
  z-index: 100;
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DroppableAreaOverlay = ({ isError, message }: IProps) => {
  return (
    <StyledOverlay data-testid="droppable-area-overlay:div" isError={isError}>
      {message && (
        <Typography
          variant="h4"
          color={isError ? 'error' : 'textPrimary'}
          align="center"
        >
          {message}
        </Typography>
      )}
    </StyledOverlay>
  );
};

DroppableAreaOverlay.propTypes = {
  isError: PropTypes.bool,
  message: PropTypes.any,
};

export default DroppableAreaOverlay;
