import { useState, cloneElement, FC, ReactElement } from 'react';

import TabPanel from './tab-panel';

type Props = {
  header?: ReactElement;
  tabLabels: (string | JSX.Element)[];
  children: ReactElement[];
  defaultTab?: number;
  className?: string;
};

const TabbedContent: FC<Props> = ({
  header,
  tabLabels,
  className,
  children,
  defaultTab = 0,
}) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);

  if (currentTab >= children.length) {
    return <div>{'Error: Current tab is greater than number of children'}</div>;
  }

  if (tabLabels.length !== children.length) {
    return <div>{'Error: not the same number of tabs as children.'}</div>;
  }

  return (
    <>
      {header && cloneElement(header, { currentTab, setCurrentTab, tabLabels })}
      {children.map((child, index) => (
        <TabPanel
          key={index}
          isActive={index === currentTab}
          className={className}
        >
          {child}
        </TabPanel>
      ))}
    </>
  );
};

export default TabbedContent;
