import { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import TagCloud from 'components/tag-cloud';
import { formatAttributeName as formatAttributeNameUtil } from 'services/ozmo-api/utils/format-attribute-name';

type Props = {
  devices?: DeviceModel[];
  deviceTypes?: DeviceTypeModel[];
  manufacturers?: ManufacturerModel[];
  operatingSystems?: OperatingSystemModel[];
  operatingSystemReleases?: OperatingSystemReleaseModel[];
  operatingSystemVersions?: OperatingSystemVersionModel[];
  disabled?: boolean;
  limitTags?: number;
  showRemovedTagCount?: boolean;
};

type AttributesWithNameFields =
  | DeviceTypeModel
  | ManufacturerModel
  | OperatingSystemModel
  | OperatingSystemReleaseModel
  | OperatingSystemVersionModel;

const AttributesTagCloud: FunctionComponent<Props> = ({
  devices = [],
  deviceTypes = [],
  manufacturers = [],
  operatingSystems = [],
  operatingSystemReleases = [],
  operatingSystemVersions = [],
  disabled,
  limitTags,
  showRemovedTagCount,
}) => {
  const formatAttributeName = (
    attribute: Attribute,
    attributeType: string
  ): string => {
    switch (attributeType) {
      case 'device': {
        const a = attribute as DeviceModel;
        return formatAttributeNameUtil(`${a.manufacturer} ${a.trackingName}`);
      }
      case 'operatingSystemRelease': {
        const a = attribute as OperatingSystemReleaseModel;
        return formatAttributeNameUtil(`${a.operatingSystemName} ${a.name}`);
      }
      case 'operatingSystemVersion': {
        const a = attribute as OperatingSystemVersionModel;
        return formatAttributeNameUtil(`${a.operatingSystem} ${a.name}`);
      }
      default: {
        return formatAttributeNameUtil(
          (attribute as AttributesWithNameFields).name
        );
      }
    }
  };

  const getTagsFromAttribute = (
    attributes: Attribute[],
    attributeType: string
  ) => {
    return Array.isArray(attributes) && attributes?.length > 0
      ? attributes.map((attribute) => ({
          name: formatAttributeName(attribute, attributeType),
          id: attribute.id,
          type: attributeType,
          disabled,
        }))
      : [];
  };

  const tagAttributes = [
    ...getTagsFromAttribute(devices, 'device'),
    ...getTagsFromAttribute(deviceTypes, 'deviceType'),
    ...getTagsFromAttribute(manufacturers, 'manufacturer'),
    ...getTagsFromAttribute(operatingSystems, 'operatingSystem'),
    ...getTagsFromAttribute(operatingSystemReleases, 'operatingSystemRelease'),
    ...getTagsFromAttribute(operatingSystemVersions, 'operatingSystemVersion'),
  ];

  if (tagAttributes.length <= 0) {
    return (
      <Typography>{'This content currently has no attributes.'}</Typography>
    );
  }

  return (
    <TagCloud
      tags={tagAttributes}
      limitTags={limitTags}
      showRemovedCount={showRemovedTagCount}
    />
  );
};

AttributesTagCloud.propTypes = {
  disabled: PropTypes.bool,
};

export default AttributesTagCloud;
