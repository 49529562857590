import { FC } from 'react';
import { Skeleton } from '@mui/material';
import AttributesTagCloud from 'components/tag-cloud/attributes-tag-cloud';
import { useContentEntry } from 'services/hooks/api-hooks';

type Props = {
  contentEntryId: number;
  disabled?: boolean;
};

const AttributeTagList: FC<Props> = ({ contentEntryId, disabled = true }) => {
  const { contentEntry, isLoading } = useContentEntry(contentEntryId);

  return (
    <div>
      {isLoading ? (
        <Skeleton data-testid="attributes-tag-list-skeleton" variant="text" />
      ) : (
        <AttributesTagCloud
          disabled={disabled}
          deviceTypes={contentEntry?.deviceTypes ?? []}
          devices={contentEntry?.devices ?? []}
          manufacturers={contentEntry?.manufacturers ?? []}
          operatingSystems={contentEntry?.operatingSystems ?? []}
          operatingSystemReleases={contentEntry?.operatingSystemReleases ?? []}
          operatingSystemVersions={contentEntry?.operatingSystemVersions ?? []}
        />
      )}
    </div>
  );
};

export default AttributeTagList;
