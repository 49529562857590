import { FC } from 'react';
import styled from 'styled-components';

import ArrowSvg from './arrow-svg';

const StyledArrowWrapper = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  transform-origin: center;
  pointer-events: none;
`;

type Props = {
  x: number;
  y: number;
  angle: number;
};

const DirectionalArrow: FC<Props> = ({ x, y, angle }) => (
  <StyledArrowWrapper
    data-testid="directional-arrow:wrapper"
    style={{
      top: `${y}%`,
      left: `${x}%`,
      transform: `translate(-50%, -50%) rotate(${angle}deg)`,
    }}
  >
    <ArrowSvg />
  </StyledArrowWrapper>
);

export default DirectionalArrow;
