import { useCallback, useMemo } from 'react';
import { useAppToast } from 'contexts/app-toast-context';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { containsIncompleteContent } from 'services/collections/utils';
import { useCatchStaleWriteError } from 'services/ozmo-api/utils/use-catch-stale-write-error';

import { useCategoryRedirect } from '../hooks';

export const useCategoryPanel = (
  insertCategory: (
    category: LocalizedCollectionCategory,
    atIndex?: number
  ) => Promise<boolean>
) => {
  const dispatchToast = useAppToast();
  const addCategory = useCallback(
    async ({
      title,
      description,
    }: Omit<LocalizedCollectionCategory, 'items'>) => {
      const inserted = await insertCategory({
        title,
        description,
        items: [],
      });

      if (inserted) {
        dispatchToast({
          level: 'success',
          message: `"${title}" was created successfully.`,
        });
      } else {
        dispatchToast({
          level: 'error',
          message:
            'Something went wrong and your category may not have been created.',
        });
      }

      return inserted;
    },
    [insertCategory, dispatchToast]
  );

  return { addCategory };
};

export const useCategories = (
  localizedCollection: LocalizedCollectionModel,
  selectedCategoryIndex: number,
  onRemoveCategory: (atIndex: number) => Promise<boolean>,
  onUpdateCategory: (
    category: Omit<LocalizedCollectionCategory, 'items'>,
    atIndex: number
  ) => Promise<boolean>
) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();
  const { catchStaleWrite } = useCatchStaleWriteError();
  const navigateToCollectionIndex = useCategoryRedirect(localizedCollection);
  const categories = useMemo(
    () => api.LocalizedCollection.getCategories(localizedCollection),
    [api.LocalizedCollection, localizedCollection]
  );

  const uncategorizedAnswers = api.LocalizedCollection.getUncategorizedItems(
    localizedCollection
  );
  const answerCount = useMemo(() => {
    return (
      categories.reduce(
        (numAnswers, category) => numAnswers + category.items.length,
        0
      ) + uncategorizedAnswers.length
    );
  }, [categories, uncategorizedAnswers]);

  const collectionContainsIncompleteContent = useMemo(() => {
    const uncategorizedAnswersContainsIncomplete = containsIncompleteContent(
      uncategorizedAnswers
    );
    const categoriesContainIncompleteContent = categories
      .map((cat) => containsIncompleteContent(cat.items))
      .some((cat) => cat);
    return (
      uncategorizedAnswersContainsIncomplete ||
      categoriesContainIncompleteContent
    );
  }, [uncategorizedAnswers, categories]);

  const handleSelectCategory = useCallback(
    (categoryIndex: number) => {
      navigateToCollectionIndex(categoryIndex);
    },
    [navigateToCollectionIndex]
  );

  const handleSelectAllAnswers = useCallback(() => {
    // if no category title is set, then assume all answers is selected
    navigateToCollectionIndex();
  }, [navigateToCollectionIndex]);

  const handleRemoveCategory = useCallback(
    async (atIndex: number) => {
      const success = await onRemoveCategory(atIndex).catch(
        catchStaleWrite(
          `We were unable to remove the category "${categories[atIndex].title}" from the collection`
        )
      );

      if (success) {
        dispatchToast({
          level: 'success',
          message: 'Your category was successfully removed.',
        });

        // if the removed category happens to be the selected category, reset to all answers
        if (selectedCategoryIndex === atIndex) {
          navigateToCollectionIndex();
        }
      }
    },
    [
      onRemoveCategory,
      dispatchToast,
      selectedCategoryIndex,
      navigateToCollectionIndex,
      catchStaleWrite,
      categories,
    ]
  );

  const handleEditCategory = useCallback(
    (atIndex: number) => {
      return async (category: Omit<LocalizedCollectionCategory, 'items'>) => {
        const success = await onUpdateCategory(category, atIndex);

        if (success) {
          dispatchToast({
            level: 'success',
            message: 'Your category was successfully updated.',
          });
        } else {
          dispatchToast({
            level: 'error',
            message:
              'Something went wrong and your category may not have been updated.',
          });
        }
        return success;
      };
    },
    [dispatchToast, onUpdateCategory]
  );

  return {
    categories,
    answerCount,
    collectionContainsIncompleteContent,
    handleSelectCategory,
    handleSelectAllAnswers,
    handleRemoveCategory,
    handleEditCategory,
  };
};
