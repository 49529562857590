const STROKE_COLOR = '#ffffff';
const STROKE_WIDTH = 0.25;
const CROSSHAIR_COLOR = '#005499';
const CARAT_COLOR = '#1bd6b866';

// This is written with the expectation that it will be included inside of an SVG, so it doesn’t
// have it’s own SVG container.
export const Crosshairs = () => {
  return (
    <g>
      {/* Children of a group inherit attributes attached to the group, but can be overridden
          locally if needed */}
      <g
        transform="translate(-13.5,-13)"
        stroke={STROKE_COLOR}
        strokeWidth={STROKE_WIDTH}
        fill={CROSSHAIR_COLOR}
      >
        {/* Cross */}
        <polygon points="55, 62, 55, 65, 62, 65, 62, 71, 65, 71, 65, 65, 71, 65, 71, 62, 65, 62, 65, 55, 62, 55, 62, 62, 55, 62" />
        {/* Arrow Caps */}
        <polygon points="53, 69, 53, 59.5, 49, 63" />
        <polygon points="73, 69, 73, 59.5, 77, 63" />
        <polygon points="59, 53, 68, 53, 63.5, 49" />
        <polygon points="59, 73, 68, 73, 63.5, 77" />
      </g>
      {/* Bottom Point */}
      <polygon
        transform="translate(-13,-11)"
        points="59, 108, 67, 108, 63, 112"
        fill={CARAT_COLOR}
      />
    </g>
  );
};
