import {
  useState,
  useMemo,
  useCallback,
  FC,
  SetStateAction,
  Dispatch,
} from 'react';
import BaseModal from 'components/modals';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ContentTypes } from 'types/enums';
import { useFlag } from 'services/flags';

import AddContentModalBody, { Props } from './modal-body';
import { AnswerExistsError } from './error';
import { useContentEntryValidity } from './hooks';

type AdditionalProps = {
  contentTypeBlocklist?: string[];
  contentTypeAllowlist?: string[];
  isOpen: boolean;
  isSaving: boolean;
  formState: Props;
  setFormState: Dispatch<SetStateAction<Props>>;
  onSave: (results: any) => Promise<AnswerExistsError | boolean>;
  onClose: () => void;
};

const AddContentModal: FC<Props & AdditionalProps> = ({
  contentTypeBlocklist = [],
  contentTypeAllowlist,
  isOpen = false,
  isSaving = false,
  formState,
  attributes,
  setFormState,
  onSave,
  onClose,
}) => {
  const [answerExistsError, setAnswerExistsError] = useState<
    AnswerExistsError | undefined
  >();
  const useCollections2 = useFlag('useCollections2', false);
  const isValid = useContentEntryValidity(formState, useCollections2);
  const setFormItem = useCallback(
    (fieldName: keyof typeof formState, value: any) => {
      setFormState((formState) => ({ ...formState, [fieldName]: value }));
      setAnswerExistsError(undefined);
    },
    [setFormState]
  );

  const doSave = useCallback(async () => {
    // if we have an existing answer, invoke the save callback with that id instead
    if (answerExistsError) {
      setAnswerExistsError(undefined);
      return onSave(answerExistsError.existingAnswerId);
    }

    const successfullyAdded = await onSave(formState);
    if (typeof successfullyAdded === 'boolean') {
      return setAnswerExistsError(undefined);
    }

    setAnswerExistsError(successfullyAdded);
  }, [onSave, formState, answerExistsError, setAnswerExistsError]);

  const doClose = useCallback(() => {
    setAnswerExistsError(undefined);
    onClose();
  }, [onClose]);

  const allowCollections = useMemo(() => {
    const allowed =
      !contentTypeAllowlist ||
      contentTypeAllowlist.includes(ContentTypes.COLLECTION);
    const blocked = contentTypeBlocklist.includes(ContentTypes.COLLECTION);

    return allowed && !blocked;
  }, [contentTypeAllowlist, contentTypeBlocklist]);

  const buttonText = useMemo(() => {
    if (isSaving) {
      return null;
    }

    if (answerExistsError) {
      // if collections are not allowed, we're in a collection
      if (!allowCollections) {
        return 'Use existing answer for this collection';
      }

      return 'Cannot create';
    }

    return 'Create content';
  }, [isSaving, answerExistsError, allowCollections]);

  return (
    <BaseModal
      fullWidth
      maxWidth="lg"
      open={isOpen}
      title="Add new content"
      onClose={doClose}
      actionChildren={
        <>
          <Button onClick={doClose}>{'Cancel'}</Button>
          <LoadingButton
            variant="contained"
            disabled={
              !isValid ||
              isSaving ||
              (Boolean(answerExistsError) && allowCollections)
            }
            loading={isSaving}
            onClick={doSave}
          >
            {buttonText}
          </LoadingButton>
        </>
      }
    >
      <AddContentModalBody
        {...formState}
        setFormItem={setFormItem}
        contentTypeAllowlist={contentTypeAllowlist}
        contentTypeBlocklist={contentTypeBlocklist}
        answerExistsError={answerExistsError}
        attributes={attributes}
      />
    </BaseModal>
  );
};

export default AddContentModal;
