import { FC } from 'react';
import styled from 'styled-components';
import {
  Typography,
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
} from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DragAndDropAreaContainer from 'components/drag-and-drop';

import { FileWithValidation } from './hooks';

type Props = {
  uploadedFiles: FileWithValidation[];
  onDrop: (files: File | FileList) => void;
};

const StyledList = styled(List)`
  width: 75%;
`;

export const ImportModalContent: FC<Props> = ({ uploadedFiles, onDrop }) => {
  return (
    <>
      <Typography
        data-testid="import-translation-disclaimer"
        sx={{ paddingBottom: '2rem' }}
      >
        {'Imported text translations will overwrite any '}
        <b>{'unpublished content text '}</b>
        {'in this collection. Already published content will not be updated.'}
      </Typography>
      <DragAndDropAreaContainer
        onDrop={onDrop}
        onError={() => {}}
        allowBulk={true}
        withFileSelector={true}
        acceptedType="application/json; charset=utf-8"
      />
      <StyledList>
        {uploadedFiles.map(({ file, isValid }, index) => {
          if (isValid) {
            return (
              <ListItem
                key={`${file.name}-${index}`}
                secondaryAction={<CheckCircleIcon color="success" />}
              >
                <ListItemAvatar>
                  <InsertDriveFileIcon color="success" />
                </ListItemAvatar>
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{
                    color: 'var(--color-messaging-success)',
                  }}
                />
              </ListItem>
            );
          }

          return (
            <ListItem
              key={`${file.name}-${index}`}
              secondaryAction={<ErrorIcon color="error" />}
            >
              <ListItemAvatar>
                <InsertDriveFileIcon color="error" />
              </ListItemAvatar>
              <ListItemText
                primary={file.name}
                secondary={`(${file.type}) is currently not supported. Please upload JSON.`}
                primaryTypographyProps={{
                  color: 'var(--color-messaging-error)',
                }}
                secondaryTypographyProps={{
                  color: 'var(--color-messaging-error)',
                }}
              />
            </ListItem>
          );
        })}
      </StyledList>
    </>
  );
};
