export const getStatusDisplayInfo = (
  status: LocalizedContentEntryStatus | undefined
) => {
  switch (status) {
    case 'published':
      return {
        text: 'Published',
        className: 'published',
        color: 'var(--color-messaging-success)',
      };
    case 'published_with_draft':
      return {
        text: 'Published with updated draft',
        className: 'published-with-draft',
        color: 'var(--color-primary-base)',
      };
    default:
      // will catch the draft state
      return {
        text: 'Draft',
        className: 'draft',
        color: 'var(--color-messaging-warning)',
      };
  }
};
