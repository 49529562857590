import { useEffect, useState, FunctionComponent } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import useOzmoApi from 'services/utils/use-ozmo-api';

type ServiceStatus = {
  name: string;
  status: string;
  detail: string;
};

type HeathCheck = {
  services: ServiceStatus[];
};

const HealthCheck: FunctionComponent = () => {
  const { get, isLoading, result, error } = useOzmoApi();
  const [recheck, setRecheck] = useState<boolean>(false);

  useEffect(() => {
    get('healthcheck');
  }, [get, recheck]);

  const databaseConnectionStatus = (
    ((result as unknown) as HeathCheck)?.services || []
  ).find((s) => s.name === 'database');

  return (
    <>
      <Grid container id="studio-check">
        <Grid item xs={6}>
          {`Ozmo Studio`}
        </Grid>
        <Grid item>
          <Typography
            data-testid="studio-check-status"
            id="studio-check-status"
          >
            {'OK'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container id="api-check">
        <Grid item xs={6}>
          {`Ozmo API Connection (${import.meta.env.VITE_APP_OZMO_API_URL}) `}
        </Grid>
        <Grid item>
          {isLoading ? (
            'Checking...'
          ) : (
            <Typography
              color={error ? 'error' : 'inherit'}
              data-testid="api-check-status"
              id="api-check-status"
            >
              {error ? JSON.stringify(error) : 'OK'}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container id="database-check">
        <Grid item xs={6}>
          {`Ozmo Database Connection`}
        </Grid>
        <Grid item>
          {isLoading ? (
            'Checking...'
          ) : (
            <Typography
              color={error ? 'error' : 'inherit'}
              data-testid="database-check-status"
              id="database-check-status"
            >
              {databaseConnectionStatus?.status !== 'ok'
                ? JSON.stringify(
                    databaseConnectionStatus?.detail || 'Unknown issue'
                  )
                : 'OK'}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Button onClick={() => setRecheck(!recheck)}>{'Recheck'}</Button>
    </>
  );
};

export default HealthCheck;
