export const isLocalizedCollectionCategory = (
  referenceOrCategory:
    | LocalizedCollectionCategory
    | LocalizedCollectionReference
    | MissingLocalizedCollectionReference
): referenceOrCategory is LocalizedCollectionCategory => {
  return (
    (referenceOrCategory as LocalizedCollectionCategory).items !== undefined
  );
};

export const isMissingCollectionReference = (
  referenceOrCategory:
    | LocalizedCollectionCategory
    | LocalizedCollectionReference
    | MissingLocalizedCollectionReference
): referenceOrCategory is MissingLocalizedCollectionReference => {
  return (
    (referenceOrCategory as MissingLocalizedCollectionReference)
      .localizedContentEntryId === null
  );
};

export const isLocalizedCollectionReference = (
  referenceOrCategory:
    | LocalizedCollectionCategory
    | LocalizedCollectionReference
    | MissingLocalizedCollectionReference
): referenceOrCategory is LocalizedCollectionReference => {
  return (
    (referenceOrCategory as LocalizedCollectionReference)
      .localizedContentEntryId !== null
  );
};
