import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';

import { ozmoApiRequest } from '../ozmo-api';
interface ResourcePathVariables {
  id?: number;
}

interface CreateOrGetDuplicateParams {
  file: File;
  mediaTypeId: number;
  tags?: string[];
  localeIds?: number[];
  statusBarId?: number;
  deviceShellId?: number;
}

type UpdateParams = Omit<
  CreateOrGetDuplicateParams,
  'file' | 'mediaTypeId' | 'statusBarId' | 'deviceShellId'
>;
class MediaEntry extends OzmoApiBase<
  MediaEntryModel,
  MediaEntryUpdateModel,
  MediaEntryCreateModel,
  ResourcePathVariables
>() {
  protected static resourcePath = 'authoring/media_entries/:id';
  protected static embedOptions = ['locales', 'tags'];
  // If you want override config values for react-query for all models
  // add them here- note that options passed to the instances will override these
  // For a full list of config options see the react-query docs: https://react-query.tanstack.com/reference/useQuery
  protected static defaultReactQueryConfig = {
    staleTime: 300000, // 5 minutes
  };

  public static async createOrGetDuplicateAsync(
    params: CreateOrGetDuplicateParams
  ): Promise<MediaEntryModel> {
    const {
      file,
      mediaTypeId,
      deviceShellId,
      statusBarId,
      tags,
      localeIds,
    } = params;
    const formData = new FormData();
    formData.append('data', file);
    formData.append('media_type_id', mediaTypeId.toString());
    deviceShellId &&
      formData.append('device_shell_id', deviceShellId?.toString() ?? '');
    statusBarId &&
      formData.append('status_bar_id', statusBarId?.toString() ?? '');
    tags && tags.map((t) => formData.append('tags[]', t));
    localeIds &&
      localeIds.map((l) => formData.append('locale_ids[]', l.toString()));

    const response = (await ozmoApiRequest(
      'authoring/media_entries/',
      formData,
      'POST',
      true,
      false
    ).catch((e) => e)) as { data: any; response: Response };

    const { data, response: { status } = {} } = response;

    if (status === 200) {
      return data as MediaEntryModel;
    }

    throw new Error(JSON.stringify(data ?? 'An unknown error occurred'));
  }

  public static updateAppendAsync(
    id: number,
    params: UpdateParams
  ): Promise<MediaEntryModel> {
    return new Promise(async (resolve, reject) => {
      try {
        const { tags, localeIds } = params;
        const mediaEntry = await this.getAsync({ id });
        // We need to merge old and new
        const updateData = {
          tags: [
            ...(mediaEntry.tags ?? []).map((t) => t.name),
            ...(tags ?? []),
          ].filter((v, i, a) => a.indexOf(v) === i),
          localeIds: [
            ...(mediaEntry.locales ?? []).map((l) => l.id),
            ...(localeIds ?? []),
          ].filter((v, i, a) => a.indexOf(v) === i),
        };

        const updatedMediaEntry = await this.updateAsync({ id }, updateData);
        resolve(updatedMediaEntry);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default MediaEntry;
