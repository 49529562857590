import { FunctionComponent } from 'react';

import { useCurrentLocalizedContentEntry } from '../hooks/use-current-localized-content-entry';
import {
  generateKeyWithCount,
  generateKeyWithIndex,
  getDefaultForField,
  parseFieldFromSchema,
} from '../utils';

import Reference from './reference';
import SchemaArray from './schema-array';
import SchemaObject from './schema-object';
import SchemaTextField from './schema-text-field';
import StringField from './schema-string-field';
import NumberField from './schema-number-field';
import SchemaMedia, { SchemaMediaProvider } from './schema-media';
import SchemaIndicator from './schema-indicator';

type Props = {
  key?: string;
  schema: ContentTypeSchemaField | ContentTypeSchemaField[];
  type: ContentTypePrimativeField['type'] | '';
  name: string;
  displayName: string;
  displayOrder: number;
  description: string;
  value: any;
  path: string;
  level: number;
  deprecated?: boolean;
  required?: boolean;
  count?: number;
  siblings?: number;
  defaultValue?: DefaultForField | DefaultForField[];
};

const SchemaComponent: FunctionComponent<Props> = ({
  schema,
  type,
  name,
  displayName,
  displayOrder,
  description,
  defaultValue,
  value,
  path,
  level,
  count,
  deprecated = false,
  required = false,
  siblings,
}) => {
  const {
    generateHandleChange,
    generateHandleAddNew,
    generateHandleRemoveItem,
  } = useCurrentLocalizedContentEntry();

  // If the field has been deprecated, don't render it
  if (deprecated) {
    return null;
  }

  switch (type) {
    case 'String':
      return (
        <StringField
          key={path}
          path={path}
          level={level}
          required={required}
          displayName={displayName}
          displayOrder={displayOrder}
          value={value}
          onChange={generateHandleChange(path)}
        />
      );
    case 'Text':
      return (
        <SchemaTextField
          key={path}
          path={path}
          level={level}
          required={required}
          displayName={displayName}
          displayOrder={displayOrder}
          value={value}
          onChange={generateHandleChange(path)}
        />
      );
    case 'Number':
      return (
        <NumberField
          key={path}
          path={path}
          level={level}
          required={required}
          displayName={displayName}
          displayOrder={displayOrder}
          value={value}
          onChange={generateHandleChange(path)}
        />
      );
    case 'Object': {
      let allFields = (schema as ContentTypeObjectField).fields;
      const children = Object.entries(value).reduce(
        (acc, [key, value]) => {
          const {
            type = '',
            name = '',
            required = false,
            default: defaultValue,
            deprecated,
            editor: {
              displayName = '',
              description = '',
              displayOrder = 10000,
            } = {},
          } =
            parseFieldFromSchema(
              (schema as ContentTypeObjectField).fields,
              key
            ) || {};
          // if the path is an empty string this is within the root object
          const subPath = path === '' ? name : `${path}.${name}`;

          // Once a field has been used, remove it from the list of all fields
          // After all fields have been procesed, the remaining fields will be
          // added with their default values
          allFields = allFields.filter((field) => field.name !== name);

          if (type === 'Media') {
            // If the media entry has been removed by the user, but the save has not yet been
            // processed by the server, then the optimistic-updated version of value
            // will have id === undefined
            const { id } = (value as Maybe<Reference>) ?? {};
            // If that is the case, we don't want to include the media
            return id
              ? { ...acc, media: { id, path: subPath, displayOrder } }
              : acc;
          }

          return {
            ...acc,
            components: [
              ...acc.components,
              <SchemaComponent
                key={generateKeyWithCount(subPath, value)}
                schema={(schema as ContentTypeObjectField).fields}
                type={type}
                name={name}
                deprecated={deprecated}
                displayName={displayName}
                displayOrder={displayOrder}
                description={description}
                required={required}
                value={value}
                path={subPath}
                level={level}
                defaultValue={defaultValue}
              />,
            ],
          };
        },
        {
          components: [] as JSX.Element[],
          media: {} as { id: number; path: string; displayOrder: number },
        }
      );

      const defaults = allFields.map((f) => (
        <SchemaComponent
          key={path === '' ? f.name : `${path}.${f.name}`}
          schema={[f]}
          type={f.type}
          name={f.name}
          deprecated={f.deprecated}
          required={f.required}
          description={f.editor.description ?? ''}
          displayName={f.editor.displayName ?? ''}
          displayOrder={f.editor.displayOrder ?? 1000}
          value={getDefaultForField(f)}
          path={path === '' ? f.name : `${path}.${f.name}`}
          level={level}
          defaultValue={f.default}
        />
      ));

      return (
        <SchemaObject
          path={path}
          key={path}
          name={name}
          displayName={displayName}
          displayOrder={displayOrder}
          level={level}
          count={count}
          siblings={siblings}
        >
          {children.media.id ? (
            <SchemaMediaProvider {...children.media}>
              {children.components}
              {defaults}
            </SchemaMediaProvider>
          ) : (
            [...children.components, ...defaults]
          )}
        </SchemaObject>
      );
    }
    case 'Array': {
      const field = parseFieldFromSchema(
        schema as ContentTypeSchemaField[],
        name
      ) as ContentTypeArrayField;
      const subSchema = field.items;

      if (
        subSchema.type === 'Indicator' ||
        subSchema.type === 'LooseIndicator'
      ) {
        return (
          <SchemaIndicator
            path={path}
            indicators={value}
            displayOrder={displayOrder}
            defaultIndicator={subSchema.default as Indicator}
            generateOnChange={generateHandleChange}
            onAddNew={generateHandleAddNew(path, subSchema)}
            onRemove={generateHandleRemoveItem(path)}
          />
        );
      }

      const items = value.map((v: any, index: number) => {
        const subPath = `${path}[${index}]`;
        return SchemaComponent({
          key: generateKeyWithIndex(subPath, index, value.length),
          schema: subSchema,
          type: subSchema.type,
          name: subSchema.name,
          required: subSchema.required,
          deprecated: subSchema.deprecated,
          displayName: subSchema.editor?.displayName ?? '',
          displayOrder: subSchema.editor?.displayOrder ?? 10000,
          description: subSchema.editor?.description ?? '',
          value: v,
          path: subPath,
          level: level + 1,
          count: index + 1,
          siblings: value.length,
        });
      });
      return (
        <SchemaArray
          key={generateKeyWithCount(path, items)}
          displayOrder={displayOrder}
          displayName={subSchema.editor.displayName}
          level={level + 1}
          maxItems={field.maxItems}
          path={path}
          schema={subSchema}
          generateOnAddNew={generateHandleAddNew}
          generateOnRemoveItem={generateHandleRemoveItem}
        >
          {items}
        </SchemaArray>
      );
    }
    case 'Reference': {
      const { referenceType, id } = value as Reference;
      return (
        <Reference
          key={path}
          name={name}
          displayName={displayName}
          description={description}
          referenceType={referenceType}
          id={id}
        />
      );
    }
    case 'Media': {
      const { id } = (value as Maybe<Reference>) ?? {};

      return (
        <SchemaMedia
          key={path}
          displayOrder={displayOrder}
          id={id}
          path={path}
          onChange={generateHandleChange(path)}
        />
      );
    }
    case 'Indicator':
    case 'LooseIndicator': {
      return (
        <SchemaIndicator
          indicator={value}
          path={path}
          displayOrder={displayOrder}
          generateOnChange={generateHandleChange}
          onAddNew={generateHandleAddNew(
            path,
            schema as ContentTypeSchemaField
          )}
          onRemove={generateHandleRemoveItem(path)}
          defaultIndicator={defaultValue as Indicator}
        />
      );
    }
    default:
      return (
        <div key={path}>{`Unknown field: ${name} ${type} ${JSON.stringify(
          value
        )}`}</div>
      );
  }
};

export default SchemaComponent;
