/**
 * stringToColor - Deterministically converts a string of text into a hexadecimal color
 *
 * Adapted from: https://mui.com/material-ui/react-avatar/#BackgroundLetterAvatars.tsx
 * @param string (String) The string to base the color on
 * @returns A hexadecimal color string (e.g.: "#0011ff")
 */

// Lower this value to darken the overall colors produced by this function
const COLOR_MASK = 0xc0;
export const stringToColor = (string: string) => {
  if (!string) {
    return '#000000';
  }

  let hash = 0;

  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & COLOR_MASK;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};
