import { FC, MouseEvent, useCallback, memo, ReactNode } from 'react';
import { Chip, ChipProps } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Close';

type Props = {
  label: string | ReactNode | undefined;
  id?: string;
  color?: ChipProps['color'];
  size?: ChipProps['size'];
  className?: string;
  sx?: SxProps<Theme>;
  onDelete?: (event: any) => void;
  onClick?: (event: any) => void;
};

const ActionableChip: FC<Props> = ({
  id,
  label,
  color = 'primary',
  size = 'small',
  className,
  sx = [],
  onDelete,
  onClick,
}) => {
  const stopEventPropagation = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <Chip
      id={id}
      label={label}
      color={color}
      size={size}
      onClick={onClick}
      onDelete={onDelete}
      className={className}
      data-testid="actionable-chip"
      deleteIcon={
        <DeleteIcon
          data-testid="actionable-chip:delete-icon"
          onMouseDown={stopEventPropagation}
        />
      }
      sx={sx}
    />
  );
};

// this assumes devs will be responsible and wrap their input functions in a useCallback
// prior to passing it into this component
const memoizedRemovableChip = memo(ActionableChip);

export default memoizedRemovableChip;
