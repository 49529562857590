import { FunctionComponent, useCallback } from 'react';
import { IconButton, styled, Box } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ContentEntrySidePanel, {
  useContentEntrySidePanel,
} from 'components/content-entry-side-panel';
import CollectionWrapper from 'components/content-entry-side-panel/collection-wrapper';
import SidePanelContainer from 'components/search-side-panel/side-panel-container';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

import { useContentTab } from './hooks';
import Categories from './categories';
import ReferenceListHeader from './reference-list-header';
import CategorizedReferenceList from './categorized-reference-list';
import CollectionsBulkActionbar from './bulk-action-bar';

const StyledContainer = styled('div')`
  display: flex;
  /* Remove margin from the <main> */
  margin: 0 -2rem -2rem;
  /* 185px is the height of the header and navbar */
  height: calc(100vh - 185px);
  width: 100vw;

  #categories {
    /* Grow to 25%, shrink as needed, no smaller than 18.75rem */
    flex: 25 1 18.75rem;
    /*  no larger than 22.875rem */
    max-width: 22.875rem;
    /* To absolutely position the open button */
    position: relative;
    background-color: var(--color-neutral-one);
    padding: 1.5rem 0 0;
    transition: flex 0.2s ease-in-out;
    box-shadow: ${({ theme }) => theme.shadows[1]};
    /* Above the answers and info panel */
    z-index: 2;
    /* Get rid of the box shadow on the bottom */
    margin-bottom: -1px;
  }

  #categories #content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #categories.hidden {
    flex: none;
    width: ${({ theme }) => theme.spacing(7)};
    min-width: 56px;
  }

  /* baseline styles for #answers */
  #answers {
    /* Grow to 50 */
    flex: 50 1 0;
    padding: ${({ theme }) => theme.spacing(3)};
    box-shadow: ${({ theme }) => theme.shadows[1]};
    transition: flex 0.2s ease-in-out;
    overflow-y: auto;
    /* above the info panel, below the categories */
    z-index: 1;
    /* Get rid of the box shadow on the bottom */
    margin-bottom: -1px;
  }

  #expand-button {
    position: absolute;
    right: -14px;
    top: 8px;
    height: 20px;
    width: 20px;
    /* Turn the button fully circular */
    border-radius: 16px;
    color: var(--color-neutral-one);
    background-color: var(--color-primary-base);
    box-shadow: ${({ theme }) => theme.shadows[1]};
  }

  #info-panel {
    /* 100% of the window - the appbar and collection header */
    height: calc(100vh - 185px);
    /* Grow to 25%, shrink as needed, no smaller than 18.75rem */
    flex: 25 1 18.75rem;
    /*  no larger than 22.875rem */
    max-width: 22.875rem;
    overflow: auto;
    transition: flex 0.2s ease-in-out;
  }
  #info-panel.hidden {
    flex: none;
    width: 0px;
  }
`;

type Props = {
  contentEntryId: number;
  localizedContentEntryId: number;
};

const ContentTab: FunctionComponent<Props> = ({
  contentEntryId,
  localizedContentEntryId,
}) => {
  const {
    panelItemId: sidePanelContentEntryId,
    hidePanel,
    togglePanel,
    isPanelOpen,
  } = useContentEntrySidePanel();

  const {
    selectedCategories,
    selectedCategoryIndex,
    showCategories,
    localizedCollection,
    isLoading,
    selectedReferences,
    setShowCategories,
    toggleShowCategories,
    handleReorder,
    handleReorderCategories,
    insertCategoryInAllLanguages,
    addReferencesInAllLanguages,
    removeCategoryInAllLanguages,
    refetchLocalizedCollection,
    updateCategory,
    handleSelectReference,
    isReferenceSelected,
    handleSelectAllReferences,
    handleDeselectAllReferences,
  } = useContentTab(contentEntryId, localizedContentEntryId, hidePanel);
  const api = useOzmoApiService();
  const { data: contentEntry } = api.ContentEntry.get({
    id: contentEntryId ?? undefined,
  });

  const handleInfoClick = useCallback(
    (id: number) => {
      if (window.innerWidth < 1400) {
        setShowCategories(false);
      }
      togglePanel(id);
    },
    [togglePanel, setShowCategories]
  );

  const sidePanelSelectedClass = `side-panel-selected-content-entry-${sidePanelContentEntryId}`;

  return (
    <StyledContainer>
      <div id="categories" className={showCategories ? '' : 'hidden'}>
        <IconButton
          id="expand-button"
          data-testid="expand-categories-button"
          size="small"
          onClick={toggleShowCategories}
        >
          {showCategories ? (
            <KeyboardArrowLeftIcon fontSize="small" />
          ) : (
            <KeyboardArrowRightIcon fontSize="small" />
          )}
        </IconButton>
        {showCategories && !isLoading && (
          <div id="content" data-testid="categories-container">
            <Categories
              localizedCollection={localizedCollection}
              selectedCategoryIndex={selectedCategoryIndex}
              onReorderCategory={handleReorderCategories}
              insertCategory={insertCategoryInAllLanguages}
              removeCategory={removeCategoryInAllLanguages}
              updateCategory={updateCategory}
            />
          </div>
        )}
      </div>
      <Box
        id="answers"
        data-testid="answers-container"
        className={
          showCategories
            ? `categories-panel-open ${sidePanelSelectedClass}`
            : sidePanelSelectedClass
        }
      >
        <ReferenceListHeader
          localizedCollection={localizedCollection}
          categoryIndex={selectedCategoryIndex}
          addReferences={addReferencesInAllLanguages}
        />
        <CategorizedReferenceList
          collectionId={localizedCollection?.contentEntryId}
          localizedCollectionId={localizedCollection?.id}
          categorizedReferences={selectedCategories}
          onReorder={handleReorder}
          onSelectReference={handleSelectReference}
          isReferenceSelected={isReferenceSelected}
          onInfoClick={handleInfoClick}
        />
      </Box>
      <div id="info-panel" className={isPanelOpen ? '' : 'hidden'}>
        <CollectionWrapper>
          {sidePanelContentEntryId && (
            <SidePanelContainer
              onClose={hidePanel}
              contentType={contentEntry?.contentType ?? ''}
              id={contentEntryId}
            >
              <ContentEntrySidePanel
                contentEntryId={sidePanelContentEntryId}
                languageId={localizedCollection?.languageId}
                hasActionableLanguages={false}
              />
            </SidePanelContainer>
          )}
        </CollectionWrapper>
      </div>
      <CollectionsBulkActionbar
        languageDisplayName={localizedCollection?.languageDisplayName}
        selectedReferences={selectedReferences}
        selectedCategories={selectedCategories}
        onSelectAllReferences={handleSelectAllReferences}
        onDeselectAllReferences={handleDeselectAllReferences}
        refetchLocalizedCollection={refetchLocalizedCollection}
      />
    </StyledContainer>
  );
};

export default ContentTab;
