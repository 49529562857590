import { FunctionComponent, ChangeEventHandler } from 'react';
import DraggableIndicators, { OnSave } from 'components/draggable-indicators';
import LoadingIndicator from 'components/loading-indicator';
import { DeviceScaleContext } from 'scenes/universal-content-editor/components/schema-media/device-scale-context';
import { PausableByMouseOverProvider } from 'contexts/pausable-by-mouseover-context';
import { styled } from '@mui/material';
import {
  RemoveItemFunction,
  AddNewFunction,
} from 'scenes/universal-content-editor';

import { useIndicatorDoubleClick } from '../schema-indicator';

import NoMediaEntry from './no-media-entry';
import { useResizableImage } from './hooks/use-resizable-image';

const StyledImg = styled('img')`
  max-height: 100%;
  max-width: 100%;
`;

type Props = {
  id?: number;
  path: string;
  indicators?: Indicator[];
  defaultIndicator?: Indicator;
  hasIndicators?: boolean;
  onFileSelect: ChangeEventHandler<HTMLInputElement>;
  onAddIndicator?: AddNewFunction;
  onChangeIndicator?: OnSave;
  onRemoveIndicator?: RemoveItemFunction;
};

const MediaEntry: FunctionComponent<Props> = ({
  id,
  path,
  defaultIndicator,
  indicators = [],
  hasIndicators = false,
  onFileSelect,
  onAddIndicator,
  onChangeIndicator,
  onRemoveIndicator,
}) => {
  const {
    imageSize,
    imageRef,
    mediaEntry,
    isLoading,
    isError,
    isImageLoadError,
    handleInitialMediaLoad,
    handleMediaLoadError,
  } = useResizableImage(id, path);

  const { handleDoubleClick } = useIndicatorDoubleClick({
    imageSize,
    defaultIndicator,
    handleAddNew: onAddIndicator,
  });

  // If there is no media entry assigned to the step
  if (id === undefined) {
    return <NoMediaEntry onFileSelect={onFileSelect} path={path} />;
  }

  if (isLoading) {
    return <LoadingIndicator message="Loading media entry" />;
  }

  // If the assigned media can't be fetched from the API or fails to load in the browser
  if (isError || isImageLoadError || !mediaEntry) {
    return (
      <NoMediaEntry
        onFileSelect={onFileSelect}
        errorMessage="Unable to load image."
        path={path}
      />
    );
  }
  return (
    <DeviceScaleContext.Provider value={imageSize}>
      <PausableByMouseOverProvider>
        {indicators.length > 0 && (
          <DraggableIndicators
            indicators={indicators}
            onSave={onChangeIndicator!}
            onDelete={onRemoveIndicator!}
          />
        )}
        <StyledImg
          ref={imageRef}
          onLoad={handleInitialMediaLoad}
          onError={handleMediaLoadError}
          onDoubleClick={hasIndicators ? handleDoubleClick : undefined}
          src={`${import.meta.env.VITE_APP_CLOUDFRONT_URL}/${
            mediaEntry.s3Path
          }`}
        />
      </PausableByMouseOverProvider>
    </DeviceScaleContext.Provider>
  );
};

export default MediaEntry;
