import { FunctionComponent } from 'react';
import { Skeleton, Typography } from '@mui/material';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

type Props = {
  contentEntryId: number;
};

const LastUpdated: FunctionComponent<Props> = ({ contentEntryId }) => {
  const api = useOzmoApiService();
  const { data: contentEntry } = api.ContentEntry.get({ id: contentEntryId });

  if (!contentEntry) {
    return (
      <Skeleton
        data-testid="content-sidebar-last-updated:skeleton"
        variant="rectangular"
      />
    );
  }

  return (
    <Typography>
      {new Date(contentEntry?.updatedAt).toLocaleDateString()}
    </Typography>
  );
};

export default LastUpdated;
