import { FC } from 'react';
import { Box, Typography, ListItem, ListItemText, styled } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';
import getPlural from 'services/utils/get-plural';
import { parseBulkAddAttributesJob } from 'services/collections/utils';

type Props = {
  bulkJob: BulkOperationJobResponse;
  activeReferences: (
    | LocalizedCollectionReference
    | MissingLocalizedCollectionReference
  )[];
};

const StyledUl = styled('ul')`
  margin: 0;
  padding-inline-start: 3rem;
  width: 100%;
`;

const JobResultsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &.success {
    .MuiSvgIcon-root,
    .MuiTypography-root {
      color: var(--color-messaging-success);
    }
  }
  &.failed {
    .MuiSvgIcon-root,
    .MuiTypography-root {
      color: var(--color-messaging-error);
    }
  }
`;

export const JobResultsContent: FC<Props> = ({ bulkJob, activeReferences }) => {
  const { failedResults } = parseBulkAddAttributesJob(bulkJob);

  return (
    <Box>
      {/* Only render failed job output when there are failed jobs */}
      {failedResults.length > 0 && (
        <JobResultsContainer marginBottom={4} className="failed">
          <Error fontSize="small" />
          <Typography marginLeft={1}>
            {'Unable to add attributes to '}
            <Typography component="span" fontWeight="bold">
              {`${failedResults.length} ${getPlural(
                failedResults.length,
                'answer',
                'answers'
              )}. `}
            </Typography>
            {'Please try again.'}
          </Typography>
          <StyledUl>
            {failedResults.map((job) => (
              <ListItem
                disablePadding
                key={job.id}
                style={{ display: 'list-item' }}
              >
                <ListItemText>
                  {activeReferences.find((r) => r.id === job.id)?.title ||
                    `A title does not exist for the answer with an id of ${job.id}.`}
                </ListItemText>
              </ListItem>
            ))}
          </StyledUl>
        </JobResultsContainer>
      )}
      <JobResultsContainer className="success">
        <CheckCircle fontSize="small" />
        <Typography marginLeft={1}>
          {'Attributes added successfully'}
        </Typography>
      </JobResultsContainer>
    </Box>
  );
};

export default JobResultsContent;
