import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import styled from 'styled-components';
import { FunctionComponent } from 'react';
import LoadingIndicator from 'components/loading-indicator';
import MultiSelect from 'components/multi-select';

const StyledDiv = styled.div`
  padding-top: 32px;
  & > div {
    width: 25%;
    margin-right: 16px;
  }
`;

type Props = {
  isLoading: boolean;
  title: string;
  handleChangeSource: (e: any) => void;
  source: string;
  languages: { value: number; chipLabel: string; label: string }[];
  handleSelectTarget: (languageIds: number[]) => void;
  handleChangeMenuTranslate: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ExportModalContent: FunctionComponent<Props> = ({
  isLoading,
  title,
  handleChangeSource,
  source,
  languages,
  handleSelectTarget,
  handleChangeMenuTranslate,
}) => {
  if (isLoading) {
    return <LoadingIndicator message="Loading collection" />;
  }

  return (
    <>
      <Typography>
        {
          'Select a source and target language for translation. This collection, '
        }
        <b>{`${title}`}</b>
        {`, and all its contents will be exported.`}
      </Typography>
      <StyledDiv>
        <FormControl>
          <InputLabel>{'Source language'}</InputLabel>
          <Select
            onChange={handleChangeSource}
            labelId="source-language-select-label"
            id="source-language"
            label="Source language"
            data-testid="translation-export-modal:source-language-select"
            value={source}
            defaultValue=""
          >
            {languages.map((l) => (
              <MenuItem key={l.value} value={l.chipLabel}>
                {`${l.label}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <MultiSelect
          label="Target languages"
          options={languages}
          onSelectionChanged={handleSelectTarget}
        />
        <Box display="flex" gap="0.45rem" alignItems="start">
          <Box marginTop="0.50rem">
            <FormControl>
              <Typography noWrap>
                <FormControlLabel
                  control={<Checkbox onChange={handleChangeMenuTranslate} />}
                  label="Translate category names"
                />
              </Typography>
            </FormControl>
          </Box>
        </Box>
      </StyledDiv>
    </>
  );
};

export default ExportModalContent;
