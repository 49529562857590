import { Box } from '@mui/material';
import { FC } from 'react';

/**
 *  A helper to render an invisible anchor tag that can be used to offset the
 *  header when the user clicks on a #link to an object
 */

const HeaderOffsetAnchor: FC<{ path: string }> = ({ path }) => (
  <Box
    data-testid={`header-offset-anchor:${path}`}
    component="span"
    id={path}
    top={-300}
    position="relative"
    visibility="hidden"
  />
);

export default HeaderOffsetAnchor;
