export const SET_AUTOSAVE = 'SET_AUTOSAVE';
export const RESET_UNDO_STACK = 'RESET_UNDO_STACK';
export const PUSH_TO_UNDO_STACK = 'PUSH_TO_UNDO_STACK';
export const UNDO = 'UNDO';
export const SCROLL_UNDO = 'SCROLL_UNDO';
export const PUSH_TO_REDO_STACK = 'PUSH_TO_REDO_STACK';
export const REDO = 'REDO';
export const SCROLL_REDO = 'SCROLL_REDO';
export const SET_STEP_REFS = 'SET_STEP_REFS';
export const SHOW_BULK_UPLOAD = 'SHOW_BULK_UPLOAD';
export const HIDE_BULK_UPLOAD = 'HIDE_BULK_UPLOAD';
export const SCROLL_TO_STEP = 'SCROLL_TO_STEP';
export const CLEAR_SCROLL_TO_STEP = 'CLEAR_SCROLL_TO_STEP';
export const SET_ATTRIBUTES = 'SET_ATTRIBUTES';
