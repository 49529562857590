import { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import DeviceSelector from '../selectors/device';
import { Operation } from '../use-attribute-editor';

import GenericSelector from './generic';
import OperatingSystemSelector from './operating-system';

type Props = {
  primaryFilter: string;
  secondaryFilter: string;
  devices: DeviceModel[];
  deviceTypes: DeviceTypeModel[];
  manufacturers: ManufacturerModel[];
  operatingSystemVersions: OperatingSystemVersionModel[];
  selectedOperatingSystemIds: number[];
  selectedOperatingSystemReleaseIds: number[];
  pendingOperations: Operation[];
  onSelect: (
    attributeName: string,
    attribute:
      | DeviceModel
      | ManufacturerModel
      | DeviceTypeModel
      | OperatingSystemVersionModel
      | OperatingSystemReleaseModel
      | OperatingSystemModel,
    action: 'add' | 'remove'
  ) => void;
};

const AttributeSelectors: FunctionComponent<Props> = ({
  primaryFilter,
  secondaryFilter,
  devices,
  deviceTypes,
  manufacturers,
  operatingSystemVersions,
  selectedOperatingSystemIds,
  selectedOperatingSystemReleaseIds,
  pendingOperations,
  onSelect,
}) => {
  switch (primaryFilter) {
    case 'device':
      return (
        <DeviceSelector
          manufacturer={secondaryFilter}
          devices={devices}
          pendingOperations={pendingOperations}
          onSelect={onSelect}
        />
      );
    case 'deviceType':
      return (
        <GenericSelector
          attributeName="deviceType"
          label="Device Type"
          attributes={deviceTypes}
          pendingOperations={pendingOperations}
          onSelect={onSelect}
        />
      );
    case 'manufacturer':
      return (
        <GenericSelector
          attributeName="manufacturer"
          label="Manufacturer"
          attributes={manufacturers}
          pendingOperations={pendingOperations}
          onSelect={onSelect}
        />
      );
    case 'operatingSystem':
      return (
        <OperatingSystemSelector
          selectedOperatingSystemIds={selectedOperatingSystemIds}
          selectedOperatingSystemReleaseIds={selectedOperatingSystemReleaseIds}
          operatingSystemVersions={operatingSystemVersions}
          operatingSystem={secondaryFilter}
          pendingOperations={pendingOperations}
          onSelect={onSelect}
        />
      );
    case 'all': {
      const attributeCount =
        devices.length +
        deviceTypes.length +
        manufacturers.length +
        operatingSystemVersions.length;

      return attributeCount > 100 ? (
        <Typography variant="caption">
          {'Too many results, Add a text filter to reduce results'}
        </Typography>
      ) : (
        <>
          <DeviceSelector
            manufacturer={secondaryFilter}
            devices={devices}
            pendingOperations={pendingOperations}
            onSelect={onSelect}
          />
          <GenericSelector
            attributeName="deviceType"
            label="Device Type"
            attributes={deviceTypes}
            pendingOperations={pendingOperations}
            onSelect={onSelect}
          />
          <GenericSelector
            attributeName="manufacturer"
            label="Manufacturer"
            attributes={manufacturers}
            pendingOperations={pendingOperations}
            onSelect={onSelect}
          />
          <OperatingSystemSelector
            selectedOperatingSystemIds={selectedOperatingSystemIds}
            selectedOperatingSystemReleaseIds={
              selectedOperatingSystemReleaseIds
            }
            operatingSystemVersions={operatingSystemVersions}
            operatingSystem={secondaryFilter}
            pendingOperations={pendingOperations}
            onSelect={onSelect}
          />
        </>
      );
    }

    default:
      return <>{'Select a filter or type to get started'}</>;
  }
};

AttributeSelectors.propTypes = {
  primaryFilter: PropTypes.string.isRequired,
  secondaryFilter: PropTypes.string.isRequired,
};

export default AttributeSelectors;
