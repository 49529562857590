import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContainer = styled.div`
  padding: 12px 0px 12px 24px;
  border-bottom: 1px solid #acacac;
`;

const StyledTypography = styled(Typography)<{ $isSelected: boolean }>`
  font-weight: ${({ $isSelected }) =>
    $isSelected ? 500 : 'normal'} !important;
`;

type Props = {
  label: string;
  count?: number;
  isSelected: boolean;
  onClick: () => void;
};

const GenericFilterPicker: FunctionComponent<Props> = ({
  label,
  count,
  isSelected,
  onClick,
}) => (
  <StyledContainer onClick={onClick}>
    <StyledTypography $isSelected={isSelected}>{`${label} ${
      count ? `(${count})` : ''
    }`}</StyledTypography>
  </StyledContainer>
);

GenericFilterPicker.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.number,
};

export default GenericFilterPicker;
