import { FC, MouseEventHandler } from 'react';
import BaseModal from 'components/modals';

import { PublishModalContent } from './modal-content';
import { PublishModalActions } from './modal-actions';

type Props = {
  open: boolean;
  isPublishInProgress: boolean;
  hasMissingData: boolean;
  publishError: any;
  showPreviewButton: boolean;
  previewButton: JSX.Element;
  onPublish: Function;
  onClose: MouseEventHandler;
};

const PublishModal: FC<Props> = ({
  open,
  isPublishInProgress,
  hasMissingData,
  publishError,
  showPreviewButton,
  previewButton,
  onPublish,
  onClose,
}) => {
  return (
    <BaseModal
      fullWidth
      maxWidth={hasMissingData ? 'md' : 'sm'}
      open={open}
      title={
        hasMissingData
          ? 'Uh oh! We are unable to publish this answer.'
          : 'Publish content'
      }
      onClose={onClose}
      actionChildren={
        <PublishModalActions
          isPublishInProgress={isPublishInProgress}
          hasMissingData={hasMissingData}
          publishError={publishError}
          showPreviewButton={showPreviewButton}
          previewButton={previewButton}
          onPublish={onPublish}
          onClose={onClose}
        />
      }
    >
      <PublishModalContent
        isPublishInProgress={isPublishInProgress}
        showPreviewButton={showPreviewButton}
        hasMissingData={hasMissingData}
        publishError={publishError}
      />
    </BaseModal>
  );
};

export default PublishModal;
