import {
  any,
  array,
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  oneOfType,
  Requireable,
  shape,
  string,
  Validator,
  ValidationMap,
} from 'prop-types';

/** ******************************************************
 *                      Base model
 ****************************************************** */
const baseModel = {
  id: number.isRequired,
  createdAt: string.isRequired,
  updatedAt: string.isRequired,
};

/** ******************************************************
 *                     Validators
 ****************************************************** */

/** ******************************************************
 *                Nullable property
 ****************************************************** */
const requiredNullableString: Validator<string | null> = (
  props: any,
  propName: string,
  componentName: string
) => {
  const prop = props[propName];

  if (prop === undefined) {
    return new Error(
      `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`undefined\``
    );
  }

  if (typeof prop === 'string' || prop instanceof String || prop === null) {
    return null;
  }

  return new Error(
    `Invalid prop \`${propName}\` of type \`${typeof prop}\` supplied to \`${componentName}\`, expected \`string | null\`.`
  );
};
const requiredNullableNumber: Validator<number | null> = (
  props: any,
  propName: string,
  componentName: string
) => {
  const prop = props[propName];

  if (prop === undefined) {
    return new Error(
      `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`undefined\``
    );
  }

  if (typeof prop === 'number' || prop instanceof Number || prop === null) {
    return null;
  }

  return new Error(
    `Invalid prop \`${propName}\` of type \`${typeof prop}\` supplied to \`${componentName}\`, expected \`number | null\`.`
  );
};
const requiredNullableObject: Validator<object | null> = (
  props: any,
  propName: string,
  componentName: string
) => {
  const prop = props[propName];

  if (prop === undefined) {
    return new Error(
      `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`undefined\``
    );
  }

  if (typeof prop === 'number' || prop instanceof Object || prop === null) {
    return null;
  }

  return new Error(
    `Invalid prop \`${propName}\` of type \`${typeof prop}\` supplied to \`${componentName}\`, expected \`object | null\`.`
  );
};

/** **********************************************************
 *              Optional non-null property
 ********************************************************** */
const nonNullOptionalNumber: Validator<number | undefined> = (
  props: any,
  propName: string,
  componentName: string
) => {
  const prop = props[propName];

  if (prop === null) {
    return new Error(
      `The prop \`${propName}\` must be \`undefined\` or \`number\` in \`${componentName}\`, but its value is \`null\``
    );
  }

  if (
    typeof prop === 'number' ||
    prop instanceof Number ||
    prop === undefined
  ) {
    return null;
  }

  return new Error(
    `Invalid prop \`${propName}\` of type \`${typeof prop}\` supplied to \`${componentName}\`, expected \`number | null\`.`
  );
};
const nonNullOptionalString: Validator<string | undefined> = (
  props: any,
  propName: string,
  componentName: string
) => {
  const prop = props[propName];

  if (prop === null) {
    return new Error(
      `The prop \`${propName}\` must be \`undefined\` or \`string\` in \`${componentName}\`, but its value is \`null\``
    );
  }

  if (
    typeof prop === 'string' ||
    prop instanceof String ||
    prop === undefined
  ) {
    return null;
  }

  return new Error(
    `Invalid prop \`${propName}\` of type \`${typeof prop}\` supplied to \`${componentName}\`, expected \`number | null\`.`
  );
};
const nonNullOptionalObject: Validator<object | undefined> = (
  props: any,
  propName: string,
  componentName: string
) => {
  const prop = props[propName];

  if (prop === null) {
    return new Error(
      `The prop \`${propName}\` must be \`undefined\` or \`object\` in \`${componentName}\`, but its value is \`null\``
    );
  }

  if (
    typeof prop === 'object' ||
    prop instanceof Object ||
    prop === undefined
  ) {
    return null;
  }

  return new Error(
    `Invalid prop \`${propName}\` of type \`${typeof prop}\` supplied to \`${componentName}\`, expected \`number | null\`.`
  );
};

/** **********************************************************
 *                      Contributor
 ********************************************************** */
// future-thought: should we add a user ID or email to this model for workflow operations/assignment?
const contributor = shape<ValidationMap<Contributor>>({
  role: string.isRequired,
  user: string.isRequired,
});

/** **********************************************************
 *                          Tag
 ********************************************************** */
const tag = shape<ValidationMap<TagModel>>({
  ...baseModel,
  name: string.isRequired,
});

/** **********************************************************
 *                        Attributes
 ********************************************************** */
const device = shape<ValidationMap<DeviceModel>>({
  ...baseModel,
  appCategory: string,
  categoryMapId: number.isRequired,
  contentType: string.isRequired,
  deviceImagePath: string.isRequired,
  deviceType: string.isRequired,
  deviceTypeId: number.isRequired,
  displayOrder: number.isRequired,
  linkedOperatingSystem: string.isRequired,
  linkedOperatingSystemId: number.isRequired,
  manufacturer: string.isRequired,
  manufacturerId: number.isRequired,
  operatingSystem: string.isRequired,
  operatingSystemId: number.isRequired,
  thumbnailPath: string.isRequired,
  trackingName: string.isRequired,
  paidStatus: string.isRequired,
  tags: arrayOf(tag.isRequired) as Validator<TagModel[] | undefined>,
});
const deviceType = shape<ValidationMap<DeviceTypeModel>>({
  ...baseModel,
  name: string.isRequired,
  isIot: bool.isRequired,
});
const manufacturer = shape<ValidationMap<ManufacturerModel>>({
  ...baseModel,
  name: string.isRequired,
  makesApps: bool.isRequired,
  makesDevices: bool.isRequired,
  logoImagePath: requiredNullableString,
});
const operatingSystem = shape<ValidationMap<OperatingSystemModel>>({
  ...baseModel,
  name: string.isRequired,
  shouldDisplayReleaseName: bool.isRequired,
  displayOrder: number.isRequired,
});
const operatingSystemRelease = shape<
  ValidationMap<OperatingSystemReleaseModel>
>({
  ...baseModel,
  name: string.isRequired,
  displayOrder: number.isRequired,
  operatingSystemId: number.isRequired,
  operatingSystemName: string.isRequired,
});
const operatingSystemVersion = shape<
  ValidationMap<OperatingSystemVersionModel>
>({
  ...baseModel,
  name: string.isRequired,
  displayOrder: number.isRequired,
  operatingSystem: string.isRequired,
  operatingSystemId: number.isRequired,
  operatingSystemRelease: string.isRequired,
  operatingSystemReleaseId: number.isRequired,
});
const space = shape<ValidationMap<SpaceModel>>({
  ...baseModel,
  name: string.isRequired,
});

/** **********************************************************
 *                          Locale
 ********************************************************** */
const locale = shape<ValidationMap<LocaleModel>>({
  ...baseModel,
  displayName: string.isRequired,
  name: string.isRequired,
});

/** **********************************************************
 *                       Client Locale
 ********************************************************** */
const clientLocale = shape<ValidationMap<ClientLocaleModel>>({
  clientId: number.isRequired,
  name: string.isRequired,
  localeChain: string.isRequired,
});

/** **********************************************************
 *                       Media Entry
 ********************************************************** */
const mediaEntry = shape<ValidationMap<MediaEntryModel>>({
  mediaTypeId: number.isRequired,
  mediaType: string.isRequired,
  metadata: any.isRequired,
  contentHash: string.isRequired,
  size: number.isRequired,
  s3Path: string.isRequired,
  name: string.isRequired,
  extension: string.isRequired,
  originalId: nonNullOptionalNumber,
  locales: arrayOf(locale.isRequired).isRequired,
  tags: arrayOf(tag.isRequired).isRequired,
});

/** **********************************************************
 *                        References
 ********************************************************** */
const reference = shape<ValidationMap<TopicItem>>({
  id: number.isRequired,
  referenceType: string.isRequired,
});
const categoryItem = shape<ValidationMap<CategoryItem>>({
  title: string.isRequired,
  description: string.isRequired,
  items: arrayOf(reference.isRequired).isRequired,
});

/** **********************************************************
 *                        Indicator
 ********************************************************** */
const waypoint = shape({
  x: number.isRequired,
  y: number.isRequired,
  duration: number.isRequired,
});
const indicator = shape<ValidationMap<Indicator>>({
  x: number.isRequired,
  y: number.isRequired,
  id: string.isRequired,
  type: string.isRequired,
  spread: nonNullOptionalNumber,
  length: nonNullOptionalNumber,
  waypoints: arrayOf(waypoint.isRequired),
});

/** **********************************************************
 *                Localized Content Entry
 ********************************************************** */
/** **********************************************************
 *                  Interactive Tutorial
 ********************************************************** */
const baseContentEntryProperties = {
  title: string.isRequired,
  description: nonNullOptionalString,
};
const interactiveTutorialStep = shape({
  media: reference as Validator<TopicItem>,
  notes: arrayOf(string.isRequired).isRequired,
  command: string.isRequired,
  indicators: arrayOf(indicator.isRequired).isRequired,
});
const interactiveTutorialProperties = shape({
  ...baseContentEntryProperties,
  notes: arrayOf(string.isRequired).isRequired,
  steps: arrayOf(interactiveTutorialStep.isRequired).isRequired,
  items: any,
});

/** **********************************************************
 *                    Points of Interest
 ********************************************************** */
const pointOfInterest = shape<ValidationMap<PointOfInterest>>({
  description: string.isRequired,
  pointOfInterest: string.isRequired,
  indicator: indicator.isRequired,
});
const pointsOfInterestProperties = shape<
  ValidationMap<PointsOfInterestProperties>
>({
  ...baseContentEntryProperties,
  media: reference.isRequired,
  pointsOfInterest: arrayOf(pointOfInterest.isRequired).isRequired,
  items: any,
});

/** **********************************************************
 *                      Collection
 ********************************************************** */
const collectionProperties = shape<ValidationMap<CollectionProperties>>({
  ...baseContentEntryProperties,
  items: arrayOf(oneOfType([reference, categoryItem]).isRequired).isRequired,
});

/** **********************************************************
 *                Localized Content Version
 ********************************************************** */
const localizedContentVersion = shape<
  ValidationMap<LocalizedContentVersionModel>
>({
  ...baseModel,
  localizedContentEntryId: number.isRequired,
  publishedOn: requiredNullableString,
  unpublishedOn: requiredNullableString,
});

/** **********************************************************
 *                Localized Content Entry
 ********************************************************** */
const localizedContentEntryProperties = oneOfType<
  Requireable<LocalizedContentEntryProperties>
>([
  collectionProperties,
  interactiveTutorialProperties,
  pointsOfInterestProperties,
]);
const localizedContentEntryBase = {
  ...baseModel,
  contentEntryId: number.isRequired,
  localeId: number.isRequired,
  locale: string.isRequired,
  properties: oneOfType([
    collectionProperties,
    interactiveTutorialProperties,
    pointsOfInterestProperties,
  ]).isRequired,
  localeDisplayName: string.isRequired,
  alternativeTitle: string,
  contributors: arrayOf(contributor.isRequired).isRequired,
  status: oneOf<LocalizedContentEntryStatus>([
    'published',
    'published_with_draft',
    'draft',
  ]).isRequired,
  lastPublished: localizedContentVersion,
};
const localizedContentEntry = shape<ValidationMap<LocalizedContentEntryModel>>({
  ...localizedContentEntryBase,
});

/** **********************************************************
 *                       Content Entry
 ********************************************************** */
const contentEntry = shape({
  ...baseModel,
  audience: requiredNullableString,
  contentTypeId: number.isRequired,
  contentType: string.isRequired,
  devices: arrayOf(device) as Validator<DeviceModel[] | undefined>,
  deviceTypes: arrayOf(deviceType) as Validator<DeviceTypeModel[] | undefined>,
  featureId: string.isRequired,
  localizedContentEntries: arrayOf(localizedContentEntry.isRequired).isRequired,
  manufacturers: arrayOf(manufacturer) as Validator<
    ManufacturerModel[] | undefined
  >,
  metadata: any,
  operatingSystems: arrayOf(operatingSystem) as Validator<
    OperatingSystemModel[] | undefined
  >,
  operatingSystemReleases: arrayOf(operatingSystem) as Validator<
    OperatingSystemReleaseModel[] | undefined
  >,
  operatingSystemVersions: arrayOf(operatingSystem) as Validator<
    OperatingSystemVersionModel[] | undefined
  >,
  proficiencyLevel: string,
  references: any,
  localizedReferences: any,
  space: space as Validator<SpaceModel | undefined>,
  spaceId: number.isRequired,
  tags: arrayOf(tag.isRequired).isRequired,
  title: string.isRequired,
});

/** */
const deviceShell = shape({
  ...baseModel,
  name: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  imagePath: string.isRequired,
  overlayImagePath: string.isRequired,
  default: bool.isRequired,
  deviceId: number.isRequired,
  device: string.isRequired,
});

export {
  any,
  array,
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
  categoryItem,
  clientLocale,
  contentEntry,
  contributor,
  device,
  deviceShell,
  deviceType,
  indicator,
  interactiveTutorialStep,
  locale,
  localizedContentEntry,
  localizedContentEntryProperties,
  manufacturer,
  mediaEntry,
  nonNullOptionalNumber,
  nonNullOptionalObject,
  nonNullOptionalString,
  operatingSystem,
  operatingSystemRelease,
  operatingSystemVersion,
  pointOfInterest,
  reference,
  requiredNullableString,
  requiredNullableObject,
  requiredNullableNumber,
  space,
  tag,
};
