import { useState, useCallback, useEffect } from 'react';
import { useAppToast } from 'contexts/app-toast-context';
import ContentEntry from 'services/ozmo-api/content-entry';
import { isFileList } from 'services/utils/type-guards/files';
import getPlural from 'services/utils/get-plural';

export type FileWithValidation = {
  file: File;
  isValid: boolean;
};

const setUploadedFilesValidation = (files: File[]): FileWithValidation[] => {
  return files.map((file) => {
    return {
      file,
      isValid: file.type === 'application/json',
    };
  });
};

const getValidFiles = (filesWithValidation: FileWithValidation[]) => {
  return filesWithValidation
    .filter(({ isValid }) => isValid)
    .map(({ file }) => file);
};

export const useImportTranslation = () => {
  const [uploadedFiles, setUploadedFiles] = useState<FileWithValidation[]>([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const dispatchToast = useAppToast();

  useEffect(() => {
    if (getValidFiles(uploadedFiles).length > 0) {
      setDisableSubmit(false);
    }
  }, [uploadedFiles]);

  const onDrop = useCallback(
    (files: File | FileList) => {
      const newFiles = [];
      if (isFileList(files)) {
        for (let i = 0; i < files.length; i++) {
          newFiles.push(files[i]);
        }
      } else {
        newFiles.push(files);
      }

      setUploadedFiles(
        uploadedFiles.concat(setUploadedFilesValidation(newFiles))
      );
    },
    [uploadedFiles]
  );

  const onRefuse = useCallback(() => {
    setUploadedFiles([]);
  }, []);

  const getConfirmButtonText = useCallback(() => {
    const validFileLength = getValidFiles(uploadedFiles).length;
    return `Import ${
      validFileLength === 0
        ? 'translated text files'
        : `${validFileLength} translated text ${getPlural(
            validFileLength,
            'file',
            'files'
          )}`
    } `;
  }, [uploadedFiles]);

  const handleImport = useCallback(async () => {
    setDisableSubmit(true);
    const results = getValidFiles(uploadedFiles).map((file) => {
      const formData = new FormData();
      formData.append('file', file, file.name);
      return ContentEntry.importTranslationsAsync(formData);
    });

    try {
      await Promise.all(results);
      dispatchToast({
        level: 'success',
        message: `Success! You imported ${
          results.length
        } translated text ${getPlural(results.length, 'file', 'files')}.`,
      });
    } catch (error) {
      dispatchToast({
        level: 'error',
        message:
          'An error occured while importing your translated files. Please try your import again.',
      });
    } finally {
      setDisableSubmit(false);
      setUploadedFiles([]);
    }
  }, [uploadedFiles, dispatchToast]);

  return {
    uploadedFiles,
    disableSubmit,
    onDrop,
    onRefuse,
    handleImport,
    getConfirmButtonText,
  };
};
