import { usePermissions } from 'components/permission-required';
import { isLegacyContent } from 'services/utils/is-legacy-content';

import { useCurrentLocalizedContentEntry } from '../hooks/use-current-localized-content-entry';

export const useReadOnlyContent = () => {
  const {
    contentEntry: { contentType = null, metadata } = {},
    localizedContentEntry: { deletedAt = null } = {},
    isLoading,
  } = useCurrentLocalizedContentEntry();

  const { isReadOnly = false } = metadata ?? {};

  const { checkPermission } = usePermissions();
  const hasPermission = checkPermission('edit_answer', 'Studio');
  // safer to assume legacy, and restrict content to read-only
  const isLegacy = isLoading || isLegacyContent(contentType!);

  return {
    isDeleted: !!deletedAt,
    isLegacy,
    isReadOnly,
    hasPermission,
  };
};
