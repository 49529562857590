import { FC } from 'react';
import PreviewButton from 'components/topic-header/preview-button';

import { useLocaleFromLangauge } from './use-find-locale-from-language';

type Props = {
  contentEntryId: number;
  languageShortCode: string;
};

const UniversalPreviewButton: FC<Props> = ({
  contentEntryId,
  languageShortCode,
}) => {
  const locale = useLocaleFromLangauge(languageShortCode);

  return (
    <PreviewButton contentEntryId={contentEntryId} locale={locale?.name} />
  );
};

export default UniversalPreviewButton;
