import { FunctionComponent, useState, useEffect } from 'react';
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from '@mui/material';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

type Props = {
  languageId: number;
  onSelect: (language?: LanguageModel) => void;
};

const SelectLanguage: FunctionComponent<Props> = ({ languageId, onSelect }) => {
  const api = useOzmoApiService();
  const { all: languages, isLoading, isFetching } = api.Language.getAll();
  const [selected, setSelected] = useState<LanguageModel>();

  const handleSelect = (event: SelectChangeEvent<LanguageModel>) => {
    const value = event.target.value as LanguageModel;
    setSelected(value);
    onSelect(value);
  };

  useEffect(() => {
    if (!selected && languages) {
      setSelected(languages.find((lang) => lang.id === languageId));
    }
  }, [languageId, languages, selected, setSelected]);

  if (isLoading || isFetching) {
    return null;
  }

  if (!selected) return null;

  return (
    <FormControl>
      <InputLabel id="select-label">{'Language'}</InputLabel>
      <Select
        style={{ height: '100%' }}
        value={selected}
        label="Language"
        labelId="select-label"
        onChange={handleSelect}
      >
        {languages.map((language) => (
          <MenuItem key={language.id} value={language as any}>
            {`${language.displayName} (${language.shortCode})`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectLanguage;
