import { FC, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import { containsIncompleteContent } from 'services/collections/utils';

import BaseCategory from './base-category';
import CategoryActions from './actions';

type Props = {
  category: CategoryModel;
  isSelected: boolean;
  hideActions?: boolean;
  onSelect: (categoryId: number) => void;
};

const Category: FC<Props> = ({
  category,
  isSelected,
  hideActions,
  onSelect,
}) => {
  const flagIncomplete = useMemo(
    () => containsIncompleteContent(category.contentEntries ?? []),
    [category.contentEntries]
  );

  return (
    <BaseCategory
      isSelected={isSelected}
      onClick={() => onSelect(category.id)}
      flagIncomplete={flagIncomplete}
    >
      <Box flex={1}>
        <Typography fontSize="1rem" whiteSpace="break-spaces" lineHeight={1.25}>
          {category.name}
        </Typography>
      </Box>
      {!hideActions && <CategoryActions category={category} />}
    </BaseCategory>
  );
};

export default Category;
