/* eslint-disable no-unused-vars */
import { useState, createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDiv = styled.div`
  max-width: 100%;
  max-height: 100%;
  position: relative;
`;

const PausableContext = createContext();
const useIsPaused = () => useContext(PausableContext);

const PausableByMouseOverProvider = ({ children }) => {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => setIsPaused(true);
  const handleMouseLeave = () => setIsPaused(false);
  const unPause = useCallback(() => setIsPaused(false), []);

  return (
    <StyledDiv onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <PausableContext.Provider value={{ isPaused, unPause }}>
        {children}
      </PausableContext.Provider>
    </StyledDiv>
  );
};

PausableByMouseOverProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PausableByMouseOverProvider, PausableContext, useIsPaused };
