import { MouseEventHandler } from 'react';
import { FCWithChildren } from 'types/fc-with-children';
import { styled } from '@mui/material';
import { CompletenessFlag } from 'components/completeness-indicators';

import CategoryContainer from './container';

type Props = {
  isSelected: boolean;
  className?: string;
  flagIncomplete?: boolean;
  onClick: MouseEventHandler;
};

const StyledCompletenessFlag = styled(CompletenessFlag)`
  border-top-right-radius: 6px;
`;

const BaseCategory: FCWithChildren<Props> = ({
  onClick,
  className = '',
  isSelected,
  flagIncomplete,
  children,
}) => {
  return (
    <CategoryContainer
      data-testid="base-category-container"
      className={`${className} ${isSelected ? 'selected' : ''}`}
      onClick={onClick}
    >
      {flagIncomplete && (
        <StyledCompletenessFlag tooltipText="Contains incomplete content" />
      )}
      {children}
    </CategoryContainer>
  );
};

export default BaseCategory;
