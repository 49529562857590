import { FC, ReactNode } from 'react';
import {
  Select,
  MenuItem,
  Checkbox,
  SelectProps,
  InputLabel,
  FormControl,
  FormHelperText,
  Box,
} from '@mui/material';
import ActionableChip from 'components/actionable-chip';

import { useMultiSelect } from './hooks';

export type Option = {
  label: string;
  chipLabel?: string;
  value: number | string;
  disabled?: boolean;
};

type Props = SelectProps & {
  label: string;
  options: Option[];
  initialSelection?: Option['value'][];
  onSelectionChanged: (values: any[]) => void;
  helperText?: ReactNode;
};

export const MultiSelect: FC<Props> = ({
  labelId,
  options,
  initialSelection,
  onSelectionChanged,
  label,
  error,
  variant,
  disabled,
  helperText,
  ...selectProps
}) => {
  const {
    selected,
    handleChange,
    handleTagDelete,
    isSelected,
  } = useMultiSelect(onSelectionChanged, initialSelection);

  const defaultLabelId = `${label.replace(' ', '-')}-label`;
  return (
    <FormControl
      sx={{ minWidth: 200 }}
      error={error}
      variant={variant}
      disabled={disabled}
      fullWidth={selectProps.fullWidth}
    >
      <InputLabel id={labelId ?? defaultLabelId}>{label}</InputLabel>
      <Select
        {...selectProps}
        multiple
        value={selected}
        onChange={handleChange}
        label={label}
        labelId={labelId ?? defaultLabelId}
        renderValue={(selected: any) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((v: any) => {
              const { chipLabel, label, disabled } =
                options.find((o) => o.value === v) ?? {};
              return (
                <ActionableChip
                  key={v}
                  label={chipLabel ?? label}
                  onDelete={disabled ? undefined : handleTagDelete(v)}
                />
              );
            })}
          </Box>
        )}
      >
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value} disabled={o.disabled} dense>
            <Checkbox checked={isSelected(o.value)} />
            {o.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

// const propsAreEqual = (prev: Props, next: Props) => {};

export default MultiSelect;
