import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  Link,
  Typography,
} from '@mui/material';
import { OpenInBrowser } from '@mui/icons-material';
import AttributeSelector, {
  AttributeSelectorProps,
  SelectedAttributes,
} from 'components/attribute-selector';
import { useHigherOrderAttributes } from 'components/attribute-selector/hooks';

type AddAttributeSelectorProps = AttributeSelectorProps & {
  showTitle?: boolean;
};

const AddAttributeSelector: FunctionComponent<AddAttributeSelectorProps> = (
  props
) => {
  const {
    showTitle = true,
    startingAttributes,
    prohibitedAttributes,
    inputPlaceholder,
    prohibitedContentTypes,
    showStaticAttributes = false,
    className,
    onSelectedAttrChange,
    onShiftEnterPressed,
    onResultsPanelToggle,
    syncWithQueryParams = false,
  } = props;
  // Track if results panel is open
  const [resultsPanelOpen, setResultsPanelOpen] = useState(false);
  const [osVersionAdded, setOsVersionAdded] = useState(false);

  const memoStartingAttributes = useMemo(() => {
    return startingAttributes;
    // We are caching the starting attributes when mounting this component
    // so that we can compare the starting attributes to the selected attributes
    // to determine if the user has added an OS Version attribute
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectedAttrChangeIntercept = useCallback(
    (selectedAttributes: SelectedAttributes) => {
      // Only set osVersionAdded if there are selected attributes not in the starting attributes
      const startingOsVersions = memoStartingAttributes?.OperatingSystemVersion;
      if (selectedAttributes.hasOwnProperty('operatingSystemVersionIds')) {
        const selectedOsVersions =
          selectedAttributes?.operatingSystemVersionIds;
        // if startingOsVersions is null or does not contain the selected OS Version ids
        // we know that the user has added an OS Version
        if (
          !selectedOsVersions?.every((id) => startingOsVersions?.includes(id))
        ) {
          setOsVersionAdded(true);
        } else {
          setOsVersionAdded(false);
        }
      } else {
        setOsVersionAdded(false);
      }
      onSelectedAttrChange?.(selectedAttributes);
    },
    // We are using the cached starting attributes for this component since
    // it is possible a component may re-render and the starting attributes
    // may change from the parent component, but we only want to compare the
    // starting attributes when the component is mounted
    [onSelectedAttrChange, memoStartingAttributes]
  );

  const onResultsPanelToggleIntercept = useCallback(
    (isOpen: boolean) => {
      setResultsPanelOpen(isOpen);
      onResultsPanelToggle?.(isOpen);
    },
    [onResultsPanelToggle]
  );

  const [onAttributesChange, higherOrderAttributes] = useHigherOrderAttributes(
    onSelectedAttrChangeIntercept,
    startingAttributes
  );

  return (
    <Box>
      {showTitle && (
        <Typography variant="subtitle2" color="inherit">
          {'Attributes'}
        </Typography>
      )}
      <AttributeSelector
        startingAttributes={higherOrderAttributes}
        prohibitedAttributes={prohibitedAttributes}
        inputPlaceholder={inputPlaceholder}
        prohibitedContentTypes={prohibitedContentTypes}
        showStaticAttributes={showStaticAttributes}
        className={className}
        onSelectedAttrChange={onAttributesChange}
        onShiftEnterPressed={onShiftEnterPressed}
        onResultsPanelToggle={onResultsPanelToggleIntercept}
        syncWithQueryParams={syncWithQueryParams}
      />
      <Collapse in={!resultsPanelOpen && osVersionAdded}>
        <Alert
          severity="error"
          sx={{
            marginTop: 1,
            width: '100%',
            visibility:
              !resultsPanelOpen && osVersionAdded ? 'visible' : 'hidden',
          }}
        >
          <AlertTitle color="inherit">{'Warning'}</AlertTitle>
          <>
            {
              'Adding an OS Version attribute will cause this content to only be visible for listed OS Versions. '
            }
            <strong>
              {
                'OS Versions should typically only be used for Apps and Apple release versions.'
              }
            </strong>
          </>
          <Link
            href="https://modeacorp.atlassian.net/wiki/spaces/CP/pages/3491922023/Studio+Attribution#When-to-use-Operating-System-Version-Attribute"
            target="_blank"
            display="flex"
            alignItems="center"
            marginTop={1}
            underline="none"
          >
            {'When to apply OS Version attributes'}&nbsp;
            <OpenInBrowser fontSize="small" />
          </Link>
        </Alert>
      </Collapse>
    </Box>
  );
};

export default AddAttributeSelector;
