import { FC } from 'react';
import { Box, Skeleton, styled } from '@mui/material';

import {
  SearchableAttribute,
  OnAttrSelect,
  OnAttrRemove,
  IsAttrSelected,
} from '../types';

import AttributeResultItem from './attribute-result-item';

type Props = {
  attributes: (SearchableAttribute | undefined)[];
  onAttrSelect: OnAttrSelect;
  onAttrRemove: OnAttrRemove;
  isAttrSelected: IsAttrSelected;
};

const StyledAttrResultItem = styled(AttributeResultItem)`
  margin: 0;
`;

const StaticAttributes: FC<Props> = ({
  attributes,
  onAttrSelect,
  onAttrRemove,
  isAttrSelected,
}) => {
  if (!attributes || attributes.length <= 0) return null;

  // to catch static attributes waiting for api response
  if (attributes.some((attr) => attr === undefined)) {
    return <Skeleton width="100%" height="100px" />;
  }

  return (
    <Box
      data-testid="attribute-selector-static-attribute-container"
      display="flex"
      gap={6}
      width="100%"
      border="2px solid var(--color-primary-background)"
      borderRadius="6px"
      margin="1.5rem 0"
      padding="0.75rem 1.5rem"
    >
      {attributes.map((attr) => (
        <StyledAttrResultItem
          key={`static-attr-${attr?.key}`}
          attribute={attr}
          onAttrSelect={onAttrSelect}
          onAttrRemove={onAttrRemove}
          isAttrUsed={isAttrSelected}
        />
      ))}
    </Box>
  );
};

export default StaticAttributes;
