import { FunctionComponent } from 'react';
import EditOff from '@mui/icons-material/EditOff';
import AutoMode from '@mui/icons-material/AutoMode';
import { Button } from '@mui/material';
import { useOzmoApiService } from 'contexts/ozmo-api-service-context';
import { useCurrentLocalizedContentEntry } from 'scenes/universal-content-editor/hooks/use-current-localized-content-entry';

import BaseStaticToast from './base-static-toast';

const LegacyStaticToast: FunctionComponent = () => {
  const api = useOzmoApiService();

  const {
    contentEntry: { id, contentType = null } = {},
  } = useCurrentLocalizedContentEntry();

  const targetType = contentType === 'legacyPointsOfInterest' ? 2 : 1;

  return (
    <BaseStaticToast
      title="This content is read-only"
      message="Attributes remain editable, but you cannot make changes to step text, notes, or images. You may attempt to convert this content to non-legacy content, but the process may fail due to content errors."
      icon={<EditOff />}
      action={
        <Button
          variant="contained"
          startIcon={<AutoMode />}
          onClick={() =>
            api.ContentEntry.convertContentTypeAsync(id!, targetType)
          }
        >
          {'Convert'}
        </Button>
      }
    />
  );
};

export default LegacyStaticToast;
