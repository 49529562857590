import { FC, useMemo } from 'react';
import { Button, styled } from '@mui/material';
import { LabelOutlined } from '@mui/icons-material';
import BaseModal, { useModal } from 'components/modals';
import { SelectedAttributes } from 'components/attribute-selector';
import { PermissionRequired } from 'components/permission-required';
import LoadingButton from 'components/loading-button';
import getPlural from 'services/utils/get-plural';

import ActionButton from '../action-button';

import { useAddAttributes } from './hooks';
import AddAttributeModalContent from './modal-content';

type Props = {
  answerCount: number;
  activeReferences: (
    | LocalizedCollectionReference
    | MissingLocalizedCollectionReference
  )[];
  onAddAttributes: (
    selectedAttributes: SelectedAttributes
  ) => Promise<BulkOperationJobResponse | undefined>;
};

// We need to target the inner dialog content to set overflow to visible
// this is so the box-shadow on the attribute selector will show.
const StyledBaseModal = styled(BaseModal)`
  .MuiDialogContent-root {
    overflow: visible;
  }
`;

export const AddAttributes: FC<Props> = ({
  answerCount,
  activeReferences,
  onAddAttributes,
}) => {
  const {
    selectedAttributes,
    bulkJobResults,
    handleAddAttributes,
    handleSelectedAttributeChange,
    handleRefuseModal,
  } = useAddAttributes(onAddAttributes);
  const {
    open,
    confirmInProgress,
    openModal,
    handleClose,
    handleConfirm,
  } = useModal({
    onConfirm: handleAddAttributes,
    onRefuse: handleRefuseModal,
    closeOnConfirm: false,
  });

  const selectedAttributesLength = useMemo(
    () =>
      Object.keys(selectedAttributes).flatMap((key) => selectedAttributes[key])
        .length,
    [selectedAttributes]
  );
  const confirmButtonText = useMemo(() => {
    if (confirmInProgress) {
      return 'Adding attributes';
    }
    if (selectedAttributesLength === 0) {
      return 'Add attributes';
    }

    return `Add ${selectedAttributesLength} ${getPlural(
      selectedAttributesLength,
      'attribute',
      'attributes'
    )}`;
  }, [selectedAttributesLength, confirmInProgress]);

  const modalActionButtons = useMemo(() => {
    if (bulkJobResults) {
      return (
        <Button onClick={handleClose} variant="contained">
          {'Done'}
        </Button>
      );
    }

    return (
      <>
        <Button onClick={handleClose}>{'Cancel'}</Button>
        <LoadingButton
          variant="contained"
          isLoading={confirmInProgress}
          disabled={selectedAttributesLength === 0 || confirmInProgress}
          onClick={handleConfirm}
        >
          {confirmButtonText}
        </LoadingButton>
      </>
    );
  }, [
    confirmButtonText,
    confirmInProgress,
    selectedAttributesLength,
    bulkJobResults,
    handleClose,
    handleConfirm,
  ]);

  return (
    <>
      <PermissionRequired permission="edit_collection" disableElement>
        <ActionButton onClick={openModal} startIcon={<LabelOutlined />}>
          {'Add attributes'}
        </ActionButton>
      </PermissionRequired>
      <StyledBaseModal
        open={open}
        fullWidth
        maxWidth="md"
        title={
          bulkJobResults
            ? 'Attributes updated'
            : 'You are about to add attributes'
        }
        onClose={handleClose}
        actionChildren={modalActionButtons}
      >
        <AddAttributeModalContent
          answerCount={answerCount}
          activeReferences={activeReferences}
          bulkJob={bulkJobResults}
          onSelectedAttributeChange={handleSelectedAttributeChange}
        />
      </StyledBaseModal>
    </>
  );
};

export default AddAttributes;
