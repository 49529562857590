import { FC } from 'react';
import { Button } from '@mui/material';
import Publish from '@mui/icons-material/Publish';

import { usePublish } from './hooks';
import PublishModal from './publish-modal';

type Props = {
  contentEntryId: number;
  localizedContentEntryId: number | undefined;
  showPreviewButton?: boolean;
  disabled?: boolean;
};

const PublishButton: FC<Props> = ({
  contentEntryId,
  localizedContentEntryId,
  showPreviewButton = false,
  disabled = false,
}) => {
  const {
    open,
    disablePublishButton,
    publishState,
    openModal,
    handleConfirm,
    handleClose,
  } = usePublish(contentEntryId, localizedContentEntryId, disabled);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={disablePublishButton}
        onClick={openModal}
        startIcon={<Publish />}
      >
        {'Publish'}
      </Button>

      <PublishModal
        open={open}
        onPublish={handleConfirm}
        onClose={handleClose}
        showPreviewButton={showPreviewButton}
        {...publishState}
      />
    </>
  );
};

export default PublishButton;
