import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import VerticalReorderableList, {
  DropResult,
} from 'components/vertical-reorderable-list';
import ReferenceCard from 'scenes/collection/reference';
import { usePermissions } from 'components/permission-required';

import { SelectedCategory } from '../hooks';
import TopicGroup from '../reference/topic-group';

type Props = {
  collectionId: number;
  localizedCollectionId: number;
  categorizedReferences: SelectedCategory[];
  onInfoClick: (contentEntryId: number) => void;
  onReorder: (categoryIndex: number) => (result: DropResult) => void;
  onSelectReference: (
    referenceId: number | number[],
    categoryIndex: number
  ) => void;
  isReferenceSelected: (referenceId: number, categoryIndex: number) => boolean;
};

const CategorizedReferenceList: FunctionComponent<Props> = ({
  categorizedReferences,
  collectionId,
  localizedCollectionId,
  onInfoClick,
  onReorder,
  onSelectReference,
  isReferenceSelected,
}) => {
  const { checkPermission } = usePermissions();
  const hasEditCollectionPermission = checkPermission('edit_collection');
  return (
    <>
      {categorizedReferences.map(({ title, items, categoryIndex }) => (
        <Box key={categoryIndex} marginBottom={6}>
          <Typography
            fontWeight={500}
          >{`${title} (${items.length})`}</Typography>
          <VerticalReorderableList
            onDragEnd={onReorder(categoryIndex)}
            listId={`category-${categoryIndex}`}
            itemCursor={hasEditCollectionPermission ? 'grab' : 'default'}
            draggableProps={{ isDragDisabled: !hasEditCollectionPermission }}
            items={items.map((item) => {
              if (Array.isArray(item)) {
                return {
                  id: item[0].topic!,
                  component: (
                    <TopicGroup
                      onInfoClick={onInfoClick}
                      onSelectChange={onSelectReference}
                      isReferenceSelected={isReferenceSelected}
                      categoryIndex={categoryIndex}
                      collectionId={collectionId}
                      localizedCollectionId={localizedCollectionId}
                      references={item}
                    />
                  ),
                };
              }
              return {
                ...item,
                component: (
                  <ReferenceCard
                    onInfoClick={onInfoClick}
                    onSelectChange={onSelectReference}
                    isReferenceSelected={isReferenceSelected}
                    collectionId={collectionId}
                    localizedCollectionId={localizedCollectionId}
                    categoryIndex={categoryIndex}
                    key={item.id}
                    {...item}
                  />
                ),
              };
            })}
          />
        </Box>
      ))}
    </>
  );
};

export default CategorizedReferenceList;
