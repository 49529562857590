import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledFadeOverlay = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 90%;
  background-image: linear-gradient(
    to bottom,
    transparent,
    ${(props) => props.backgroundColor}
  );
`;

const StyledWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const TextFade = ({ backgroundColor = '#f2f2f2', isDisabled, children }) => {
  return (
    <StyledWrapper>
      {children}
      {!isDisabled && <StyledFadeOverlay backgroundColor={backgroundColor} />}
    </StyledWrapper>
  );
};

TextFade.propTypes = {
  backgroundColor: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
};

export default TextFade;
