import { FC } from 'react';
import { Box, Grid, Typography, MenuItem } from '@mui/material';
import Contributor from 'components/contributor';
import { Select } from 'components/select';
import LoadingIndicator from 'components/loading-indicator';
import { TopicSelector } from 'components/topic-selector';
import ContentTypeWithIcon from 'components/content-type-with-icon';
import ReferencedCollectionsList, {
  useReferencedCollectionsList,
} from 'components/referenced-collections-list';

import { useDetailsPage } from './hooks';
import DetailItem from './item';

type Props = {
  contentEntryId: number;
  disableEdit: boolean;
};

const DetailsPage: FC<Props> = ({ contentEntryId, disableEdit }) => {
  const {
    contentEntry,
    contentTypeLabel,
    contributors,
    contentEntryLoading,
    contentEntryUpdating,
    handleTopicSelect,
    handleAudienceSelect,
    handleProficiencySelect,
  } = useDetailsPage({ contentEntryId });

  const { referencedCollections } = useReferencedCollectionsList(
    contentEntryId
  );
  if (contentEntryLoading && !contentEntryUpdating) {
    return <LoadingIndicator message="Loading content details" />;
  }
  return (
    <Grid
      container
      spacing={8}
      width={{ sm: '100%', md: '70%', lg: '50%' }}
      maxWidth={700}
      data-testid="details-page"
    >
      <DetailItem title="content type">
        <ContentTypeWithIcon
          contentType={contentEntry.contentType}
          label={contentTypeLabel}
        />
      </DetailItem>
      <DetailItem title="space">
        <Typography>{contentEntry.space?.name}</Typography>
      </DetailItem>
      <DetailItem title={`Collections (${referencedCollections?.length})`}>
        <ReferencedCollectionsList
          contentEntryId={contentEntryId}
          collapsedHeight="7.5rem"
        />
      </DetailItem>
      <DetailItem title="contributors">
        <Box display="flex" gap={1.25} flexDirection="column">
          {!contributors || contributors.length <= 0 ? (
            <Typography fontStyle="italic">
              {'This answer has no contributors'}
            </Typography>
          ) : (
            contributors.map((c) => <Contributor key={c.email} {...c} />)
          )}
        </Box>
      </DetailItem>
      <DetailItem title="topic">
        <TopicSelector
          disabled={disableEdit || contentEntryUpdating}
          currentTopicId={contentEntry.topicId}
          label="Topic"
          onChange={handleTopicSelect}
        />
      </DetailItem>
      <DetailItem
        title="audience"
        subTitle="Applies to all localized versions"
        helperText="The group for whom the content is created. (i.e. agents, customers, not specified)"
      >
        <Select
          label="Audience"
          value={contentEntry.audience || ''}
          // @ts-ignore - this complains that it doesn't recognize the value as string.
          // I believe this is due to the composed nature of the inner mui select in this
          // component, and it can't correctly infer the type.
          onChange={handleAudienceSelect}
          displayEmpty
          shrinkLabel
          disabled={contentEntryUpdating || disableEdit}
        >
          <MenuItem value="">{'Not specified'}</MenuItem>
          <MenuItem value="agents">{'Agents'}</MenuItem>
          <MenuItem value="customers">{'Customers'}</MenuItem>
        </Select>
      </DetailItem>
      <DetailItem
        title="proficiency Level"
        subTitle="Applies to all localized versions"
        helperText="Level of understsanding the audience should have when going through the topic. (i.e. beginner, intermediate, advanced)"
      >
        <Select
          label="Proficiency"
          value={contentEntry.proficiencyLevel || 'beginner'}
          // @ts-ignore - this complains that it doesn't recognize the value as string.
          // Same reason as above.
          onChange={handleProficiencySelect}
          disabled={contentEntryUpdating || disableEdit}
        >
          <MenuItem value="beginner">{'Beginner'}</MenuItem>
          <MenuItem value="intermediate">{'Intermediate'}</MenuItem>
          <MenuItem value="advanced">{'Advanced'}</MenuItem>
        </Select>
      </DetailItem>
    </Grid>
  );
};

export default DetailsPage;
