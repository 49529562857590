import { FunctionComponent } from 'react';
import { Box, styled } from '@mui/material';
import DroppableAreaOverlay from 'components/droppable-area-overlay';
import {
  AddNewFunction,
  ChangeFunction,
  RemoveItemFunction,
} from 'scenes/universal-content-editor';
import { OnSave } from 'components/draggable-indicators';

import AddIndicatorButton from '../schema-indicator/add-indicator-button/add-indicator-button';

import { useImageUpload } from './hooks/use-image-upload';
import ImageButton from './image-button';
import ImageUploadError from './image-upload-error';
import MediaEntry from './media-entry';

const StyledPendingImg = styled('img')`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  opacity: 0.15;
`;

type Props = {
  id?: number;
  displayOrder: number;
  path: string;
  /**
   * Does the content schema allow this MediaEntry have cooresponding indicators?
   * This is not the same as whether or not the MediaEntry has indicators.
   */
  hasIndicators?: boolean;
  indicators?: Indicator[];
  defaultIndicator?: Indicator;
  onChange: ChangeFunction;
  onChangeIndicator?: OnSave;
  onAddNewIndicator?: AddNewFunction;
  onRemoveIndicator?: RemoveItemFunction;
};

const SchemaMedia: FunctionComponent<Props> = ({
  id,
  indicators,
  hasIndicators,
  defaultIndicator,
  displayOrder,
  path,
  onChange,
  onChangeIndicator,
  onAddNewIndicator,
  onRemoveIndicator,
}) => {
  const {
    dragAndDropError,
    dragAndDropRef,
    isBeingDraggedOver,
    isBulkTransfer,
    pendingImageSrc,
    uploadError,
    handleClearError,
    handleFileEvent,
    handleRemoveImage,
  } = useImageUpload(onChange);

  const hasImage = Boolean(id);

  return (
    <Box component="div" order={displayOrder} tabIndex={displayOrder}>
      <Box
        marginBottom={1}
        display="flex"
        height={hasImage ? '50vh' : '25vh'}
        ref={dragAndDropRef}
        data-testid="schema-media:drag-and-drop-target"
        position="relative"
      >
        {pendingImageSrc ? (
          <StyledPendingImg src={pendingImageSrc} />
        ) : (
          <MediaEntry
            id={id}
            path={path}
            indicators={indicators}
            onFileSelect={handleFileEvent}
            hasIndicators={hasIndicators}
            onAddIndicator={onAddNewIndicator}
            onChangeIndicator={onChangeIndicator}
            onRemoveIndicator={onRemoveIndicator}
            defaultIndicator={defaultIndicator}
          />
        )}
        {uploadError && (
          <ImageUploadError
            details={uploadError.details}
            filename={uploadError.filename}
            hasPreviousImage={hasImage}
            clearError={handleClearError}
          />
        )}
        {isBeingDraggedOver && (
          <DroppableAreaOverlay
            isError={isBulkTransfer}
            message={dragAndDropError}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="left" gap={2}>
        <ImageButton
          hasImage={hasImage}
          onRemoveImage={handleRemoveImage}
          onUpdateImage={handleFileEvent}
        />
        {hasIndicators && (
          <AddIndicatorButton
            onAdd={onAddNewIndicator}
            defaultValue={defaultIndicator}
          />
        )}
      </Box>
    </Box>
  );
};

export default SchemaMedia;
