import { FCWithChildren } from 'types/fc-with-children';
import { memo } from 'react';
import { TableCell, TableCellProps, styled } from '@mui/material';

type Props = TableCellProps & {
  hideOverflow?: boolean;
};

export const StyledCellFade = styled('div')`
  display: flex;
  padding-right: 25px;
  width: 100%;
  white-space: nowrap;
  position: relative;
  overflow: hidden;

  :after {
    content: '';
    width: 50px;
    height: 100%;
    position: absolute;
    left: calc(100% - 50px);
    top: 0;
    background: linear-gradient(90deg, transparent, white);
  }

  .MuiTableRow-root:hover & {
    :after {
      background: linear-gradient(
        90deg,
        transparent,
        var(--color-neutral-three)
      );
    }
  }
`;

const StyledTableCell = styled(TableCell)`
  border-bottom: 1px solid var(--color-neutral-four);
  max-width: 325px;
`;

const ContentTableCell: FCWithChildren<Props> = ({
  hideOverflow,
  children,
  ...tableCellProps
}) => {
  if (hideOverflow) {
    return (
      <StyledTableCell {...tableCellProps}>
        <StyledCellFade data-testid="content-table-cell-fade">
          {children}
        </StyledCellFade>
      </StyledTableCell>
    );
  }

  return <StyledTableCell {...tableCellProps}>{children}</StyledTableCell>;
};

const memoizedContentTableCell = memo(ContentTableCell);

export default memoizedContentTableCell;
