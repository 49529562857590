import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { DropResult } from 'components/vertical-reorderable-list';

import AddCategory from './category-details/add-edit-category';
import CategoriesList from './categories-list';
import { useCategoryPanel } from './hooks';

type Props = {
  localizedCollection: LocalizedCollectionModel;
  selectedCategoryIndex: number;
  onReorderCategory: (result: DropResult) => void;
  insertCategory: (
    category: LocalizedCollectionCategory,
    atIndex?: number
  ) => Promise<boolean>;
  removeCategory: (atIndex: number) => Promise<boolean>;
  updateCategory: (
    category: Omit<LocalizedCollectionCategory, 'items'>,
    atIndex: number
  ) => Promise<boolean>;
};

const Categories: FC<Props> = ({
  localizedCollection,
  selectedCategoryIndex,
  onReorderCategory,
  insertCategory,
  removeCategory,
  updateCategory,
}) => {
  const { addCategory } = useCategoryPanel(insertCategory);

  return (
    <>
      <Grid container justifyContent="space-between" paddingX={4}>
        <Typography
          variant="button"
          flex="auto"
          alignSelf="center"
          textTransform="uppercase"
        >
          {'Categories'}
        </Typography>
        <AddCategory onSubmit={addCategory} />
      </Grid>
      <CategoriesList
        localizedCollection={localizedCollection}
        selectedCategoryIndex={selectedCategoryIndex}
        onReorderCategory={onReorderCategory}
        onRemoveCategory={removeCategory}
        onEditCategory={updateCategory}
      />
    </>
  );
};

export default Categories;
