import { styled } from '@mui/material';
import { FCWithChildren } from 'types/fc-with-children';

import PermissionStaticToast from './toasts/permissions-static-toast';
import LegacyStaticToast from './toasts/legacy-static-toast';
import SoftDeletedStaticToast from './toasts/soft-deleted-static-toast';
import { useReadOnlyContent } from './use-read-only-content';
import ReadOnlyToast from './toasts/read-only-toast';

const StyledDiv = styled('div')`
  position: absolute;
  height: 100%;
  margin-top: ${({ theme }) => `-${theme.spacing(6)}`};
  left: ${({ theme }) => `-${theme.spacing(6)}`};
  right: ${({ theme }) => `-${theme.spacing(6)}`};
  // Must use inline opacity here, because using the opacity property
  // creates a new stacking context, which greatly complicates things
  background-color: rgba(var(--color-neutral-eight-rgb), 0.15);
  z-index: 2;
`;

const ReadOnlyContent: FCWithChildren = ({ children }) => {
  const {
    hasPermission,
    isLegacy,
    isDeleted,
    isReadOnly,
  } = useReadOnlyContent();

  if (isDeleted) {
    return (
      <StyledDiv data-testid="read-only-content:soft-deleted">
        <SoftDeletedStaticToast />
        {children}
      </StyledDiv>
    );
  }

  if (isLegacy) {
    return (
      <StyledDiv data-testid="read-only-content:legacy-content">
        <LegacyStaticToast />
        {children}
      </StyledDiv>
    );
  }

  if (!hasPermission) {
    return (
      <StyledDiv data-testid="read-only-content:lacks-permission">
        <PermissionStaticToast />
        {children}
      </StyledDiv>
    );
  }

  if (isReadOnly) {
    return (
      <StyledDiv data-testid="read-only-content:readonly-content">
        <ReadOnlyToast />
        {children}
      </StyledDiv>
    );
  }

  return <div data-testid="read-only-content:has-permission">{children}</div>;
};

export default ReadOnlyContent;
