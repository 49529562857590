/**
 * Returns either the url specified language, or the default language. If neither are available within the content entry, then the first localized content entry's language is returned.
 * @param {number} contentEntry id of the content entry
 * @param {string} urlParamLanguage the language short code sourced form the url.
 * @param defaultLanguage the default language short code
 * @returns
 */
export const getBestDefaultLanguage = (
  contentEntry: ContentEntryModel,
  urlParamLanguage = '',
  defaultLanguage?: 'en' | 'es' | 'fr' | 'ja'
) => {
  const hasParamLanguage = contentEntry.localizedContentEntries.some(
    (lce) => lce.languageShortCode === urlParamLanguage
  );

  if (hasParamLanguage) {
    return urlParamLanguage;
  }

  const undeletedLanguages = contentEntry.localizedContentEntries.filter(
    (lce) => !lce.deletedAt
  );

  // check to see if the preferred locale actually exists within the content entry
  const hasLanguage = undeletedLanguages.some(
    (lce) => lce.languageShortCode === defaultLanguage
  );

  const preferredFallback =
    undeletedLanguages[0] || contentEntry.localizedContentEntries[0];

  // If the localized content entry exists for the preferred language then return that
  // Otherwise return the language of the first localized content entry
  return defaultLanguage && hasLanguage
    ? defaultLanguage
    : preferredFallback.languageShortCode;
};
