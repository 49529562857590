import { FCWithChildren } from 'types/fc-with-children';
import { Box } from '@mui/material';
import { CustomTypes } from 'slate';

type Props = {
  leaf: CustomTypes['Text'];
  attributes: any;
};

const Leaf: FCWithChildren<Props> = ({ attributes, children, leaf }) => (
  <Box
    component="span"
    fontWeight={leaf.bold ? 'bold' : 'inherit'}
    fontStyle={leaf.italic ? 'italic' : 'inherit'}
    {...attributes}
  >
    {children}
  </Box>
);

export default Leaf;
