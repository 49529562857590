import { useState, useMemo, useEffect, FunctionComponent } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandIcon from '@mui/icons-material/Add';
import ContractIcon from '@mui/icons-material/Remove';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledAccordion = styled(Accordion)<{ $containerHeight?: number }>`
  border-radius: 0px !important;
  margin: 3px 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #acacac;
  .MuiAccordionSummary-root {
    padding: 0px 24px;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: unset;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
  .MuiAccordionDetails-root {
    padding: 8px 24px 16px;
    max-height: ${({ $containerHeight }) =>
      $containerHeight ? $containerHeight - 350 : 200}px;
    overflow-y: auto;
  }
  &.MuiAccordion-root:before {
    display: none !important;
  }
`;

const StyledSelectableTypography = styled(Typography)<{ $isSelected: boolean }>`
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: rgba(0, 109, 199, 0.2);
      margin: -2px -24px !important;
      padding: 2px 24px;
    `}
`;

const StyledTypography = styled(Typography)<{ $isSelected: boolean }>`
  font-weight: ${({ $isSelected }) =>
    $isSelected ? 500 : 'normal'} !important;
`;

type Props = {
  operatingSystemVersions: OperatingSystemVersionModel[];
  isSelected: boolean;
  selectedOperatingSystem: string;
  onClick: (filter: string, manufacturer?: string) => void;
  containerHeight?: number;
};

type Accumulator = {
  [key: string]: OperatingSystemVersionModel[];
};

const OperatingSystemFilterPicker: FunctionComponent<Props> = ({
  operatingSystemVersions = [],
  selectedOperatingSystem,
  isSelected,
  onClick,
  containerHeight,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const toggleAccordion = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    setIsExpanded(isSelected);
  }, [isSelected]);

  const versionsByOperatingSystemAndRelease = useMemo(
    () =>
      operatingSystemVersions.reduce(
        (acc, version) => ({
          ...acc,
          [version.operatingSystem]: [
            ...(acc[version.operatingSystem] ?? []),
            version,
          ],
        }),
        {} as Accumulator
      ),
    [operatingSystemVersions]
  );

  const handleAccordionClick = () => {
    onClick('operatingSystem');
    toggleAccordion();
  };

  const handleOperatingSystemClick = (operatingSystem: string) => {
    onClick('operatingSystem', operatingSystem);
  };

  return (
    <StyledAccordion expanded={isExpanded} $containerHeight={containerHeight}>
      <AccordionSummary
        onClick={handleAccordionClick}
        expandIcon={isExpanded ? <ContractIcon /> : <ExpandIcon />}
      >
        <StyledTypography
          $isSelected={isSelected}
          variant="body1"
        >{`Operating System (${operatingSystemVersions.length})`}</StyledTypography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        {operatingSystemVersions.length === 0 ? (
          <Typography>{'No devices'}</Typography>
        ) : (
          Object.entries(versionsByOperatingSystemAndRelease)
            .sort()
            .map(([operatingSystem, versions]) => (
              <StyledSelectableTypography
                $isSelected={operatingSystem === selectedOperatingSystem}
                onClick={() => handleOperatingSystemClick(operatingSystem)}
                key={operatingSystem}
              >{`${operatingSystem} (${versions.length})`}</StyledSelectableTypography>
            ))
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

OperatingSystemFilterPicker.propTypes = {
  selectedOperatingSystem: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OperatingSystemFilterPicker;
