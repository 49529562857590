import { useCallback } from 'react';
import { OnSave } from 'components/draggable-indicators';
import { isDefined } from 'services/utils/type-guards/generic';
import {
  AddNewFunction,
  GenerateChangeFunction,
  RemoveItemFunction,
} from 'scenes/universal-content-editor';

import { useMediaContext } from '../schema-media';

type Params = {
  displayOrder: number;
  indicator?: Indicator;
  indicators?: Indicator[];
  path: string;
  generateOnChange: GenerateChangeFunction;
  onAddNew: AddNewFunction;
  onRemove: RemoveItemFunction;
};

export const useSchemaIndicator = ({
  displayOrder,
  indicator,
  indicators,
  path,
  generateOnChange,
  onAddNew,
  onRemove,
}: Params) => {
  const media = useMediaContext();
  const hasManyIndicators = isDefined(indicators);

  const definedIndicators = [...(indicators ?? []), indicator].filter(
    isDefined
  );
  const order = media?.displayOrder ?? displayOrder;

  const onChangeIndicator: OnSave = useCallback(
    (indicatorIndex: number, indicator: Indicator) => {
      const pathWithIndex = hasManyIndicators
        ? `${path}[${indicatorIndex}]`
        : path;
      generateOnChange(pathWithIndex)(indicator);
    },
    [generateOnChange, hasManyIndicators, path]
  );

  const onRemoveIndicator = useCallback(
    (index: number) => {
      if (hasManyIndicators) {
        onRemove(index);
      } else {
        generateOnChange(path)(undefined);
      }
    },
    [generateOnChange, hasManyIndicators, onRemove, path]
  );

  const onAddNewIndicator: AddNewFunction = useCallback(
    (newIndicator) => {
      if (hasManyIndicators) {
        onAddNew(newIndicator);
      } else {
        generateOnChange(path)(newIndicator as Indicator);
      }
    },
    [generateOnChange, hasManyIndicators, onAddNew, path]
  );

  return {
    media,
    definedIndicators,
    order,
    onAddNewIndicator,
    onChangeIndicator,
    onRemoveIndicator,
  };
};
