import { FC } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import CollapsibleAttributeList from 'components/collapsible-attribute-list';

type Props = {
  lce: LocalizedContentEntryModel | undefined;
};

export const ModalContent: FC<Props> = ({ lce }) => {
  if (!lce) {
    return (
      <Skeleton
        data-testid="delete-content-modal-rect-skeleton"
        variant="rectangular"
        width={300}
        height={200}
      />
    );
  }

  return (
    <Box>
      <Typography marginBottom={2}>
        {'You are about to delete the answer '}
        <Typography fontWeight="bold" component="span">
          {lce?.properties?.title}
        </Typography>
        {' in '}
        <Typography fontWeight="bold" component="span">
          {lce?.language}
        </Typography>
        {
          '. This answer will no longer be served as tutorial content, and will show as deleted in any referenced collections. Are you sure you want to delete this answer?'
        }
      </Typography>
      <CollapsibleAttributeList
        contentEntryId={lce?.contentEntryId}
        attributesDisabled={false}
      />
    </Box>
  );
};
