import { useState, useCallback } from 'react';
import { useAppToast } from 'contexts/app-toast-context';
import getPlural from 'services/utils/get-plural';

import { addTopicsToCollection } from '../../collection-utils';

export const useExistingContent = (
  collectionId: number,
  handleClose: VoidFunction,
  onAdd?: (addedContentEntryIds: number[]) => Promise<boolean>
) => {
  const [selectedContent, setSelectedContent] = useState<number[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const dispatchToast = useAppToast();

  const handleSelectedContentChange = useCallback((selection: number[]) => {
    setSelectedContent(selection);
  }, []);

  const handleAddContent = useCallback(async () => {
    const contentCount = selectedContent.length;
    // ideally this would never occur since the button is disabled
    // under these same conditions
    if (contentCount <= 0) return;

    setIsSaving(true);
    // redirect to external adding logic if provided
    if (onAdd) {
      const contentAdded = await onAdd(selectedContent);
      if (contentAdded) {
        handleClose();
        setIsSaving(false);
        return;
      }
    } else {
      const success = await addTopicsToCollection(
        collectionId,
        selectedContent
      );
      if (success) {
        dispatchToast({
          level: 'success',
          message: `${contentCount} ${getPlural(
            contentCount,
            'topic has',
            'topics have'
          )} been successfully added to your collection.`,
        });
        handleClose();
        setIsSaving(false);
        return;
      }
    }

    // if all above adding attempts fail...
    dispatchToast({
      level: 'error',
      message: (
        <>
          <b>
            {'We were unable to add the selected answers to the collection. '}
          </b>
          {'Please try again.'}
        </>
      ),
    });
  }, [selectedContent, onAdd, collectionId, dispatchToast, handleClose]);

  return {
    addContentButtonIsDisabled: selectedContent.length <= 0,
    handleAddContent,
    handleSelectedContentChange,
    isSaving,
  };
};
