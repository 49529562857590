import { FC, useCallback, useEffect, useState } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import { useRemoveArrayItem } from 'scenes/universal-content-editor/hooks/use-remove-array-item';
import { Delete } from '@mui/icons-material';

import AddNewButton from '../add-new-button';
import HeaderOffsetAnchor from '../header-offset-anchor';

type Props = {
  path: string;
  displayName: string;
  displayOrder: number;
  value?: number;
  level: number;
  required?: boolean;
  onAddNew?: VoidFunction;
  onChange: (newValue: number) => void;
  onRemove?: VoidFunction;
};

const SchemaNumberField: FC<Props> = ({
  path,
  level,
  displayName,
  displayOrder,
  value,
  required = false,
  onAddNew,
  onChange,
  onRemove,
}) => {
  const [_value, setValue] = useState<number>(value || 0);
  const {
    removeConfirmationModal,
    openRemoveConfirmationModal,
  } = useRemoveArrayItem(displayName, onRemove);

  // Update the internal value when the value prop changes
  useEffect(() => setValue(value || 0), [value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setValue(Number(e.target.value)),
    []
  );

  // When the user clicks away from the input, call the onChange
  // but only if the value has changed
  const onBlur = useCallback(() => {
    if (_value !== value) {
      onChange(_value);
    }
  }, [_value, onChange, value]);

  return (
    <Box component="div" order={displayOrder} tabIndex={displayOrder}>
      <HeaderOffsetAnchor path={path} />
      <Box display="flex" flexDirection="row-reverse">
        {onRemove && (
          <IconButton
            aria-label={`Delete ${displayName}`}
            onClick={openRemoveConfirmationModal}
          >
            <Delete fontSize="small" color="error" />
          </IconButton>
        )}
      </Box>
      <TextField
        fullWidth
        type="number"
        error={required && _value === 0}
        helperText={required && value === 0 && 'This field is required'}
        required={required}
        label={required ? displayName : `${displayName} (optional)`}
        onBlur={onBlur}
        value={_value}
        onChange={handleChange}
      />

      {onRemove && removeConfirmationModal}
      {onAddNew && (
        <AddNewButton
          onAddNew={onAddNew}
          displayName={displayName}
          level={level}
        />
      )}
    </Box>
  );
};

export default SchemaNumberField;
