import { ChangeEvent, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, FormControlLabel, Checkbox } from '@mui/material';
import { formatAttributeName } from 'services/ozmo-api/utils/format-attribute-name';
import styled from 'styled-components';
import { Operation } from 'components/attribute-editor/use-attribute-editor';

import { getPendingState, shouldBeChecked } from '../utils';

const StyledTypography = styled(Typography)`
  margin-bottom: 12px !important;
  font-weight: 500 !important;
`;

const StyledContainer = styled.div`
  margin-bottom: 24px;
`;

type Props = {
  label: string;
  attributeName: string;
  attributes: DeviceTypeModel[] | ManufacturerModel[];
  pendingOperations: Operation[];
  onSelect: (
    attributeName: string,
    attribute: DeviceTypeModel | ManufacturerModel,
    action: 'add' | 'remove'
  ) => void;
};

const GenericSelector: FunctionComponent<Props> = ({
  label,
  attributes,
  attributeName,
  pendingOperations,
  onSelect,
}) => {
  const handleSelect = (
    event: ChangeEvent<HTMLInputElement>,
    attribute: DeviceTypeModel | ManufacturerModel
  ) => {
    const action = event?.target.checked ? 'add' : 'remove';
    onSelect(attributeName, attribute, action);
  };
  return (
    <StyledContainer>
      <StyledTypography variant="h6">{label}</StyledTypography>
      {attributes.length === 0 && <Typography>{'No results'}</Typography>}
      {attributes.map((attribute: any) => {
        const { isPendingAdd, isPendingRemove } = getPendingState(
          attributeName,
          attribute.id,
          pendingOperations
        );

        return (
          <div key={attribute.id}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={shouldBeChecked(
                    attribute.isSelected,
                    isPendingRemove,
                    isPendingAdd
                  )}
                  onChange={(e) => handleSelect(e, attribute)}
                />
              }
              label={formatAttributeName(attribute.name)}
            />
          </div>
        );
      })}
    </StyledContainer>
  );
};

GenericSelector.propTypes = {
  label: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default GenericSelector;
