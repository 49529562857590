import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';

import { UseQueryOptionsWithPrefetch } from '../use-query-cache';

interface ResourcePathVariables {
  id?: number;
}
class ContentType extends OzmoApiBase<
  ContentTypeModel,
  void,
  void,
  ResourcePathVariables
>() {
  protected static resourcePath = 'authoring/content_types/:id';
  protected static embedOptions = [];
  protected static defaultReactQueryConfig: UseQueryOptionsWithPrefetch = {
    staleTime: 60000, // 1 minute
  };

  // Make this model read only- don't allow mutating or creating anything on the server
  // This will disable the update/create/delete methods
  protected static readonly = true;

  public getByName = (name: string): ContentTypeModel | undefined => {
    if (this.isLoading || this.all.length > 0) {
      return this.all.find((contentType) => contentType.name === name);
    }

    throw new Error('You must call ContentType.getAll() to use this method');
  };
}

export default ContentType;
