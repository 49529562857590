import { FunctionComponent } from 'react';
import { Skeleton, Typography, Box } from '@mui/material';
import ContentTypeWithIcon from 'components/content-type-with-icon';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { isLegacyContent } from 'services/utils/is-legacy-content';

type Props = {
  contentEntryId: number;
  languageId?: number;
};

const TitleAndContentType: FunctionComponent<Props> = ({
  contentEntryId,
  languageId,
}) => {
  const api = useOzmoApiService();
  const { data: contentEntry } = api.ContentEntry.get({ id: contentEntryId });

  if (!contentEntry) {
    return (
      <Skeleton
        data-testid="content-sidebar-description:skeleton"
        variant="rectangular"
      />
    );
  }

  const { contentType } = contentEntry;

  const isLegacyContentType = isLegacyContent(contentType);

  // Find and use the localized title if the language is provided
  const localizedTitle = contentEntry.localizedContentEntries.find(
    (lce) => lce.languageId === languageId
  )?.properties?.title;

  return (
    <Box display="flex" gap="0.45rem" alignItems="start">
      <Box marginTop="0.25rem">
        <ContentTypeWithIcon contentType={contentType} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography fontSize="1.15rem !important" fontWeight={500}>
          {localizedTitle ?? contentEntry?.title}
          <Typography
            component="span"
            color="var(--color-neutral-six) !important"
            paddingLeft={0.5}
            fontSize="0.938rem !important"
          >
            {`(#${contentEntryId})`}
          </Typography>
        </Typography>
        {isLegacyContentType && (
          <Typography
            color="var(--color-neutral-six) !important"
            fontStyle="italic"
          >
            {'(Legacy, read-only content)'}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TitleAndContentType;
