import { FC } from 'react';
import { EditOff } from '@mui/icons-material';
import ContentTypeWithIcon from 'components/content-type-with-icon';
import ContentTypeBase, {
  StyleOverrides,
} from 'components/content-type-with-icon/content-type-base';
import { isLegacyContent } from 'services/utils/is-legacy-content';

type Props = {
  contentType: ContentTypeModel;
};

const ContentTypeName: FC<Props> = ({ contentType: { name, displayName } }) => {
  const styleOverrides: StyleOverrides = {
    color: 'inherit',
    fontSize: '1em',
    fontWeight: 'normal',
    iconSize: '1.2em',
  };

  // In most cases the legacy content is just rendered with the name "legacy", not specifying which type.
  // The content type chip, however, needs to be more specific. As such, this uses the ContentTypeBase
  // to accomplish that.
  if (isLegacyContent(name)) {
    return (
      <ContentTypeBase label={displayName} Icon={EditOff} {...styleOverrides} />
    );
  }

  return (
    <ContentTypeWithIcon
      contentType={name}
      label={displayName}
      styleOverrides={styleOverrides}
    />
  );
};

export default ContentTypeName;
