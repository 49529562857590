import { useState, useCallback, ChangeEvent, useMemo, useEffect } from 'react';
import { useActionModal } from 'components/modals';
import { useAppToast } from 'contexts/app-toast-context';
import { useOzmoApiService } from 'contexts/ozmo-api-service-context';
import { generateCollectionPath } from 'scenes/nuevo-collection/util';
import pushOrRemove from 'services/utils/push-or-remove';
import { formatAttributeName } from 'services/ozmo-api/utils/format-attribute-name';
import { KEY_MAP } from 'components/duplicate-content-entry-dialog/hooks';
import { isListEqual } from 'components/duplicate-content-entry-dialog/utils';

import DuplicateCollectionModal from './duplicate-modal';

const toId = (item: BaseModel) => item.id;

const formatContentData = (
  collections: CollectionSearchResult[]
): CollectionTableData[] =>
  collections.map((ce) => {
    return {
      ...ce,
      attributes: [
        ...ce.devices.map((d) => ({ ...d, name: formatAttributeName(d.name) })),
        ...ce.deviceTypes,
        ...ce.manufacturers,
        ...ce.operatingSystems,
        ...ce.operatingSystemReleases,
        ...ce.operatingSystemVersions,
      ],
    };
  });

// Note: When we introduce sorting via the API, look into consolidating this hook and the useContentTable
// hook. They'll end up sharing a lot of functionality, and might be able to be abstracted into one hook.
export const useCollectionTable = (
  collections: CollectionSearchResult[],
  onSelectedChanged: (selectedIds: number[]) => void
) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const tableData: CollectionTableData[] = useMemo(
    () => formatContentData(collections),
    [collections]
  );

  useEffect(() => {
    onSelectedChanged(selectedIds);
  }, [selectedIds, onSelectedChanged]);

  const handleSelectItem = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const id = Number(event.target.value);
      setSelectedIds((currentSelected) =>
        pushOrRemove<number>(currentSelected, id)
      );
    },
    []
  );

  const handleSelectAll = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const itemIds = collections.map((td) => td.id);
        setSelectedIds(itemIds);
        return;
      }
      setSelectedIds([]);
    },
    [collections]
  );

  const isSelected = useCallback(
    (itemId: number) => selectedIds.includes(itemId),
    [selectedIds]
  );

  return {
    tableData,
    selectedIds,
    isSelected,
    handleSelectItem,
    handleSelectAll,
  };
};

export const useDuplicateCollection = () => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();
  const [activeCollectionId, setActiveCollectionId] = useState<
    number | undefined
  >(undefined);

  const { data: collection } = api.Collection.get({
    id: activeCollectionId,
  });

  const [attributes, setAttributes] = useState({});

  const collectionAttributes = useMemo(() => {
    return {
      Device: collection?.devices?.map(toId),
      DeviceType: collection?.deviceTypes?.map(toId),
      Manufacturer: collection?.manufacturers?.map(toId),
      OperatingSystem: collection?.operatingSystems?.map(toId),
      OperatingSystemRelease: collection?.operatingSystemReleases?.map(toId),
      OperatingSystemVersion: collection?.operatingSystemVersions?.map(toId),
    };
  }, [collection]);

  const onDuplicate = useCallback(async () => {
    try {
      const newCollection = await api.Collection.duplicate(
        collection.id,
        attributes
      );

      if (newCollection) {
        dispatchToast({
          level: 'success',
          message: `${newCollection.name} was duplicated`,
          linkText: 'View collection',
          linkPath: generateCollectionPath(newCollection.id),
        });

        return newCollection;
      }

      dispatchToast({
        level: 'error',
        message: `${collection.name} could not be duplicated. Please try again.`,
      });
    } catch (_) {
      dispatchToast({
        level: 'error',
        message: `${collection.name} could not be duplicated. Please try again.`,
      });
    }
  }, [api.Collection, collection, attributes, dispatchToast]);

  const canDuplicate = useMemo(() => {
    if (Object.keys(attributes).length === 0) {
      return false;
    }

    const allAttributesEqual = Object.entries(KEY_MAP).every(
      ([initialKey, attributeKey]) => {
        return isListEqual(
          collectionAttributes[initialKey as keyof typeof collectionAttributes],
          attributes[attributeKey as keyof typeof attributes]
        );
      }
    );

    return !allAttributesEqual;
  }, [attributes, collectionAttributes]);

  const { modal, openModal } = useActionModal({
    modalProps: {
      title: 'You are about to duplicate a collection',
      maxWidth: 'md',
      confirmButtonText: 'Duplicate collection',
      disableConfirmButton: !canDuplicate,
    },
    modalContent: (
      <DuplicateCollectionModal
        key={collection?.id ?? 0}
        collection={collection}
        startingAttributes={collectionAttributes}
        setAttributes={setAttributes}
      />
    ),
    onConfirm: onDuplicate,
  });

  const openModalAndSetActive = useCallback(
    (activeCollectionId: number) => {
      setActiveCollectionId(activeCollectionId);
      setAttributes({});
      openModal();
    },
    [openModal]
  );

  return { modal, openModal: openModalAndSetActive };
};
