import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppToast } from 'contexts/app-toast-context';

import { useCurrentCollection } from '../hooks';
import { generateCollectionPath } from '../util';

export const useCategories = () => {
  const {
    collection,
    language,
    categoryId,
    isLoading: collectionisLoading,
    addCategory,
    updateCategory,
    deleteCategory,
  } = useCurrentCollection();
  const dispatchToast = useAppToast();
  const navigate = useNavigate();
  const location = useLocation();

  // categories are currently single-layered, but can be pre-processed here to nest in the future
  const categories = useMemo(() => {
    return collection?.categories ?? [];
  }, [collection?.categories]);

  const allAnswersCategory = useMemo(() => {
    const allContentEntries =
      collection?.categories
        ?.flatMap((cat) => cat.contentEntries)
        ?.filter((ce) => ce) ?? [];
    return ({
      name: `All answers (${allContentEntries.length})`,
      contentEntries: allContentEntries,
    } as unknown) as CategoryModel;
  }, [collection?.categories]);

  const selectedCategory = useMemo(() => {
    const catId = Number(categoryId);
    return categories.find((cat) => cat.id === catId) ?? allAnswersCategory;
  }, [categories, allAnswersCategory, categoryId]);

  const onSelectCategory = useCallback(
    (categoryId: number) => {
      navigate({
        pathname: generateCollectionPath(collection.id, language, categoryId),
        search: location.search,
      });
    },
    [navigate, collection, language, location]
  );

  const addOrEditCategory = useCallback(
    async (category: Pick<CategoryModel, 'name'>, categoryId?: number) => {
      if (categoryId) {
        const success = await updateCategory(categoryId, category.name);
        if (success) {
          dispatchToast({
            level: 'success',
            message: 'Your category was successfully updated.',
          });
        } else {
          dispatchToast({
            level: 'error',
            message:
              'Something went wrong and your category may not have been updated.',
          });
        }
        return success;
      }

      const success = await addCategory(category.name);
      if (success) {
        dispatchToast({
          level: 'success',
          message: `${category.name} was created successfully.`,
        });
      } else {
        dispatchToast({
          level: 'error',
          message:
            'Something went wrong and your category may not have been created.',
        });
      }
      return success;
    },
    [addCategory, dispatchToast, updateCategory]
  );

  return {
    collection,
    categories,
    collectionisLoading,
    allAnswersCategory,
    onSelectCategory,
    addOrEditCategory,
    deleteCategory,
    selectedCategory,
    selectedCategoryId: categoryId ? Number(categoryId) : 0,
  };
};
