import styled, { css } from 'styled-components';

import { Hotspot } from '../../base';

const HotspotIndicator = styled(Hotspot).attrs({
  role: 'presentation',
  'aria-hidden': true,
})<HotspotIndicator>`
  top: ${(p) => p.y}%;
  left: ${(p) => p.x}%;
  transform: translate(-50%, -50%);
  transition: transform 0.5s cubic-bezier(0.5, 1.86, 0.45, 0.69);
  z-index: 1;

  & > circle:first-of-type {
    transition: stroke-width 0.3s ease-in-out;
  }

  & > circle:nth-of-type(2) {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.92;
  }

  ${(p) =>
    p.isActive &&
    p.isSynced &&
    !p.ieMode &&
    css`
      transform: scale(1.5) translate(-35.5%, -35.5%);
      z-index: 8;
      cursor: pointer;
      & > circle:first-of-type {
        stroke-width: 3px;
      }

      circle:nth-of-type(2) {
        opacity: 0.2;
      }
    `}

  /* ie11 - overview list styles */
  ${(p) =>
    p.isActive &&
    p.isSynced &&
    p.ieMode &&
    css`
      transform: scale(1.5) translate(-35.5%, -35.5%);
      & > circle:first-of-type {
        stroke: var(--color-indicator-inner);
        stroke-width: 3px;
      }

      circle:nth-of-type(2) {
        opacity: 0;
      }
    `}

    ${(p) =>
    p.isZoomed &&
    css`
      transform: scale(1.5) translate(-35.5%, -35.5%);

      & > circle:first-of-type {
        stroke: var(--color-indicator-inner);
        stroke-width: 3px;
      }

      circle:nth-of-type(2) {
        opacity: 0;
      }
    `}

    ${(p) =>
    !p.isZoomed &&
    !p.ieMode &&
    css`
      &:hover {
        transform: scale(1.5) translate(-35.5%, -35.5%);

        & > circle:first-of-type {
          stroke-width: 3px;
        }

        circle:nth-of-type(2) {
          opacity: 0.2;
        }
      }
    `}

    /* ie11 - hotspot step styles */
    ${(p) =>
    p.ieMode &&
    css`
      transform: translate(-50, -50%);

      & > circle:first-of-type {
        stroke: var(--color-indicator-inner);
        stroke-width: 3px;
      }

      circle:nth-of-type(2) {
        opacity: 0.2;
      }

      circle:nth-of-type(2):hover {
        opacity: 0;
      }
    `}


      @media screen and (max-width: 650px) {
    transform: scale(1.5) translate(-35.5%, -35.5%);

    & > circle:first-of-type {
      stroke: var(--color-indicator-inner);
      stroke-width: 3px;
    }

    circle:nth-of-type(2) {
      opacity: 0.2;
    }

    circle:nth-of-type(2):hover {
      opacity: 0;
    }

    ${(p) =>
      p.isZoomed &&
      css`
        transform: scale(1.5) translate(-35.5%, -35.5%);

        & > circle:first-of-type {
          stroke: var(--color-indicator-inner);
          stroke-width: 3px;
        }

        circle:nth-of-type(2) {
          opacity: 0;
        }
      `}
  }

  @media print {
    animation: none;
  }
`;

export default HotspotIndicator;
