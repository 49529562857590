import { FC, useMemo } from 'react';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  SelectProps,
} from '@mui/material';

type Props = SelectProps & {
  label: string;
  value: number | string;
  shrinkLabel?: boolean;
};

const Select: FC<Props> = ({
  label,
  value,
  shrinkLabel,
  children,
  ...selectProps
}) => {
  const normalizedLabel = useMemo(() => {
    return `select-${label.toLocaleLowerCase().replace(/[^\w]+/g, '-')}`;
  }, [label]);

  return (
    <FormControl fullWidth>
      <InputLabel id={normalizedLabel} shrink={shrinkLabel}>
        {label}
      </InputLabel>
      <MuiSelect
        {...selectProps}
        notched={shrinkLabel}
        labelId={normalizedLabel}
        label={label}
        value={value ? value.toString() : ''}
      >
        {children}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
