import { FunctionComponent } from 'react';
import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { isLegacyContent } from 'services/utils/is-legacy-content';

import { useLocalizedReferenceActions } from './hooks';
import LocalizedReferenceActionsMenuOptions from './localized-reference-actions-menu';

type Props = LocalizedCollectionReference & {
  editUrl: string;
  categoryId: number;
  collectionId: number;
};

const LocalizedReferenceActions: FunctionComponent<Props> = ({
  editUrl,
  collectionId,
  categoryId,
  ...reference
}) => {
  const { contentType, title } = reference;
  const isLegacyContentType = isLegacyContent(contentType);
  const {
    anchorEl,
    isOpen,
    handleOpen,
    handleClose,
  } = useLocalizedReferenceActions();

  return (
    <>
      <IconButton key={0} aria-label="More options" onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <LocalizedReferenceActionsMenuOptions
          answerTitle={title}
          editUrl={editUrl}
          contentEntryId={reference.id}
          contentEntryTitle={title}
          collectionId={collectionId}
          categoryId={categoryId}
          isLegacyContentType={isLegacyContentType}
        />
      </Menu>
    </>
  );
};

export default LocalizedReferenceActions;
