import { HTMLProps } from 'react';
import { FCWithChildren } from 'types/fc-with-children';
import { CustomTypes } from 'slate';

import {
  isLinkElement,
  isBulletedListElement,
  isListemItemElement,
} from '../types';

type Props = {
  attributes: HTMLProps<any>;
  element: CustomTypes['Element'];
};

const Element: FCWithChildren<Props> = ({ attributes, children, element }) => {
  if (isBulletedListElement(element)) {
    return (
      <ul style={{ listStyle: 'disc' }} {...attributes}>
        {children}
      </ul>
    );
  }
  if (isListemItemElement(element)) {
    return <li {...attributes}>{children}</li>;
  }
  if (isLinkElement(element)) {
    return (
      <a href={element.url} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }
  return <p {...attributes}>{children}</p>;
};

export default Element;
