import { FC } from 'react';
import { PermissionRequired } from 'components/permission-required';
import DeleteLocalizedContentEntry from 'components/delete-localized-content-entry';
import RestoreLocalizedContentEntry from 'components/restore-localized-content-entry';

import { useCurrentLocalizedContentEntry } from '../hooks/use-current-localized-content-entry';

type Props = {
  contentEntryId: number;
};

const DeleteRestoreButton: FC<Props> = ({ contentEntryId }) => {
  const { localizedContentEntry } = useCurrentLocalizedContentEntry();

  return localizedContentEntry.deletedAt ? (
    <PermissionRequired
      key="restore-button"
      permission="POST:/v1/authoring/content_entries/*/localized_content_entries/*/restore/"
      authApplicationName="API"
    >
      <RestoreLocalizedContentEntry
        contentEntryId={contentEntryId}
        localizedContentEntryId={localizedContentEntry.id}
      />
    </PermissionRequired>
  ) : (
    <PermissionRequired
      key="delete-button"
      permission="DELETE:/v1/authoring/content_entries/*/localized_content_entries/*/"
      authApplicationName="API"
    >
      <DeleteLocalizedContentEntry
        contentEntryId={contentEntryId}
        localizedContentEntryId={localizedContentEntry.id}
      />
    </PermissionRequired>
  );
};

export default DeleteRestoreButton;
