import { FC } from 'react';
import { Skeleton, Collapse, Box, Button } from '@mui/material';
import AttributesTagCloud from 'components/tag-cloud/attributes-tag-cloud';

import { useCollapsibleAttributeList } from './hooks';

type Props = {
  contentEntryId: number;
  startExpanded?: boolean;
  attributesDisabled?: boolean;
};

const CollapsibleAttributeList: FC<Props> = ({
  contentEntryId,
  startExpanded = false,
  attributesDisabled = true,
}) => {
  const {
    open,
    isLoading,
    buttonIcon,
    buttonText,
    attributes,
    handleOpen,
  } = useCollapsibleAttributeList(contentEntryId, startExpanded);

  if (isLoading) {
    return (
      <Skeleton
        data-testid="collapsible-attributes-tag-list-skeleton"
        variant="text"
      />
    );
  }

  return (
    <Box>
      <Button startIcon={buttonIcon} onClick={handleOpen}>
        {buttonText}
      </Button>
      <Collapse in={open} timeout="auto">
        <AttributesTagCloud disabled={attributesDisabled} {...attributes} />
      </Collapse>
    </Box>
  );
};

export default CollapsibleAttributeList;
