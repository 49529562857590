import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';
import api from 'services/ozmo-api';
import { isLocalizedCollectionCategory } from 'services/utils/type-guards/localized-collection';
import camelCaseToSnakeCase from 'services/utils/convert-object-keys-camel-to-snake';
import {
  updateData,
  onMutationError,
  updateCacheOptimistically,
  UrlParamOptions,
  generateQueryKey,
  UseQueryOptionsWithPrefetch,
} from 'services/ozmo-api/use-query-cache';
import { useMemo } from 'react';

import { StaleWriteError } from '../errors';

interface ResourcePathVariables {
  id: number;
  contentEntryId: number;
}

class LocalizedCollection extends OzmoApiBase<
  LocalizedCollectionModel,
  LocalizedCollectionUpdateModel,
  LocalizedCollectionCreateModel,
  ResourcePathVariables
>() {
  /* Protected members */
  protected static readonly = true;
  protected static resourcePath =
    'authoring/content_entries/:contentEntryId/localized_content_entries/:id';
  protected static embedOptions = [];
  protected static defaultReactQueryConfig: UseQueryOptionsWithPrefetch = {
    staleTime: 300000, // 5 minutes
  };
  protected static urlParamOptions: UrlParamOptions = { asCollection: true };

  /* Public members */
  // This is *appears* to be a violation of the Rules of Hooks, because it thinks this is a class
  // _component_, but really this is just a normal JS class, and will be instantiated inside a
  // react function component, so this is perfectly safe and good
  /* eslint-disable react-hooks/rules-of-hooks */
  public categories = useMemo(
    () => LocalizedCollection.getCategories(this.data),
    [this.data?.properties?.items]
  );

  public uncategorizedItems = useMemo(
    () => LocalizedCollection.getUncategorizedItems(this.data),
    [this.data?.properties?.items]
  );

  public allReferences = useMemo(
    () => LocalizedCollection.getAllReferences(this.data),
    [this.data?.properties?.items]
  );
  /* eslint-enable react-hooks/rules-of-hooks */

  public static getCategories = (
    l: LocalizedCollectionModel
  ): LocalizedCollectionCategory[] =>
    (l?.properties?.items ?? []).filter(isLocalizedCollectionCategory);

  // returns all the reference items inside the localized collection. this will include
  // both uncategorized and categorized items.
  public static getAllReferences = (
    l: LocalizedCollectionModel,
    removeDups = false
  ): (LocalizedCollectionReference | MissingLocalizedCollectionReference)[] => {
    const uncategorizedReferences = LocalizedCollection.getUncategorizedItems(
      l
    );
    const categorizedReferences = LocalizedCollection.getCategories(l).flatMap(
      (c) => c.items
    );

    if (removeDups) {
      return [...categorizedReferences, ...uncategorizedReferences].filter(
        (ref, index, arr) => index === arr.findIndex((r) => r.id === ref.id)
      );
    }

    return [...categorizedReferences, ...uncategorizedReferences];
  };

  // returns all references inside the localized collection that are of the status(es) given.
  public static getReferencesPerStatus = (
    l: LocalizedCollectionModel,
    status: LocalizedContentEntryStatus[]
  ): LocalizedCollectionReference[] => {
    const allReferences = LocalizedCollection.getAllReferences(l);
    // @ts-ignore - TS can't infer here that this will never be MissingLocalizedCollectionReference
    // the reason is that type's status is null, which should not be passed in as a legitmate status
    return allReferences.filter((ref) => status.includes(ref.status));
  };

  // It would be preferred to implement a proper getAllAsync method, but this will work for now.
  public static getAllLocalizedCollectionsAsync = async (
    contentEntryId: number
  ): Promise<LocalizedCollectionModel[]> => {
    const { localizedContentEntries } = await api.ContentEntry.getAsync({
      id: contentEntryId,
    });

    const promises = localizedContentEntries.map(
      async (lce) =>
        await LocalizedCollection.getAsync({
          id: lce.id,
          contentEntryId: lce.contentEntryId,
        })
    );

    return await Promise.all(promises);
  };

  public static getUncategorizedItems = (l: LocalizedCollectionModel) =>
    (l?.properties?.items ?? []).filter(
      (item) => !isLocalizedCollectionCategory(item)
      // while this negates the type guard for finding categories, TS
      // cant seem to infer the restricted types from it. So casting manually here.
    ) as (LocalizedCollectionReference | MissingLocalizedCollectionReference)[];

  public static extractTopicGroups = (
    items: (
      | LocalizedCollectionReference
      | MissingLocalizedCollectionReference
    )[]
  ) => {
    const topicGroups = items.reduce<{
      [key: string]: (
        | LocalizedCollectionReference
        | MissingLocalizedCollectionReference
      )[];
    }>(
      (acc, cur) => ({
        ...acc,
        [cur.topicSlug!]: [...(acc[cur.topicSlug!] ?? []), cur],
      }),
      {}
    );

    const topicsWithManyReferences = Object.keys(topicGroups).filter(
      (topicSlug) => {
        if (topicGroups[topicSlug].length <= 1) {
          return !topicGroups[topicSlug];
        }

        return true;
      }
    );

    return {
      topicGroups,
      topicsWithManyReferences,
    };
  };
  /**
   * Update a LocalizedCollection
   *
   * This method takes in data formatted as a LocalizedCollection and reformats it to the
   * id/reference format used by the underlying LocalizedContentEntry before updating the API.
   * It also optimisitically updates the cache using the LocalizedCollection format, and will rollback
   * if the update request is unsuccessful.
   */
  public update = async (
    data: LocalizedCollectionUpdateModel
  ): Promise<LocalizedCollectionModel> => {
    const { id, contentEntryId } = this.data;
    const { resourcePath } = LocalizedCollection;
    const [parsedPath] = OzmoApiBase().parseResourcePathString(resourcePath, {
      id,
      contentEntryId,
    });

    const queryKeyAsCollection = generateQueryKey(
      id,
      parsedPath,
      LocalizedCollection.urlParamOptions
    );

    const { queryClient } = api;
    // Get a copy of the current value so we can rollback to it if the server mutation fails
    const previousVersion = queryClient.getQueryData(
      queryKeyAsCollection
    ) as LocalizedCollectionModel;

    return LocalizedCollection.doUpdate(
      id,
      parsedPath,
      queryKeyAsCollection,
      data,
      previousVersion
    );
  };

  /**
   * Update a LocalizedCollection
   *
   * This method takes in data formatted as a LocalizedCollection and reformats it to the
   * id/reference format used by the underlying LocalizedContentEntry before updating the API.
   * It also optimisitically updates the cache using the LocalizedCollection format, and will rollback
   * if the update request is unsuccessful.
   *
   * @param id LocalizedContentEntryId of the collection
   * @param contentEntryId ContentEntryID of the collection
   * @param data Data to update
   * @returns A promise that resolves to the updated data
   */
  public static updateAsync = async (
    options: ResourcePathVariables,
    data: LocalizedCollectionUpdateModel
  ): Promise<LocalizedCollectionModel> => {
    const { id, contentEntryId } = options;
    const { resourcePath } = LocalizedCollection;
    const [parsedPath] = OzmoApiBase().parseResourcePathString(resourcePath, {
      id,
      contentEntryId,
    });

    const queryKeyAsCollection = generateQueryKey(
      id,
      parsedPath,
      LocalizedCollection.urlParamOptions
    );

    // Get a copy of the current value so we can rollback to it if the server mutation fails
    const previousVersion = await LocalizedCollection.getAsync({
      id,
      contentEntryId,
    });

    return LocalizedCollection.doUpdate(
      id,
      parsedPath,
      queryKeyAsCollection,
      data,
      previousVersion
    );
  };

  public moveCategoryInAllLanguages = async (
    sourceIndex: number,
    destinationIndex: number
  ): Promise<boolean> => {
    return LocalizedCollection.updateAllLocalizedCollections(
      this.data.contentEntryId,
      async (localizedCollection) => {
        const categories = LocalizedCollection.getCategories(
          localizedCollection
        );
        const uncategorizedItems = LocalizedCollection.getUncategorizedItems(
          localizedCollection
        );
        const category = categories[sourceIndex];
        categories.splice(sourceIndex, 1);
        categories.splice(destinationIndex, 0, category);

        const newItems = [...categories, ...uncategorizedItems];

        return {
          properties: {
            ...localizedCollection.properties,
            items: newItems,
          },
        };
      }
    );
  };

  /**
   *
   * @param sourceIndex Index of the item to move
   * @param destinationIndex Index of the place to move the item to
   * @param categoryIndex Index of the category this is happening in
   * @returns (boolean) Were all localized versions of the collection updated
   */
  public moveItemInCategoryInAllLanguages = async (
    sourceIndex: number,
    destinationIndex: number,
    categoryIndex: number
  ): Promise<boolean> => {
    return LocalizedCollection.updateAllLocalizedCollections(
      this.data.contentEntryId,
      async (localizedCollection) => {
        const categories = LocalizedCollection.getCategories(
          localizedCollection
        );
        const uncategorizedItems = LocalizedCollection.getUncategorizedItems(
          localizedCollection
        );
        const category = categories[categoryIndex];

        // -1 categoryIndex indicates uncategorized item was moved
        if (categoryIndex === -1) {
          const newUncategorizedItems = [...uncategorizedItems];
          const item = uncategorizedItems[sourceIndex];
          newUncategorizedItems.splice(sourceIndex, 1);
          newUncategorizedItems.splice(destinationIndex, 0, item);

          return {
            properties: {
              ...localizedCollection.properties,
              items: [...categories, ...newUncategorizedItems],
            },
          };
        }

        // else assume an item within a category was moved
        const items = [...category.items];
        const item = category.items[sourceIndex];
        items.splice(sourceIndex, 1);
        items.splice(destinationIndex, 0, item);

        const newCategories = Object.assign([], categories, {
          [categoryIndex]: { ...category, items },
        });

        return {
          properties: {
            ...localizedCollection.properties,
            items: [...newCategories, ...uncategorizedItems],
          },
        };
      }
    );
  };

  public removeItemInCategoryInAllLanguages = async (
    itemId: number,
    categoryIndex: number
  ): Promise<boolean> => {
    return LocalizedCollection.removeItemInCategoryInAllLanguagesAsync(
      this.data.contentEntryId,
      itemId,
      categoryIndex
    );
  };

  public static removeItemInCategoryInAllLanguagesAsync = async (
    collectionId: number,
    itemId: number,
    categoryIndex: number
  ): Promise<boolean> => {
    return LocalizedCollection.updateAllLocalizedCollections(
      collectionId,
      async (localizedCollection) => {
        const categories = LocalizedCollection.getCategories(
          localizedCollection
        );
        const uncategorizedItems = LocalizedCollection.getUncategorizedItems(
          localizedCollection
        );
        if (categoryIndex === -1) {
          const newUncategorizedItems = [...uncategorizedItems];
          const itemIndex = newUncategorizedItems.findIndex(
            (i) => i.id === itemId
          );
          newUncategorizedItems.splice(itemIndex, 1);
          return {
            properties: {
              ...localizedCollection.properties,
              items: [...categories, ...newUncategorizedItems],
            },
          };
        }
        const category = categories[categoryIndex];
        const itemIndex = category.items.findIndex((i) => i.id === itemId);
        const items = [
          ...category.items.slice(0, itemIndex),
          ...category.items.slice(itemIndex + 1),
        ];

        const newCategories = Object.assign([], categories, {
          [categoryIndex]: { ...category, items },
        });

        return {
          properties: {
            ...localizedCollection.properties,
            items: [...newCategories, ...uncategorizedItems],
          },
        };
      }
    );
  };

  /**
   * Ensure a reference is in all the included categories:
   *  - If it is leave it
   *  - If it isn't but should be, add it at the end
   *  - If it is but shouldn't be remove it
   * @param reference The LocalizedCollectionReference to add/remove
   * @param categoryIndices The category indcides the reference could be in
   */
  public reconcileReferenceInCategoriesInAllLanguages = async (
    reference: LocalizedCollectionReference,
    categoryIndices: number[]
  ): Promise<boolean> => {
    return LocalizedCollection.updateAllLocalizedCollections(
      this.data.contentEntryId,
      async (localizedCollection) => {
        const categories = LocalizedCollection.getCategories(
          localizedCollection
        );
        const uncategorizedItems = LocalizedCollection.getUncategorizedItems(
          localizedCollection
        );

        const newCategories = categories.map((category, categoryIndex) => {
          // Find the reference in the category
          const index = category.items.findIndex(
            (item) => item.id === reference.id
          );
          // Is the reference in the category
          if (index > -1) {
            // If it should be no changes needed
            if (categoryIndices.indexOf(categoryIndex) > -1) {
              return category;
            }
            // If it shouldn't be, remove it
            return {
              ...category,
              items: [
                ...category.items.slice(0, index),
                ...category.items.slice(index + 1),
              ],
            };
          }
          // Otherwise the reference isn't currently in the category
          // If it should be, add it
          if (categoryIndices.indexOf(categoryIndex) > -1) {
            return {
              ...category,
              items: [...category.items, reference],
            };
          }
          // Otherwise return the category unchanged
          return category;
        });

        // If the answer was in the Uncategorized Items, remove it
        const newUncategorizedItems = uncategorizedItems.filter(
          (item) => item.id !== reference.id
        );

        if (categoryIndices.length === 0) {
          newUncategorizedItems.push(reference);
        }
        return {
          properties: {
            ...localizedCollection.properties,
            items: [...newCategories, ...newUncategorizedItems],
          },
        };
      }
    );
  };

  public insertCategoryInAllLanguages = async (
    category: LocalizedCollectionCategory,
    atIndex?: number
  ): Promise<boolean> => {
    return LocalizedCollection.updateAllLocalizedCollections(
      this.data.contentEntryId,
      async (localizedCollection) => {
        const categories = LocalizedCollection.getCategories(
          localizedCollection
        );
        const uncategorizedItems = LocalizedCollection.getUncategorizedItems(
          localizedCollection
        );
        const index = Number(atIndex);

        let newCategories: (
          | LocalizedCollectionCategory
          | LocalizedCollectionReference
          | MissingLocalizedCollectionReference
        )[] = [];

        if (
          Number.isInteger(index) &&
          index >= 0 &&
          index < categories.length
        ) {
          newCategories = [
            ...categories.slice(0, index),
            category,
            ...categories.slice(index),
            ...uncategorizedItems,
          ];
        } else {
          newCategories = [...categories, category, ...uncategorizedItems];
        }

        return {
          properties: {
            ...localizedCollection.properties,
            items: newCategories,
          },
        };
      }
    );
  };

  public removeCategoryInAllLanguages = async (
    atIndex: number
  ): Promise<boolean> => {
    return LocalizedCollection.updateAllLocalizedCollections(
      this.data.contentEntryId,
      async (localizedCollection) => {
        const categories = LocalizedCollection.getCategories(
          localizedCollection
        );
        const uncategorizedItems = LocalizedCollection.getUncategorizedItems(
          localizedCollection
        );

        if (atIndex < 0 || atIndex > categories.length) {
          return {
            properties: { ...localizedCollection.properties },
          };
        }

        categories.splice(atIndex, 1);
        const newItems = [...categories, ...uncategorizedItems];

        return {
          properties: {
            ...localizedCollection.properties,
            items: newItems,
          },
        };
      }
    );
  };

  public updateCategory = async (
    category: Omit<LocalizedCollectionCategory, 'items'>,
    atIndex: number
  ): Promise<boolean> => {
    const localizedCollection = this.data;
    const categories = LocalizedCollection.getCategories(localizedCollection);
    const uncategorizedItems = LocalizedCollection.getUncategorizedItems(
      localizedCollection
    );
    const index = Number(atIndex);

    // check category defined, and index is within the bounds of all categories
    if (
      !category ||
      !Number.isInteger(index) ||
      index < 0 ||
      index > categories.length
    ) {
      return false;
    }

    const updatedCategoryItems = categories[index].items;
    const updatedCategory: LocalizedCollectionCategory = {
      ...category,
      items: updatedCategoryItems,
    };

    categories.splice(index, 1, updatedCategory);

    const newProperties: Pick<LocalizedCollectionUpdateModel, 'properties'> = {
      properties: {
        ...localizedCollection.properties,
        items: [...categories, ...uncategorizedItems],
      },
    };

    await LocalizedCollection.updateAsync(
      {
        id: localizedCollection.id,
        contentEntryId: localizedCollection.contentEntryId,
      },
      newProperties
    );
    return true;
  };

  public addReferencesInAllLanguages = async (
    referenceIds: number[],
    intoCategoryIndex = -1
  ) => {
    const result = await LocalizedCollection.updateAllLocalizedCollections(
      this.data.contentEntryId,
      async (localizedCollection) => {
        const newReferences = await Promise.all(
          referenceIds.map(async (refId) => {
            const reference = await api.ContentEntry.getAsync({ id: refId });

            return ({
              id: refId,
              contentType: reference.contentType,
              contentTypeId: reference.contentTypeId,
              space: reference.space?.name,
              spaceId: reference.spaceId,
              topic: reference.topic?.title,
              topicSlug: reference.topic?.slug,
              createdAt: reference.createdAt,
              updatedAt: reference.updatedAt,
              title: reference.title,
            } as unknown) as LocalizedCollectionReference;
          })
        );

        // categoryIndex of -1 indicates uncategorized
        if (intoCategoryIndex === -1) {
          // add to end of uncategorized when no category index provided
          const newItems = localizedCollection.properties.items.concat(
            newReferences
          );

          return {
            properties: {
              ...localizedCollection.properties,
              items: newItems,
            },
          };
        }

        const categories = LocalizedCollection.getCategories(
          localizedCollection
        );
        const uncategorizedItems = LocalizedCollection.getUncategorizedItems(
          localizedCollection
        );

        const beforeCategories = categories.slice(0, intoCategoryIndex);
        const afterCategories = categories.slice(intoCategoryIndex + 1);
        const thisCategory = categories[intoCategoryIndex];

        const newCategory = {
          ...thisCategory,
          items: [...thisCategory.items, ...newReferences],
        };

        return {
          properties: {
            ...localizedCollection.properties,
            items: [
              ...beforeCategories,
              newCategory,
              ...afterCategories,
              ...uncategorizedItems,
            ],
          },
        };
      }
    );

    this.refetch();
    return result;
  };

  public static getAll = (): any => {
    console.warn('Not implemented');
  };

  public static getAllAsync = async (): Promise<any> => {
    console.warn('Not implemented');
  };

  /* Private members */
  /* perform operation on all localized variants */
  private static async updateAllLocalizedCollections(
    contentEntryId: number,
    fn: (
      localizedCollection: LocalizedCollectionModel
    ) => Promise<Pick<LocalizedCollectionUpdateModel, 'properties'>>
  ): Promise<boolean> {
    const { localizedContentEntries } = await api.ContentEntry.getAsync({
      id: contentEntryId,
    });

    const promises = localizedContentEntries.map(async (lce) => {
      const localizedCollection = await LocalizedCollection.getAsync({
        id: lce.id,
        contentEntryId: lce.contentEntryId,
      });

      const updatedLce = await fn(localizedCollection);

      await LocalizedCollection.updateAsync(
        { id: lce.id, contentEntryId: lce.contentEntryId },
        updatedLce
      );
      return true;
    });

    const results = await Promise.all(promises);
    // If any of them failed, report a failure
    return results.every((r) => r);
  }

  // Convert from the fancy LocalizedCollection format back to the simple Reference format
  private static formatItemsCollectionToContent = (
    items: LocalizedCollectionProperties['items']
  ): CollectionProperties['items'] =>
    items.map((item) => {
      if (isLocalizedCollectionCategory(item)) {
        return {
          ...item,
          items: item.items.map((i) => ({
            id: i.id,
            referenceType: 'ContentEntry',
          })),
        };
      }

      return { id: item.id, referenceType: 'ContentEntry' };
    });

  private static doUpdate = async (
    id: number,
    parsedPath: string,
    queryKey: string[],
    data: LocalizedCollectionUpdateModel,
    previousVersion: LocalizedCollectionModel
  ): Promise<LocalizedCollectionModel> => {
    const { queryClient } = api;
    const properties: CollectionProperties = {
      ...previousVersion.properties,
      ...data.properties,
      items: LocalizedCollection.formatItemsCollectionToContent(
        data.properties.items
      ),
    };

    // update the overall status within the optimistic update
    const updatedStatus =
      previousVersion.status === 'published'
        ? 'published_with_draft'
        : previousVersion.status;

    const snakeyData = camelCaseToSnakeCase({ ...data, properties });

    try {
      updateCacheOptimistically(queryClient, queryKey, {
        ...data,
        status: updatedStatus,
      });

      await updateData(
        parsedPath,
        {
          id,
          ...snakeyData,
        },
        previousVersion.updatedAt
      );

      return { ...previousVersion, ...data };
    } catch (error: any) {
      onMutationError(queryClient, queryKey, error, data, previousVersion);
      if (error.code === 412) {
        throw new StaleWriteError(error.message, queryKey);
      }
      throw error;
    }
  };
}

export default LocalizedCollection;
