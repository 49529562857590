import { useCallback, useMemo, FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, styled } from '@mui/material';
import { useConfirmationModal } from 'components/modals';
import { ContentTypes } from 'types/enums';
import { MultiLineOverflowTypography } from 'components/typography';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { useAppToast } from 'contexts/app-toast-context';
import usePreviousPage from 'services/utils/use-previous-page';
import { useCurrentCollection } from 'scenes/nuevo-collection/hooks';

import Reference, {
  Props as ReferenceProps,
  StyledHiddenIfCategoriesOpen,
} from './base-reference';
import { MissingTitle, Topic } from './components';

const StyledReference = styled(Reference)`
  & > *,
  p:not(.keep-color-if-missing),
  svg:not(.keep-color-if-missing):not(:hover) {
    color: var(--color-neutral-four) !important;
  }
`;

type Props = MissingLocalizedCollectionReference & ReferenceProps;

const MissingReference: FunctionComponent<Props> = ({
  id,
  contentType,
  space,
  topic,
  topicSlug,
  selected,
  asCard,
  onSelectChange,
}) => {
  const {
    languageId,
    language,
    languageDisplayName,
    refetch,
  } = useCurrentCollection();
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();
  const { generateUrlWithPreviousPage } = usePreviousPage();
  const { pathname, search } = useLocation();

  const onCreateLocalizedEntry = useCallback(async () => {
    await api.LocalizedContentEntry.createAsync(
      {
        contentEntryId: id,
        languageId,
      },
      { contentEntryId: id }
    );

    const newUrl = generateUrlWithPreviousPage(
      `/edit/${id}/${language}`,
      `${pathname}${search}`,
      'collection'
    );

    refetch();

    dispatchToast({
      level: 'success',
      message: `Answer successfully created!`,
      linkPath: newUrl,
      linkText: "Please update this answer's localized details.",
    });
  }, [
    api.LocalizedContentEntry,
    id,
    languageId,
    generateUrlWithPreviousPage,
    language,
    pathname,
    search,
    refetch,
    dispatchToast,
  ]);

  const { modal, openModal } = useConfirmationModal({
    onConfirm: onCreateLocalizedEntry,
    modalProps: {
      title: 'Create answer for this language',
      message: (
        <>
          {'You are about to create a localized answer in '}
          <b>{languageDisplayName}</b>
          {' for '}
          <b>{`${topic} (${topicSlug})`}</b>
          {
            '. Upon creation, you should update all localized details of this answer.'
          }
        </>
      ),
      confirmButtonText: 'Create answer',
    },
  });

  const referenceActions = useMemo(() => {
    // represents content types that can not create new localized content
    const blockedContentTypes = [
      ContentTypes.LEGACY_TUTORIAL,
      ContentTypes.LEGACY_TOUR,
      ContentTypes.COLLECTION,
    ] as string[];

    if (blockedContentTypes.includes(contentType)) {
      return null;
    }

    return [
      <Button
        key={0}
        variant="outlined"
        onClick={openModal}
        sx={{ whiteSpace: 'nowrap' }}
      >
        {'Add Answer'}
      </Button>,
    ];
  }, [contentType, openModal]);

  return (
    <>
      <StyledReference
        asCard={asCard}
        actions={referenceActions}
        contentType={contentType}
        selected={selected}
        onSelectChange={onSelectChange}
      >
        <Box display="block" minWidth="10rem" flex={10}>
          <MissingTitle>
            {'This answer does not have localized content for this language'}
          </MissingTitle>
          <Topic slug={topicSlug} />
        </Box>
        <StyledHiddenIfCategoriesOpen breakpoint="md" flex={6}>
          <MultiLineOverflowTypography
            color="var(--color-neutral-seven)"
            lines={2}
            textAlign="left"
          >
            {space}
          </MultiLineOverflowTypography>
        </StyledHiddenIfCategoriesOpen>
      </StyledReference>
      {modal}
    </>
  );
};

export default MissingReference;
