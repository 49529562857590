import { FC } from 'react';
import { useContentEntrySidePanel } from 'components/content-entry-side-panel';
import CollectionTable from 'scenes/collection-table';
import CollectionSidePanel from 'components/collection-side-panel/';
import SidePanelContainer from 'components/search-side-panel/side-panel-container';

import { useCollectionSearch, useFiltersAndTable } from './hooks';
import ContentTableSidebarContainer from './content-filter-table-sidebar';

type Props = {
  onSelectedContentChanged?: (selection: number[]) => void;
};

export const CollectionTableSidebar: FC<Props> = ({
  onSelectedContentChanged,
}) => {
  const {
    showPanel,
    hidePanel,
    panelItemId,
    isPanelOpen,
  } = useContentEntrySidePanel();
  const {
    searchResults,
    isSearching,
    hasError,
    handleSearch,
  } = useCollectionSearch();
  const {
    filterResultsOpen,
    handleFilterResultsToggle,
    handleSelectedContentChange,
  } = useFiltersAndTable(onSelectedContentChanged);

  return (
    <ContentTableSidebarContainer
      isPanelOpen={isPanelOpen}
      panelItemId={panelItemId}
      probibitedAttributes={['Language', 'Space', 'Topic', 'ContentType']}
      syncSearchAttributesWithQueryParams
      handleFilterResultsToggle={handleFilterResultsToggle}
      handleSearch={handleSearch}
    >
      <CollectionTable
        collections={searchResults}
        isPopulating={isSearching}
        hasError={hasError}
        showOverlay={filterResultsOpen}
        onSelectedChanged={handleSelectedContentChange}
        onInfoClick={showPanel}
      />
      {panelItemId && (
        <SidePanelContainer
          onClose={hidePanel}
          contentType="collection2"
          id={panelItemId}
        >
          <CollectionSidePanel id={panelItemId} />
        </SidePanelContainer>
      )}
    </ContentTableSidebarContainer>
  );
};
