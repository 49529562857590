import { FC } from 'react';
import { Box, styled } from '@mui/material';
import AttributeSelector, {
  AllowedAttributeKeys,
} from 'components/attribute-selector';
import { ContentTypes } from 'types/enums';
import { SignalProvider } from 'components/signal';

import SortRow from './sort-row';
import ResultsRow from './results-row';
import { useCollectionFilters } from './hooks';

const prohibitedContentTypes = [ContentTypes.COLLECTION];
const prohibitedAttributes = ['Language'] as AllowedAttributeKeys[];

const StyledBox = styled(Box)`
  & .attribute-wrapper {
    box-shadow: none;
  }
`;

const Filters: FC = () => {
  const { startingAttributes } = useCollectionFilters();

  return (
    <StyledBox marginBottom={3}>
      <SignalProvider>
        <AttributeSelector
          inputPlaceholder="Search to filter content in this collection"
          prohibitedContentTypes={prohibitedContentTypes}
          prohibitedAttributes={prohibitedAttributes}
          startingAttributes={startingAttributes}
          syncWithQueryParams
          showStaticAttributes
        />
        <SortRow />
        <ResultsRow />
      </SignalProvider>
    </StyledBox>
  );
};

export default Filters;
