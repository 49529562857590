import { toCamel } from 'services/utils/convert-object-keys-snake-to-camel';
import { toSnake } from 'services/utils/convert-object-keys-camel-to-snake';
import {
  set as setObjectKeyByString,
  get as getObjectKeyByString,
  cloneDeep,
} from 'lodash';

/**
 * Given a source object, a path, and a new value, update the source object
 *
 * @param source The source data to modify
 * @param path The string path of to the item to update
 * @param value The new value of the item
 * @returns A deep copy of the source object with the new value set
 * @example
 * const original = { items: [{ id: 1, foo: 'hello world' }] };
 * const result = deepCopyAndMergeChanges(original, 'items[0].foo', 'bar');
 * // result = { items: [{ id: 1, foo: 'bar' }] };
 */
export const deepCopyAndMergeChanges = (
  source: object,
  path: string,
  value: string | number
) => {
  const pathCamel = toCamel(path);
  const copy = cloneDeep(source);
  setObjectKeyByString(copy, pathCamel, value);
  return copy;
};

/**
 * Given a source object, a path, and a new item, add the new item to the source object
 *
 * @param source The source data to modify
 * @param newItem The new item to add
 * @param path The string path of to the item to update
 * @param insertAfter (optional) The index to insert the new item after (defaults to the end)
 * @returns A deep copy of the source object with the new item added
 * @example
 * const original = { someObject: { items: [{ id: 1 }, { id: 3 }] } };
 * const result = deepCopyAndAddObject(original, { id: 2 }, 'someObject.items', 0);
 * // result = { someObject { items: [{ id: 1 }, { id: 2 }, { id: 3 }] } };
 */
export const deepCopyAndAddObject = (
  source: object,
  newItem: any,
  path: string,
  insertAfter?: number
) => {
  const pathCamel = toCamel(path);
  const copy = cloneDeep(source);
  // Get the object, and if it doesn't exist create an empty array
  const oldItems = getObjectKeyByString(copy, pathCamel) ?? [];
  const newItems =
    insertAfter === undefined
      ? [...oldItems, newItem]
      : [
          ...oldItems.slice(0, insertAfter + 1),
          newItem,
          ...oldItems.slice(insertAfter + 1),
        ];

  setObjectKeyByString(copy, pathCamel, newItems);
  return copy;
};

/**
 * Given a source object, a path to an array within the object, and an index, remove the item to the source's array
 *
 * @param source The source data to modify
 * @param index The index of the item to remove
 * @param path The string path of to the array to update
 * @returns A deep copy of the source object with the item removed
 * @example
 * const original = { someObject: { items: [{ id: 1 }, { id: 2 }] } };
 * const result = deepCopyAndRemoveFromArray(original, 0, 'someObject.items');
 * // result = { someObject { items: [{ id: 2 }] } };
 */
export const deepCopyAndRemoveFromArray = (
  source: object,
  index: number,
  path: string
) => {
  const pathCamel = toCamel(path);
  const copy = cloneDeep(source);
  const oldItems = getObjectKeyByString(copy, pathCamel);
  const newItems = [...oldItems.slice(0, index), ...oldItems.slice(index + 1)];

  setObjectKeyByString(copy, pathCamel, newItems);
  return copy;
};

/**
 * Given a key and value, generate a key with the length of the value appended if the value is an array
 * @param key The name of the key
 * @param value The value
 * @returns Either the key, or the key with the length of the value appended
 */
export const generateKeyWithCount = (key: string, value: any) =>
  `${key}${Array.isArray(value) ? `(length:${value.length})` : ''}`;

/**
 * Given a key and value, generate a key with the length of the value appended if the value is an array
 * @param key The name of the key
 * @param value The value
 * @returns Either the key, or the key with the length of the value appended
 */
export const generateKeyWithIndex = (
  key: string,
  index: number,
  count: number
) => `${key}[${index}/${count}]`;

/**
 * Given a content schema field type (e.g.: a String or Object or Indicator)
 * get a valid "blank" or new instance with reasonable/validated defaults
 * @param field The Schema field to get the default value for
 * @returns The default value for a "blank"/new instance of the field
 */
export const getDefaultForField = (
  field: ContentTypeSchemaField
): DefaultForField | DefaultForField[] => {
  // default is a reserved word in javascript, so rename it
  const { type, default: defaultValue } = field;

  if (defaultValue !== undefined) {
    return defaultValue;
  }

  switch (type) {
    case 'Text':
    case 'String':
      return defaultValue ?? '';
    case 'Object': {
      const { fields } = field as ContentTypeObjectField;
      return fields.reduce<{ [name: string]: any }>(
        (acc, field) => ({
          ...acc,
          [field.name]: getDefaultForField(field),
        }),
        {}
      );
    }
    case 'Array': {
      const { items } = field as ContentTypeArrayField;
      return [getDefaultForField(items)] as DefaultForField[];
    }
    case 'Indicator':
    case 'LooseIndicator':
      return undefined;
    case 'Media':
      return undefined;
    default:
      return defaultValue ?? '';
  }
};

/**
 * Finds a field, or subschema given a field name
 * @param schema The schema to search within
 * @param field The field to find
 * @returns The sub-schema for that field
 */
export const parseFieldFromSchema = (
  schema: ContentTypeSchemaField[],
  field: string
) => schema.find((f) => f.name === toSnake(field));

/**
 *
 * @param contentEntryId The content entry id to edit
 * @param language (Optional) The language to edit
 * @returns The formatted string path to the editor
 */
export const generateEditorPath = (contentEntryId: number, language?: string) =>
  `/edit/${contentEntryId}${language ? `/${language}` : ''}`;
