import { ComponentType, useCallback, useRef, useState } from 'react';
import Draggable, { DraggableEvent } from 'react-draggable';
import styled from 'styled-components';
import { ClickAwayListener } from '@mui/material';
import { func, number, bool, string } from 'services/utils/prop-types';

import {
  useForcedReposition,
  useKeyboardNudge,
  useSelectionState,
} from '../hooks';
import { toPercentage, toPixels } from '../draggable-utils';

const StyledDraggableWrapper = styled.div`
  height: 0px;
  width: 0px;
  cursor: pointer;
  &.react-draggable-dragging {
    cursor: grabbing;
  }
`;

export type Props = {
  index: number;
  indicatorType: string;
  initialX: number;
  initialY: number;
  parentHeight: number;
  parentWidth: number;
  isPaused?: boolean;
  initialSelected?: boolean;
  boundingParentSelector?: string;
  onSave: (index: number, indicator: Indicator) => void;
  onDelete: (index: number) => void;
  onSelected?: (selected: boolean) => void;
  onMove?: (x: number, y: number) => void;
  selectedComponent: ComponentType<any>;
  unselectedComponent: ComponentType<any>;
  wrapper?: ComponentType<any>;
  direction?: string;
};

export type BaseProps = Omit<
  Props,
  | 'wrapper'
  | 'selectedComponent'
  | 'unselectedComponent'
  | 'direction'
  | 'onSelected'
  | 'indicatorType'
>;

/**
 * DraggableBaseIndicator: A semi-controlled draggable indicator container
 *
 * By default it expects to live within a position: relative or absolute parent,
 * and is restricted from moving outside that parent.
 *
 * A note on boundingParentSelector: Provide a selector (#id, .class, etc) to an element
 * and the indicator will use that element's size as it's movement bounds.  If you do not
 * provide one, then the nearest relative or absolutely positioned element will be used
 *
 * @param initialX - The initial position in percentages
 * @param initialY - The initial position in percentages
 * @param parentHeight - The height of the container element in pixels
 * @param parentWidth - The width of the container element in pixels
 * @param initialSelected - Should the indicator start out in the isSelected state
 * @param boundingParentSelector - The selector for the element that parent element bounds
 * @param isPaused - Pause the indicator animation
 * @param onSave - Callback function when the user clicks away from the indicator
 * @param onDelete - callback function for when a user deletes an indicator
 * @param selectedComponent - a selected indicator
 * @param unselectedComponent - an unselected indicator
 * @param wrapper - the bounding box for the indicators
 * @param direction - used for pinch indicators determines if a pinch is in or out.
 *
 */
const DraggableBase: React.FunctionComponent<Props> = ({
  index,
  indicatorType,
  initialX,
  initialY,
  parentHeight,
  parentWidth,
  isPaused = false,
  initialSelected = false,
  boundingParentSelector = 'parent',
  onSave,
  onDelete,
  onSelected,
  onMove,
  selectedComponent,
  unselectedComponent,
  wrapper,
  direction,
}) => {
  const SelectedIndicator = selectedComponent;
  const UnselectedIndicator = unselectedComponent;
  const Wrapper = wrapper || StyledDraggableWrapper;

  const draggableNodeRef = useRef<HTMLDivElement | null>(null);
  const [isSelected, setIsSelected, handleMouseDown] = useSelectionState(
    initialSelected,
    onSelected
  );

  const [position, setPosition] = useState({
    // Convert to pixels here, Draggable uses pixels internally
    // We will convert back to percentage when calling onSave
    x: toPixels(initialX, parentWidth),
    y: toPixels(initialY, parentHeight),
  });

  useForcedReposition(
    setPosition,
    initialX,
    initialY,
    parentWidth,
    parentHeight
  );

  const handleSave = useCallback(() => {
    const { x, y } = position;
    setIsSelected(false);
    // Convert back to percentage here, because that is what the rest of the app expects
    const indicatorId = direction
      ? `${indicatorType}_${direction}`.toLocaleUpperCase()
      : indicatorType;

    onSelected?.(false);
    onSave(index, {
      id: indicatorId,
      type: indicatorId,
      x: toPercentage(x, parentWidth),
      y: toPercentage(y, parentHeight),
    });
  }, [
    index,
    onSave,
    parentHeight,
    parentWidth,
    position,
    indicatorType,
    direction,
    setIsSelected,
    onSelected,
  ]);

  useKeyboardNudge(
    index,
    parentWidth,
    parentHeight,
    isSelected,
    setPosition,
    handleSave,
    onDelete
  );

  const handleDrag = useCallback(
    (e: DraggableEvent, position: DraggableData) => {
      const { x, y } = position;
      setPosition({ x, y });
      onMove?.(x, y);
    },
    [onMove]
  );

  return (
    <Draggable
      nodeRef={draggableNodeRef}
      bounds={boundingParentSelector}
      defaultPosition={{ x: initialX, y: initialY }}
      onMouseDown={handleMouseDown}
      position={position}
      onDrag={handleDrag}
      allowAnyClick={false}
    >
      <Wrapper
        data-testid="draggable-base-indicator:wrapper"
        ref={draggableNodeRef}
      >
        {isSelected ? (
          <ClickAwayListener
            onClickAway={handleSave}
            // onClick does not seem to bubble correctly for some reason; so when using onClick
            // if there are multiple indicators on screen you can click another indicator and
            // the first one will still be selected until you click again.  This does not
            // happen if you use the onMouseDown event instead  ¯\_(ツ)_/¯
            mouseEvent={'onMouseDown'}
          >
            <SelectedIndicator
              x={position.x}
              y={position.y}
              parentHeight={parentHeight}
              parentWidth={parentWidth}
              type={indicatorType}
              direction={direction}
            />
          </ClickAwayListener>
        ) : (
          <UnselectedIndicator x={0} y={0} isPaused={isPaused} />
        )}
      </Wrapper>
    </Draggable>
  );
};

DraggableBase.propTypes = {
  index: number.isRequired,
  initialX: number.isRequired,
  initialY: number.isRequired,
  parentHeight: number.isRequired,
  parentWidth: number.isRequired,
  initialSelected: bool,
  isPaused: bool,
  boundingParentSelector: string,
  onSave: func.isRequired,
  onDelete: func.isRequired,
};

export default DraggableBase;
