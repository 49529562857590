type Primitive = string | number | boolean | null | undefined;

/**
 * A function that takes an array and a value. The value is added to the array if it does not exist in the array; otherwise all instances of the value are removed from the array.
 * @param array
 * @param value
 * @returns {Array} new array with value added or removed.
 */
export default function pushOrRemove<T extends Primitive>(
  array: T[],
  value: T
) {
  if (!array) return [] as T[];

  const valueIdx = array.indexOf(value);

  if (valueIdx === -1) {
    return [...array, value];
  }

  return array.filter((currentValue) => currentValue !== value);
}
