import { FunctionComponent } from 'react';
import { SelectedReference } from 'scenes/collection/hooks';
import ExpansionPanel from 'components/expansion-panel';
import getPlural from 'services/utils/get-plural';
import { Typography } from '@mui/material';

type Props = {
  completeReferencesCount: number;
  incompleteReferences: SelectedReference[];
  languageDisplayName: string;
};

const ModalBody: FunctionComponent<Props> = ({
  completeReferencesCount,
  incompleteReferences,
  languageDisplayName,
}) => {
  if (completeReferencesCount === 0) {
    return (
      <Typography>
        {'You cannot publish any answers at this '}
        {'time because they have incomplete content.'}
      </Typography>
    );
  }

  return (
    <Typography component="span">
      {'You are about to publish '}
      <b>{`${completeReferencesCount} completed ${getPlural(
        completeReferencesCount,
        'answer',
        'answers'
      )} in ${languageDisplayName}.  `}</b>
      {'Completed answers will be updated wherever their '}
      {'instances are referenced in Studio.'}
      {incompleteReferences.length > 0 && (
        <Typography component="span">
          <b>
            &nbsp;&nbsp;
            {`${incompleteReferences.length} ${getPlural(
              incompleteReferences.length,
              'answer',
              'answers'
            )} will not be published `}
          </b>
          {`at this time because ${getPlural(
            incompleteReferences.length,
            'it has',
            'they have'
          )} incomplete content.`}
          <ExpansionPanel
            buttonOnTop={true}
            expandText={`Incomplete answers (${incompleteReferences.length})`}
          >
            {incompleteReferences.map(({ id, title }, index) => (
              <Typography key={`${id}-${index}`}>
                {title ??
                  `Missing localized content in ${languageDisplayName} for content entry #${id}`}
              </Typography>
            ))}
          </ExpansionPanel>
        </Typography>
      )}
    </Typography>
  );
};

export default ModalBody;
