import { useCallback, FC } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import StatusIndicator, { Chip } from 'components/status-indicator';

import useAnswerFiltering from '../category/use-answer-filter';

const statuses = [
  'draft',
  'published',
  'published_with_draft',
] as LocalizedContentEntryStatus[];

const PublishState: FC = () => {
  const { setPublishStates, status } = useAnswerFiltering();

  const onChangeStates = useCallback(
    (event: SelectChangeEvent<LocalizedContentEntryStatus[]>) => {
      const {
        target: { value },
      } = event;

      setPublishStates(value as LocalizedContentEntryStatus[]);
    },
    [setPublishStates]
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="status-select-label">{'Status'}</InputLabel>
      <Select
        multiple
        size="small"
        label="Status"
        labelId="status-select-label"
        onChange={onChangeStates}
        value={status}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.slice(0, 2).map((value) => (
              <Chip key={value} status={value} />
            ))}
            {selected.length > 2 ? '...' : undefined}
          </Box>
        )}
      >
        {statuses.map((s) => (
          <MenuItem key={s} value={s}>
            <Checkbox checked={status.includes(s)} size="small" />
            <StatusIndicator withText status={s} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PublishState;
