import { SelectChangeEvent } from '@mui/material';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

import useAddLanguagesModal from './use-add-languages-to-content-entry';

const useSelectLanguageFromContentEntry = (
  contentEntryId: number | undefined,
  onSelect: (language: string) => void,
  copyContent?: boolean
) => {
  const api = useOzmoApiService();
  const { data: contentEntry, ...rest } = api.ContentEntry.get({
    id: contentEntryId,
  });

  const { modal, openModal } = useAddLanguagesModal(contentEntry, copyContent);
  const handleSelect = (e: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = e;

    if (value === 'add') {
      openModal();
      return;
    }

    onSelect(value);
  };

  return {
    contentEntry,
    handleSelect,
    addLocalModal: modal,
    ...rest,
  };
};

export default useSelectLanguageFromContentEntry;
