import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useEventTracking = () => {
  const trackEvent = useCallback(
    <T extends keyof TrackingEvents>(
      eventName: T,
      parameters: TrackingEvents[T]
    ) => {
      if (window.gtag) {
        window.gtag('event', eventName, parameters);
      }
    },
    []
  );

  return trackEvent;
};

export const useTrackPageViews = () => {
  const location = useLocation();
  const trackEvent = useEventTracking();

  useEffect(() => {
    trackEvent('page_view', {
      // eslint-disable-next-line camelcase
      page_location: location.pathname + location.search,
    });
  }, [location, trackEvent]);
};
