import { FC } from 'react';
import {
  Table,
  TableFooter,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import ContentTableContainer from 'components/content-table-container';
import ContentTableBody from 'components/content-table-body';
import getPlural from 'services/utils/get-plural';

import { useContentTable, useContentActions } from './hooks';
import ContentTableHeader from './header';
import ContentTableRow from './table-row';

type Props = {
  content: ContentSearchResult[];
  isPopulating: boolean;
  hasError: boolean;
  defaultOrderBy?: keyof ContentTableData;
  enableContentActions?: boolean;
  hideTable?: boolean;
  onSelectedChange: (selectedContent: number[]) => void;
  onInfoClick: (id: number) => void;
  openEditInNewTab?: boolean;
};

const ContentTable: FC<Props> = ({
  content,
  isPopulating,
  hasError,
  hideTable,
  enableContentActions,
  defaultOrderBy = 'updatedAt',
  openEditInNewTab,
  onSelectedChange,
  onInfoClick,
}) => {
  const {
    tableData,
    selected,
    order,
    orderBy,
    handleSelectAll,
    handleSelectItem,
    handleSort,
    isSelected,
  } = useContentTable(content, defaultOrderBy, onSelectedChange);
  const {
    duplicateModal,
    openDuplicateModal,
    handleSetItemId,
  } = useContentActions();
  const rowCount = tableData.length;

  return (
    <>
      <ContentTableContainer showOverlay={hideTable}>
        <Table
          aria-label="content-table"
          stickyHeader
          data-testid="content-table"
        >
          <ContentTableHeader
            selectCount={selected.length}
            rowCount={rowCount}
            order={order}
            orderBy={orderBy}
            handleSelectAll={handleSelectAll}
            handleSort={handleSort}
          />
          <ContentTableBody
            rowCount={rowCount}
            colCount={9}
            isPopulating={isPopulating}
            hasError={hasError}
          >
            {tableData.map((data) => (
              <ContentTableRow
                key={data.id}
                data={data}
                enableContentActions={enableContentActions}
                isSelected={isSelected(data.id)}
                handleSelectItem={handleSelectItem}
                handleInfoClick={onInfoClick}
                openDuplicateModel={openDuplicateModal}
                handleSetItemId={handleSetItemId}
                openEditInNewTab={openEditInNewTab}
              />
            ))}
          </ContentTableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={9}>
                <Typography fontWeight={500}>{`${tableData.length} ${getPlural(
                  tableData.length,
                  'result',
                  'results'
                )}`}</Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </ContentTableContainer>
      {duplicateModal}
    </>
  );
};

export default ContentTable;
