import { FunctionComponent, ReactElement, useMemo, cloneElement } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

import { usePermissions } from '.';

type Props = {
  permission: string;
  authApplicationName?: string;
  children: ReactElement;
  disableElement?: boolean;
  hideTooltip?: boolean;
  className?: string;
  tooltipProps?: Partial<TooltipProps>;
};

const PermissionRequired: FunctionComponent<Props> = ({
  permission,
  children,
  authApplicationName,
  disableElement,
  hideTooltip,
  tooltipProps,
  className,
}) => {
  const { checkPermission } = usePermissions();
  const hasPermission = checkPermission(permission, authApplicationName);
  const defaultTooltipTitle =
    'Contact your production manager for permission to perform this action.';

  const disabledElement = useMemo(
    () => cloneElement(children, { disabled: !hasPermission }),
    [hasPermission, children]
  );

  if (hasPermission) {
    return <>{children}</>;
  }

  if (disableElement) {
    return (
      // an empty string will not render the tooltip, effectively hiding it.
      <Tooltip
        title={hideTooltip ? '' : defaultTooltipTitle}
        enterDelay={300}
        arrow
        {...tooltipProps}
      >
        {/* disabled elements don't trigger user interactions, so have to wrap in span for tooltip to work */}
        <span className={className}>{disabledElement}</span>
      </Tooltip>
    );
  }

  return null;
};

export default PermissionRequired;
