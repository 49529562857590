import { memo } from 'react';
import { Typography, Box } from '@mui/material';
import theme from 'ozmo-theme';

const SearchInstructions = () => {
  return (
    <Box
      marginTop={2}
      padding={1.5}
      borderRadius="6px"
      bgcolor="var(--color-primary-background)"
    >
      <Typography color="primary">
        <Typography
          component="span"
          color={theme.palette.primary.dark}
          fontWeight="bold"
        >
          {'Start typing to find attributes. '}
        </Typography>
        {'Use arrow keys or mouse to select multiple. '}
      </Typography>
    </Box>
  );
};

const memoizedInstructions = memo(SearchInstructions);

export default memoizedInstructions;
