export function isCategory(
  referenceOrCategory?: TopicItem | CategoryItem
): referenceOrCategory is CategoryItem {
  return (referenceOrCategory as CategoryItem).items !== undefined;
}

export const isTopicGroup = (
  answerOrTopicGroup: CategoryContentEntry | CategoryContentEntry[]
): answerOrTopicGroup is CategoryContentEntry[] =>
  Array.isArray(answerOrTopicGroup);
