import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { createClient } from 'configcat-js';
import { useOzmoAuthUser } from 'scenes/google-auth';

type MaybeFlagsProvider = ReturnType<typeof createClient> | null;

export const FlagsContext = createContext<MaybeFlagsProvider>(null);

export const useFlagsClient = () => useContext(FlagsContext);

export const useFlag = (flagName: string, defaultValue = false) => {
  const user = useOzmoAuthUser();
  const flagsClient = useFlagsClient();
  const [flagValue, setFlagValue] = useState(defaultValue);

  const userObject = useMemo(
    () => ({
      identifier: user?.email || '(not signed in)',
    }),
    [user?.email]
  );

  useEffect(() => {
    flagsClient
      ?.getValueAsync(flagName, defaultValue, userObject)
      ?.then(setFlagValue);
  }, [defaultValue, flagName, flagsClient, setFlagValue, userObject]);

  return flagValue;
};

export const useAddFlagsToWindow = (
  flagsClient: ReturnType<typeof createClient>
) => {
  const user = useOzmoAuthUser();

  useEffect(() => {
    const fetchFlags = async (identifier: string) => {
      window.ozmoAuthoringFlags = {};
      const flags = await flagsClient.getAllValuesAsync({
        identifier,
      });
      flags.forEach(
        ({ settingKey, settingValue }) =>
          (window.ozmoAuthoringFlags![settingKey] = settingValue)
      );
    };

    user && fetchFlags(user.email);
  }, [flagsClient, user]);
};
