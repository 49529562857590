import styled from 'styled-components';
import { number, string } from 'services/utils/prop-types';

type Props = {
  x: number;
  y: number;
  parentHeight: number;
  parentWidth: number;
  type: string;
  innerRadius: number;
};

type CrosshairProps = {
  length: number;
  isCentered: boolean;
};

const StyledHR = styled.hr<CrosshairProps>`
  pointer-events: none;
  border: none;
  color: #fff;
  background-color: #fff;
  position: absolute;
  border-color: ${({ isCentered }) =>
    isCentered
      ? 'var(--color-branded-base)'
      : 'var(--color-primary-light)'} !important;
`;

// TypeScript bug?  Have to cast StyledHR as any here to avoid compiler error
const StyledHorizontalCrosshair = styled(StyledHR as any)<CrosshairProps>`
  border-top: 1px dashed;
  height: 1px;
  width: ${({ length }) => length}px;
`;

// TypeScript bug?  Have to cast StyledHR as any here to avoid compiler error
const StyledVerticalCrosshair = styled(StyledHR as any)<CrosshairProps>`
  border-left: 1px dashed;
  height: ${({ length }) => length}px;
  width: 1px;
`;

const SelectedIndicatorCrosshairs: React.FunctionComponent<Props> = ({
  x,
  y,
  parentHeight,
  parentWidth,
  type,
  innerRadius,
}) => {
  const isCenteredX = x / parentWidth > 0.498 && x / parentWidth < 0.502;
  const isCenteredY = y / parentHeight > 0.498 && y / parentHeight < 0.502;

  return (
    <>
      <StyledHorizontalCrosshair
        data-testid="selected-indicator:horizontal-crosshair"
        length={parentWidth}
        isCentered={isCenteredY}
        style={{ transform: `translate(-${x}px,-${innerRadius / 2}px)` }}
      />
      <StyledVerticalCrosshair
        data-testid="selected-indicator:vertical-crosshair"
        length={parentHeight}
        isCentered={isCenteredX}
        style={{ transform: `translateY(-${y + innerRadius / 2}px)` }}
      />
    </>
  );
};
SelectedIndicatorCrosshairs.propTypes = {
  x: number.isRequired,
  y: number.isRequired,
  parentHeight: number.isRequired,
  parentWidth: number.isRequired,
  type: string.isRequired,
  innerRadius: number.isRequired,
};

export default SelectedIndicatorCrosshairs;
