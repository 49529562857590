import {
  AnswerExistsError,
  AnswerExistsOtherLanguages,
  AnswerExistsSameLanguage,
} from './error';

export const isAnswerExistsResponse = (
  exception: unknown
): exception is AnswerExistsResponse =>
  (exception as any).message === 'DuplicateContentEntry';

export const isAnswerExistsError = (
  error: unknown
): error is AnswerExistsError =>
  error instanceof AnswerExistsSameLanguage ||
  error instanceof AnswerExistsOtherLanguages;

export const createAnswerExistsError = (
  ex: AnswerExistsResponse,
  requestedLanguageIds: number[]
) => {
  const responseLanguages = ex.detail.languageIds;
  const missingLanguages = (requestedLanguageIds ?? []).filter((languageId) => {
    return responseLanguages.indexOf(languageId) < 0;
  });

  if (missingLanguages.length > 0) {
    return new AnswerExistsOtherLanguages(
      ex.detail.id,
      ex.detail.referenceCount,
      ex.detail.languageIds
    );
  }

  return new AnswerExistsSameLanguage(
    ex.detail.id,
    ex.detail.referenceCount,
    ex.detail.languageIds
  );
};
