import { useParams } from 'react-router-dom';

export const useCurrentLanguage = () => {
  const { localeOrLanguage = '' } = useParams();
  // Locales are in the format "en-us" and languages are just "en"
  // so either way we'll get the language from this, if the URL param is provided
  const [urlLanguage] = localeOrLanguage.split('-');
  // If the URL param wasn't provided urlLanguage will be an emptry string ('') so default to english
  const languageShortCode = urlLanguage || 'en';

  return languageShortCode;
};
