import { useState, useMemo, useCallback } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { useAppToast } from 'contexts/app-toast-context';
import { useModal } from 'components/modals';
import { usePermissions } from 'components/permission-required';
import { isLegacyContent } from 'services/utils/is-legacy-content';

import PreviewButton from '../preview-button';
export const usePublish = (
  contentEntryId: number,
  lceId: number | undefined,
  defaultDisabled: boolean
) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();
  const { checkPermission } = usePermissions();
  const {
    data: contentEntry,
    isLoading: contentEntryLoading,
  } = api.ContentEntry.get({ id: contentEntryId });
  const {
    data: localizedContentEntry,
    isLoading,
  } = api.LocalizedContentEntry.get({
    id: lceId,
    contentEntryId,
  });
  const [isPublishInProgress, setIsPublishInProgress] = useState(false);
  const [publishError, setPublishError] = useState(null);
  const hasPublishingPermissions = checkPermission('publish');
  // better to assume legacy content and restrict publishing
  const legacyContent =
    contentEntryLoading || isLegacyContent(contentEntry?.contentType);
  const hasChanges = localizedContentEntry?.status !== 'published';
  const hasMissingData = !localizedContentEntry?.complete;

  const previewButton = useMemo(
    () => (
      <PreviewButton
        contentEntryId={contentEntryId}
        locale={localizedContentEntry?.locale}
      />
    ),
    [contentEntryId, localizedContentEntry]
  );

  const handleCloseModal = useCallback(() => {
    setIsPublishInProgress(false);
    setPublishError(null);
  }, []);

  const executePublish = useCallback(async () => {
    setIsPublishInProgress(true);
    if (contentEntryId && lceId) {
      try {
        await api.LocalizedContentEntry.updateStatusAsync(
          lceId,
          contentEntryId,
          'published'
        );
        setIsPublishInProgress(false);
        dispatchToast({
          level: 'success',
          message: 'Successfully published content.',
        });
      } catch (error: any) {
        setPublishError(error);
        setIsPublishInProgress(false);
        throw new Error(error); // throw the error again so it is caught by the modal hook
      }
    }
  }, [contentEntryId, lceId, api.LocalizedContentEntry, dispatchToast]);

  // publish modal
  const { open, openModal, handleConfirm, handleClose } = useModal({
    closeOnReject: false,
    onConfirm: executePublish,
    onRefuse: handleCloseModal,
  });

  const publishState = {
    isPublishInProgress,
    publishError,
    hasMissingData,
    previewButton,
  };

  const disablePublishButton =
    isLoading ||
    defaultDisabled ||
    !contentEntryId ||
    !lceId ||
    hasMissingData ||
    !hasPublishingPermissions ||
    !hasChanges ||
    legacyContent;

  return {
    publishState,
    open,
    disablePublishButton,
    openModal,
    handleConfirm,
    handleClose,
  };
};
