import {
  useState,
  useCallback,
  useMemo,
  cloneElement,
  FC,
  ChangeEvent,
  ReactElement,
} from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useActionModal } from 'components/modals';
import { useCatchStaleWriteError } from 'services/ozmo-api/utils/use-catch-stale-write-error';
import { PermissionRequired } from 'components/permission-required';

import CategoryDetailsModal from './category-details-modal';

type Props = {
  onSubmit: (
    category: Omit<LocalizedCollectionCategory, 'items'>,
    atIndex?: number
  ) => Promise<boolean>;
  onRefuse?: Function;
  categoryIndex?: number;
  existingCategory?: LocalizedCollectionCategory;
  children?: ReactElement;
};

const DEFAULT_BUTTON = <Button startIcon={<AddIcon />}>{'Category'}</Button>;

const AddOrEditCategory: FC<Props> = ({
  onSubmit,
  onRefuse,
  categoryIndex,
  existingCategory,
  children = DEFAULT_BUTTON,
}) => {
  const { catchStaleWrite } = useCatchStaleWriteError();
  const [formState, setFormState] = useState({
    title: existingCategory?.title ?? '',
    description: existingCategory?.description ?? '',
  });

  const resetForm = useCallback(
    () => setFormState({ title: '', description: '' }),
    []
  );

  const setFormItem = useCallback(
    (fieldName: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setFormState((state) => ({
        ...state,
        [fieldName]: e.target.value,
      }));
    },
    [setFormState]
  );

  const handleConfirm = useCallback(() => {
    onSubmit(formState, categoryIndex).catch((e) => {
      const message = existingCategory
        ? 'edit the category name'
        : `add the category "${formState.title}" to the collection`;
      catchStaleWrite(`We were unable to ${message}`)(e);
    });
    resetForm();
  }, [
    onSubmit,
    formState,
    categoryIndex,
    resetForm,
    existingCategory,
    catchStaleWrite,
  ]);

  const handleRefuse = useCallback(() => {
    onRefuse?.();
    resetForm();
  }, [onRefuse, resetForm]);

  const { modal, openModal } = useActionModal({
    modalContent: (
      <CategoryDetailsModal {...formState} setFormItem={setFormItem} />
    ),
    onConfirm: handleConfirm,
    onRefuse: handleRefuse,
    modalProps: {
      title: existingCategory ? 'Category details' : 'Add a new category',
      confirmButtonText: existingCategory
        ? 'Update category'
        : 'Create category',
      disableConfirmButton:
        formState.title.length === 0 ||
        (existingCategory?.title === formState.title &&
          existingCategory?.description === formState.description),
      maxWidth: 'md',
      fullWidth: true,
    },
  });

  const childrenWithEvent = useMemo(() => {
    return cloneElement(children, {
      onClick: () => openModal(),
    });
  }, [children, openModal]);

  return (
    <>
      <PermissionRequired permission="edit_collection" disableElement>
        {childrenWithEvent}
      </PermissionRequired>
      {modal}
    </>
  );
};

export default AddOrEditCategory;
