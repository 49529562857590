import { useState, useMemo, useEffect, FunctionComponent } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandIcon from '@mui/icons-material/Add';
import ContractIcon from '@mui/icons-material/Remove';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledAccordion = styled(Accordion)<{ $containerHeight?: number }>`
  border-radius: 0px !important;
  margin: 3px 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #acacac;
  cursor: pointer;
  .MuiAccordionSummary-root {
    padding: 0px 24px;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: unset;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
  .MuiAccordionDetails-root {
    padding: 8px 24px 16px;
    max-height: ${({ $containerHeight }) =>
      $containerHeight ? $containerHeight - 350 : 200}px;
    overflow-y: auto;
  }
  &.MuiAccordion-root:before {
    display: none !important;
  }
`;

const StyledSelectableTypography = styled(Typography)<{ $isSelected: boolean }>`
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: rgba(0, 109, 199, 0.2);
      margin: -2px -24px !important;
      padding: 2px 24px;
    `}
`;

const StyledTypography = styled(Typography)<{ $isSelected: boolean }>`
  font-weight: ${({ $isSelected }) =>
    $isSelected ? 500 : 'normal'} !important;
`;

type Props = {
  devices: DeviceModel[];
  isSelected: boolean;
  selectedManufacturer: string;
  onClick: (filter: string, manufacturer?: string) => void;
  containerHeight?: number;
};

type Accumulator = {
  [key: string]: DeviceModel[];
};

const DeviceFilterPicker: FunctionComponent<Props> = ({
  devices = [],
  selectedManufacturer,
  isSelected,
  onClick,
  containerHeight,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const toggleAccordion = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    setIsExpanded(isSelected);
  }, [isSelected]);

  const devicesByManufacturer = useMemo(
    () =>
      devices.reduce(
        (acc, device) => ({
          ...acc,
          [device.manufacturer]: [...(acc[device.manufacturer] ?? []), device],
        }),
        {} as Accumulator
      ),
    [devices]
  );

  const handleAccordionClick = () => {
    onClick('device');
    toggleAccordion();
  };

  const handleManufacturerClick = (manufacturer: string) => {
    onClick('device', manufacturer);
  };

  return (
    <StyledAccordion expanded={isExpanded} $containerHeight={containerHeight}>
      <AccordionSummary
        onClick={handleAccordionClick}
        expandIcon={isExpanded ? <ContractIcon /> : <ExpandIcon />}
      >
        <StyledTypography
          $isSelected={isSelected}
          variant="body1"
        >{`Device (${devices.length})`}</StyledTypography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        {devices.length === 0 ? (
          <Typography>{'No devices'}</Typography>
        ) : (
          Object.entries(devicesByManufacturer)
            .sort()
            .map(([manufacturer, devices]) => (
              <StyledSelectableTypography
                $isSelected={manufacturer === selectedManufacturer}
                onClick={() => handleManufacturerClick(manufacturer)}
                key={manufacturer}
              >{`${manufacturer} (${devices.length})`}</StyledSelectableTypography>
            ))
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

DeviceFilterPicker.propTypes = {
  selectedManufacturer: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DeviceFilterPicker;
