import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { ContentCopy, Create, Link as LinkIcon } from '@mui/icons-material';
import { generateCollectionPath } from 'scenes/nuevo-collection/util';
import { PermissionRequired } from 'components/permission-required';
import RowActionsMenu from 'components/content-table-row-action-menu';
import usePreviousPage from 'services/utils/use-previous-page';
import { useCopyLink } from 'services/hooks/use-copy-link';
import { useEventTracking } from 'services/analytics';

type Props = {
  rowId: number;
  openDuplicateModal: (rowId: number) => void;
};

const RowActions: FC<Props> = ({ rowId, openDuplicateModal }) => {
  const { generateUrlWithPreviousPage } = usePreviousPage();
  const editLink = generateUrlWithPreviousPage(generateCollectionPath(rowId));
  const [onClick] = useCopyLink('collection2', rowId, undefined, true);
  const trackEvent = useEventTracking();

  return (
    <>
      <RowActionsMenu>
        {/* edit */}
        <MenuItem
          component={Link}
          to={editLink}
          onClick={() => {
            trackEvent('search-result-selected', {
              id: rowId,
              type: 'collection',
            });
          }}
        >
          <ListItemIcon>
            <Create fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'Edit'}</ListItemText>
        </MenuItem>
        {/* duplicate */}
        <PermissionRequired
          authApplicationName="API"
          permission="POST:/v1/authoring/collections/*/copy/"
          disableElement
        >
          <MenuItem onClick={() => openDuplicateModal(rowId)}>
            <ListItemIcon>
              <ContentCopy fontSize="small" />
            </ListItemIcon>
            <ListItemText>{'Duplicate'}</ListItemText>
          </MenuItem>
        </PermissionRequired>
        {/* copy link: requires `as any` because is not a ButtonElement */}
        <MenuItem onClick={onClick as any}>
          <ListItemIcon>
            <LinkIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'Copy link'}</ListItemText>
        </MenuItem>
      </RowActionsMenu>
    </>
  );
};

const memoizedRowActions = memo(RowActions);

export default memoizedRowActions;
