import { ReactNode } from 'react';
import getPlural from 'services/utils/get-plural';

interface AnswerExistsErrorBase {
  existingAnswerId: number;
  occurrences: number;
  message(): string;
  formattedMessage(): ReactNode;
}

export type AnswerExistsError =
  | AnswerExistsSameLanguage
  | AnswerExistsOtherLanguages;

export class AnswerExistsSameLanguage implements AnswerExistsErrorBase {
  public existingAnswerId: number;
  public occurrences: number;
  public languageIds: number[];

  private messageParts = [
    'This answer already exists and is referenced in ',
    '. Please use the existing answer for this collection.',
  ];

  constructor(
    existingAnswerId: number,
    occurrences: number,
    languageIds: number[]
  ) {
    this.existingAnswerId = existingAnswerId;
    this.occurrences = occurrences;
    this.languageIds = languageIds;
  }

  message() {
    const placePlural = getPlural(this.occurrences, 'place', 'places');
    return [
      this.messageParts[0],
      `${this.occurrences} ${placePlural}`,
      this.messageParts[1],
    ].join();
  }

  formattedMessage() {
    const placePlural = getPlural(this.occurrences, 'place', 'places');
    return (
      <>
        {this.messageParts[0]}
        <strong>{`${this.occurrences} ${placePlural}`}</strong>
        {this.messageParts[1]}
      </>
    );
  }
}

export class AnswerExistsOtherLanguages implements AnswerExistsErrorBase {
  public existingAnswerId: number;
  public occurrences: number;
  public languageIds: number[];

  constructor(
    existingAnswerId: number,
    occurrences: number,
    languageIds: number[]
  ) {
    this.existingAnswerId = existingAnswerId;
    this.occurrences = occurrences;
    this.languageIds = languageIds;
  }

  message() {
    return 'This answer already exists in other languages. We will add the existing answer to this collection, but you will need to add the localized content entry upon creation.';
  }

  formattedMessage() {
    return this.message();
  }
}
