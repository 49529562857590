import AutocompleteMultiselect from 'components/autocomplete-multiselect';
import { useDuplicateContentModal } from 'components/duplicate-content-entry-dialog';
import { useActionModal, useConfirmationModal } from 'components/modals';
import { useAppToast } from 'contexts/app-toast-context';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { useState, MouseEvent, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useCatchStaleWriteError } from 'services/ozmo-api/utils/use-catch-stale-write-error';

type Args = {
  collectionId: number;
  itemId: number;
  categoryIndex: number;
  answerTitle: string;
};

export const useLocalizedReferenceActions = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  return {
    anchorEl,
    isOpen: Boolean(anchorEl),
    handleClose,
    handleOpen,
  };
};

export const useRemoveItemAction = ({
  collectionId,
  itemId,
  categoryIndex,
  answerTitle,
}: Args) => {
  const dispatchToast = useAppToast();
  const { catchStaleWrite } = useCatchStaleWriteError();
  const api = useOzmoApiService();

  const handleRemove = async () => {
    const success = await api.LocalizedCollection.removeItemInCategoryInAllLanguagesAsync(
      collectionId,
      itemId,
      categoryIndex
    ).catch(
      catchStaleWrite(
        `We were unable to remove "${answerTitle}" from the collection`
      )
    );

    success &&
      dispatchToast({
        level: 'success',
        message: 'Success! Answer removed from category.',
      });
  };
  const modalMessage = (
    <>
      {`You are about to remove this instance of `}
      <Typography component="span" fontWeight="bold">
        {answerTitle}
      </Typography>
      {`. Any other instances of this answer within this collection will remain. This action will only remove the answer, not delete it from the system.`}
    </>
  );
  const { modal, openModal } = useConfirmationModal({
    onConfirm: handleRemove,
    modalProps: {
      title: 'You are about to remove this answer',
      message: modalMessage,
      confirmButtonColor: 'danger',
      confirmButtonText: 'Yes, remove answer',
      closeButtonText: 'No, keep answer',
    },
  });

  return {
    handleRemoveItemClick: openModal,
    removeItemModal: modal,
  };
};

type AutocompleteOption = { id: number; name: string };
type AddToCategoryArgs = {
  reference: LocalizedCollectionReference;
  collectionId: number;
  localizedCollectionId: number;
};
export const useAddToCategoryAction = ({
  reference,
  collectionId,
  localizedCollectionId,
}: AddToCategoryArgs) => {
  const [categoryIndices, setCategoryIndices] = useState<AutocompleteOption[]>(
    []
  );
  const dispatchToast = useAppToast();
  const { catchStaleWrite } = useCatchStaleWriteError();
  const api = useOzmoApiService();
  const {
    categories,
    reconcileReferenceInCategoriesInAllLanguages,
  } = api.LocalizedCollection.get({
    id: localizedCollectionId,
    contentEntryId: collectionId,
  });

  useEffect(() => {
    const categoryIndicesWithReference = categories.reduce(
      (acc, category, categoryIndex) => {
        const referenceIndexInCategory = category.items.findIndex(
          (i) => i.id === reference.id
        );

        if (referenceIndexInCategory > -1) {
          return [...acc, { id: categoryIndex, name: category.title }];
        }
        return acc;
      },
      [] as AutocompleteOption[]
    );
    setCategoryIndices(categoryIndicesWithReference);
  }, [categories, reference.id]);

  const handleConfirm = async () => {
    const success = await reconcileReferenceInCategoriesInAllLanguages(
      reference,
      categoryIndices.map(({ id }) => id)
    ).catch(
      catchStaleWrite(`We were unable to recategorize "${reference.title}"`)
    );

    success &&
      dispatchToast({
        level: 'success',
        message: 'Success! Categories updated.',
      });
  };

  const handleChange = (_: any, options: AutocompleteOption[]) => {
    setCategoryIndices(options);
  };

  const { modal, openModal } = useActionModal({
    modalProps: {
      title: 'Assign an answer to a category or categories',
      confirmButtonText: 'Update categories',
    },
    onConfirm: handleConfirm,
    modalContent: (
      <AutocompleteMultiselect<AutocompleteOption>
        label="Category"
        onChange={handleChange}
        initialSelectedOptions={categoryIndices}
        options={categories.map((c, i) => ({ id: i, name: c.title }))}
      />
    ),
  });

  return {
    categoriesModal: modal,
    handleAddToCategoryClick: openModal,
  };
};

export const useDuplicateAction = (
  contentEntryId: number,
  categoryIndex: number,
  collectionId: number,
  localizedCollectionId: number
) => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { catchStaleWrite } = useCatchStaleWriteError();
  const api = useOzmoApiService();
  const { addReferencesInAllLanguages } = api.LocalizedCollection.get({
    id: localizedCollectionId,
    contentEntryId: collectionId,
  });

  const handleDuplicateComplete = (duplicatedContentEntryId: number) => {
    addReferencesInAllLanguages(
      [duplicatedContentEntryId],
      categoryIndex
    ).catch(
      catchStaleWrite(
        'Answer duplicated, but we were unable to add it to the collection',
        'Please use existing answer search to add your answer'
      )
    );
    setShouldFetch(false);
  };

  const handleDuplicateClick = () => {
    setShouldFetch(true);
    openModal();
  };
  const { modal: duplicateModal, openModal } = useDuplicateContentModal(
    shouldFetch ? contentEntryId : null,
    handleDuplicateComplete
  );

  return {
    duplicateModal,
    handleDuplicateClick,
  };
};
