import { forwardRef } from 'react';
import styled from 'styled-components';
import { number, string } from 'services/utils/prop-types';

import SelectedIndicatorCrosshairs from './selected-indicator-crosshairs';
import SelectedIndicatorCircles from './selected-indicator-circles';
import SelectedIndicatorArrows from './selected-indicator-arrows';

// Define the shape of the indictator
const STROKE_WIDTH = 7.2;
const INNER_RADIUS = 18;

type Props = {
  x: number;
  y: number;
  parentHeight: number;
  parentWidth: number;
  type: string;
  direction?: string;
};

const StyledIndicator = styled.svg`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke-width: ${STROKE_WIDTH}px;
  fill: none;
  stroke: #ffdf38;
  .middleRing {
    opacity: 0.45;
  }
  .outerRing {
    stroke: var(--color-primary-light);
    stroke-width: 1px;
  }
`;

// Fowarding ref here because out-of-the-box functional components cannot take a ref
// and this component will be wrapped in a MUI ClickAwayListener, which requires its
// child to take a ref, otherwise it can't tell what is being clicked away _from_
const SelectedIndicator = forwardRef<HTMLDivElement, Props>(
  ({ x, y, parentHeight, parentWidth, type, direction }, ref) => {
    const arrows =
      type === 'pinch' && direction ? (
        <SelectedIndicatorArrows direction={direction} />
      ) : (
        <></>
      );
    return (
      <div ref={ref}>
        <SelectedIndicatorCrosshairs
          x={x}
          y={y}
          parentHeight={parentHeight}
          parentWidth={parentWidth}
          type={type}
          innerRadius={INNER_RADIUS}
        />
        {type === 'pinch' ? (
          <StyledIndicator
            height="150"
            width="150"
            viewBox="0 0 150 150"
            x="50"
            y="50"
          >
            <SelectedIndicatorCircles
              innerRadius={INNER_RADIUS}
              strokeWidth={STROKE_WIDTH}
              direction={direction}
              type={type}
            />
            {arrows}
            <path
              d="M9.334 3.7h-.3v2H6.967v-2H5.391l2.61-2.609 2.608 2.61H9.334zM3.7 6.667v.3h2v2.066h-2v1.576L1.091 8l2.61-2.609v1.276zm8.3.3h.3V5.39l2.61 2.61-2.61 2.608V9.033h-2V6.967H12zM6.667 12.3h.3v-2h2.067v2h1.575L8 14.91 5.391 12.3h1.276z"
              fill="#fff"
              stroke="#1A242D"
              strokeWidth=".6"
              transform="translate(68, 67)"
            />
          </StyledIndicator>
        ) : (
          <StyledIndicator
            height="100"
            width="100"
            viewBox="0 0 100 100"
            x="50"
            y="50"
          >
            <SelectedIndicatorCircles
              innerRadius={INNER_RADIUS}
              strokeWidth={STROKE_WIDTH}
              direction={direction}
              type={type}
            />
          </StyledIndicator>
        )}
      </div>
    );
  }
);

SelectedIndicator.displayName = 'SelectedIndicator';
SelectedIndicator.propTypes = {
  x: number.isRequired,
  y: number.isRequired,
  parentHeight: number.isRequired,
  parentWidth: number.isRequired,
  type: string.isRequired,
  direction: string,
};

export default SelectedIndicator;
