import { FC, useCallback, MouseEvent } from 'react';
import { Button } from '@mui/material';

type Props = {
  isPublishInProgress: boolean;
  showPreviewButton: boolean;
  hasMissingData: boolean;
  publishError: any;
  previewButton: JSX.Element;
  onPublish: Function;
  onClose: Function;
};

export const PublishModalActions: FC<Props> = ({
  isPublishInProgress,
  showPreviewButton,
  hasMissingData,
  publishError,
  previewButton,
  onPublish,
  onClose,
}) => {
  const stableHandlePublish = useCallback((e: MouseEvent) => onPublish(), [
    onPublish,
  ]);

  const stableHandleClose = useCallback((e: MouseEvent) => onClose(), [
    onClose,
  ]);

  const disablePublish = publishError !== null || isPublishInProgress;

  if (hasMissingData) {
    return (
      <Button onClick={stableHandleClose} disabled={isPublishInProgress}>
        {'Close'}
      </Button>
    );
  }

  return (
    <>
      <Button onClick={stableHandleClose} disabled={isPublishInProgress}>
        {'Cancel'}
      </Button>
      {showPreviewButton ? (
        <>
          <Button
            color="error"
            disabled={disablePublish}
            onClick={stableHandlePublish}
          >
            {'Publish anyway'}
          </Button>
          {previewButton}
        </>
      ) : (
        <Button
          disabled={disablePublish}
          variant="contained"
          color="primary"
          onClick={stableHandlePublish}
        >
          {'Publish'}
        </Button>
      )}
    </>
  );
};
