import { useCallback, useState, useEffect } from 'react';

import { Option } from './multi-select';

export const useMultiSelect = (
  onChange: (v: Option['value'][]) => void,
  initialSelection?: Option['value'][]
) => {
  const [selected, setSelected] = useState<Option['value'][]>([]);

  useEffect(() => {
    initialSelection && setSelected(initialSelection);
    // Only run this effect at initial mount, not when initialSelection changes
    // Otherwise you 1) make this a weird semi-controlled component and
    // 2) can introduce render loops very easily if initialSelection is not referentially stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (e: any) => {
      setSelected(e.target.value);
      onChange(e.target.value);
    },
    [onChange]
  );

  const handleTagDelete = useCallback(
    (valueToRemove: Option['value']) => () => {
      setSelected((cur) => {
        const indexToRemove = cur.indexOf(valueToRemove);
        const values = [
          ...cur.slice(0, indexToRemove),
          ...cur.slice(indexToRemove + 1),
        ];
        onChange(values);
        return values;
      });
    },
    [onChange]
  );

  const isSelected = useCallback(
    (value: Option['value']) => selected.indexOf(value) >= 0,
    [selected]
  );

  return {
    selected,
    handleChange,
    isSelected,
    handleTagDelete,
  };
};
