import { FC } from 'react';
import { Typography } from '@mui/material';
import LoadingIndicator from 'components/loading-indicator';

type Props = {
  showPreviewButton: boolean;
  isPublishInProgress: boolean;
  hasMissingData: boolean;
  publishError: any;
};

export const PublishModalContent: FC<Props> = ({
  showPreviewButton,
  isPublishInProgress,
  hasMissingData,
  publishError,
}) => {
  if (publishError) {
    return (
      <>
        <Typography>{`Unable to publish ${
          publishError?.code ? `(error: ${publishError?.code})` : ''
        }`}</Typography>
        <Typography component="div">
          <pre>
            {publishError?.details?.message || JSON.stringify(publishError)}
          </pre>
        </Typography>
      </>
    );
  }
  if (hasMissingData) {
    return (
      <Typography>
        {
          "It looks like you may be missing images and/or step text. Please resolve these entries before selecting 'Publish'."
        }
      </Typography>
    );
  }
  if (isPublishInProgress && !publishError) {
    return <LoadingIndicator message="Publishing content..." />;
  }
  return (
    <Typography>
      {showPreviewButton
        ? 'Do you want to preview this content before you publish it?'
        : 'Are you sure you want to publish this content? This action cannot be undone.'}
    </Typography>
  );
};
