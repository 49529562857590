import { FunctionComponent, ReactNode } from 'react';
import { Button, Link } from '@mui/material';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: white;
  transform: rotate(270deg);
  transform-origin: center;
  position: fixed;
  top: 50%;
  right: -55px;
  z-index: 1000;
`;
const StyledButton = styled(Button)`
  background-color: #55116d;
  color: white;
  :hover {
    background-color: #55116d;
    color: white;
    transform: translateY(0px);
  }
`;
type Props = {
  text: string;
  url?: string;
  icon?: ReactNode;
};

const VerticalFlag: FunctionComponent<Props> = ({ text, url, icon }) => {
  return (
    <StyledLink
      href={url}
      target="_blank"
      rel="noopener,noreferrer"
      underline="none"
    >
      <StyledButton variant="contained" startIcon={icon} disableElevation>
        {text}
      </StyledButton>
    </StyledLink>
  );
};

export default VerticalFlag;
