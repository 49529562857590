import { FunctionComponent, useState } from 'react';
import PropTypes from 'services/utils/prop-types';
import { Button, MenuItem, Menu } from '@mui/material';
import styled from 'styled-components';
import TranslateIcon from '@mui/icons-material/Translate';
import { useActionModal } from 'components/modals';
import { PermissionRequired } from 'components/permission-required';

import ExportModalContent from './translation-export';
import { ImportModalContent, useImportTranslation } from './translation-import';
import { useTranslationExport } from './translation-export/hooks';

const StyledButton = styled(Button)`
  margin-left: 16px;
  padding: 16px;
`;

type Props = {
  collectionId: number;
  currentLanguage: string;
  disabled?: boolean;
};

const TranslationsButton: FunctionComponent<Props> = ({
  collectionId,
  currentLanguage,
}) => {
  const {
    uploadedFiles,
    disableSubmit,
    onDrop,
    onRefuse,
    handleImport,
    getConfirmButtonText,
  } = useImportTranslation();

  const {
    exportButtonText,
    onExportRefuse,
    handleExportSubmit,
    isLoading,
    source,
    handleChangeSource,
    handleSelectTarget,
    languages,
    title,
    targets,
    handleChangeMenuTranslate,
  } = useTranslationExport(collectionId, currentLanguage);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { modal: importModal, openModal: openImportModal } = useActionModal({
    onRefuse,
    onConfirm: handleImport,
    modalContent: (
      <ImportModalContent uploadedFiles={uploadedFiles} onDrop={onDrop} />
    ),
    modalProps: {
      title: 'Import translated text',
      disableConfirmButton: disableSubmit,
      confirmButtonText: getConfirmButtonText(),
      maxWidth: 'md',
      fullWidth: true,
    },
  });

  const { modal: exportModal, openModal: openExportModal } = useActionModal({
    onRefuse: onExportRefuse,
    onConfirm: handleExportSubmit,
    modalContent: (
      <ExportModalContent
        isLoading={isLoading}
        title={title}
        handleChangeSource={handleChangeSource}
        source={source}
        languages={languages}
        handleSelectTarget={handleSelectTarget}
        handleChangeMenuTranslate={handleChangeMenuTranslate}
      />
    ),
    modalProps: {
      title: 'Export text translations',
      disableConfirmButton: targets.length === 0,
      confirmButtonText: exportButtonText,
      maxWidth: 'md',
      fullWidth: true,
    },
  });

  const open = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleImportModalOpen = () => {
    openImportModal();
    setAnchorEl(null);
  };
  const handleExportModalOpen = () => {
    openExportModal();
    setAnchorEl(null);
  };
  return (
    <>
      <PermissionRequired permission="edit_collection" disableElement>
        <StyledButton
          variant="outlined"
          color="whitePrimary"
          onClick={handleMenuOpen}
          startIcon={<TranslateIcon />}
        >
          {'Translations'}
        </StyledButton>
      </PermissionRequired>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleImportModalOpen}>
          {'Import text translations'}
        </MenuItem>
        <MenuItem onClick={handleExportModalOpen}>
          {'Export text translations'}
        </MenuItem>
      </Menu>
      {exportModal}
      {importModal}
    </>
  );
};

TranslationsButton.propTypes = {
  disabled: PropTypes.bool,
  collectionId: PropTypes.number.isRequired,
};

export default TranslationsButton;
