import { useState, useCallback, MouseEvent, ReactNode, useEffect } from 'react';

import { InfoModal, Props as InfoModalProps } from './info';
import { ConfirmModal, Props as ConfirmModalProps } from './confirm';
import { ActionModal, Props as ActionModalProps } from './action';

// All the specialized modal's props extend from the Base modal props
// so they will all have 'open' and 'onClose' which need to be omitted.
type ModalProps<T> = Omit<T, 'open' | 'onClose' | 'onConfirm'>;

type ModalHook = {
  onConfirm?: Function;
  onRefuse?: Function;
  closeOnConfirm?: boolean;
  closeOnReject?: boolean;
  stopPropagation?: boolean;
};
type BaseModalHook<T> = ModalHook & {
  modalProps: ModalProps<T>;
};
type InfoModalHook = Omit<BaseModalHook<InfoModalProps>, 'onConfirm'>;
type ConfirmModalHook = BaseModalHook<ConfirmModalProps> & {
  disableConfirmButtonOnClick?: boolean;
};
type ActionModalHook = BaseModalHook<ActionModalProps> & {
  modalContent: ReactNode | JSX.Element;
  disableConfirmButtonOnClick?: boolean;
};

const useDisableOnClick = (
  onClick: (e: MouseEvent) => void,
  disableOnClick: boolean,
  open: boolean
) => {
  const [disable, setDisable] = useState(false);
  const handleClick = useCallback(
    (e: MouseEvent) => {
      disableOnClick && setDisable(true);
      onClick(e);
    },
    [onClick, disableOnClick]
  );

  // re-enable when external desire changes
  useEffect(() => {
    if (!disableOnClick) {
      setDisable(false);
    }
  }, [disableOnClick]);

  // Re-enable when opened
  useEffect(() => {
    open && setDisable(false);
  }, [open]);

  return {
    disable,
    handleClick,
  };
};

export const useModal = (modalArgs?: ModalHook) => {
  const {
    onConfirm,
    onRefuse,
    closeOnConfirm = true,
    closeOnReject = true,
    stopPropagation = false,
  } = modalArgs || {};
  const [open, setOpen] = useState(false);
  const [confirmInProgress, setConfirmInProgress] = useState(false);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  // the 'e' is defined here for typing purposes.
  // If you pass this directly to a Dialog's "onClose" prop, it
  // must be typed as a MouseEvent
  const closeModal = useCallback((e?: MouseEvent) => {
    setConfirmInProgress(false);
    setOpen(false);
  }, []);

  const handleClose = useCallback(
    (e?: MouseEvent) => {
      if (e && stopPropagation) {
        e.stopPropagation();
      }
      onRefuse?.();
      closeModal();
    },
    [onRefuse, stopPropagation, closeModal]
  );

  const handleConfirm = useCallback(
    async (e: MouseEvent) => {
      setConfirmInProgress(true);
      if (stopPropagation) {
        e.stopPropagation();
      }
      try {
        await onConfirm?.();
        closeOnConfirm && closeModal();
      } catch (e) {
        closeOnReject && closeModal();
      } finally {
        setConfirmInProgress(false);
      }
    },
    [onConfirm, closeOnConfirm, closeOnReject, stopPropagation, closeModal]
  );

  return { open, confirmInProgress, openModal, handleConfirm, handleClose };
};

export const useInfoModal = ({ onRefuse, modalProps }: InfoModalHook) => {
  const { open, openModal, handleClose } = useModal({ onRefuse });

  const modal = <InfoModal {...modalProps} open={open} onClose={handleClose} />;

  return { modal, openModal };
};

export const useConfirmationModal = ({
  onConfirm,
  onRefuse,
  closeOnConfirm,
  closeOnReject,
  modalProps,
  disableConfirmButtonOnClick = false,
  stopPropagation,
}: ConfirmModalHook) => {
  const {
    open,
    confirmInProgress,
    openModal,
    handleConfirm,
    handleClose,
  } = useModal({
    onConfirm,
    onRefuse,
    closeOnConfirm,
    closeOnReject,
    stopPropagation,
  });

  const {
    disable: disableConfirmButton,
    handleClick: handleConfirmClick,
  } = useDisableOnClick(handleConfirm, disableConfirmButtonOnClick, open);

  const modal = (
    <ConfirmModal
      {...modalProps}
      open={open}
      onConfirm={handleConfirmClick}
      onClose={handleClose}
      confirmInProgress={confirmInProgress}
      disableConfirmButton={
        disableConfirmButton || modalProps.disableConfirmButton
      }
    />
  );

  return { modal, openModal };
};

export const useActionModal = ({
  modalContent,
  onConfirm,
  onRefuse,
  closeOnConfirm,
  closeOnReject,
  modalProps,
  disableConfirmButtonOnClick = false,
  stopPropagation,
}: ActionModalHook) => {
  const {
    open,
    confirmInProgress,
    openModal,
    handleConfirm,
    handleClose,
  } = useModal({
    onConfirm,
    onRefuse,
    closeOnConfirm,
    closeOnReject,
    stopPropagation,
  });

  const {
    disable: disableConfirmButton,
    handleClick: handleConfirmClick,
  } = useDisableOnClick(handleConfirm, disableConfirmButtonOnClick, open);

  const modal = (
    <ActionModal
      {...modalProps}
      open={open}
      onConfirm={handleConfirmClick}
      onClose={handleClose}
      disableConfirmButton={
        disableConfirmButton || modalProps.disableConfirmButton
      }
      confirmInProgress={confirmInProgress}
    >
      {modalContent}
    </ActionModal>
  );

  return { modal, openModal };
};
