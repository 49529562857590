import { Link, LinkOff } from '@mui/icons-material';
import { MouseEventHandler, useCallback } from 'react';
import { useSlate } from 'slate-react';

import { insertLink, isLinkActive, unwrapLink } from '../plugins';
import { BlockOptions, MarkOptions } from '../types';
import { toggleMark, toggleBlock, isMarkActive, isBlockActive } from '../utils';

const getColors = (isActive: boolean) => {
  const color = isActive ? 'var(--color-neutral-one)' : 'inherit';
  const backgroundColor = isActive ? 'var(--color-neutral-seven)' : undefined;
  return { color, backgroundColor };
};

export const useLinkButton = () => {
  const editor = useSlate();
  const isActive = isLinkActive(editor);
  const label = isActive ? 'Remove link' : 'Add link';
  const icon = isActive ? <LinkOff /> : <Link />;
  const { color, backgroundColor } = getColors(isActive);

  const handleClick: MouseEventHandler = (e) => {
    e.preventDefault();
    if (isActive) {
      unwrapLink(editor);
    } else {
      const url = window.prompt('Enter the URL of the link:');
      if (!url) return;
      insertLink(editor, url);
    }
  };

  return { icon, color, backgroundColor, label, handleClick };
};

export const useFormatButton = (format: MarkOptions) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, format);
  const { color, backgroundColor } = getColors(isActive);

  const handleClick: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      toggleMark(editor, format);
    },
    [editor, format]
  );

  return { color, backgroundColor, handleClick };
};

export const useBlockButton = (format: BlockOptions) => {
  const editor = useSlate();
  const isActive = isBlockActive(editor, format);
  const { color, backgroundColor } = getColors(isActive);

  const handleClick: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      toggleBlock(editor, format);
    },
    [editor, format]
  );

  return { color, backgroundColor, handleClick };
};
