import { FC } from 'react';
import { Typography, Box } from '@mui/material';

import AttributeChip from '../attribute-chip';
import {
  OnAttrSelect,
  KeywordAttribute as KeywordAttributeType,
} from '../types';

type Props = {
  keywordAttribute: KeywordAttributeType | undefined;
  onSelect: OnAttrSelect;
};
// The KeywordAttribute represents the Content Title.
const KeywordAttribute: FC<Props> = ({ keywordAttribute, onSelect }) => {
  if (!keywordAttribute) return null;

  return (
    <Box marginBottom={1} marginTop={1}>
      <Box display="flex">
        <Typography
          textTransform="uppercase"
          fontWeight="bold"
          marginBottom={0.25}
        >
          {'Content Title'}
        </Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" gap={1}>
        <AttributeChip
          attribute={keywordAttribute}
          isAttrUsed={false}
          onAttrSelect={onSelect}
        />
      </Box>
    </Box>
  );
};

export default KeywordAttribute;
