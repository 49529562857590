import { AddCircle, CheckCircle } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import ContentTypeWithIcon from 'components/content-type-with-icon';
import { FunctionComponent } from 'react';
import Title from 'scenes/nuevo-collection/answers/answers-list/reference/components/title';
import Topic from 'scenes/nuevo-collection/answers/answers-list/reference/components/topic';

import { useQuickAddResult } from './use-quick-add-result';

type Props = {
  categoryName: string;
  contentType: ContentEntryContentType;
  id: number;
  isExistingReference: boolean;
  title: string;
  topicSlug: string;
  onSelectResult: (id: number) => Promise<any>;
  onRemoveResult: (id: number) => Promise<any>;
};

const QuickAddSearchResult: FunctionComponent<Props> = ({
  categoryName,
  contentType,
  id,
  isExistingReference,
  title,
  topicSlug,
  onSelectResult,
  onRemoveResult,
}) => {
  const { addedSuccessfully, handleSelectResult } = useQuickAddResult(
    id,
    isExistingReference,
    categoryName,
    onSelectResult,
    onRemoveResult
  );

  return (
    <Box alignItems="center" display="flex" gap={2}>
      <ContentTypeWithIcon contentType={contentType} />
      <Box flex="1 1 auto" maxWidth="22rem">
        <Box display="flex" flexDirection="row">
          <Title>{title}</Title>
          <Typography
            color="var(--color-neutral-six)"
            fontSize="0.875rem"
            display="inline"
            margin="0 2rem 0 3px"
            alignSelf="self-end"
          >{`(#${id})`}</Typography>
        </Box>
        <Topic slug={topicSlug} />
      </Box>
      <IconButton
        onClick={handleSelectResult}
        aria-label={
          addedSuccessfully || isExistingReference
            ? 'Answer added'
            : 'Add answer'
        }
      >
        {addedSuccessfully || isExistingReference ? (
          <CheckCircle
            color="success"
            data-testid="quick-add-search-result:check-icon"
          />
        ) : (
          <AddCircle
            color="primary"
            data-testid="quick-add-search-result:plus-icon"
          />
        )}
      </IconButton>
    </Box>
  );
};

export default QuickAddSearchResult;
