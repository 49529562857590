import { Token } from 'prismjs';
import { Descendant } from 'slate';
import {
  LinkElement,
  ParagraphElement,
  BulletedListElement,
  ListItemElement,
  BlockElement,
} from 'types/slate';

import { LISTTYPES } from './utils';

export type ListOptions = 'bulleted-list';

export type MarkOptions = 'bold' | 'italic';
export type BlockOptions = ListOptions;

export const isLinkElement = (el: Descendant): el is LinkElement => {
  return (el as LinkElement).type === 'link';
};

export const isParagraphElement = (el: Descendant): el is ParagraphElement => {
  return (el as ParagraphElement).type === 'paragraph';
};

export const isBulletedListElement = (
  el: Descendant
): el is BulletedListElement => {
  return (el as BulletedListElement).type === 'bulleted-list';
};

export const isListemItemElement = (el: Descendant): el is ListItemElement => {
  return (el as ListItemElement).type === 'list-item';
};

export const isBlockElement = (el: Descendant): el is BlockElement => {
  return isBulletedListElement(el);
};

export const isBlockOptions = (o: string): o is BlockOptions =>
  LISTTYPES.includes(o as ListOptions);

export const isPrismToken = (t: Token | string): t is Token =>
  (t as Token).type !== undefined;
