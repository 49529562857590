import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import { CircularIndicator } from '../../base';

type IndicatorWithChildren<P> = P & { children?: React.ReactNode };

const tapAnimation = () => keyframes`
  0% { opacity: 0.1; }
  50% { opacity: 1; }
  100% { opacity: 0.1; }
`;

const TapIndicator = styled(CircularIndicator).attrs(
  ({ x, y }: IndicatorWithChildren<IndicatorVariant>) => ({
    style: {
      top: `${y}%`,
      left: `${x}%`,
    },
  })
)<WithLofiMode<IndicatorVariant>>`
  /* Optional prop: isPaused defaults to false to set opacity to 100% */
  ${({ isPaused = false }) =>
    isPaused
      ? css`
          opacity: 1 !important;
        `
      : ''}
  /* Optional prop: isPaused defaults to false to pause the animation */
  animation-play-state: ${({ isPaused = false }) =>
    isPaused ? 'paused' : 'running'} !important;
  transform: translate(-50%, -50%);
  ${({ selected }) =>
    selected
      ? css`
          animation: none !important;
          opacity: 1 !important;
          & > circle:first-of-type {
            stroke: #1bd6b866 !important;
            stroke-width: 11px !important;
            fill: none !important;
          }
          & > circle:nth-of-type(2) {
            stroke: #1bd6b8 !important;
            stroke-width: 15px !important;
          }
        `
      : null}

  @supports (display: grid) {
    animation: ${tapAnimation} 1.5s infinite;
  }

  @media print {
    animation: none;
  }
`;

export default TapIndicator;
