export const extractTopicGroups = (items: CategoryContentEntry[] = []) => {
  const topicGroups = items.reduce<{
    [key: string]: CategoryContentEntry[];
  }>(
    (acc, cur) => ({
      ...acc,
      [cur.topicSlug!]: [...(acc[cur.topicSlug!] ?? []), cur],
    }),
    {}
  );

  return Object.values(topicGroups).map((references) => {
    if (references.length > 1) {
      return references;
    }
    return references[0];
  });
};
