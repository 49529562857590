import { FunctionComponent } from 'react';
import { Skeleton, Typography } from '@mui/material';
import MultiSelect, { Option } from 'components/multi-select';

type Props = {
  languages: Option[];
  onSelectionChanged: (values: number[]) => void;
};

const ModalContent: FunctionComponent<Props> = ({
  languages,
  onSelectionChanged,
}) => {
  return (
    <>
      <Typography marginBottom={4}>
        {'You will need to update the content within each language you add. '}
        {'You cannot remove a language once it has been added.'}
      </Typography>
      {languages.length === 0 ? (
        <Skeleton
          variant="rectangular"
          height={40}
          data-testid="add-languages-modal:skeleton"
        />
      ) : (
        <MultiSelect
          label="Languages"
          options={languages}
          initialSelection={languages
            .filter((l) => l.disabled)
            .map((l) => l.value)}
          onSelectionChanged={onSelectionChanged}
        />
      )}
    </>
  );
};

export default ModalContent;
