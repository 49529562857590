import { FC } from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { Grid, Typography } from '@mui/material';

type Props = {
  message: string;
  className?: string;
  alignment?: 'row' | 'column';
  indicatorOptions?: CircularProgressProps;
};

const LoadingIndicator: FC<Props> = ({
  message,
  className,
  alignment,
  indicatorOptions,
}) => (
  <Grid
    className={className}
    container
    justifyContent="center"
    alignItems="center"
    spacing={4}
  >
    <Grid item xs={alignment === 'column' ? 12 : undefined}>
      <CircularProgress {...indicatorOptions} />
    </Grid>
    <Grid item xs={alignment === 'column' ? 12 : undefined}>
      <Typography>{message}</Typography>
    </Grid>
  </Grid>
);

export default LoadingIndicator;
