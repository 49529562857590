import { useCallback, FunctionComponent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CollectionHeader } from 'scenes/sticky-header';
import TabbedContent from 'scenes/tabbed-content';
import TranslationsButton from 'scenes/translations';
import CopyButton from 'components/copy-button';

import ContentTab from './content-tab';
import DetailsTab from './details-tab';
import { useCurrentCollection } from './hooks';
import { generateCollectionPath } from './util';

type Props = {};

const Collection: FunctionComponent<Props> = () => {
  const {
    language,
    collectionId,
    categoryId,
    languageId,
  } = useCurrentCollection();
  const navigate = useNavigate();
  const location = useLocation();

  const onSelectLanguage = useCallback(
    (languageShortcode: string) => {
      navigate({
        pathname: generateCollectionPath(
          collectionId,
          languageShortcode,
          categoryId
        ),
        search: location.search,
      });
    },
    [navigate, location, collectionId, categoryId]
  );

  return (
    <>
      <TabbedContent
        header={
          <CollectionHeader
            onSelectLanguage={onSelectLanguage}
            actionButtons={[
              <CopyButton
                key="copy-button"
                contentEntryId={collectionId}
                contentType="collection2"
                language={language}
              />,
              <TranslationsButton
                key="translations-button"
                collectionId={collectionId}
                currentLanguage={languageId}
                isContentEntryBased={false}
              />,
            ]}
          />
        }
        tabLabels={['Content', 'Details']}
      >
        <ContentTab />
        <DetailsTab />
      </TabbedContent>
    </>
  );
};

export default Collection;
