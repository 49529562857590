import { FC, useCallback, useState, memo } from 'react';
import styled from 'styled-components';
import { Tooltip, Button } from '@mui/material';

type Props = {
  disabled: boolean;
  indicateAttrsChanged: boolean;
  onClick: VoidFunction;
};

type StyledProps = {
  $shakeAnimation: boolean;
};

const StyledButton = styled(Button)<StyledProps>`
  // to be overtop the result panel underlay so a user can
  // click search without closing the result panel
  z-index: 7;
  animation: ${(p) =>
    p.$shakeAnimation
      ? 'shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both'
      : 'none'};
  transform: translate3d(0, 0, 0);
  perspective: 1000px;

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-2px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(2px, 0, 0);
    }
  }
`;

const SearchButton: FC<Props> = ({
  disabled,
  indicateAttrsChanged,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleOpen = useCallback(() => setOpen(indicateAttrsChanged), [
    indicateAttrsChanged,
  ]);
  return (
    <Tooltip
      open={open}
      arrow
      title="Click 'Search' to update your results"
      enterDelay={500}
      onOpen={handleOpen}
      onClose={handleClose}
      // @ts-ignore - complains 'sx' does not exist on the type for PopperProps; but this does in fact work
      PopperProps={{ sx: { '& .MuiTooltip-tooltip': { maxWidth: '145px' } } }}
    >
      <StyledButton
        id="answer-filter-search-button"
        variant="contained"
        color="primary"
        disabled={disabled}
        $shakeAnimation={!disabled && indicateAttrsChanged}
        onClick={onClick}
      >
        {'Search'}
      </StyledButton>
    </Tooltip>
  );
};

const memoizedSearch = memo(
  SearchButton,
  (prevProps, nextProps) =>
    prevProps.disabled === nextProps.disabled &&
    prevProps.indicateAttrsChanged === nextProps.indicateAttrsChanged &&
    prevProps.onClick === nextProps.onClick
);

export default memoizedSearch;
