export const toPercentage = (pixels: number, bound: number) =>
  (pixels / bound) * 100;

export const toPixels = (percentage: number, bound: number) =>
  (percentage / 100) * bound;

export const verifyMoveIsWithinBounds = (
  x: number,
  y: number,
  height: number,
  width: number
) => x <= width && x >= 0 && y <= height && y >= 0;

/**
 * Returns the next position if the move is within the bounds,
 *  otherwise returns the current position
 *
 * @param x Current x position
 * @param y Current y position
 * @param newX Next x position
 * @param newY Next y position
 * @param height Height of container
 * @param width Width of container
 * @returns ({x, y}) - The next position
 */
export const calculateNextPosition = (
  x: number,
  y: number,
  newX: number,
  newY: number,
  height: number,
  width: number
) => {
  if (verifyMoveIsWithinBounds(newX, newY, height, width)) {
    return { x: newX, y: newY };
  }

  return { x, y };
};

/**
 * Returns a set of sliding array windows of the specified size.
 * @param array An array of elements (T[])
 * @param windowSize How many elements to include in each window
 * @returns T[][]
 */
export const slidingWindows = <T>(array: T[], windowSize = 2) =>
  array
    .map((_, i, ary) => ary.slice(i, i + windowSize))
    .slice(0, -windowSize + 1);

type DragPosition = {
  x: number;
  y: number;
  index: number;
};

/**
 * Takes a set of waypoints and information about the state of the image, and returns
 * a set of positions and angles for arrows to follow.
 * @param waypoints Returns a set of waypoint arrows and angles to the next waypoint.
 * @param parentWidth The width of the parent container.
 * @param parentHeight The height of the parent container.
 * @param dragPosition A reference to the current drag positioning.
 * @returns Angles[]
 */
export const createConnections = (
  waypoints: Waypoint[],
  parentWidth: number,
  parentHeight: number,
  dragPosition: DragPosition
) => {
  const connections = slidingWindows(waypoints);
  return connections.map(([wpA, wpB], ix) => {
    // convert each waypoint position to pixels for angle calculation
    const aPx = {
      x: toPixels(wpA.x, parentWidth),
      y: toPixels(wpA.y, parentHeight),
    };

    const bPx = {
      x: toPixels(wpB.x, parentWidth),
      y: toPixels(wpB.y, parentHeight),
    };

    // overwrite position with in-progress drag, if active
    if (dragPosition.index === ix) {
      aPx.x = dragPosition.x;
      aPx.y = dragPosition.y;
    }

    if (dragPosition.index === ix + 1) {
      bPx.x = dragPosition.x;
      bPx.y = dragPosition.y;
    }

    // reset to 0,0 for the origin so atan2 works properly.
    const x = bPx.x - aPx.x;
    const y = bPx.y - aPx.y;

    const initialAngle = (Math.atan2(y, x) * 180) / Math.PI;
    const angle = (initialAngle + 90) % 360; // adjust for initial up-position

    return {
      x:
        dragPosition.index === ix
          ? toPercentage(dragPosition.x, parentWidth)
          : wpA.x,
      y:
        dragPosition.index === ix
          ? toPercentage(dragPosition.y, parentHeight)
          : wpA.y,
      angle,
    };
  });
};
