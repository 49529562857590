import { FunctionComponent } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import AttributesTagList from 'components/attributes-tag-list';
import ReferencedCollectionsList, {
  useReferencedCollectionsList,
} from 'components/referenced-collections-list';
import { getAttributeCount } from 'services/utils/get-attribute-count';

import Section from './section';
import Contributors from './contributors';
import LastUpdated from './last-updated';
import Space from './space';
import TitleAndContentType from './title-and-content-type';
import Languages from './languages';

type Props = {
  contentEntryId: number | null;
  hasActionableLanguages: boolean;
  languageId?: number;
};

const ContentEntrySidePanel: FunctionComponent<Props> = ({
  contentEntryId,
  languageId,
  hasActionableLanguages,
}) => {
  const api = useOzmoApiService();
  const { data: contentEntry } = api.ContentEntry.get({
    id: contentEntryId ?? undefined,
  });

  const { referencedCollections } = useReferencedCollectionsList(
    contentEntryId
  );

  if (!contentEntryId) {
    return null;
  }

  const everyLceDeleted = contentEntry?.localizedContentEntries?.every(
    (lce) => !!lce.deletedAt
  );

  return (
    <div data-testid="content-sidepanel">
      <Section padding="12px 24px 0px 24px">
        <TitleAndContentType
          contentEntryId={contentEntryId}
          languageId={languageId}
        />
      </Section>
      <Section title="Languages">
        <Languages
          contentEntryId={contentEntryId}
          hasActionableLanguages={hasActionableLanguages}
        />
      </Section>
      <Section title="Space">
        <Space contentEntryId={contentEntryId} />
      </Section>
      <Section title="Last Updated">
        <LastUpdated contentEntryId={contentEntryId} />
      </Section>
      <Section title={`Collections (${referencedCollections?.length})`}>
        <ReferencedCollectionsList
          contentEntryId={contentEntryId}
          collapsedHeight="6.5rem"
        />
      </Section>
      <Section title={`Attributes (${getAttributeCount(contentEntry)})`}>
        <AttributesTagList
          contentEntryId={contentEntryId}
          disabled={everyLceDeleted}
        />
      </Section>
      <Section title="Contributors">
        <Contributors contentEntryId={contentEntryId} />
      </Section>
    </div>
  );
};

export default ContentEntrySidePanel;
