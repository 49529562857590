import {
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  MouseEvent,
} from 'react';
import { Typography } from '@mui/material';
import { useActionModal } from 'components/modals';

export const useRemoveCategoryModal = (
  categoryTitle: string,
  collectionTitle: string,
  onRemove: VoidFunction,
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
) => {
  const removeModalContent = useMemo(
    () => (
      <Typography>
        {'You are about to remove this category, '}
        <Typography component="span" fontWeight="bold">
          {categoryTitle}
        </Typography>
        {', from the collection, '}
        <Typography component="span" fontWeight="bold">
          {collectionTitle}
        </Typography>
        {' in all locales. This action will remove the category'}
        <Typography component="i">{' and '}</Typography>
        {'answers from this collection.'}
      </Typography>
    ),
    [categoryTitle, collectionTitle]
  );

  const { openModal, modal } = useActionModal({
    modalContent: removeModalContent,
    onConfirm: onRemove,
    disableConfirmButtonOnClick: true,
    stopPropagation: true,
    modalProps: {
      title: 'Remove category from collection?',
      confirmButtonText: 'Yes, remove category',
      closeButtonText: 'No, keep category',
      confirmButtonColor: 'danger',
    },
  });

  const openRemoveModal = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setAnchorEl(null);
      openModal();
    },
    [openModal, setAnchorEl]
  );

  return {
    removeModal: modal,
    openRemoveModal,
  };
};

export const useCategoryDetailsModal = (
  categoryTitle: string,
  onEdit: VoidFunction,
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
) => {
  const editModalContent = useMemo(
    () => (
      <Typography>
        {'You are about to edit this category, '}
        <Typography component="span" fontWeight="bold">
          {categoryTitle}
        </Typography>
        {', in all locales. This action will only edit the category from '}
        <Typography component="i">{'this '}</Typography>
        {'collection. Answers within this category will still be accessible.'}
      </Typography>
    ),
    [categoryTitle]
  );

  const { openModal, modal } = useActionModal({
    modalContent: editModalContent,
    onConfirm: onEdit,
    disableConfirmButtonOnClick: true,
    stopPropagation: true,
    modalProps: {
      title: 'Edit category from collection?',
      confirmButtonText: 'Yes, edit category',
      closeButtonText: 'No, keep category',
      confirmButtonColor: 'danger',
    },
  });

  const openEditModal = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setAnchorEl(null);
      openModal();
    },
    [openModal, setAnchorEl]
  );

  return {
    editModal: modal,
    openEditModal,
  };
};
