import { queryClient } from 'contexts/ozmo-api-service-context';
import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';
import {
  createData,
  deleteData,
  generateQueryKey,
  updateData,
  UrlParamOptions,
  UseQueryOptionsWithPrefetch,
} from 'services/ozmo-api/use-query-cache';
import keysToSnake from 'services/utils/convert-object-keys-camel-to-snake';

type ResourcePathVariables = {
  id?: number | '/';
};

class Collection extends OzmoApiBase<
  CollectionModel,
  CollectionUpdateModel,
  CollectionCreateModel,
  ResourcePathVariables
>() {
  protected static resourcePath = 'authoring/collections/:id';
  protected static categoryResourcePath =
    'authoring/collections/:collectionId/categories/:id';
  protected static referenceResourcePath =
    'authoring/collections/:collectionId/categories/:id/content_entries/:contentEntryId';
  protected static embedOptions = [
    'categories',
    'devices',
    'device_types',
    'manufacturers',
    'operating_systems',
    'operating_system_releases',
    'operating_system_versions',
  ];
  protected static defaultReactQueryConfig: UseQueryOptionsWithPrefetch = {
    staleTime: 300000, // 5 minutes
  };

  updateCategory = async (
    id: number,
    name: string,
    parentCategoryId?: number
  ) => {
    return this.refetchedOperation(() =>
      updateData(this.parseCategoryPath(id), {
        id,
        name,
        parentId: parentCategoryId,
      })
    );
  };

  addCategory = async (name: string, parentCategoryId?: number) => {
    return this.refetchedOperation(() =>
      createData(this.parseCategoryPath(), {
        name,
        parentId: parentCategoryId,
      })
    );
  };

  deleteCategory = async (id: number) => {
    return this.refetchedOperation(() =>
      deleteData(this.parseCategoryPath(), id)
    );
  };

  addReferenceToCategory = async (categoryId: number, referenceId: number) => {
    const parsedPath = this.parseReferencePath(categoryId);
    return this.refetchedOperation(() =>
      createData(parsedPath, { contentEntryId: referenceId })
    );
  };

  static duplicate = async (
    collectionId: number,
    newAttributes: CollectionUpdateModel
  ): Promise<CollectionModel> => {
    return createData(`authoring/collections/${collectionId}/copy`, {
      ...keysToSnake(newAttributes),
    });
  };

  static searchAsync(
    searchParams: CollectionSearchParams,
    options?: UrlParamOptions
  ): Promise<CollectionSearchResult[]> {
    return this.createAsyncWithCache<
      CollectionSearchParams,
      CollectionSearchResult[]
    >(searchParams, 'authoring/collections/search', undefined, options);
  }

  static addReferenceToCategoryAsync = async (
    collectionId: number,
    categoryId: number,
    contentEntryId: number
  ) => {
    const [parsedPath] = OzmoApiBase().parseResourcePathString(
      Collection.referenceResourcePath,
      {
        id: categoryId,
        collectionId,
      }
    );
    return Collection.refetchedOperationAsync<CategoryModel>(
      () => createData(parsedPath, keysToSnake({ contentEntryId })),
      collectionId
    );
  };

  deleteReferenceFromCategory = async (
    categoryId: number,
    referenceId: number
  ) => {
    const parsedPath = this.parseReferencePath(categoryId, referenceId);
    return this.refetchedOperation(() => deleteData(parsedPath, referenceId));
  };

  static async bulkAsync(
    collectionId: number,
    operations: BulkOperation[]
  ): Promise<{ job: number }> {
    const body = operations.map((op) => JSON.stringify(op)).join('\n');
    const result = await createData(
      `authoring/collections/${collectionId}/categories`,
      body,
      undefined,
      undefined,
      false,
      { 'Content-Type': 'application/x-ndjson' }
    );
    return result;
  }

  static deleteReferenceFromCategoryAsync = async (
    collectionId: number,
    categoryId: number,
    contentEntryId: number
  ) => {
    const [parsedPath] = OzmoApiBase().parseResourcePathString(
      Collection.referenceResourcePath,
      {
        id: categoryId,
        collectionId,
        contentEntryId,
      }
    );
    return Collection.refetchedOperationAsync<CategoryModel>(
      () => deleteData(parsedPath),
      collectionId
    );
  };

  static async refetchedOperationAsync<T>(
    fn: () => Promise<T>,
    collectionId: number
  ): Promise<T> {
    const result = await fn();
    const parsedPath = Collection.parseCollectionPath(collectionId);
    queryClient.refetchQueries(generateQueryKey(collectionId, parsedPath));
    return result;
  }

  private async refetchedOperation<T>(fn: () => Promise<T>): Promise<T> {
    const result = await fn();
    this.refetch();
    return result;
  }

  private static parseCollectionPath(id: number) {
    const [parsedPath] = OzmoApiBase().parseResourcePathString(
      Collection.resourcePath,
      {
        id,
      }
    );

    return parsedPath;
  }

  static getCollectionsByReferencedContentEntryId(
    referencedContentEntryId: Maybe<number>,
    config?: UseQueryOptionsWithPrefetch,
    embedOptions?: string[],
    urlParamOptions: UrlParamOptions = {}
  ) {
    const [resourcePath] = this.parseResourcePathString(this.resourcePath);
    const queryResponse = this.getQueryCacheResponse(
      '/' as any,
      resourcePath,
      embedOptions ?? [],
      {
        ...this.defaultReactQueryConfig,
        ...config,
        enabled: referencedContentEntryId !== null,
      },
      {
        ...urlParamOptions,
        referencedContentEntryId: referencedContentEntryId ?? undefined,
      }
    );
    return new this(queryResponse, this.readonly);
  }

  private parseCategoryPath(categoryId?: number) {
    const [parsedPath] = OzmoApiBase().parseResourcePathString(
      Collection.categoryResourcePath,
      {
        id: categoryId,
        collectionId: this.id,
      }
    );

    return parsedPath;
  }

  private parseReferencePath(categoryId: number, referenceId?: number) {
    const [parsedPath] = OzmoApiBase().parseResourcePathString(
      Collection.referenceResourcePath,
      {
        id: categoryId,
        collectionId: this.id,
        answerId: referenceId,
      }
    );

    return parsedPath;
  }
}

export default Collection;
