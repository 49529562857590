import { FC } from 'react';
import { Button, Tooltip } from '@mui/material';
import Link from '@mui/icons-material/Link';
import { useCopyLink } from 'services/hooks/use-copy-link';

type Props = {
  contentType?: string;
  contentEntryId: number;
  language?: string;
};

const CopyButton: FC<Props> = ({ contentType, contentEntryId, language }) => {
  const [onClick, tooltipText] = useCopyLink(
    contentType,
    contentEntryId,
    undefined,
    false,
    language
  );

  return (
    <Tooltip arrow title={tooltipText}>
      <Button
        onClick={onClick}
        aria-label={tooltipText}
        variant="outlined"
        color="whitePrimary"
        startIcon={<Link />}
      >
        {'Copy Link'}
      </Button>
    </Tooltip>
  );
};

export default CopyButton;
