import { FunctionComponent, useCallback, useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import PropTypes from 'services/utils/prop-types';
import DragAndDrop from 'components/drag-and-drop';
import { getFileArray } from 'services/utils/file-utils/file-utils';
import SelectDevice from 'components/select-device';
import SelectDeviceShell from 'components/select-device-shell';
import styled from 'styled-components';
import { Typography } from '@mui/material';

const StyledSelectContainer = styled.div`
  display: flex;
  & > div {
    margin: 8px 16px 8px 0px;
  }
`;

type Device = {
  id: number;
};

type Props = {
  onAddFiles: (files: File[]) => void;
  onSelectDeviceShell: (shell?: DeviceShellModel) => void;
  error?: string;
};

const DragAndDropArea: FunctionComponent<Props> = ({
  onAddFiles,
  onSelectDeviceShell,
  error,
}) => {
  const [device, setDevice] = useState<Device>();

  const handleDrop = useCallback(
    (fileOrFiles: File | FileList) => {
      const files = getFileArray(fileOrFiles);
      onAddFiles(files);
    },
    [onAddFiles]
  );
  const handleDropError = useCallback(() => {}, []);

  const handleSetDevice = useCallback(
    (device: Device) => {
      setDevice(device);
      if (device === null) {
        onSelectDeviceShell(undefined);
      }
    },
    [onSelectDeviceShell]
  );

  const handleSelectShell = useCallback(
    (deviceShell?: DeviceShellModel) => {
      onSelectDeviceShell(deviceShell);
    },
    [onSelectDeviceShell]
  );

  return (
    <>
      <StyledSelectContainer>
        <SelectDevice
          device={device}
          operatingSystemRelease={null}
          hasHighlight={false}
          setDevice={handleSetDevice}
        />
        {device?.id && (
          <SelectDeviceShell
            onSelect={handleSelectShell}
            deviceId={device.id}
          />
        )}
      </StyledSelectContainer>
      {error && (
        <Typography
          color="error"
          display="flex"
          alignItems="center"
          margin="8px 0px"
        >
          <ErrorIcon />
          &nbsp;
          {error}
        </Typography>
      )}

      <DragAndDrop
        withFileSelector={true}
        allowBulk={true}
        onDrop={handleDrop}
        onError={handleDropError}
      />
    </>
  );
};

DragAndDropArea.propTypes = {
  onAddFiles: PropTypes.func.isRequired,
};

export default DragAndDropArea;
