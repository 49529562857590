import { FunctionComponent } from 'react';
import { Skeleton, Typography } from '@mui/material';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

type Props = {
  contentEntryId: number;
};

const Space: FunctionComponent<Props> = ({ contentEntryId }) => {
  const api = useOzmoApiService();
  const { data: contentEntry } = api.ContentEntry.get({ id: contentEntryId });

  if (!contentEntry) {
    return (
      <Skeleton
        data-testid="content-sidebar-space:skeleton"
        variant="rectangular"
      />
    );
  }

  return (
    <Typography>{contentEntry?.space?.name ?? 'No space available'}</Typography>
  );
};

export default Space;
