import ozmoApi from 'services/ozmo-api';
import { isLocalizedContentEntryPublishError } from 'services/utils/type-guards';

const getLocalizedCategories = (
  localizedCollection: LocalizedContentEntryModel
): CategoryItem[] => {
  return localizedCollection.properties.items.filter(
    (item: any) => item?.title
  );
};

const getUncategorizedLocalizedContentReferences = (
  localizedCollection: LocalizedContentEntryModel
): Reference[] => {
  return localizedCollection.properties.items.filter(
    (item: any) => !item?.title
  );
};

// helper function meant to rollback a localizedCollection if an error
// occurs during an update
const localizedCollectionRollback = async (collection: ContentEntryModel) => {
  return collection.localizedContentEntries.map(
    (localizedCollection: LocalizedContentEntryModel) =>
      ozmoApi.LocalizedContentEntry.updateAsync(
        {
          id: localizedCollection?.id,
          contentEntryId: collection.id,
        },
        {
          properties: localizedCollection?.properties,
        }
      )
  );
};

/** Publishing utils
/**
 *
 * @param collectionId
 * @param localizedCollectionId
 * @param status desired status: 'draft' | 'published' | 'published_with_draft'
 * @returns - A promise that resolves to the updated version of the localized collection
 */
const updateLocalizedCollectionStatus = (
  collectionId: number,
  localizedCollectionId: number,
  status: LocalizedContentEntryStatus
) =>
  ozmoApi.LocalizedContentEntry.updateStatusAsync(
    localizedCollectionId,
    collectionId,
    status
  );

/**
 * @param localizedCollection - the localizedCollection to be published
 * @return A promise that resolves to the updated localized collection or an error
 */

const publishLocalizedCollection = async (
  localizedCollection: LocalizedContentEntryModel
): Promise<LocalizedContentEntryModel | PublishLocalizedContentEntryError> => {
  try {
    switch (localizedCollection.status) {
      case 'draft':
      case 'published_with_draft':
        return updateLocalizedCollectionStatus(
          localizedCollection.contentEntryId,
          localizedCollection.id,
          'published'
        );
      case 'published':
        return {
          success: true,
          updateStatusError: `${localizedCollection.locale} is already published`,
        };
      default:
        return {
          success: false,
          updateStatusError: `Unknown status transition from ${localizedCollection.status}`,
        };
    }
  } catch (error) {
    return { success: false, updateStatusError: (error as Error).toString() };
  }
};

/**
 *
 * @param collection - the collection to publish
 * @param languagesToPublish - ID's of the languages to publish in this collection
 * @returns - { success: boolean, errors: Array of string errors }
 */
type PublishCollectionResult = { success: boolean; errors: string[] };
const publishCollection = async (
  collection: ContentEntryModel,
  languagesToPublish: number[]
) => {
  const collectionsToPublish = collection.localizedContentEntries.filter(
    (localizedCollection: LocalizedContentEntryModel) =>
      languagesToPublish.includes(localizedCollection.languageId!)
  );
  try {
    const publishPromises = collectionsToPublish.map(
      (localizedCollection: LocalizedContentEntryModel) =>
        publishLocalizedCollection(localizedCollection)
    );
    const results = await Promise.all(publishPromises);
    return results.reduce<PublishCollectionResult>(
      (acc, cur) => {
        if (isLocalizedContentEntryPublishError(cur)) {
          return {
            success: false,
            errors: [...acc.errors, cur.updateStatusError],
          };
        }
        return acc;
      },
      { success: true, errors: [] }
    );
  } catch (error: any) {
    return { success: false, errors: [error.toString()] };
  }
};

const addTopicsToCollection = async (
  collectionId: number,
  topicIds: number[]
) => {
  const collection = await ozmoApi.ContentEntry.getAsync({ id: collectionId });
  if (!collection) return false;
  const localizedCollectionPromises = collection.localizedContentEntries.map(
    async (lce) => {
      const localizedContentEntry = await ozmoApi.LocalizedContentEntry.getAsync(
        {
          id: lce.id,
          contentEntryId: collectionId,
        }
      );
      const categories = getLocalizedCategories(
        localizedContentEntry
      ) as CategoryItem[];
      const references = getUncategorizedLocalizedContentReferences(
        localizedContentEntry
      );

      // get the topic id's from uncategorized topics and from within categories
      const categoryTopicIds = categories.reduce(
        (categoryTopicIds: number[], category) => {
          return [
            ...categoryTopicIds,
            ...category.items.map((topic) => topic.id),
          ];
        },
        []
      );
      const referenceIds: number[] = references.map((ref: TopicItem) => ref.id);

      // filter out any matching topic id's
      const uniqueTopicIds = topicIds.filter(
        (topicId) => ![...referenceIds, ...categoryTopicIds].includes(topicId)
      );

      const newReferences = uniqueTopicIds.map((id) => ({
        id,
        referenceType: 'ContentEntry',
      }));

      const newItems = [...categories, ...newReferences, ...references];

      return ozmoApi.LocalizedContentEntry.updateAsync(
        {
          id: localizedContentEntry.id,
          contentEntryId: collectionId,
        },
        {
          properties: {
            ...localizedContentEntry.properties,
            items: newItems,
          },
        }
      );
    }
  );

  try {
    await Promise.all(localizedCollectionPromises);
    const queryKey = ozmoApi.ContentEntry.getQueryKey(
      { id: collection.id },
      undefined,
      []
    );
    ozmoApi.queryClient.invalidateQueries(queryKey);
    return true;
  } catch (error) {
    console.log(
      'add topic to collection failed - rolling back to previous localized collections',
      error
    );

    // if update fails, rollback localized collections
    const localizedCollectionRollbacks = await localizedCollectionRollback(
      collection
    );
    await Promise.allSettled(localizedCollectionRollbacks);
    return false; // update failed
  }
};

export {
  addTopicsToCollection,
  publishCollection,
  updateLocalizedCollectionStatus,
};
