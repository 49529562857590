import { FC } from 'react';
import {
  Table,
  TableFooter,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import ContentTableContainer from 'components/content-table-container';
import ContentTableBody from 'components/content-table-body';
import getPlural from 'services/utils/get-plural';

import { useCollectionTable, useDuplicateCollection } from './hooks';
import CollectionTableHeader from './header';
import CollectionTableRow from './row';

type Props = {
  collections: CollectionSearchResult[];
  isPopulating: boolean;
  hasError: boolean;
  showOverlay?: boolean;
  onSelectedChanged: (selectedIds: number[]) => void;
  onInfoClick: (id: number) => void;
};

const CollectionTable: FC<Props> = ({
  collections,
  isPopulating,
  hasError,
  showOverlay,
  onSelectedChanged,
  onInfoClick,
}) => {
  const {
    tableData,
    selectedIds,
    isSelected,
    handleSelectAll,
    handleSelectItem,
  } = useCollectionTable(collections, onSelectedChanged);

  const { modal, openModal: openDuplicateModal } = useDuplicateCollection();

  const rowCount = tableData.length;
  return (
    <ContentTableContainer showOverlay={showOverlay}>
      <Table
        aria-label="collection-table"
        data-testid="collection-table"
        stickyHeader
      >
        <CollectionTableHeader
          selectedCount={selectedIds.length}
          rowCount={rowCount}
          handleSelectAll={handleSelectAll}
        />
        <ContentTableBody
          rowCount={rowCount}
          colCount={6}
          isPopulating={isPopulating}
          hasError={hasError}
        >
          {tableData.map((data) => (
            <CollectionTableRow
              key={data.id}
              data={data}
              isSelected={isSelected(data.id)}
              openDuplicateModal={openDuplicateModal}
              handleSelect={handleSelectItem}
              onInfoClick={onInfoClick}
            />
          ))}
        </ContentTableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={9}>
              <Typography fontWeight={500}>{`${tableData.length} ${getPlural(
                tableData.length,
                'result',
                'results'
              )}`}</Typography>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      {modal}
    </ContentTableContainer>
  );
};

export default CollectionTable;
