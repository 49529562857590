import { FC, useCallback, ReactNode, memo } from 'react';
import { styled } from '@mui/material';
import ActionChip from 'components/actionable-chip';

import { AttributeOptionWithKey, OnAttrSelect, OnAttrRemove } from './types';

type Props = {
  label?: string | ReactNode;
  attribute: AttributeOptionWithKey;
  isAttrUsed: boolean;
  grayOutWhenUsed?: boolean;
  onAttrSelect?: OnAttrSelect;
  onAttrRemove?: OnAttrRemove;
};

type StyledProps = {
  chipColor: AttributeOptionWithKey['chipColor'];
  chipColorUsed: AttributeOptionWithKey['chipColor'];
};

const StyledActionChip = styled(ActionChip)<StyledProps>(
  ({ theme, chipColor, chipColorUsed }) => ({
    borderColor: 'transparent',
    borderWidth: '2px',
    '&:focus': {
      color: theme.palette[chipColorUsed!]?.contrastText,
      backgroundColor: theme.palette[chipColorUsed!]?.main,
      borderStyle: 'solid',
      borderColor: theme.palette[chipColor!]?.main,

      '.MuiTypography-root, .MuiChip-deleteIcon': {
        color: theme.palette[chipColorUsed!]?.contrastText,
      },
    },
  })
);

const AttributeChip: FC<Props> = ({
  label,
  attribute,
  isAttrUsed,
  grayOutWhenUsed,
  onAttrSelect,
  onAttrRemove,
}) => {
  const handleSelect = useCallback(() => {
    onAttrSelect?.(attribute);
  }, [attribute, onAttrSelect]);

  const handleRemove = useCallback(() => {
    onAttrRemove?.(attribute);
  }, [attribute, onAttrRemove]);

  // Why the ternary nonsense below, you might ask? This is taking advantage of the fact that
  // MUI chips change their appearance, and behavior, if they're supplied with an onClick / onDelete
  // function. So by conditionally providing this functions, we also get similar appearance and behavior
  // "for free".
  return (
    <StyledActionChip
      id={attribute.domId}
      label={label || attribute.name}
      onClick={!isAttrUsed && onAttrSelect ? handleSelect : undefined}
      onDelete={isAttrUsed && onAttrRemove ? handleRemove : undefined}
      // these colors go to the styled component and set the focused colors
      chipColor={attribute.chipColor}
      chipColorUsed={attribute.chipColorUsed}
      // this color goes directly to the component, and is the "default" state color
      // it uses the Palette to control text and hover colors.
      color={
        grayOutWhenUsed && isAttrUsed
          ? attribute.chipColorUsed
          : attribute.chipColor
      }
    />
  );
};

const memoizedAttributeChip = memo(
  AttributeChip,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.attribute) ===
      JSON.stringify(nextProps.attribute) &&
    prevProps.label === nextProps.label &&
    prevProps.isAttrUsed === nextProps.isAttrUsed &&
    prevProps.grayOutWhenUsed === nextProps.grayOutWhenUsed
);

export default memoizedAttributeChip;
