import {
  appendToFilename,
  changeFileExtension,
} from 'services/utils/file-utils';

const getImageDimensions = (src: string) =>
  new Promise<{ height: number; width: number }>((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = function () {
      const { height, width } = this as HTMLImageElement;
      resolve({ height, width });
    };
    image.onerror = () => reject(new Error('Unable to get shell dimensions'));

    image.src = src;
  });

const drawImageOnCanvas = (
  context: CanvasRenderingContext2D,
  src: string,
  x = 0,
  y = 0,
  height?: number,
  width?: number
) =>
  new Promise<void>((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = function () {
      if (height && width) {
        context.drawImage(image, x, y, width, height);
      } else {
        context.drawImage(image, x, y);
      }
      resolve();
    };
    image.onerror = () => reject(new Error(`Unable to load image: ${src}`));

    image.src = src;
  });

export const createCompositeCanvasLink = async (
  canvas: HTMLCanvasElement,
  file: File,
  shellSource: string,
  screenCoordinates: { x: number; y: number; height: number; width: number },
  overlaySource?: string,
  shellName = ''
) => {
  const { x, y, height, width } = screenCoordinates;
  const context = canvas.getContext('2d');

  if (!context) throw new Error('Unable to get canvas context');

  const shellDimensions = await getImageDimensions(shellSource);
  canvas.setAttribute('height', shellDimensions.height.toString());
  canvas.setAttribute('width', shellDimensions.width.toString());

  // Draw the shell first
  await drawImageOnCanvas(context, shellSource);

  // Construct an Object URL from the screen image
  const url = URL.createObjectURL(file);
  // Draw the screen image in the middle
  await drawImageOnCanvas(context, url, x, y, height, width);
  // Don't leak
  URL.revokeObjectURL(url);

  // Finally draw the overlay on top if the shell has one (not all do)
  if (overlaySource) {
    await drawImageOnCanvas(context, overlaySource);
  }

  // Create an <a> element we can "click" programatically elsewhere
  // to trigger a download of the composite image in the canvas
  // The canvas is has transparancy, so the output file will be image/png
  // regardless of what the input file was, so change the extension
  const filename = changeFileExtension(file.name, 'png');
  const link = document.createElement('a');
  link.download = appendToFilename(filename, shellName);
  link.href = canvas.toDataURL();
  return link;
};
