import { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { Chip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Clear';
import { Attribute } from 'components/attribute-editor/attribute-editor';
import { formatAttributeName } from 'services/ozmo-api/utils/format-attribute-name';
import styled from 'styled-components';
import { Operation } from 'components/attribute-editor/use-attribute-editor';
import { getPendingState } from 'components/attribute-editor/selectors/utils';

const StyledContainer = styled.div`
  min-height: 80px;
  margin-bottom: 24px;
  & > div {
    margin: 4px 4px 0px 0px;
  }
`;

type AttributesWithNameFields =
  | DeviceTypeModel
  | ManufacturerModel
  | OperatingSystemModel
  | OperatingSystemReleaseModel
  | OperatingSystemVersionModel;

type Props = {
  label: string;
  attributeName: string;
  attributes: Attribute[];
  pendingOperations: Operation[];
  onDelete?: (
    attributeName: string,
    attribute: Attribute,
    action: 'remove'
  ) => void;
};

const AttributeTagGroup: FunctionComponent<Props> = ({
  label,
  attributeName,
  attributes,
  pendingOperations = [],
  onDelete,
}) => {
  const toAdd = pendingOperations
    .filter(
      (operation) => operation.op === 'add' && operation.type === attributeName
    )
    .map((operation) => operation.attribute);

  return (
    <StyledContainer>
      <Typography>{`${label}:`}</Typography>
      {[...attributes, ...toAdd].map((attribute) => {
        let label = (attribute as AttributesWithNameFields).name;

        if (attributeName === 'device') {
          const a = attribute as DeviceModel;
          label = `${a.manufacturer} ${a.trackingName}`;
        } else if (attributeName === 'operatingSystemVersion') {
          const a = attribute as OperatingSystemVersionModel;
          label = `${a.operatingSystem} ${a.name}`;
        } else if (attributeName === 'operatingSystemRelease') {
          const a = attribute as OperatingSystemReleaseModel;
          label = `${a.operatingSystemName} ${a.name}`;
        }

        const { isPendingAdd, isPendingRemove } = getPendingState(
          attributeName,
          attribute,
          pendingOperations
        );

        // eslint-disable-next-line no-nested-ternary
        const color = isPendingRemove
          ? 'error'
          : isPendingAdd
          ? 'success'
          : 'primary';

        return (
          <Chip
            color={color}
            key={attribute.id}
            label={formatAttributeName(label)}
            onDelete={
              onDelete && (() => onDelete(attributeName, attribute, 'remove'))
            }
            deleteIcon={
              <DeleteIcon
                data-testid={`attribute-tag-group:delete-icon-${attribute.id}`}
              />
            }
          />
        );
      })}
    </StyledContainer>
  );
};

AttributeTagGroup.propTypes = {
  label: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default AttributeTagGroup;
