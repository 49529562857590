import { styled, TableRow } from '@mui/material';

import { StyledCellFade } from './content-table-cell';

const StyledTableRow = styled(TableRow)<{ $rowId: number }>`
  ${({ $rowId }) =>
    `.selected-content-entry-${$rowId} .content-entry-${$rowId}`}& {
    background-color: var(--color-primary-background);

    ${StyledCellFade}:after {
      background: linear-gradient(
        90deg,
        transparent,
        var(--color-primary-background)
      );
    }
  }
`;

export default StyledTableRow;
