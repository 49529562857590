import { Box } from '@mui/material';
import { FunctionComponent } from 'react';
import {
  AddNewFunction,
  GenerateChangeFunction,
  RemoveItemFunction,
} from 'scenes/universal-content-editor';

import SchemaMedia from '../schema-media';

import { useSchemaIndicator } from './use-schema-indicator';

type Props = {
  indicator?: Indicator;
  indicators?: Indicator[];
  path: string;
  displayOrder: number;
  defaultIndicator?: Indicator;
  generateOnChange: GenerateChangeFunction;
  onAddNew: AddNewFunction;
  onRemove: RemoveItemFunction;
};

const SchemaIndicator: FunctionComponent<Props> = ({
  defaultIndicator,
  displayOrder,
  indicators,
  indicator,
  path,
  generateOnChange,
  onAddNew,
  onRemove,
}) => {
  const {
    definedIndicators,
    media,
    order,
    onAddNewIndicator,
    onChangeIndicator,
    onRemoveIndicator,
  } = useSchemaIndicator({
    displayOrder,
    indicator,
    indicators,
    path,
    generateOnChange,
    onAddNew,
    onRemove,
  });

  return (
    <Box component="div" order={order} tabIndex={order}>
      {media && (
        <SchemaMedia
          id={media.id}
          hasIndicators
          indicators={definedIndicators}
          defaultIndicator={defaultIndicator}
          path={media.path}
          displayOrder={media.displayOrder}
          onChange={generateOnChange(media.path)}
          onAddNewIndicator={onAddNewIndicator}
          onChangeIndicator={onChangeIndicator}
          onRemoveIndicator={onRemoveIndicator}
        />
      )}
    </Box>
  );
};

export default SchemaIndicator;
