import { Typography, CircularProgress, Box } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { useAutoSaveContext } from 'contexts/topic-context';

const AutoSaveIndicator = () => {
  const autoSaveInProgress = useAutoSaveContext();

  return (
    <Box
      data-testid="autosave-indicator"
      height="48px"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      minWidth="88px"
      marginRight={0.5}
      gap={0.5}
    >
      {autoSaveInProgress === true ? (
        <>
          <CircularProgress size={20} />
          <Typography color="primary" fontSize="0.875rem">
            {'Saving'}
          </Typography>
        </>
      ) : (
        <>
          <CheckCircle color="primary" fontSize="inherit" />
          <Typography color="primary" fontSize="0.875rem">
            {'Changes Saved'}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default AutoSaveIndicator;
