import { styled } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import {
  DragDropContext,
  DroppableProvidedProps,
  Draggable,
  DraggableProps,
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import StrictModeDroppable from 'components/react-dnd-strict-mode-droppable';

// Styles to apply to each item when/not dragging
type StyledItemProps = Partial<DraggableProvidedDragHandleProps> &
  Partial<DraggableProvidedDraggableProps> & {
    draggableStyle?: DraggingStyle | NotDraggingStyle;
    $cursorOverride: string;
  };
const StyledItem = styled('div')<StyledItemProps>`
  ${({ draggableStyle, $cursorOverride }) => ({
    ...draggableStyle,
    // !important applied to override the cursor style applied by the StyledList
    cursor: `${$cursorOverride} !important`,
  })};

  background-color: var(--color-neutral-one);
  transition: background-color 0.1s ease-in-out;
  border-radius: 6px;
  margin: ${({ theme }) => `${theme.spacing(1)} 0`};

  &.is-dragging > * {
    box-shadow: ${({ theme }) => theme.shadows[6]};
    background-color: var(--color-primary-background) !important;
  }
`;

// Styles to apply to the entire list when/not dragging
type StyledListProps = Partial<DroppableProvidedProps>;
const StyledList = styled('div')<StyledListProps>``;

type Props = {
  listId: string;
  items: { id: number | string; component: ReactNode }[];
  itemCursor?: string;
  onDragEnd: (result: DropResult) => void;
  draggableProps?: Partial<DraggableProps>;
};

const VerticalReorderableList: FunctionComponent<Props> = ({
  items,
  listId,
  itemCursor = 'grab',
  draggableProps,
  onDragEnd,
}) => {
  const handleDragEng = (result: DropResult, provided: ResponderProvided) => {
    onDragEnd(result);
  };

  return (
    <DragDropContext onDragEnd={handleDragEng}>
      <StrictModeDroppable droppableId={listId}>
        {(provided, snapshot) => (
          <StyledList
            {...provided.droppableProps}
            className={snapshot.isDraggingOver ? 'is-dragging-over' : ''}
            ref={provided.innerRef}
          >
            {items.map((item, i) => (
              <Draggable
                {...draggableProps}
                key={item.id}
                draggableId={item.id.toString()}
                index={i}
              >
                {(provided, snapshot) => (
                  <StyledItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    $cursorOverride={itemCursor}
                    className={snapshot.isDragging ? 'is-dragging' : ''}
                  >
                    {item.component}
                  </StyledItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </StyledList>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};

export type { DropResult, ResponderProvided };
export default VerticalReorderableList;
