import { useCallback } from 'react';
import { SelectedAttributes } from 'components/attribute-selector';
import { useAppToast } from 'contexts/app-toast-context';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { assembleBulkOperation } from 'services/utils/assemble-bulk-operation';
import { recursiveJobRequest } from 'services/ozmo-api/utils/recursive-job-request';
import { isPermissionDeniedError } from 'services/utils/type-guards/is-permission-denied-error';

import { SelectedReference } from '../hooks/use-content-tab';

const assembleBulkOperations = (
  id: number,
  selectedAttributes: SelectedAttributes,
  op: BulkOperationActions
): BulkOperation[] => {
  const attributeKeys = Object.keys(selectedAttributes);
  return attributeKeys.map((key) => {
    return assembleBulkOperation(id, op, key, selectedAttributes[key] || []);
  });
};

export const useBulkActions = (selectedReferences: SelectedReference[]) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();
  const onAddAttributes = useCallback(
    async (selectedAttributes: SelectedAttributes) => {
      try {
        const bulkOperations = selectedReferences.flatMap((ref) =>
          assembleBulkOperations(ref.id, selectedAttributes, 'add')
        );
        const { job } = await api.ContentEntry.bulkAsync(bulkOperations);
        const results = await recursiveJobRequest<BulkOperationJobResponse>(
          job
        );
        bulkOperations.forEach(({ id }) =>
          api.queryClient.invalidateQueries(
            api.ContentEntry.getQueryKey({ id })
          )
        );
        return results;
      } catch (error) {
        // A toast will be popped from use-query-cache.tsx in this case
        if (isPermissionDeniedError(error)) {
          return;
        }
        // This occurs if the initial request to kick off the bulk operation job fails
        // this does not handle the individual status of each bulk operation.
        dispatchToast({
          level: 'error',
          message: 'An error occured. Please try adding your attributes again.',
        });
      }
    },
    [selectedReferences, api.ContentEntry, api.queryClient, dispatchToast]
  );

  return {
    onAddAttributes,
  };
};
