import { useParams, useSearchParams } from 'react-router-dom';
import { Grid, styled } from '@mui/material';
import usePreviousPage from 'services/utils/use-previous-page';
import PublishButton from 'components/topic-header/publish-button';
import StudioLogo from 'components/studio-logo';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

import PreviewContentEntryBody from './preview-content-entry-body';

const StyledFullscreenContainerGrid = styled(Grid)`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1299; /* Just below the Dialog z-index of 1300 */
  background: #fff;
  padding: 16px;
  height: 100%;
  width: calc(100% + 30px) !important;
`;

const StyledHeaderGrid = styled(Grid)`
  background-color: #f2f2f2;
  max-height: 84px;
  padding-bottom: 12px;
  padding-right: 12px;
  margin: -4px !important;
`;

const Preview = () => {
  const { contentEntryId, language } = useParams<
    'contentEntryId' | 'language'
  >();
  const [searchParams] = useSearchParams();
  // previewOnly indicates that the user can only preview. No publish or navigation back.
  // the primary use for this is when preview is opened in a new tab.
  const previewOnly = searchParams.get('previewOnly') === 'true';
  const { previousPageButton } = usePreviousPage();

  const parsedContentEntryId =
    contentEntryId === undefined ? undefined : parseInt(contentEntryId, 10);

  const api = useOzmoApiService();

  const {
    isLoading,
    isError,
    error,
    data: contentEntry,
  } = api.ContentEntry.get({
    id: parsedContentEntryId,
  });

  // preview still runs in locales
  // this is due to the way e2e/core works, so to find the right entry,
  // shorten to root language shortcode
  const rootLanguage = language?.split(/-/gi)[0];

  const localizedContentEntry = contentEntry?.localizedContentEntries.find(
    (l) => l.languageShortCode === rootLanguage
  );

  return (
    <StyledFullscreenContainerGrid container spacing={3}>
      <StyledHeaderGrid item xs={12}>
        <Grid container justifyContent="space-between">
          {previewOnly ? (
            <Grid item>
              <StudioLogo />
            </Grid>
          ) : (
            <>
              <Grid item>{previousPageButton}</Grid>
              <Grid item>
                {parsedContentEntryId && (
                  <PublishButton
                    contentEntryId={parsedContentEntryId}
                    localizedContentEntryId={localizedContentEntry?.id}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </StyledHeaderGrid>
      <Grid item xs={12}>
        <PreviewContentEntryBody
          contentEntryId={parsedContentEntryId}
          locale={localizedContentEntry?.locale ?? language}
          isLoading={isLoading}
          isError={isError}
          error={JSON.stringify(error)}
          localizedContentEntryId={localizedContentEntry?.id}
        />
      </Grid>
    </StyledFullscreenContainerGrid>
  );
};

export default Preview;
