import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import magnifyingGlass from 'icons/no-results-found-laptop-magnifying-glass.svg';

const NoAnswers: FC = () => (
  <Grid
    container
    flexDirection="column"
    width="100%"
    alignItems="center"
    spacing={1}
  >
    <Grid item>
      <img src={magnifyingGlass} alt="no results" width="350px" />
    </Grid>
    <Grid item>
      <Typography fontWeight="bold">
        {'Whoops! We weren’t able to find any answers matching your filters'}
      </Typography>
    </Grid>
    <Grid item>
      <Typography>
        {'Please change your filters to find answers in the Collection'}
      </Typography>
    </Grid>
  </Grid>
);

export default NoAnswers;
