import {
  ThemeProvider as MaterialUiThemeProvider,
  StyledEngineProvider,
  styled,
} from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'theme';
import ozmoTheme from 'ozmo-theme';
import GlobalContexts from 'scenes/app/global-contexts';
import ApplicationShell from 'scenes/application_shell';
import 'ozmo-theme.css';

import AuthoringRouting from './routing';
import ErrorBoundary from './error';

export const ApplicationContainer = styled('div')`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  width: 100%;
  height: 100%;
  ol,
  ul {
    list-style: none;
  }
`;
const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <MaterialUiThemeProvider theme={ozmoTheme}>
        <ThemeProvider theme={defaultTheme}>
          <ApplicationContainer>
            <GlobalContexts>
              <ErrorBoundary>
                <ApplicationShell>
                  <AuthoringRouting />
                </ApplicationShell>
              </ErrorBoundary>
            </GlobalContexts>
          </ApplicationContainer>
        </ThemeProvider>
      </MaterialUiThemeProvider>
    </StyledEngineProvider>
  );
};
export default App;
