import { useCallback, useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useActionModal } from 'components/modals';
import { useAppToast } from 'contexts/app-toast-context';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import useRestoreAnswer from 'services/hooks/use-restore-answer';
import { generateQueryKey } from 'services/ozmo-api/use-query-cache';

import { ModalContent } from './modal-content';

export const useDeleteAnswerHook = (
  contentEntryId: number,
  localizedContentEntryId: number
) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();
  const [stableLce, setStableLce] = useState<
    LocalizedContentEntryModel | undefined
  >();
  const { handleRestoreAnswer } = useRestoreAnswer(
    contentEntryId,
    localizedContentEntryId
  );

  // If LocalizedContentEntry.get was used then it would briefly return undefined
  // once the LCE is deleted. This would cause the modal to not show the lce info
  // during deletion.
  useEffect(() => {
    const getLce = async () => {
      const lce = await api.LocalizedContentEntry.getAsync({
        id: localizedContentEntryId,
        contentEntryId,
      });

      setStableLce(lce);
    };
    getLce();
  }, [api.LocalizedContentEntry, contentEntryId, localizedContentEntryId]);

  const handleDeleteAnswer = useCallback(async () => {
    const { title } = stableLce?.properties || {};
    const success = await api.LocalizedContentEntry.deleteAsync({
      id: localizedContentEntryId,
      contentEntryId,
    });

    if (success) {
      // invalidate the query to refetch the now deleted LCE
      api.queryClient.invalidateQueries(
        generateQueryKey(contentEntryId, 'authoring/content_entries')
      );
      dispatchToast({
        level: 'success',
        buttonText: 'UNDO',
        onButtonClick: async () => {
          await handleRestoreAnswer();
        },
        message: (
          <Typography>
            <Typography fontWeight="bold" component="span">
              {title}
            </Typography>
            {' has been successfully deleted.'}
          </Typography>
        ),
      });
    } else {
      dispatchToast({
        level: 'error',
        message: (
          <Typography>
            {'Unable to delete '}
            <Typography fontWeight="bold" component="span">
              {title}
            </Typography>
            {'. Please try again'}
          </Typography>
        ),
      });
    }
  }, [
    api.LocalizedContentEntry,
    api.queryClient,
    contentEntryId,
    dispatchToast,
    handleRestoreAnswer,
    stableLce?.properties,
    localizedContentEntryId,
  ]);

  const { modal, openModal } = useActionModal({
    onConfirm: handleDeleteAnswer,
    disableConfirmButtonOnClick: true,
    modalContent: <ModalContent lce={stableLce} />,
    modalProps: {
      title: 'Heads up! You are about to delete an answer',
      confirmButtonText: 'Yes, delete answer',
      confirmButtonColor: 'danger',
      closeButtonText: 'No, keep answer',
      confirmInProgressText: 'Deleting…',
      maxWidth: 'md',
      disableConfirmButton: !stableLce,
    },
  });

  return {
    modal,
    openModal,
    disableDelete: !stableLce || !!stableLce?.deletedAt,
  };
};
