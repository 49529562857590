import { useMemo, useCallback, ChangeEventHandler, FC } from 'react';
import MultiSelect from 'components/multi-select';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { Skeleton } from '@mui/material';

type Props = {
  languageIds: number[];
  setLanguages: ChangeEventHandler;
};

const LanguageSelect: FC<Props> = ({ languageIds, setLanguages }) => {
  const api = useOzmoApiService();
  const { all: languages, isLoading } = api.Language.getAll();

  const languageOptions = useMemo(() => {
    return languages.map((language) => ({
      label: `${language.displayName} (${language.shortCode})`,
      value: language.id,
    }));
  }, [languages]);

  const onLanguagesSelected = useCallback(
    (values: number[]) => {
      setLanguages({ target: { value: values } } as any);
    },
    [setLanguages]
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" />;
  }

  return (
    <MultiSelect
      fullWidth
      label="Languages"
      options={languageOptions}
      initialSelection={languageIds}
      onSelectionChanged={onLanguagesSelected}
    />
  );
};

export default LanguageSelect;
