import { FunctionComponent } from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import ModalBase, { useModal } from 'components/modals';

import ActionButton from '../action-button';

import { usePublishAnswers } from './hooks';
import ModalBody from './modal/body';
import ModalActions from './modal/actions';

type Props = {
  languageDisplayName: string;
  selectedReferences: any[];
  refetchCollection: Function;
};

const PublishAnswers: FunctionComponent<Props> = ({
  languageDisplayName,
  selectedReferences,
  refetchCollection,
}) => {
  const {
    completeReferencesCount,
    incompleteReferences,
    handlePublish,
  } = usePublishAnswers(selectedReferences, refetchCollection);
  const { open, openModal, handleClose } = useModal();

  return (
    <>
      <ModalBase
        open={open}
        scroll="paper"
        onClose={handleClose}
        title={
          completeReferencesCount
            ? `Publish answers in ${languageDisplayName}`
            : 'Cannot publish answers'
        }
        actionChildren={
          <ModalActions
            completeReferencesCount={completeReferencesCount}
            onPublish={handlePublish}
            onClose={handleClose}
          />
        }
      >
        <ModalBody
          completeReferencesCount={completeReferencesCount}
          incompleteReferences={incompleteReferences}
          languageDisplayName={languageDisplayName}
        />
      </ModalBase>
      <ActionButton onClick={openModal} startIcon={<PublishIcon />}>
        {'Publish'}
      </ActionButton>
    </>
  );
};

export default PublishAnswers;
