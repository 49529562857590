import PublishButton from 'components/topic-header/publish-button';
import { FC } from 'react';
import { useCurrentLocalizedContentEntry } from 'scenes/universal-content-editor/hooks/use-current-localized-content-entry';

type Props = {
  contentEntryId: number;
};
const UniversalPublishButton: FC<Props> = ({ contentEntryId }) => {
  const { localizedContentEntry } = useCurrentLocalizedContentEntry();

  return (
    <PublishButton
      contentEntryId={contentEntryId}
      localizedContentEntryId={localizedContentEntry?.id}
      showPreviewButton={true}
    />
  );
};

export default UniversalPublishButton;
