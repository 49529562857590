import { KeyboardEventHandler, useCallback, useEffect, useMemo } from 'react';
import { createEditor, Editor, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';

import { Element, Leaf } from './components';
import { withInlines, withHtml, withOzmoFormatting } from './plugins';
import {
  toggleMark,
  serialize,
  deserialize,
  HOTKEYS,
  toggleBlock,
} from './utils';
import { isBlockOptions } from './types';

export const useRichTextEditor = (
  initialValue: string,
  onSave: (t: string) => void
) => {
  const editor = useMemo(
    () =>
      withOzmoFormatting(
        withHtml(withInlines(withReact(withHistory(createEditor()))))
      ),
    []
  );
  const value = useMemo(() => deserialize(initialValue), [initialValue]);

  // When the value changes- which means the data on the server changed  reset the
  // editor's internal storage- this is the recommend method from the SlateJS docs:
  // https://docs.slatejs.org/walkthroughs/06-saving-to-a-database
  useEffect(() => {
    editor.children = value;
    editor.onChange();
    // Move the cursor to the end of the line
    Transforms.select(editor, Editor.end(editor, []));
  }, [editor, value]);

  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);

  const handleClickAway = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const { children } = editor;
      onSave(serialize(children));
    },
    [editor, onSave]
  );

  const handleEditorKeydown: KeyboardEventHandler = useCallback(
    (e) => {
      const { ctrlKey, metaKey, key } = e;
      const isHotkey =
        (ctrlKey || metaKey) && Object.keys(HOTKEYS).includes(key);

      if (isHotkey) {
        e.preventDefault();
        const option = HOTKEYS[key];
        if (isBlockOptions(option)) {
          toggleBlock(editor, option);
        } else {
          toggleMark(editor, option);
        }
      }
    },
    [editor]
  );

  return {
    editor,
    value,
    renderElement,
    renderLeaf,
    handleEditorKeydown,
    handleClickAway,
  };
};
