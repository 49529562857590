import { useParams } from 'react-router-dom';
import { useOzmoApiService } from 'contexts/ozmo-api-service-context';
import { useCallback } from 'react';

import {
  deepCopyAndAddObject,
  deepCopyAndMergeChanges,
  deepCopyAndRemoveFromArray,
  getDefaultForField,
} from '../utils';

import { useCurrentLanguage } from './use-current-language';

export type ChangeFunction = (
  value: string | number | Reference | Indicator | undefined
) => void;
export type GenerateChangeFunction = (path: string) => ChangeFunction;

export type AddNewFunction = (options?: Record<string, any>) => void;
export type GenerateAddNewFunction = (
  path: string,
  newItemSchema: ContentTypeSchemaField,
  insertAfter?: number
) => AddNewFunction;

export type RemoveItemFunction = (index: number) => void;
export type GenerateRemoveItemFunction = (path: string) => RemoveItemFunction;

export const useCurrentLocalizedContentEntry = () => {
  const { contentEntryId } = useParams();
  const languageShortCode = useCurrentLanguage();
  const api = useOzmoApiService();

  const contentEntry = api.ContentEntry.get({
    id: Number(contentEntryId) || undefined,
  });

  // Try to find the localized version of the content for that language
  // If it doesn't exist, default to the first one
  const lce =
    contentEntry.data?.localizedContentEntries.find(
      (lce) => lce.languageShortCode === languageShortCode
    ) ?? contentEntry.data?.localizedContentEntries[0];

  const localizedContentEntry = api.LocalizedContentEntry.get({
    contentEntryId: lce?.contentEntryId,
    id: lce?.id,
  });

  const generateHandleChange: GenerateChangeFunction = useCallback(
    (path) => (value) => {
      const properties = deepCopyAndMergeChanges(
        localizedContentEntry.data.properties,
        path,
        value as any
      );
      localizedContentEntry.update({ properties });
    },
    [localizedContentEntry]
  );

  const generateHandleAddNew: GenerateAddNewFunction = useCallback(
    (path, newItemSchema, insertAfter) => (overrides) => {
      let newItem = getDefaultForField(newItemSchema) ?? {};
      // Currently this only supports overriding the properties of an object-based field
      // but we only need to do that for indicators for now.  If in the future we need to
      // allow adding of new fields with overrides for something other than indicators,
      // we'll need to update this to be more robust and generic
      if (overrides && newItem) {
        if (typeof newItem === 'object' && typeof overrides === 'object') {
          newItem = { ...newItem, ...overrides };
        }
      }
      const properties = deepCopyAndAddObject(
        localizedContentEntry.data.properties,
        newItem,
        path,
        insertAfter
      );
      localizedContentEntry.update({ properties });
    },
    [localizedContentEntry]
  );

  const generateHandleRemoveItem: GenerateRemoveItemFunction = useCallback(
    (path) => (index) => {
      const properties = deepCopyAndRemoveFromArray(
        localizedContentEntry.data.properties,
        index,
        path
      );
      localizedContentEntry.update({ properties });
    },
    [localizedContentEntry]
  );

  return {
    contentEntry: contentEntry.data,
    localizedContentEntry: localizedContentEntry.data,
    isLoading: contentEntry.isLoading || localizedContentEntry.isLoading,
    isFetching: contentEntry.isFetching || localizedContentEntry.isFetching,
    isError: contentEntry.isError || localizedContentEntry.isError,
    error: contentEntry.error || localizedContentEntry.error,
    update: localizedContentEntry.update,
    generateHandleChange,
    generateHandleAddNew,
    generateHandleRemoveItem,
  };
};
