import { useCallback, useState, useMemo } from 'react';
import {
  SelectedAttributes,
  AttributesWithValueType,
  extractQueryAttributes,
} from 'components/attribute-selector';
import { useLocation } from 'react-router-dom';
import { useEventTracking } from 'services/analytics';

export const convertAttributeMapToSearchParams = (
  attributes: AttributesWithValueType
): ContentEntrySearchParams => {
  const searchParams: ContentEntrySearchParams = {};

  Object.entries(attributes).forEach(([k, v]) => {
    // This type-cast is forcible and strictly speaking, unsafe.
    // It will remain correct so long as the AttributeMap and ContentEntrySearchParams
    // types stay in sync. C.E.S.P. is essentially a superset of AttributeMap
    // with some superficial formatting differences.
    if (k === 'searchQuery') {
      searchParams[k] = (v as unknown) as string;
    } else {
      const newKey = `${k.charAt(0)!.toLowerCase()}${k.slice(
        1
      )}Ids` as keyof Omit<ContentEntrySearchParams, 'searchQuery'>;
      searchParams[newKey] = v as number[];
    }
  });

  return searchParams;
};

export const useContentSearch = (
  onSearch: (
    searchParams: ContentEntrySearchParams,
    searchResultLimit?: number
  ) => void
) => {
  const [
    activeAttributeFilters,
    setActiveAttributeFilters,
  ] = useState<SelectedAttributes>({});
  const [
    pendingAttributeFilters,
    setPendingAttributeFilters,
  ] = useState<SelectedAttributes>({});
  const location = useLocation();

  const trackEvent = useEventTracking();

  const filtersChanged =
    JSON.stringify(pendingAttributeFilters) !==
    JSON.stringify(activeAttributeFilters);

  const isSearchDisabled =
    Object.keys(pendingAttributeFilters).length === 0 || !filtersChanged;

  const handleSelectedAttrChange = useCallback((attrs: SelectedAttributes) => {
    setPendingAttributeFilters(attrs);
  }, []);
  const handleSearch = useCallback(() => {
    setActiveAttributeFilters(pendingAttributeFilters);
    onSearch(pendingAttributeFilters);
    trackEvent('searched', {
      terms: JSON.stringify(pendingAttributeFilters),
    });
  }, [pendingAttributeFilters, onSearch, trackEvent]);

  const startingAttributes = useMemo(() => {
    const attributes = extractQueryAttributes(location);
    const searchFilters = convertAttributeMapToSearchParams(attributes);
    const filtersSet = Object.values(attributes).some((val) => {
      if (Array.isArray(val)) {
        return val.length > 0;
      }
      return !!val;
    });

    // HACKY HACK
    // can't call onSearch within this normally during render, due to it being a
    // setState call from another component; move outside of render cycle
    // with a setTimeout
    setTimeout(() => {
      if (filtersSet) {
        onSearch(searchFilters);
      } else {
        onSearch({}, 20);
      }
    }, 0);

    return attributes;
    // we do not want to recalculate this based on query params changing
    // as users change the attributes; only the first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearch]);

  return {
    isSearchDisabled,
    filtersChanged,
    handleSearch,
    handleSelectedAttrChange,
    startingAttributes,
  };
};
