import { FC } from 'react';
import RichTextEditor from 'components/rich-text-editor';
import { Box, IconButton, Typography } from '@mui/material';
import { useRemoveArrayItem } from 'scenes/universal-content-editor/hooks/use-remove-array-item';
import { Delete } from '@mui/icons-material';

import AddNewButton from '../add-new-button';
import HeaderOffsetAnchor from '../header-offset-anchor';

type Props = {
  displayName: string;
  displayOrder: number;
  path: string;
  level: number;
  value?: string;
  required?: boolean;
  onChange: (newValue: string) => void;
  onAddNew?: VoidFunction;
  onRemove?: VoidFunction;
};

const SchemaTextField: FC<Props> = ({
  displayName,
  displayOrder,
  level,
  path,
  value,
  required = false,
  onAddNew,
  onChange,
  onRemove,
}) => {
  const {
    removeConfirmationModal,
    openRemoveConfirmationModal,
  } = useRemoveArrayItem(displayName, onRemove);
  const handleSave = (v: string) => {
    if (v !== value) {
      onChange(v);
    }
  };

  return (
    <Box component="div" order={displayOrder} tabIndex={displayOrder}>
      <div>
        <HeaderOffsetAnchor path={path} />
        <Box display="flex" justifyContent="space-between" alignItems="end">
          <Typography variant="body2">
            <>
              {required && (
                <Typography color="error" display="inline" component="span">
                  {'* '}
                </Typography>
              )}
              {displayName}
              {required || ' (optional)'}
            </>
          </Typography>
          {onRemove && (
            <IconButton
              aria-label={`Delete ${displayName}`}
              onClick={openRemoveConfirmationModal}
            >
              <Delete fontSize="small" color="error" />
            </IconButton>
          )}
        </Box>
        <RichTextEditor
          initialValue={value ?? ''}
          onSave={handleSave}
          autoFocus={false}
        />
      </div>
      {onAddNew && (
        <AddNewButton
          onAddNew={onAddNew}
          displayName={displayName}
          level={level}
        />
      )}
      {onRemove && removeConfirmationModal}
    </Box>
  );
};

export default SchemaTextField;
