import { useMemo } from 'react';
import { useCurrentCollection } from 'scenes/nuevo-collection/hooks';

import { extractTopicGroups } from '../answers-list';

import useAnswerFiltering from './use-answer-filter/use-answer-filter';

export type AttributeIds = {
  Device: number[];
  DeviceType: number[];
  Manufacturer: number[];
  OperatingSystem: number[];
  OperatingSystemRelease: number[];
  OperatingSystemVersion: number[];
};

export const useCurrentCategory = () => {
  const {
    collection: {
      id,
      name,
      categories = [],
      devices,
      deviceTypes,
      manufacturers,
      operatingSystems,
      operatingSystemReleases,
      operatingSystemVersions,
    } = {},
    languageId,
    categoryId,
    isLoading,
    refetch,
  } = useCurrentCollection();
  const { applyFilters } = useAnswerFiltering();

  const category = useMemo(
    () =>
      applyFilters(
        categories.find((c) => c.id === Number(categoryId))
      ) as CategoryModel,
    [categories, categoryId, applyFilters]
  );

  const topicGroupedReferences = extractTopicGroups(category?.contentEntries);

  const uniqueReferences = useMemo(
    () => category?.contentEntries.map(({ id }) => id) ?? [],
    [category?.contentEntries]
  );

  const attributeIds = useMemo<AttributeIds>(
    () => ({
      Device: devices?.map((d) => d.id) ?? [],
      DeviceType: deviceTypes?.map((dt) => dt.id) ?? [],
      Manufacturer: manufacturers?.map((m) => m.id) ?? [],
      OperatingSystem: operatingSystems?.map((os) => os.id) ?? [],
      OperatingSystemRelease:
        operatingSystemReleases?.map((osr) => osr.id) ?? [],
      OperatingSystemVersion:
        operatingSystemVersions?.map((osv) => osv.id) ?? [],
    }),
    [
      deviceTypes,
      devices,
      manufacturers,
      operatingSystemReleases,
      operatingSystemVersions,
      operatingSystems,
    ]
  );

  return {
    attributeIds,
    categoryId,
    collectionId: id,
    collectionName: name,
    category,
    categories: applyFilters(categories) as CategoryModel[],
    languageId,
    refetchCollection: refetch,
    topicGroupedReferences,
    uniqueReferences,
    isLoading,
  };
};
