import { FC } from 'react';
import { Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import usePreviousPage from 'services/utils/use-previous-page';

type Props = {
  contentEntryId: number | undefined;
  locale: string | undefined;
};

const PreviewButton: FC<Props> = ({ contentEntryId, locale }) => {
  const { pathname, search } = useLocation();
  const { generateUrlWithPreviousPage } = usePreviousPage();

  const previewUrl = generateUrlWithPreviousPage(
    `/preview/${locale}/tutorial/entry/${contentEntryId}`,
    `${pathname}${search}`,
    'editing'
  );

  return (
    <Button
      variant="outlined"
      color="whitePrimary"
      component={Link}
      to={previewUrl}
      disabled={!contentEntryId || !locale}
      startIcon={<Visibility />}
    >
      {'Preview'}
    </Button>
  );
};

export default PreviewButton;
