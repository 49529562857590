import { useState, useCallback } from 'react';
import { SelectedAttributes } from 'components/attribute-selector';

export const useAddAttributes = (
  onAddAttributes: (
    selectedAttributes: SelectedAttributes
  ) => Promise<BulkOperationJobResponse | undefined>
) => {
  const [
    selectedAttributes,
    setSelectedAttributes,
  ] = useState<SelectedAttributes>({});
  const [bulkJobResults, setBulkJobResults] = useState<
    BulkOperationJobResponse | undefined
  >(undefined);
  const handleSelectedAttributeChange = useCallback(
    (selectedAttributes: SelectedAttributes) =>
      setSelectedAttributes(selectedAttributes),
    []
  );
  const handleAddAttributes = useCallback(async () => {
    const jobResponse = await onAddAttributes(selectedAttributes);
    setBulkJobResults(jobResponse);
  }, [selectedAttributes, onAddAttributes]);
  // when the modal is closed, reset the job results. The settimeout is to give time for the modal
  // to fully close, so a user does not see the modal content change while closing
  const handleRefuseModal = useCallback(
    () => setTimeout(() => setBulkJobResults(undefined), 200),
    []
  );

  return {
    selectedAttributes,
    bulkJobResults,
    handleSelectedAttributeChange,
    handleAddAttributes,
    handleRefuseModal,
  };
};
