import { useEffect, useState, useCallback } from 'react';
import { useAuthLogout, useOzmoApiToken } from 'scenes/google-auth';

/*
  useOzmoApi: A custom hook to safely use the ozmo API in a functional react world
  Returns several state variables and callbacks
    - (dict || null) result: The JSON-parsed result of the fetch
    - (dict || null) pagination: If the repsonse is paginated, contains { page, pageCount, count, countPerPage }
    - (bool) isLoading: is a request in flight
    - (dict || null) error: If an error occurred on the server while processing the request
    - (func) patch(resource, body): Callback to execute a patch
    - (func) put(resource, body): Callback to execute a PUT
    - (func) get(resource): Callback to execute a GET
    - (func) post(resource, body): Callback to execute a POST

  Special notes:
    * This hook will automagically abort any requests that are in-flight if the component
      is unmounted, and will prevent it from setting internal state, since it has been unmounted
      to prevent react no-op complaints
    * In all cases, a "resource" is the path to a resource in the API, not the full URL, and not including version
      e.g: authoring/media-entry/1
    * The error value will be in the format { code: 404, message: 'Content not found', details: {} }
      for some errors, the Ozmo API will return helpful details like what required fields are missing
      from a POST, or wha unique constraint you have violated
*/

const OZMO_BASE_URL = import.meta.env.VITE_APP_OZMO_API_URL;

const downloadableTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const stringify = (body, noStringify) => {
  if (noStringify) {
    return body;
  }
  return JSON.stringify(body);
};

const doFetch = (resource, body, method, token, abortSignal, noStringify) => {
  return new Promise((resolve, reject) => {
    // Remove any leading slashes from the resource
    const trimmedResource = resource.replace(/^\/+/, '');
    let url = `${OZMO_BASE_URL}/${trimmedResource}`;
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-Api-Key': `${import.meta.env.VITE_APP_OZMO_X_API_KEY}`,
    };
    if (noStringify) {
      headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    // Allow the user to override and specify a full url for an API call
    if (resource.includes('https://')) {
      url = resource;
    }
    fetch(url, {
      signal: abortSignal,
      headers,
      method,
      body: stringify(body, noStringify),
    })
      .then(async (response) => {
        // If status code is 204 (No Content), there will be no body
        // So the respose.json() call could fail, but the actual ozmo api call succeeded
        // So instead just return the response as the data so the user has access to the status
        // code and headers and whatnot
        if (response.status === 204) {
          return resolve({ response, data: { ...response, code: 204 } });
        }

        // If not JSON probably a blob for downloads from translation export.
        if (
          response.headers &&
          downloadableTypes.includes(response.headers.get('Content-Type'))
        ) {
          const data = await response.blob();
          resolve({ response, data });
        }

        const data = await response.json();
        resolve({ response, data });
      })
      .catch((error) => reject(error));
  });
};

const useOzmoApi = () => {
  const [result, setResult] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fileName, setFilename] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const ozmoApiToken = useOzmoApiToken();
  const logout = useAuthLogout();

  // If we need to cancel a request that is already in flight
  // we will use the abort controller to signal to the fetch to cancel
  const [abortController] = useState(new AbortController());

  // Workaround for <StrictMode> which will cause any useEffect to be called twice
  // in a development env. As a result, the useEffect that aborts any fetch calls
  // when unmounted gets called twice by default, and thus aborts any fetches.
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  // If we get unmounted we need a way to signal to kill any requests
  // that are already in flight.  We can useEffect's cleanup for this purpose
  useEffect(() => {
    // Cleanup: Executes on unmount
    return () => {
      enabled && abortController.abort();
    };
  }, [enabled, abortController]);

  const initStateForNewRequest = useCallback(() => {
    // Set the table
    setResult(null);
    setIsLoading(false);
    setError(null);
  }, []);

  const fireRequest = useCallback(
    (resource, body, method, noStringify) => {
      return new Promise((resolve, reject) => {
        const parseError = (response, data) => {
          const parsedError = {
            code: response.status,
            message: response.statusText,
            details: data,
          };
          setError(parsedError);
          setIsLoading(false);
          return parsedError;
        };

        initStateForNewRequest();
        setIsLoading(true);

        doFetch(
          resource,
          body,
          method,
          ozmoApiToken,
          abortController.signal,
          noStringify
        )
          .then(({ response, data }) => {
            if (response.status === 401) {
              console.log('Got forbidden response:  Logging out');
              logout();
            }
            // Fetch only throws an exception for coding errors
            // All valid responses from the server are parsed so we
            // need to check for server errors here, AKA:
            // if the response code is not is not 2xx
            if (!response.ok) {
              const parsedError = parseError(response, data);
              return resolve(parsedError);
            }
            if (response.headers) {
              setPagination({
                pageCount: Math.ceil(
                  response.headers.get('X-Total') /
                    response.headers.get('X-Per-Page')
                ),
                page: parseInt(response.headers.get('X-Page'), 10),
                countPerPage: parseInt(response.headers.get('X-Per-Page'), 10),
                count: parseInt(response.headers.get('X-Total'), 10),
              });

              if (response.headers.get('content-disposition')) {
                setFilename(
                  response.headers.get('content-disposition').split('"')[1]
                );
                // eslint-disable-next-line prefer-destructuring, no-param-reassign
                data.fileName = response.headers
                  .get('content-disposition')
                  .split('"')[1];
              }
            }

            setResult(data);
            setIsLoading(false);
            resolve(data);
          })
          .catch((err) => {
            // Fetch will throw a DOMException error if request
            // is aborted, so if that was the case, the component
            // is unmounted, so we must not try to update state or react
            // yell at us.
            if (err.name === 'AbortError') {
              // Do nothing
            } else {
              // otherwise set the error state
              setError(err);
              setIsLoading(false);
              reject(err);
            }
          });
      });
    },
    [abortController.signal, logout, initStateForNewRequest, ozmoApiToken]
  );

  // Keep things as stable as possible, so all of these functions
  // need to useCallback so their refs don't change on each render
  // Otherwise any useEffects in components that use them will get stuck
  // in render reload loops where the useEffect dependency on the get function
  // triggers a rerender, which triggers a new get reference which triggers a rerender...
  const post = useCallback(
    (resource, body, noStringify) => {
      return fireRequest(resource, body, 'post', noStringify);
    },
    [fireRequest]
  );

  const put = useCallback(
    (resource, body) => {
      return fireRequest(resource, body, 'put');
    },
    [fireRequest]
  );

  const patch = useCallback(
    (resource, body, noStringify) => {
      return fireRequest(resource, body, 'PATCH', noStringify);
    },
    [fireRequest]
  );

  const get = useCallback(
    (resource) => {
      return fireRequest(resource, undefined, 'get');
    },
    [fireRequest]
  );

  // "delete" is a reserved word
  const deleteResource = useCallback(
    (resource) => {
      return fireRequest(resource, undefined, 'DELETE');
    },
    [fireRequest]
  );

  return {
    result,
    pagination,
    isLoading,
    error,
    put,
    get,
    patch,
    post,
    deleteResource,
    fileName,
    reset: initStateForNewRequest,
  };
};

export default useOzmoApi;
export { doFetch };
