import { Box } from '@mui/material';
import { cloneElement, FC, ReactElement } from 'react';
import {
  GenerateAddNewFunction,
  GenerateRemoveItemFunction,
} from 'scenes/universal-content-editor';

import AddNewButton from '../add-new-button';

type Props = {
  path: string;
  level: number;
  displayName: string;
  displayOrder: number;
  schema: ContentTypeSchemaField;
  maxItems?: number;
  generateOnAddNew: GenerateAddNewFunction;
  generateOnRemoveItem: GenerateRemoveItemFunction;
  children: ReactElement[];
};

// Adds an onAddNew prop to all children
const SchemaArray: FC<Props> = ({
  displayName,
  displayOrder,
  path,
  level,
  maxItems,
  schema,
  generateOnAddNew,
  generateOnRemoveItem,
  children,
}) => {
  const handleRemove = generateOnRemoveItem(path);
  const canHaveMoreItems = maxItems ? children.length < maxItems : true;

  return (
    <Box component="div" order={displayOrder} tabIndex={displayOrder}>
      <Box display="flex" flexDirection="column" gap={level === 1 ? 5 : 3}>
        {children.map((c, index) =>
          cloneElement(c, {
            onRemove: () => {
              handleRemove(index);
            },
            onAddNew: canHaveMoreItems
              ? generateOnAddNew(path, schema, index)
              : undefined,
          })
        )}
      </Box>
      {children.length === 0 && canHaveMoreItems && (
        <AddNewButton
          onAddNew={generateOnAddNew(path, schema, 0)}
          displayName={displayName}
          level={level}
        />
      )}
    </Box>
  );
};
export default SchemaArray;
