import { FunctionComponent, useState, useRef, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Popper,
  Grow,
  MenuItem,
  Paper,
  MenuList,
  ClickAwayListener,
  Tooltip,
  ButtonProps,
} from '@mui/material';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StyledButtonGroup = styled(ButtonGroup)`
  // Don't let the button text wrap to two lines
  white-space: nowrap;
  .MuiButtonGroup-grouped:last-of-type {
    padding: 0px 8px;
  }
`;

const StyledMenuList = styled(MenuList)`
  width: 200px;
`;

type Props = {
  onClick: (selectedIndex: number) => void;
  options: string[];
  text: string;
  color?: ButtonProps['color'];
  icon?: any;
  buttonTooltip: string;
  arrowTooltip: string;
  isDisabled?: boolean;
};

const SplitButton: FunctionComponent<Props> = ({
  onClick,
  options,
  color = 'primary',
  text,
  icon,
  buttonTooltip,
  arrowTooltip,
  isDisabled,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const onKeydown = (event: KeyboardEvent) => {
      const { key } = event;

      if (key === 'Escape') {
        setOpen(false);
      }
    };

    window.addEventListener('keydown', onKeydown);

    return () => window.removeEventListener('keydown', onKeydown);
  });

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (index: number) => {
    onClick(index);
    setOpen(false);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <StyledButtonGroup
        ref={anchorRef}
        aria-label="split button"
        disabled={isDisabled}
        color={color}
        variant="outlined"
      >
        <Tooltip arrow title={buttonTooltip}>
          <Button
            onClick={() => onClick(0)}
            startIcon={icon}
            // Need to do this, or we get a ton of console warnings about Tooltip being passed a disabled button
            // and not responding to mouse events. Could wrap in a span but that would require so much manual css styling
            // to make it look like the button.
            component={isDisabled ? 'div' : 'button'}
          >
            {text}
          </Button>
        </Tooltip>
        <Tooltip arrow title={arrowTooltip}>
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label={arrowTooltip}
            aria-haspopup="menu"
            onClick={handleToggle}
            // Need to do this, or we get a ton of console warnings about Tooltip being passed a disabled button
            // and not responding to mouse events. Could wrap in a span but that would require so much manual css styling
            // to make it look like the button.
            component={isDisabled ? 'div' : 'button'}
          >
            <ArrowDropDownIcon />
          </Button>
        </Tooltip>
      </StyledButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledMenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </StyledMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SplitButton;
