import { Alert, AlertTitle, Box, AlertProps, Stack } from '@mui/material';
import { FunctionComponent, ReactElement, cloneElement } from 'react';

type Props = {
  title: string;
  message: string | ReactElement;
  icon: ReactElement;
  color?: AlertProps['color'];
  variant?: AlertProps['variant'];
  action?: ReactElement;
};

const BaseStaticToast: FunctionComponent<Props> = ({
  title,
  message,
  icon,
  color,
  variant,
  action,
}) => (
  <Box
    marginTop={4}
    marginLeft="auto"
    marginRight="auto"
    maxWidth={700}
    borderRadius={2}
    boxShadow={2}
  >
    <Alert
      severity="warning"
      color={color}
      variant={variant}
      icon={cloneElement(icon, { fontSize: 'inherit' })}
    >
      <AlertTitle color="inherit">{title}</AlertTitle>
      {message}
      {action && (
        <Stack direction="row" margin={2} justifyContent="flex-end">
          {action}
        </Stack>
      )}
    </Alert>
  </Box>
);

export default BaseStaticToast;
