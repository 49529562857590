import { FunctionComponent } from 'react';
import EditOff from '@mui/icons-material/EditOff';

import BaseStaticToast from './base-static-toast';

const ReadOnlyToast: FunctionComponent = () => (
  <BaseStaticToast
    title="This content is read-only"
    message="Attributes remain editable, but you cannot make changes to the content"
    icon={<EditOff />}
  />
);

export default ReadOnlyToast;
