import { FC } from 'react';
import { Grid } from '@mui/material';

import PublishStateMultiSelect from './publish-state-multiselect';
import OnlyIncompleteButton from './only-incomplete-button';
import SortSelector from './sort-selector';

const SortRow: FC = () => (
  <Grid container spacing={1} paddingTop={2}>
    <Grid item xs={4}>
      <SortSelector />
    </Grid>
    <Grid item xs={4}>
      <PublishStateMultiSelect />
    </Grid>
    <Grid item>
      <OnlyIncompleteButton />
    </Grid>
    <Grid item>{/* topic expander goes here */}</Grid>
  </Grid>
);

export default SortRow;
