import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { FCWithChildren } from 'types/fc-with-children';

type OnSignal = VoidFunction;
type SignalCall = { signal: string; signalTime: number };

const SignalConsumerContext = createContext<SignalCall>({
  signal: '',
  signalTime: 0,
});
const SignalProducerContext = createContext((signal: string) => {});

/**
 * Produce signal events by calling the function returned from this hook
 * @returns A function that will produce a signal when called
 * @example
 * import { SOME_SIGNAL, useProduceSignal } from 'components/signal';
 * const produceSignal = useProduceSignal();
 * produceSignal(SOME_SIGNAL);
 */
export const useProduceSignal = () => useContext(SignalProducerContext);

/**
 * Watch for a specific signal and execute a callback function if/when it is received
 * @param signalToConsume The name of the signal to watch for
 * @param onSignal The callback to execute when a matching signal is received
 */
export const useConsumeSignal = (
  signalToConsume: string,
  onSignal: OnSignal
) => {
  const { signal, signalTime } = useContext(SignalConsumerContext);

  useEffect(() => {
    if (signal === signalToConsume) {
      onSignal();
    }
  }, [onSignal, signal, signalToConsume, signalTime]);
};

/**
 * Context provider to enable producing/consuming signals across components
 * in any direction (child -> parent, siblings, etc).
 *
 * THIS SHOULD ONLY BE USED AS A LAST RESORT; there are almost always better patterns
 * to use to accomplish the same thing- e.g.:
 *  - Lifting state up to a parent
 *  - Passing callback functions from parent to child, or between siblings
 *
 * IF you do use this, you should add your signal as a constant export in signals.ts
 * and consume/produce using that, instead of hardcoding strings in your components
 */
export const SignalProvider: FCWithChildren = ({ children }) => {
  const [signal, setSignal] = useState<SignalCall>({
    signal: '',
    signalTime: 0,
  });

  const produceSignal = useCallback((s: string) => {
    setSignal({ signal: s, signalTime: new Date().getTime() });
  }, []);

  return (
    <SignalConsumerContext.Provider value={signal}>
      <SignalProducerContext.Provider value={produceSignal}>
        {children}
      </SignalProducerContext.Provider>
    </SignalConsumerContext.Provider>
  );
};
