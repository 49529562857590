import { Checkbox, FormControlLabel, Typography } from '@mui/material';

interface Props {
  inputRef: React.RefObject<HTMLInputElement>;
}

export const SteptextOnlyCheckbox = ({ inputRef }: Props) => (
  <FormControlLabel
    sx={{ mb: 3 }}
    control={<Checkbox inputRef={inputRef} />}
    label={
      <Typography variant="body1">
        {'Duplicate '}
        <strong>
          {'step-text '}
          <em>{'only'}</em>
        </strong>
      </Typography>
    }
    labelPlacement="end"
    data-testid="duplicate-content-modal:steptext-only-checkbox"
  />
);
