import { FunctionComponent } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import LinkIcon from '@mui/icons-material/Link';
import { Link, useParams } from 'react-router-dom';
import { PermissionRequired } from 'components/permission-required';
import { useCopyLink } from 'services/hooks/use-copy-link';
import StopKeyboardEventPropagation from 'components/stop-keyboard-event-propagation';

import {
  useAddToCategoryAction,
  useDuplicateAction,
  useRemoveItemAction,
} from './hooks';

type Props = {
  answerTitle: string;
  categoryId: number;
  collectionId: number;
  contentEntryId: number;
  contentEntryTitle: string;
  editUrl: string;
  isLegacyContentType: boolean;
};

const LocalizedReferenceActionsMenuOptions: FunctionComponent<Props> = ({
  answerTitle,
  categoryId,
  collectionId,
  contentEntryId,
  contentEntryTitle,
  editUrl,
  isLegacyContentType,
}) => {
  const { removeItemModal, handleRemoveItemClick } = useRemoveItemAction({
    collectionId,
    contentEntryId,
    categoryId,
    answerTitle: contentEntryTitle,
  });

  const { handleAddToCategoryClick, categoriesModal } = useAddToCategoryAction({
    contentEntryId,
    collectionId,
    answerTitle,
  });

  const { modal: duplicateModal, handleDuplicateClick } = useDuplicateAction(
    contentEntryId,
    categoryId,
    collectionId,
    answerTitle
  );

  const { language } = useParams();

  const [onClick] = useCopyLink(
    'answer',
    contentEntryId,
    undefined,
    false,
    language
  );
  return (
    <StopKeyboardEventPropagation>
      {categoriesModal}
      {duplicateModal}
      {removeItemModal}
      <MenuItem component={Link} to={editUrl}>
        <ListItemIcon>
          <CreateIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{'Edit'}</ListItemText>
      </MenuItem>
      <PermissionRequired permission="edit_collection" disableElement>
        <MenuItem onClick={handleAddToCategoryClick}>
          <ListItemIcon>
            <PlaylistAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'Categorize'}</ListItemText>
        </MenuItem>
      </PermissionRequired>
      <PermissionRequired permission="edit_collection" disableElement>
        <MenuItem onClick={handleDuplicateClick} disabled={isLegacyContentType}>
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'Duplicate'}</ListItemText>
        </MenuItem>
      </PermissionRequired>
      <PermissionRequired permission="edit_collection" disableElement>
        <MenuItem onClick={handleRemoveItemClick}>
          <ListItemIcon>
            <RemoveCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'Remove'}</ListItemText>
        </MenuItem>
      </PermissionRequired>
      <MenuItem onClick={onClick as any}>
        <ListItemIcon>
          <LinkIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{'Copy link'}</ListItemText>
      </MenuItem>
    </StopKeyboardEventPropagation>
  );
};

export default LocalizedReferenceActionsMenuOptions;
