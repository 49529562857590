import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';
import api, { ozmoApiRequest } from 'services/ozmo-api';

import {
  createData,
  onMutationError,
  onUpdateSuccess,
  updateCacheOptimistically,
  UseQueryOptionsWithPrefetch,
} from '../use-query-cache';

interface ResourcePathVariables {
  id?: number;
  contentEntryId?: number;
}
class LocalizedContentEntry extends OzmoApiBase<
  LocalizedContentEntryModel,
  LocalizedContentEntryUpdateModel,
  LocalizedContentEntryCreateModel,
  ResourcePathVariables
>() {
  protected static resourcePath =
    'authoring/content_entries/:contentEntryId/localized_content_entries/:id';
  protected static embedOptions = [];
  protected static defaultReactQueryConfig: UseQueryOptionsWithPrefetch = {
    staleTime: 60000, // 1 minute
  };

  static restoreAsync = ({
    id,
    contentEntryId,
  }: {
    id: number;
    contentEntryId: number;
  }): Promise<LocalizedContentEntryModel> =>
    new Promise(async (resolve, reject) => {
      const resource = `authoring/content_entries/${contentEntryId}/localized_content_entries/${id}/restore`;
      try {
        const { data: lce } = await ozmoApiRequest(
          resource,
          undefined,
          'POST',
          true,
          false
        );
        resolve(lce);
      } catch (error) {
        reject(error);
      }
    });

  static updateStatusAsync = (
    id: number,
    contentEntryId: number,
    status: string
  ): Promise<LocalizedContentEntryModel> =>
    new Promise(async (resolve, reject) => {
      const resource = `authoring/content_entries/${contentEntryId}/localized_content_entries`;
      const queryKey = LocalizedContentEntry.getQueryKey({
        contentEntryId,
        id,
      });

      const previousVersion = api.queryClient.getQueryData(queryKey);
      try {
        // Optimistically update the cache
        updateCacheOptimistically(api.queryClient, queryKey, {
          status,
        });
        const result: any = await ozmoApiRequest(
          `${resource}/${id}/update_status`,
          { status },
          'PATCH'
        );
        if ('code' in result) {
          onMutationError(
            api.queryClient,
            queryKey,
            result,
            { status },
            previousVersion
          );
          reject(result);
        }
        onUpdateSuccess(
          api.queryClient,
          queryKey,
          result,
          { status },
          previousVersion
        );
        resolve(result);
      } catch (error) {
        onMutationError(
          api.queryClient,
          queryKey,
          error,
          { status },
          previousVersion
        );
        reject(error);
      }
    });

  public static bulkAsync(
    operations: BulkOperation[]
  ): Promise<{ job: number }> {
    return new Promise(async (resolve, reject) => {
      try {
        const body = operations.map((op) => JSON.stringify(op)).join('\n');
        const result = await createData(
          'authoring/batch/localized_content_entries',
          body,
          undefined,
          undefined,
          false,
          { 'Content-Type': 'application/x-ndjson' }
        );
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default LocalizedContentEntry;
