import { useCallback, useMemo } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { useCurrentCollection as useCurrentCollectionBase } from 'scenes/nuevo-collection/hooks';

type CurrentItemResponse = {
  collection?: CollectionModel;
  contentEntry?: ContentEntryModel;
  localizedContentEntry?: LocalizedContentEntryModel | LocalizedCollectionModel;
  handleTitleUpdate: (newTitle: string) => void;
  isLoading: boolean;
  isError: boolean;
  error: any;
  language: string;
  languageId?: number;
  title: string;
};

export const useCurrentLocalizedContentEntry = (
  contentEntryId: number,
  language: string,
  contentType: 'collection' | 'contentEntry',
  titleField: string
): CurrentItemResponse => {
  const api = useOzmoApiService();

  const {
    data: contentEntry,
    error,
    isLoading,
    isError,
  } = api.ContentEntry.get({ id: contentEntryId });

  const localizedContentEntryId =
    !isLoading &&
    api.ContentEntry.getLocalizedContentEntryIdByLanguage(
      contentEntry,
      language
    );

  let apiResponse;
  if (contentType === 'collection') {
    apiResponse = api.LocalizedCollection.get(
      {
        id: localizedContentEntryId || 0,
        contentEntryId,
      },
      { enabled: !!localizedContentEntryId }
    );
  } else {
    apiResponse = api.LocalizedContentEntry.get(
      {
        id: localizedContentEntryId || 0,
        contentEntryId,
      },
      { enabled: !!localizedContentEntryId }
    );
  }

  const {
    data: localizedContentEntry,
    isLoading: isLceLoading,
    update: updateLocalizedEntry,
  } = apiResponse;

  const determinedLanguage = useMemo(() => {
    if (isLoading || isLceLoading) {
      return language;
    }

    const matchingEntry = contentEntry.localizedContentEntries.find(
      (lce) => lce.id === localizedContentEntryId
    );

    return matchingEntry?.languageShortCode ?? language;
  }, [
    contentEntry,
    isLoading,
    isLceLoading,
    language,
    localizedContentEntryId,
  ]);

  const handleTitleUpdate = useCallback(
    (title: string) => {
      updateLocalizedEntry({
        ...localizedContentEntry,
        properties: {
          ...localizedContentEntry.properties,
          [titleField]: title,
        } as any,
      });
    },
    [updateLocalizedEntry, localizedContentEntry, titleField]
  );

  return {
    contentEntry: contentEntry || {},
    localizedContentEntry: localizedContentEntry || {},
    handleTitleUpdate,
    isLoading: isLoading || isLceLoading,
    isError,
    error,
    language: determinedLanguage,
    title: (localizedContentEntry?.properties as any)?.[titleField] ?? '',
  };
};

export const useCurrentCollection = (): Omit<CurrentItemResponse, 'title'> => {
  const {
    collection,
    language,
    languageId,
    error,
    isLoading,
    isError,
    update,
  } = useCurrentCollectionBase();

  const handleTitleUpdate = useCallback(
    (title: string) => {
      update({ name: title });
    },
    [update]
  );

  return {
    collection,
    handleTitleUpdate,
    isLoading,
    isError,
    error,
    languageId,
    language,
  };
};
