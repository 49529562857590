import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';
interface ResourcePathVariables {
  id?: number;
}
class Language extends OzmoApiBase<
  LanguageModel,
  void,
  void,
  ResourcePathVariables
>() {
  protected static resourcePath = 'config/languages/:id';
  protected static embedOptions = [];
  protected static defaultReactQueryConfig = {
    staleTime: 300000, // 5 minutes
  };

  // Make this model read only- don't allow mutating or creating anything on the server
  // This will disable the update/create/delete methods
  protected static readonly = true;

  public getByName = (name: string): LanguageModel | undefined => {
    if (this.isLoading || this.all.length > 0) {
      return this.all.find((language) => language.name === name);
    }

    throw new Error('You must call Language.getAll() to use this method');
  };

  static getByNameAsync = (name: string): Promise<LanguageModel> =>
    new Promise(async (resolve, reject) => {
      const languages = await Language.getAllAsync();
      const language = languages.find((l) => l.name === name);
      if (language) resolve(language);
      else reject(`No language found with name "${name}"`);
    });
}

export default Language;
