import { createContext, useContext, ReactNode, useState } from 'react';

interface SearchResultsStatus {
  isSearching: boolean;
  hasError: boolean;
  searchResults: (ContentSearchResult | CollectionSearchResult)[];
}

interface SearchResultsStatusContextType {
  searchResultsStatus: SearchResultsStatus;
  setSearchResultsStatus: (status: SearchResultsStatus) => void;
}

interface SearchResultsStatusProviderProps {
  children: ReactNode;
  prohibitedFilterContentTypes?: ContentEntryContentType[];
}

export const SearchResultsStatusContext = createContext<SearchResultsStatusContextType | null>(
  null
);

export const useSearchResultsStatus = () =>
  useContext(SearchResultsStatusContext) ?? null;

export const SearchResultsStatusProvider = ({
  children,
  prohibitedFilterContentTypes,
}: SearchResultsStatusProviderProps) => {
  const [
    searchResultsStatus,
    setSearchResultsStatus,
  ] = useState<SearchResultsStatus>({
    isSearching: false,
    hasError: false,
    searchResults: [],
  });

  return (
    <SearchResultsStatusContext.Provider
      value={{
        searchResultsStatus,
        setSearchResultsStatus,
      }}
    >
      {children}
    </SearchResultsStatusContext.Provider>
  );
};
