import { ReactNode, useState, CSSProperties } from 'react';
import { FCWithChildren } from 'types/fc-with-children';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextFade from 'components/text-fade';

type Props = {
  startExpanded?: boolean;
  collapsedHeight?: number | string;
  expandText: string;
  collapseText?: string;
  textColor?: 'primary' | 'secondary' | 'inherit';
  buttonStyles?: CSSProperties;
  hasFade?: boolean;
  disableElevation?: boolean;
  buttonOnTop?: boolean;
  expandIcon?: ReactNode;
  collapseIcon?: ReactNode;
};

type StyledProps = {
  reverseFlexDirection: boolean;
};

const StyledDivContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: ${(p) =>
    p.reverseFlexDirection ? 'column-reverse' : 'column'};

  button {
    align-self: flex-start;
  }
`;

const Accordion: FCWithChildren<Props> = ({
  startExpanded = false,
  collapsedHeight = 0,
  expandText,
  collapseText,
  textColor = 'primary',
  buttonStyles,
  hasFade = false,
  disableElevation = false,
  buttonOnTop = false,
  expandIcon = <ExpandMoreIcon />,
  collapseIcon = <ExpandLessIcon />,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(startExpanded);

  const getButtonText = () => {
    return isExpanded ? collapseText || expandText : expandText;
  };

  return (
    <StyledDivContainer reverseFlexDirection={buttonOnTop}>
      <Collapse collapsedSize={collapsedHeight} in={isExpanded}>
        {hasFade ? (
          <TextFade backgroundColor="#f2f2f2" isDisabled={isExpanded}>
            {children}
          </TextFade>
        ) : (
          children
        )}
      </Collapse>
      <Button
        style={buttonStyles}
        variant="text"
        color={textColor}
        startIcon={isExpanded ? collapseIcon : expandIcon}
        disableElevation={disableElevation}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {getButtonText()}
      </Button>
    </StyledDivContainer>
  );
};

export default Accordion;
