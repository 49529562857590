import { generatePath } from 'react-router-dom';

// TODO: Revert to rootPath as a const variable after collection2 migration
// export const rootPath = '/collection/:id';
export const getRootPath = () =>
  `/collection${window.ozmoAuthoringFlags?.useCollections2 ? '' : '2'}/:id`;
// TODO Revert to const variable after collection2 migration
// const languagePath = `${rootPath}/:language`;
export const getLanguagePath = () => `${getRootPath()}/:language`;
// TODO Revert to const variable after collection2 migration
// export const categoryPath = `${languagePath}/:categoryId`;
export const getCategoryPath = () => `${getLanguagePath()}/:categoryId`;

export const generateCollectionPath = (
  collectionId: number | string,
  language?: string,
  categoryId?: number
) => {
  if (!language) {
    return generatePath(getRootPath(), { id: collectionId.toString() });
  }

  if (!categoryId) {
    return generatePath(getLanguagePath(), {
      id: collectionId.toString(),
      language,
    });
  }

  return generatePath(getCategoryPath(), {
    id: collectionId.toString(),
    language,
    categoryId: categoryId.toString(),
  });
};
