import { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  TextField,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Save from '@mui/icons-material/Save';
import BulkActionBar from 'components/bulk-action-bar';
import ActionButton from 'scenes/nuevo-collection/bulk-action-bar/actions/action-button';

import DeviceFilterPicker from './filter-pickers/device';
import AttributeSelectors from './selectors';
import TagDisplay from './tag-display';
import GenericFilterPicker from './filter-pickers/generic';
import OperatingSystemFilterPicker from './filter-pickers/operating-system';
import AttributeHeader from './header';
import { useAttributEditor } from './use-attribute-editor';

const StyledFilterTypography = styled(Typography)`
  padding: 24px;
  font-weight: 500 !important;
  padding-bottom: 12px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: var(--color-neutral-five);
`;

const StyledContainer = styled.div`
  flex: 4 1;
  & > div {
    margin: 12px 12px 12px 0px;
  }
`;

const StyledAttributeSummaryContainer = styled.div`
  flex: 1 1;
  order: 1;
  max-width: 30%;
  margin-top: 12px;
  padding: 24px;
  background-color: var(--color-neutral-two);
  border-radius: 6px;
  overflow-y: auto;
  overscroll-behavior: contain;
`;

const StyledFiltersAndAttributesContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 6px;
  height: calc(100% - 80px);
`;

const StyledLeftColumn = styled.div`
  order: 0;
  flex: 1 1;
  min-width: 265px;
  border-right: 2px solid #b2b2b3;
  height: 100%;
  overflow-y: auto;
`;

const StyledRightColumn = styled.div`
  order: 1;
  flex: 3 1;
  overflow-y: auto;
  padding: 24px;
`;

const StyledFilterTextField = styled(TextField)`
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(26, 36, 45, 0.2),
    0px 1px 3px rgba(26, 36, 45, 0.1);
  border: none;

  & .MuiOutlinedInput-root fieldset {
    border: none;
    &:hover fieldset {
      border: none !important;
      box-shadow: 0px 1px 2px rgba(26, 36, 45, 0.2),
        0px 1px 3px rgba(26, 36, 45, 0.1);
    }
  }
`;

const StyledBulkActionBar = styled(BulkActionBar)`
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: 900px;
`;

type Props = {
  contentEntryId?: number;
  onChange?: (
    attributeName: string,
    attribute: Attribute,
    action: 'add' | 'remove'
  ) => void;
  selectedDevices?: DeviceModel[];
  selectedDeviceTypes?: DeviceTypeModel[];
  selectedManufacturers?: ManufacturerModel[];
  selectedOperatingSystems?: OperatingSystemModel[];
  selectedOperatingSystemReleases?: OperatingSystemReleaseModel[];
  selectedOperatingSystemVersions?: OperatingSystemVersionModel[];
};

export type Selectable = {
  isSelected?: boolean;
};

export type Attribute =
  | DeviceModel
  | DeviceTypeModel
  | ManufacturerModel
  | OperatingSystemModel
  | OperatingSystemReleaseModel
  | OperatingSystemVersionModel;

const AttributeEditor: FunctionComponent<Props> = (props) => {
  const {
    hasPermission,
    filterBarRef,
    primary,
    secondary,
    filter,
    filterText,
    filteredDevices,
    filteredDeviceTypes,
    filteredManufacturers,
    filteredOperatingSystemVersions,
    title,
    contentTypeId,
    devices,
    deviceTypes,
    manufacturers,
    operatingSystems,
    operatingSystemReleases,
    operatingSystemVersions,
    pendingOperations,
    isSaving,
    handleClickFilter,
    handleClearAllFilters,
    handleSelect,
    handleSetFilterText,
    onSave,
    onClear,
  } = useAttributEditor(props);

  return (
    <>
      {hasPermission && (
        <AttributeHeader title={title} contentTypeId={contentTypeId} />
      )}
      <div
        style={{ display: 'flex', height: '100%' }}
        data-testid="attributes-editor"
      >
        {hasPermission && (
          <StyledContainer data-testid="attribute-editor:editor">
            <div>
              <StyledFilterTextField
                fullWidth
                placeholder="Search"
                InputProps={{
                  startAdornment: <StyledSearchIcon />,
                  endAdornment: (
                    <IconButton onClick={handleClearAllFilters} size="large">
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                variant="outlined"
                value={filterText}
                onChange={handleSetFilterText}
              />
            </div>
            <StyledFiltersAndAttributesContainer>
              <StyledLeftColumn ref={filterBarRef}>
                <div>
                  <StyledFilterTypography variant="h6">
                    {'Filters'}
                  </StyledFilterTypography>
                  <GenericFilterPicker
                    isSelected={primary === 'all'}
                    label="All Attributes"
                    onClick={() => handleClickFilter('all')}
                  />
                  <GenericFilterPicker
                    count={filteredManufacturers.length}
                    isSelected={primary === 'manufacturer'}
                    label="Manufacturer"
                    onClick={() => handleClickFilter('manufacturer')}
                  />
                  <GenericFilterPicker
                    count={filteredDeviceTypes.length}
                    isSelected={primary === 'deviceType'}
                    label="Device Type"
                    onClick={() => handleClickFilter('deviceType')}
                  />
                  <DeviceFilterPicker
                    devices={filteredDevices}
                    isSelected={primary === 'device'}
                    selectedManufacturer={filter.secondary}
                    onClick={handleClickFilter}
                    containerHeight={filterBarRef?.current?.clientHeight}
                  />
                  <OperatingSystemFilterPicker
                    operatingSystemVersions={filteredOperatingSystemVersions}
                    selectedOperatingSystem={filter.secondary}
                    isSelected={primary === 'operatingSystem'}
                    onClick={handleClickFilter}
                    containerHeight={filterBarRef?.current?.clientHeight}
                  />
                </div>
              </StyledLeftColumn>
              <StyledRightColumn>
                <AttributeSelectors
                  onSelect={handleSelect}
                  primaryFilter={primary}
                  secondaryFilter={secondary}
                  devices={filteredDevices}
                  deviceTypes={filteredDeviceTypes}
                  manufacturers={filteredManufacturers}
                  operatingSystemVersions={filteredOperatingSystemVersions}
                  selectedOperatingSystemIds={operatingSystems.map(
                    ({ id }) => id
                  )}
                  selectedOperatingSystemReleaseIds={operatingSystemReleases.map(
                    ({ id }) => id
                  )}
                  pendingOperations={pendingOperations}
                />
              </StyledRightColumn>
            </StyledFiltersAndAttributesContainer>
          </StyledContainer>
        )}
        <StyledAttributeSummaryContainer>
          <TagDisplay
            devices={devices}
            deviceTypes={deviceTypes}
            manufacturers={manufacturers}
            operatingSystems={operatingSystems}
            operatingSystemReleases={operatingSystemReleases}
            operatingSystemVersions={operatingSystemVersions}
            pendingOperations={pendingOperations}
            onClear={hasPermission ? handleSelect : undefined}
          />
        </StyledAttributeSummaryContainer>
      </div>
      {pendingOperations.length > 0 && (
        <StyledBulkActionBar
          selectedCount={pendingOperations.length}
          onClose={onClear}
        >
          <Typography color="white">
            {'Higher-order attributes will be automatically added upon save.'}
          </Typography>
          <ActionButton onClick={onSave} startIcon={<Save />}>
            {'Save'}
          </ActionButton>
        </StyledBulkActionBar>
      )}
      {isSaving && (
        <Backdrop open sx={{ zIndex: 9_999_999 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};

export default AttributeEditor;
