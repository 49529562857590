import {
  useMemo,
  useCallback,
  useState,
  cloneElement,
  FC,
  ReactElement,
} from 'react';

import AddContentDefaultButton from './default-button';
import { useSaveNewContent } from './hooks';
import AddContentModal from './modal';
import { Props as ModalProps } from './modal-body';

type Props = ModalProps & {
  open?: boolean;
  onClose?: VoidFunction;
  contentTypeAllowlist?: string[];
  contentTypeBlocklist?: string[];
  sourceName: 'home' | 'collection' | 'topic';
  onSave?: (savedContentEntryId: number) => void;
  children?: ReactElement;
};

const AddContent: FC<Props> = ({
  open,
  onClose,
  contentTypeAllowlist,
  contentTypeBlocklist = [],
  sourceName,
  onSave,
  children = <AddContentDefaultButton />,
  contentTypeId,
  spaceId,
  languageIds,
  topicId,
  attributes,
}) => {
  const isControlled = onClose !== undefined;
  const [isOpenUncontrolled, setIsOpenUncontrolled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const defaultFormState = useMemo(
    () => ({
      title: '',
      contentTypeId,
      spaceId,
      languageIds,
      topicId,
      attributes,
    }),
    [contentTypeId, spaceId, languageIds, topicId, attributes]
  );

  const [formState, setFormState] = useState<ModalProps>(defaultFormState);
  const handleSave = useSaveNewContent(
    sourceName,
    defaultFormState,
    setIsSaving,
    isControlled ? onClose : setIsOpenUncontrolled,
    setFormState,
    onSave
  );

  const handleClose = useCallback(() => {
    setFormState(defaultFormState);
    isControlled ? onClose() : setIsOpenUncontrolled(false);
  }, [setFormState, defaultFormState, onClose, isControlled]);

  const button = useMemo(() => {
    return cloneElement(children, {
      onClick: () => setIsOpenUncontrolled(true),
    });
  }, [children]);

  return (
    <>
      {isControlled ? null : button}
      <AddContentModal
        contentTypeAllowlist={contentTypeAllowlist}
        contentTypeBlocklist={contentTypeBlocklist}
        formState={formState}
        setFormState={setFormState}
        isOpen={isControlled ? open! : isOpenUncontrolled}
        isSaving={isSaving}
        onSave={handleSave}
        onClose={handleClose}
        attributes={attributes}
      />
    </>
  );
};

export default AddContent;
