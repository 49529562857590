import { MutableRefObject, useEffect, useState, useRef } from 'react';

const useIsBeingDraggedOver = (
  ref: MutableRefObject<HTMLElement | null>,
  handleDrop: (e: DragEvent) => void
) => {
  const [dragEnterCount, setDragEnterCount] = useState(0);
  const isBulkTransfer = useRef(false);
  const isBeingDraggedOver = dragEnterCount > 0;

  useEffect(() => {
    if (ref.current) {
      const refClosure = ref.current;

      const onDragOver = (e: DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.dataTransfer) {
          // eslint-disable-next-line no-param-reassign
          e.dataTransfer.dropEffect = 'move';
        }
      };

      const onDragEnter = (e: DragEvent) => {
        if (e.dataTransfer) {
          isBulkTransfer.current =
            e.dataTransfer.items.length > 1 &&
            e.dataTransfer.types[0] !== 'text/uri-list';
        }
        setDragEnterCount((currentCount) => currentCount + 1);
      };

      const onDragLeave = () => {
        setDragEnterCount((currentCount) => currentCount - 1);
      };

      const onDrop = (e: DragEvent) => {
        e.preventDefault();
        setDragEnterCount(0);
        handleDrop(e);
      };

      refClosure.addEventListener('dragover', onDragOver);
      refClosure.addEventListener('dragenter', onDragEnter);
      refClosure.addEventListener('dragleave', onDragLeave);
      refClosure.addEventListener('drop', onDrop);

      return () => {
        refClosure.removeEventListener('dragover', onDragOver);
        refClosure.removeEventListener('dragenter', onDragEnter);
        refClosure.removeEventListener('dragleave', onDragLeave);
        refClosure.removeEventListener('drop', onDrop);
      };
    }
  }, [ref, handleDrop]);

  return { isBulkTransfer: isBulkTransfer.current, isBeingDraggedOver };
};

export default useIsBeingDraggedOver;
