import { useCallback, useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { getBestDefaultLanguage } from 'services/utils/get-best-default-language';
import { DropResult } from 'components/vertical-reorderable-list';
import { useCatchStaleWriteError } from 'services/ozmo-api/utils/use-catch-stale-write-error';
import { usePerUserFlag } from 'services/hooks/use-per-user-flag';
import { useAppToast } from 'contexts/app-toast-context';

import { generateCollectionPath } from './util';

type CategoryReference =
  | LocalizedCollectionReference
  | MissingLocalizedCollectionReference;

type CategoryReferencesWithTopicGroups = (
  | CategoryReference
  | CategoryReference[]
)[];

export type SelectedCategory = {
  categoryIndex: number;
  title: string;
  description: string;
  items: CategoryReferencesWithTopicGroups;
};

export type SelectedReference = {
  id: number;
  categoryIndex: number;
  localizedContentEntryId?: Maybe<number>;
  title?: Maybe<string>;
  complete?: Maybe<boolean>;
};

export const useCategoryRedirect = (
  localizedCollection: LocalizedCollectionModel
) => {
  const { language } = useParams<'language'>();

  const navigate = useNavigate();
  const navigateToCategoryIndex = useCallback(
    (categoryIndex?: number) => {
      if (categoryIndex !== undefined) {
        navigate(
          generateCollectionPath(
            localizedCollection.contentEntryId,
            language || localizedCollection.languageShortCode,
            categoryIndex
          ),
          { replace: true }
        );
      } else {
        navigate(
          generateCollectionPath(
            localizedCollection.contentEntryId,
            language || localizedCollection.languageShortCode
          ),
          { replace: true }
        );
      }
    },
    [navigate, localizedCollection, language]
  );

  return navigateToCategoryIndex;
};

export const useContentTab = (
  contentEntryId: number,
  localizedContentEntryId: number,
  hideSidePanel: () => void
) => {
  const api = useOzmoApiService();
  const {
    data: localizedCollection,
    isLoading,
    moveItemInCategoryInAllLanguages,
    moveCategoryInAllLanguages,
    insertCategoryInAllLanguages,
    addReferencesInAllLanguages,
    removeCategoryInAllLanguages,
    categories,
    refetch,
    updateCategory,
  } = api.LocalizedCollection.get(
    {
      id: localizedContentEntryId,
      contentEntryId,
    },
    {
      // refetch every 5 minutes
      refetchInterval: 300000,
      // do not refetch while in the background, because refetch will happen when re-focusing
      refetchIntervalInBackground: false,
      // refetch when a component consuming it mounts, regardless of if the data is stale or not
      refetchOnMount: 'always',
      // refetch when window regains focus, regardless of if the data is stale or not
      refetchOnWindowFocus: 'always',
      // refetch when network reconnects, regardless of if the data is stale or not
      refetchOnReconnect: 'always',
    }
  );

  const navigateToCategoryIndex = useCategoryRedirect(localizedCollection);
  const { catchStaleWrite } = useCatchStaleWriteError();
  const [showCategories, setShowCategories] = useState(true);
  const { selectedCategoryIndex } = useParams();
  const [selectedReferences, setSelectedReferences] = useState<
    SelectedReference[]
  >([]);

  const toggleShowCategories = useCallback(
    () => setShowCategories((cur) => !cur),
    []
  );

  const allReferences = useMemo(
    () => api.LocalizedCollection.getAllReferences(localizedCollection),
    [localizedCollection, api.LocalizedCollection]
  );

  const handleAddReferences = useCallback(
    (referenceIds: number[], intoCategoryIndex?: number) =>
      addReferencesInAllLanguages(referenceIds, intoCategoryIndex).catch(
        catchStaleWrite(
          'Answer created, but we were unable to add it to the collection',
          'Please use existing answer search to add your answer'
        )
      ),
    [addReferencesInAllLanguages, catchStaleWrite]
  );

  const handleReorder = useCallback(
    (categoryIndex: number) => {
      return async (result: DropResult) => {
        const { destination, source } = result;
        if (!destination) {
          return false;
        }
        const destinationIndex = destination.index;
        const sourceIndex = source.index;

        await moveItemInCategoryInAllLanguages(
          sourceIndex,
          destinationIndex,
          categoryIndex
        ).catch((e) => {
          const reference = allReferences.find(
            (r) => r.id === parseInt(result.draggableId, 10)
          );
          const categoryName = categories[categoryIndex]?.title;
          const message = `${
            reference
              ? `"${reference.title}"`
              : `the topic bundle "${result.draggableId}"`
          } in ${
            categoryName ? `category "${categoryName}"` : 'the collection'
          }`;
          catchStaleWrite(`We were unable to reorder ${message}`)(e);
        });
      };
    },
    [
      allReferences,
      catchStaleWrite,
      categories,
      moveItemInCategoryInAllLanguages,
    ]
  );

  const handleReorderCategories = useCallback(
    async (result: DropResult) => {
      const { destination, source } = result;

      if (!destination) {
        return false;
      }

      const destinationIndex = destination.index;
      const sourceIndex = source.index;
      const selectedCategoriesIncludeSource =
        Number(selectedCategoryIndex) === sourceIndex;

      // if the selected category was moved; update it to match the new destination
      // we assume success because otherwise there could be a delay between the
      // categories actually moving, and the success response below
      if (selectedCategoriesIncludeSource) {
        navigateToCategoryIndex(destinationIndex);
      }

      const success = await moveCategoryInAllLanguages(
        sourceIndex,
        destinationIndex
      ).catch(
        catchStaleWrite(
          `We were unable to reorder the category "${
            categories[source.index].title
          }"`
        )
      );

      // if failed, revert to original selection index
      if (!success && selectedCategoriesIncludeSource) {
        navigateToCategoryIndex(sourceIndex);
      }
    },
    [
      navigateToCategoryIndex,
      selectedCategoryIndex,
      moveCategoryInAllLanguages,
      catchStaleWrite,
      categories,
    ]
  );

  // Given an array of collection references (localized or missing), extract all the Topic Groups
  // contained within, and return a new array consisting of references and groups of references with the same topic
  // For example, given the input items: [{ id: 1, topic: "foo" }, { id: 2, topic: "bar" }, { id: 3, topic: "foo" }]
  // Returns: [[{ id: 1, topic: "foo" }, { id: 3, topic: "foo" }], { id: 2, topic: "bar" }]
  const extractItemsAndTopicGroups = useCallback(
    (
      items: (
        | LocalizedCollectionReference
        | MissingLocalizedCollectionReference
      )[]
    ) => {
      const {
        topicGroups,
        topicsWithManyReferences,
      } = api.LocalizedCollection.extractTopicGroups(items);

      const handledGroups: string[] = [];

      return items.reduce<CategoryReferencesWithTopicGroups>((acc, cur) => {
        // If this reference is included in a topic group
        if (topicsWithManyReferences.includes(cur.topicSlug!)) {
          // We only want to add the topicSlug group ONCE for all the
          // references contained in it
          if (handledGroups.includes(cur.topicSlug!)) {
            return acc;
          }

          handledGroups.push(cur.topicSlug!);
          return [...acc, topicGroups[cur.topicSlug!]];
        }

        return [...acc, cur];
      }, []);
    },
    [api.LocalizedCollection]
  );

  const uncategorizedAnswersCategory: SelectedCategory = useMemo(() => {
    const baseCategory = {
      categoryIndex: -1,
      title: 'Uncategorized',
      description: '',
      items: [],
    };

    if (!localizedCollection) {
      return baseCategory;
    }

    const uncategorizedReferences = api.LocalizedCollection.getUncategorizedItems(
      localizedCollection
    );

    const items = extractItemsAndTopicGroups(uncategorizedReferences);

    return {
      ...baseCategory,
      items,
    };
  }, [
    api.LocalizedCollection,
    localizedCollection,
    extractItemsAndTopicGroups,
  ]);

  const selectedCategories: SelectedCategory[] = useMemo(() => {
    if (!localizedCollection) {
      return [];
    }

    const categories = api.LocalizedCollection.getCategories(
      localizedCollection
    );

    const categoriesWithIndices = categories.map((c, categoryIndex) => ({
      ...c,
      items: extractItemsAndTopicGroups(c.items),
      categoryIndex,
    }));

    if (!selectedCategoryIndex) {
      return [uncategorizedAnswersCategory, ...categoriesWithIndices];
    }

    const fin = [Number(selectedCategoryIndex)]
      .filter((index) => index > -1 && index < categoriesWithIndices.length)
      .map((index) => categoriesWithIndices[index]);

    return fin;
  }, [
    api.LocalizedCollection,
    localizedCollection,
    selectedCategoryIndex,
    uncategorizedAnswersCategory,
    extractItemsAndTopicGroups,
  ]);

  const handleSelectReference = useCallback(
    (referenceIdOrIds: number | number[], categoryIndex: number) => {
      setSelectedReferences((currentReferences) => {
        const referenceIds = [referenceIdOrIds].flat();
        return referenceIds.reduce((acc, currentRefId) => {
          const existingReferenceIndex = acc.findIndex(
            (ref) =>
              ref.id === currentRefId && ref.categoryIndex === categoryIndex
          );
          // remove (deselect) the reference if it is currently selected
          if (existingReferenceIndex >= 0) {
            acc.splice(existingReferenceIndex, 1);
            return [...acc];
          }
          // otherwise add (select) the reference
          // Get the full reference so we can use the title and complete
          const reference = allReferences.find(({ id }) => id === currentRefId);

          return [
            ...acc,
            {
              id: currentRefId,
              categoryIndex,
              localizedContentEntryId: reference?.localizedContentEntryId,
              title: reference?.title,
              complete: reference?.complete,
            },
          ];
        }, currentReferences);
      });
    },
    [allReferences]
  );

  const isReferenceSelected = useCallback(
    (referenceId: number, categoryIndex: number) => {
      return !!selectedReferences.find(
        (ref) => ref.id === referenceId && ref.categoryIndex === categoryIndex
      );
    },
    [selectedReferences]
  );

  const handleDeselectAllReferences = useCallback(
    () => setSelectedReferences([]),
    []
  );

  const handleSelectAllReferences = useCallback(() => {
    const allReferences: SelectedReference[] = selectedCategories.reduce(
      (prevRefs, currentCategory) => {
        const newRefs: SelectedReference[] = currentCategory.items
          .map((refOrTopicGroup) => {
            if (Array.isArray(refOrTopicGroup)) {
              return refOrTopicGroup.map(
                ({ id, title, complete, localizedContentEntryId }) => ({
                  id,
                  complete,
                  categoryIndex: currentCategory.categoryIndex,
                  localizedContentEntryId,
                  title,
                })
              );
            }
            return {
              id: refOrTopicGroup.id,
              categoryIndex: currentCategory.categoryIndex,
              complete: refOrTopicGroup.complete,
              localizedContentEntryId: refOrTopicGroup.localizedContentEntryId,
              title: refOrTopicGroup.title,
            };
          })
          .flat();
        return [...prevRefs, ...newRefs];
      },
      [] as SelectedReference[]
    );
    setSelectedReferences((currentSelectedRefs) => {
      // if all references are selected; unselect them
      if (currentSelectedRefs.length === allReferences.length) {
        return [];
      }

      return allReferences;
    });
  }, [selectedCategories]);

  // close side panel if selected category changes
  useEffect(hideSidePanel, [hideSidePanel, selectedCategoryIndex]);

  return {
    selectedCategories,
    selectedCategoryIndex: selectedCategoryIndex
      ? Number(selectedCategoryIndex)
      : -1,
    showCategories,
    selectedReferences,
    toggleShowCategories,
    setShowCategories,
    handleReorder,
    insertCategoryInAllLanguages,
    updateCategory,
    handleReorderCategories,
    addReferencesInAllLanguages: handleAddReferences,
    removeCategoryInAllLanguages,
    refetchLocalizedCollection: refetch,
    handleSelectReference,
    isReferenceSelected,
    handleSelectAllReferences,
    handleDeselectAllReferences,
    localizedCollection,
    isLoading,
  };
};

export const useCollection = () => {
  const navigate = useNavigate();
  const api = useOzmoApiService();
  const { id = '', language: urlParamLanguage = '' } = useParams<
    'id' | 'language'
  >();
  const contentEntryId = parseInt(id, 10);

  const { data: contentEntry, isLoading, error } = api.ContentEntry.get({
    id: contentEntryId,
  });

  const handleSelectLanguage = useCallback(
    (newLanguage: string) =>
      navigate(generateCollectionPath(contentEntryId, newLanguage)),
    [contentEntryId, navigate]
  );

  let language: string | undefined;
  let localizedContentEntry: LocalizedContentEntryModel | undefined;

  if (!isLoading && !error) {
    language = getBestDefaultLanguage(contentEntry, urlParamLanguage, 'en');

    localizedContentEntry =
      contentEntry.localizedContentEntries.find(
        (lce) => lce.languageShortCode === language
      ) ?? contentEntry.localizedContentEntries[0];
  }

  // redirect to the language/locale version if not in the URL
  useEffect(() => {
    if (language && language !== urlParamLanguage) {
      navigate(generateCollectionPath(id, language), { replace: true });
    }
  }, [navigate, id, language, urlParamLanguage]);

  return {
    contentEntryId,
    error,
    language: localizedContentEntry?.languageShortCode ?? language,
    localizedContentEntry,
    isLoading,
    handleSelectLanguage,
  };
};

export const useShowPublishingMoved = () => {
  const { getFlag, setFlag } = usePerUserFlag();
  const dispatchToast = useAppToast();

  useEffect(() => {
    const flag = 'has-seen-publishing-moved-message';
    const hasSeenMessage = getFlag(flag);

    if (!hasSeenMessage) {
      dispatchToast({
        level: 'info',
        message: (
          <>
            <b>{'Publishing has moved!'}</b>
            <div>
              {'You can now publish answers using bulk actions. '}
              {'Select an answer’s checkbox to bring up the bulk actions bar.'}
            </div>
          </>
        ),
      });
      setFlag(flag, true);
    }
    // disable warning because we only want this to run once at mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
