import { useCallback } from 'react';
import { useAppToast } from 'contexts/app-toast-context';
import { SelectedReference } from 'scenes/collection/hooks';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { assembleBulkOperation } from 'services/utils/assemble-bulk-operation';
import {
  getSuccessCount,
  recursiveJobRequest,
} from 'services/ozmo-api/utils/recursive-job-request';

type PublishableReference = Omit<
  SelectedReference,
  'localizedContentEntryId'
> & { localizedContentEntryId: number };

const usePublishAnswers = (
  references: SelectedReference[],
  refetchCollection: Function
) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();
  const incompleteReferences = references.filter(({ complete }) => !complete);
  const completeReferencesCount =
    references.length - incompleteReferences.length;

  const handlePublish = useCallback(async () => {
    try {
      // Only localized references can be published, we can't publish
      // missing localized references... because the locale is missing!
      // The API is responsible for validating/adjudicating completeness so don't do that here
      const publishableReferences = references.filter(
        ({ localizedContentEntryId }) => localizedContentEntryId !== null
      ) as PublishableReference[];
      const bulkOperations = publishableReferences.flatMap((ref) =>
        assembleBulkOperation(
          ref.localizedContentEntryId,
          'replace',
          '/status',
          'published'
        )
      );
      const { job } = await api.LocalizedContentEntry.bulkAsync(bulkOperations);
      const response = await recursiveJobRequest<BulkOperationJobResponse>(job);
      const successCount = getSuccessCount(response);

      if (successCount !== bulkOperations.length) {
        console.error(
          'Not all bulk publish operations succeeded:',
          response.results
        );
      }

      dispatchToast({
        level: 'success',
        message: (
          <>
            {'Success! You have published '}
            <b>{`${successCount} answers`}</b>
          </>
        ),
      });
    } catch (error) {
      // This occurs if the initial request to kick off the bulk operation job fails
      // this does not handle the individual status of each bulk operation.
      dispatchToast({
        level: 'error',
        message:
          'We were unable to publish your answers. Please try publishing your answers again.',
      });
    } finally {
      refetchCollection();
    }
  }, [api.LocalizedContentEntry, dispatchToast, references, refetchCollection]);

  return { completeReferencesCount, incompleteReferences, handlePublish };
};

export { usePublishAnswers };
