import { FC, useMemo } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import getPlural from 'services/utils/get-plural/get-plural';

import useAnswerFiltering from '../category/use-answer-filter';
import { useCurrentCategory } from '../category';

const ResultsRow: FC = () => {
  const { filterCount, clearFilters } = useAnswerFiltering();
  const { category, categories } = useCurrentCategory();

  const [answerCount, categoryName] = useMemo(() => {
    if (category) {
      return [category.contentEntries.length, category.name];
    }

    return [
      categories.flatMap((category) => category.contentEntries).length,
      'All answers',
    ];
  }, [categories, category]);

  if (filterCount === 0) return null;

  const filterPlural = getPlural(filterCount, 'filter', 'filters');
  const resultsPlural = getPlural(answerCount, 'result', 'results');

  return (
    <Grid container spacing={2} paddingTop={0.188}>
      <Grid item>
        <Typography
          variant="body2"
          fontStyle="italic"
          paddingTop={1}
        >{`${filterCount} ${filterPlural} applied to "${categoryName}", ${answerCount} ${resultsPlural}`}</Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={clearFilters}
          variant="text"
          color="error"
          startIcon={<Clear />}
          sx={{
            textDecoration: 'underline',
            fontWeight: '400',
            '&:hover': { textDecoration: 'underline' },
          }}
        >
          {`Clear ${filterPlural}`}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ResultsRow;
