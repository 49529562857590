import { IconButton, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledTableWrapper } from 'components/content-entry-side-panel';
import { StyledCollectionWrapper } from 'components/content-entry-side-panel/collection-wrapper';
import CopyContentEntryLink from 'components/content-entry-side-panel/copy-content-entry-link';
import { FCWithChildren } from 'types/fc-with-children';
import { useParams } from 'react-router-dom';

const StyledContainer = styled('div')`
  border: 1px solid var(--color-neutral-five);
  background-color: var(--color-neutral-one);
  overflow-y: auto;
  border-radius: 6px;

  /* Default all children typography to this size and color */
  * > p.MuiTypography-body1 {
    font-size: 0.875rem;
    color: var(--color-neutral-seven);
  }

  &.deleted * > p.MuiTypography-body1,
  &.deleted * > .MuiSvgIcon-root {
    color: var(--color-neutral-six) !important;
  }

  /* If the parent to this component has the "tableWrapper" class */
  ${StyledTableWrapper}.panel-open & {
    flex: 1;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
    max-width: 375px;
  }

  ${StyledCollectionWrapper} & {
    width: 100%;
    padding-top: ${({ theme }) => theme.spacing(2)};
    border: none;
    border-left: 6px solid var(--color-primary-background);
    border-radius: 0px;
  }
`;

const StyledHeader = styled('div')`
  background-color: var(--color-neutral-four);
  box-shadow: 0 1px 3px 0 rgba(26, 36, 45, 0.1),
    0 1px 2px 0 rgba(26, 36, 45, 0.2);
  border-radius: 6px 6px 0px 0px;
  padding: 3px 24px 2px 24px !important;
  text-transform: uppercase;import { StyledTableWrapper } from 'components/content-entry-side-panel';

  display: flex;
  align-items: center;
  justify-content: space-between;

  /* If the parent to this component has the "tableWrapper" or "collectionWrapper" class */
  ${StyledTableWrapper}, ${StyledCollectionWrapper} & {
    border-top-left-radius: 0px;
  }

  ${StyledCollectionWrapper} & {
    background-color: var(--color-neutral-one);
    box-shadow: none;
    & > p.MuiTypography-body1 {
      font-size: 1rem;
    }
  }
`;

type Props = {
  id: number | null;
  contentType: string;
  onClose?: () => void;
};

const SidePanelContainer: FCWithChildren<Props> = ({
  children,
  id,
  contentType,
  onClose,
}) => {
  const { language } = useParams();
  return (
    <StyledContainer data-testid="side-panel-container">
      <StyledHeader>
        <Typography
          fontWeight={500}
          color="var(--color-neutral-eight) !important"
        >
          {'DETAILS'}
        </Typography>
        <div>
          <CopyContentEntryLink
            contentEntryId={id!}
            contentType={contentType}
            language={language}
          />
          <IconButton onClick={onClose} aria-label="Close side panel">
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </StyledHeader>
      {children}
    </StyledContainer>
  );
};

export default SidePanelContainer;
