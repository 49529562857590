import { useEffect, useRef, useState, KeyboardEvent, MouseEvent } from 'react';
import { useOzmoApiService } from 'contexts/ozmo-api-service-context';
import { getBestLocalizedContentEntry } from 'services/utils/get-best-localized-content-entry';

export const useQuickAddSearch = (languageId: number) => {
  const inputRef = useRef<HTMLInputElement>();
  const [id, setId] = useState<number>();

  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      const timeout = setTimeout(() => input.focus(), 0);
      return () => clearTimeout(timeout);
    }
  }, []);

  const handleQuickAddClick = (e: MouseEvent) => {
    inputRef.current?.focus();
  };

  const api = useOzmoApiService();
  const { data: result, isError, isFetching } = api.ContentEntry.get(
    { id },
    undefined,
    ['localized_content_entries', 'topic']
  );
  const localizedResult = getBestLocalizedContentEntry(
    result?.localizedContentEntries ?? [],
    languageId
  );

  const handleKeyDown = (e: KeyboardEvent) => {
    const { key, target } = e;

    if (key === 'Enter' && (target as HTMLInputElement)?.value) {
      const newId = Number((target as HTMLInputElement).value);
      setId(newId);
    }
  };

  return {
    inputRef,
    handleQuickAddClick,
    handleKeyDown,
    isError,
    isFetching,
    result:
      result && localizedResult
        ? {
            title: localizedResult.properties.title,
            id: result.id,
            topicSlug: result.topic!.slug,
            contentType: result.contentType,
          }
        : null,
  };
};
