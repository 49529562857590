import { FC, memo, useCallback, ChangeEvent } from 'react';
import { Checkbox, Typography, IconButton, Box } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import ContentType from 'components/content-type-with-icon';
import TagCloud from 'components/tag-cloud';
import LinkBox from 'components/link-box';
import {
  ContentTableCell,
  ContentTableRow,
} from 'components/content-table-row';
import { generateCollectionPath } from 'scenes/nuevo-collection/util';
import usePreviousPage from 'services/utils/use-previous-page';
import { useEventTracking } from 'services/analytics';

import RowActions from './row-actions';

type Props = {
  data: CollectionTableData;
  isSelected: boolean;
  openDuplicateModal: (rowId: number) => void;
  handleSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  onInfoClick: (id: number) => void;
};

const CollectionTableRow: FC<Props> = ({
  data,
  isSelected,
  openDuplicateModal,
  handleSelect,
  onInfoClick,
}) => {
  const { generateUrlWithPreviousPage } = usePreviousPage();
  const { id, title, updatedAt, attributes } = data;
  const handleInfoClick = useCallback(() => onInfoClick(id), [id, onInfoClick]);
  const trackEvent = useEventTracking();

  return (
    <ContentTableRow
      $rowId={id}
      data-testid="collection-table-row"
      selected={isSelected}
      hover
      tabIndex={-1}
      aria-checked={isSelected}
      className={`content-entry-${id}`}
    >
      {/* checkbox */}
      <ContentTableCell padding="checkbox">
        <Checkbox
          data-testid="collection-table-row-checkbox"
          color="primary"
          checked={isSelected}
          value={id}
          onChange={handleSelect}
        />
      </ContentTableCell>
      {/* type */}
      <ContentTableCell align="left">
        <ContentType contentType="Collection" label="Collection" />
      </ContentTableCell>
      {/* title */}
      <ContentTableCell align="left">
        <LinkBox
          url={generateUrlWithPreviousPage(generateCollectionPath(id))}
          onClick={() => {
            trackEvent('search-result-selected', { id, type: 'collection' });
          }}
        >
          <Typography fontWeight="bold">
            {title}
            <Typography
              color="var(--color-neutral-six)"
              fontSize="0.81rem"
              paddingLeft="3px"
              component="span"
            >{`(#${id})`}</Typography>
          </Typography>
        </LinkBox>
      </ContentTableCell>
      {/* updated */}
      <ContentTableCell align="left">
        {new Date(updatedAt).toLocaleDateString()}
      </ContentTableCell>
      {/* attributes */}
      <ContentTableCell align="left" hideOverflow>
        <TagCloud tags={attributes} limitTags={3} />
      </ContentTableCell>
      {/* info panel & row actions */}
      <ContentTableCell align="right" style={{ position: 'relative' }}>
        <Box display="flex" alignItems="center" justifyContent="right" gap={1}>
          <IconButton onClick={handleInfoClick} aria-label="Info">
            <InfoOutlined />
          </IconButton>
          <RowActions rowId={id} openDuplicateModal={openDuplicateModal} />
        </Box>
      </ContentTableCell>
    </ContentTableRow>
  );
};

const memoizedCollectionTableRow = memo(
  CollectionTableRow,
  (prevProps, nextProps) =>
    prevProps.data === nextProps.data &&
    prevProps.isSelected === nextProps.isSelected
);

export default memoizedCollectionTableRow;
