import type { Attributes } from 'scenes/add-content/modal-body';

// The duplicate dialog states if a collection or topic is being duplicated
// this function determines which text to show based on the content entry's
// content type.
export const getDisplayContentType = (contentTypeId: number) => {
  switch (contentTypeId) {
    case 1: // interactiveTutorial
      return 'Topic';
    case 2: // pointOfInterest
      return 'Topic';
    case 3: // collection
      return 'Collection';
    default:
      return 'Content';
  }
};

const extractId = (model: BaseModel) => model.id;

export const isListEqual = <T>(arr1: T[] = [], arr2: T[] = []) => {
  return (
    arr1.length === arr2.length &&
    arr1.every((item) => {
      return arr2.includes(item);
    })
  );
};

export const contentEntryToAttributes = (
  contentEntry?: ContentEntryModel
): Attributes | undefined => {
  if (!contentEntry) return;

  return {
    Device: contentEntry?.devices?.map(extractId) ?? [],
    DeviceType: contentEntry?.deviceTypes?.map(extractId) ?? [],
    Manufacturer: contentEntry?.manufacturers?.map(extractId) ?? [],
    OperatingSystem: contentEntry?.operatingSystems?.map(extractId) ?? [],
    OperatingSystemRelease:
      contentEntry?.operatingSystemReleases?.map(extractId) ?? [],
    OperatingSystemVersion:
      contentEntry?.operatingSystemVersions?.map(extractId) ?? [],
  };
};
