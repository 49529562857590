import { useEffect, useState, ReactNode } from 'react';

export const useStaggeredRenderedList = (
  items: ReactNode[],
  perPage: number
) => {
  const [renderedItems, setRenderedItems] = useState(items.slice(0, perPage));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handle = setInterval(() => {
      setRenderedItems((cur) => {
        const index = cur.length;
        if (cur.length + perPage >= items.length) {
          setIsLoading(false);
          clearInterval(handle);
        }
        return [...cur, ...items.slice(index, index + perPage)];
      });
    }, 100);

    return () => {
      clearInterval(handle);
    };
  }, [items, perPage]);

  useEffect(() => {
    if (!isLoading) {
      setRenderedItems(items);
    }
  }, [items, isLoading]);

  return { renderedItems, isLoading };
};
