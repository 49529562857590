import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';

type Props = {
  slug?: string;
};

const TopicSlug: FunctionComponent<Props> = ({ slug }) => (
  <Typography
    variant="body2"
    overflow="hidden"
    whiteSpace="nowrap"
    textOverflow="ellipsis"
  >
    {slug}
  </Typography>
);

export default TopicSlug;
