export const formatAttributeName = (name: string) => {
  try {
    // Split on spaces or dashes
    const words = name.split(/[\s-]+/);
    const upperCasedWords = words.map((word) => {
      if (word === 'iphone') {
        return 'iPhone';
      }
      if (word === 'ipad') {
        return 'iPad';
      }
      return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    });
    return upperCasedWords.reduce((acc, word) => `${acc} ${word}`);
  } catch (TypeError) {
    return name;
  }
};
