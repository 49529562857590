import { useEffect, useState } from 'react';
import { Droppable, DroppableProps } from 'react-beautiful-dnd';

// This is a StrictMode only issue.
// Due to React 18 concurrent rendering, and how 'react-beutiful-dnd' registers their droppables,
// the register is not re-set when a remount occurs. This causes a "unable to find draggable id" warning.

// This workaround comes from: https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1167427762
const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

export default StrictModeDroppable;
