import { FC } from 'react';
import { Chip, Typography, styled } from '@mui/material';
import { ErrorOutline, CheckCircleOutline } from '@mui/icons-material';

type Props = {
  complete: boolean;
  className?: string;
};

const StyledChip = styled(Chip)`
  border: none;
  border-radius: 25px;

  &.complete {
    background-color: var(--color-messaging-success-light);
    .MuiChip-icon {
      color: var(--color-messaging-success);
    }
  }

  &.incomplete {
    background-color: var(--color-messaging-error-light);
    .MuiChip-icon {
      color: var(--color-messaging-error);
    }
  }
`;

export const CompletenessChip: FC<Props> = ({ complete, className }) => {
  return (
    <StyledChip
      className={`${className} ${complete ? 'complete' : 'incomplete'}`}
      icon={
        complete ? (
          <CheckCircleOutline fontSize="small" />
        ) : (
          <ErrorOutline fontSize="small" />
        )
      }
      label={
        <Typography fontSize="0.85rem">
          {'Content '}
          <Typography fontSize="inherit" component="span" fontWeight="bold">
            {complete ? 'complete' : 'incomplete'}
          </Typography>
        </Typography>
      }
    />
  );
};
