import { useAppToast } from 'contexts/app-toast-context';
import { useCallback } from 'react';

import { StaleWriteError } from '../errors';

export const useCatchStaleWriteError = () => {
  const dispatchToast = useAppToast();

  const catchStaleWrite = useCallback(
    (boldedMessage: string, unboldedMessage: string = 'Please try again') => (
      e: Error
    ) => {
      if (e instanceof StaleWriteError) {
        dispatchToast({
          message: (
            <>
              <b>{`${boldedMessage}. `}</b>
              {`${unboldedMessage}.`}
            </>
          ),
          level: 'error',
        });
        return false;
      }
      // otherwise, rethrow the error so someone else can deal with it
      throw e;
    },
    [dispatchToast]
  );

  return { catchStaleWrite };
};
