import { styled, Box } from '@mui/material';

const StyledCategoryContainer = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  border-radius: 6px;
  background-color: var(--color-neutral-two);
  min-height: 3.375rem;
  cursor: pointer;

  transition: box-shadow 0.1s ease-in-out;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows[3]};
  }

  &.selected {
    background-color: var(--color-primary-background);

    p {
      font-weight: bold;
      color: var(--color-primary-base);
    }
  }
`;

export default StyledCategoryContainer;
