import { FCWithChildren } from 'types/fc-with-children';

type Props = {
  isActive: boolean;
  className?: string;
};

const TabPanel: FCWithChildren<Props> = ({ isActive, className, children }) => (
  <div role="tabpanel" hidden={!isActive} className={className}>
    {isActive && children}
  </div>
);

export default TabPanel;
