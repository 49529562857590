import { FC } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type Props = {
  filename: string;
  details: JSX.Element | string;
  hasPreviousImage: boolean;
  clearError?: () => void;
};

type StyleProps = Pick<Props, 'hasPreviousImage'>;

const StyledImageUploadErrorContainer = styled.div<StyleProps>`
  position: absolute;
  inset: ${(p) => (p.hasPreviousImage ? 0 : '2px')};
  background-color: ${(p) =>
    p.hasPreviousImage ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 1)'};
  // get above the indicators
  z-index: 5;

  color: var(--color-messaging-error);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 24px;

  & > svg {
    margin-bottom: 6px;
  }

  & > .filename {
    font-weight: bold;
    max-width: 100%;
    word-wrap: anywhere;
  }

  & > .MuiButtonBase-root {
    margin-top: 6px;
    text-decoration: underline;

    &:hover:not(:disabled):not([disabled]) {
      text-decoration: underline;
      background-color: transparent;
      box-shadow: none !important;
    }
  }
`;

const ImageUploadError: FC<Props> = ({
  filename,
  details,
  hasPreviousImage,
  clearError,
}) => (
  <StyledImageUploadErrorContainer hasPreviousImage={hasPreviousImage}>
    <ErrorOutlineIcon />
    <div className="filename">{filename}</div>
    <div>{details}</div>
    {clearError && (
      <Button size="small" onClick={clearError}>
        {'Close'}
      </Button>
    )}
  </StyledImageUploadErrorContainer>
);

export default ImageUploadError;
