import { number, string } from 'services/utils/prop-types';

type Props = {
  innerRadius: number;
  strokeWidth: number;
  type: string;
  direction?: string;
};

const SelectedIndicatorCircles: React.FunctionComponent<Props> = ({
  innerRadius,
  strokeWidth,
  type,
  direction,
}) => {
  const pinchIndicators =
    direction === 'in' ? (
      <>
        {/* Top Right circle */}
        <circle cx="120" cy="30" r={innerRadius} />
        <circle
          className="middleRing"
          cx="120"
          cy="30"
          r={innerRadius + strokeWidth}
        />
        <circle
          className="outerRing"
          cx="120"
          cy="30"
          r={innerRadius + 1.5 * strokeWidth}
        />
        {/* Bottom left circle */}
        <circle cx="30" cy="120" r={innerRadius} />
        <circle
          className="middleRing"
          cx="30"
          cy="120"
          r={innerRadius + strokeWidth}
        />
        <circle
          className="outerRing"
          cx="30"
          cy="120"
          r={innerRadius + 1.5 * strokeWidth}
        />
      </>
    ) : (
      <>
        {/* Top Right circle */}
        <circle cx="110" cy="40" r={innerRadius} />
        <circle
          className="middleRing"
          cx="110"
          cy="40"
          r={innerRadius + strokeWidth}
        />
        <circle
          className="outerRing"
          cx="110"
          cy="40"
          r={innerRadius + 1.5 * strokeWidth}
        />
        {/* Bottom left circle */}
        <circle cx="40" cy="110" r={innerRadius} />
        <circle
          className="middleRing"
          cx="40"
          cy="110"
          r={innerRadius + strokeWidth}
        />
        <circle
          className="outerRing"
          cx="40"
          cy="110"
          r={innerRadius + 1.5 * strokeWidth}
        />
      </>
    );

  const indicatorCircles =
    type === 'pinch' ? (
      pinchIndicators
    ) : (
      <>
        <circle cx="50" cy="50" r={innerRadius} />
        <circle
          className="middleRing"
          cx="50"
          cy="50"
          r={innerRadius + strokeWidth}
        />
        <circle
          className="outerRing"
          cx="50"
          cy="50"
          r={innerRadius + 1.5 * strokeWidth}
        />
      </>
    );

  return indicatorCircles;
};

SelectedIndicatorCircles.propTypes = {
  innerRadius: number.isRequired,
  strokeWidth: number.isRequired,
  type: string.isRequired,
  direction: string,
};

export default SelectedIndicatorCircles;
