import { useState } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { useActionModal } from 'components/modals';
import { useAppToast } from 'contexts/app-toast-context';
import getPlural from 'services/utils/get-plural';
import { generateQueryKey } from 'services/ozmo-api/use-query-cache';
import { isPermissionDeniedError } from 'services/utils/type-guards/is-permission-denied-error';

import ModalContent from './add-languages-modal-content';

const useAddLanguagesToContentEntry = (
  contentEntry: ContentEntryModel,
  copyContent: boolean = false
) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();

  const { all: languages } = api.Language.getAll();

  const [selectedLanguageIds, setSelectedLanguageIds] = useState<number[]>([]);

  // The languages for which there is already a localized version
  const initialLanguageIds = contentEntry?.localizedContentEntries.map(
    (lce) => lce.languageId
  );

  // The formatted options array the multiselect needs
  const languageOptions = languages.map((l) => ({
    value: l.id,
    label: `${l.displayName} (${l.shortCode})`,
    chipLabel: `${l.displayName} (${l.shortCode})`,
    disabled: initialLanguageIds?.includes(l.id),
  }));

  // The languages selected from the multiselect for which there is NOT already a localized version
  const newLanguageIds = selectedLanguageIds.filter(
    (id) => !initialLanguageIds.includes(id)
  );

  const onSelectionChanged = (languageIds: number[]) => {
    setSelectedLanguageIds(languageIds);
  };

  const resetSelection = () => setSelectedLanguageIds([]);

  const createLocalizedContentEntries = async () => {
    // Use the properties from the first localized content entry
    // when creating the new ones, if copyContent is true
    const [{ properties }] = contentEntry.localizedContentEntries;
    try {
      await Promise.all(
        newLanguageIds.map((languageId) => {
          return api.LocalizedContentEntry.createAsync(
            {
              contentEntryId: contentEntry.id,
              properties: copyContent ? properties : undefined,
              languageId,
            },
            { contentEntryId: contentEntry.id }
          );
        })
      );

      // Invalidate the content entry query so the list of languages in the dropdown will update
      api.queryClient.invalidateQueries(
        generateQueryKey(contentEntry.id, 'authoring/content_entries')
      );

      const message = getPlural(
        newLanguageIds.length,
        `Success! ${newLanguageIds.length} language was added. You will need to update the content in this language.`,
        `Success! ${newLanguageIds.length} languages were added. You will need to update the content in these languages.`
      );

      dispatchToast({
        level: 'success',
        message,
      });
    } catch (e) {
      // A toast will be popped from use-query-cache.tsx in this case
      if (isPermissionDeniedError(e)) {
        return;
      }
      dispatchToast({
        level: 'error',
        message:
          "We're sorry. We couldn't create these localized versions. Please try adding your languages again.",
      });
    } finally {
      resetSelection();
    }
  };

  const { modal, openModal } = useActionModal({
    modalContent: (
      <ModalContent
        languages={languageOptions}
        onSelectionChanged={onSelectionChanged}
      />
    ),
    onConfirm: createLocalizedContentEntries,
    onRefuse: resetSelection,
    disableConfirmButtonOnClick: true,
    modalProps: {
      title: 'You are about to add languages',
      maxWidth: 'lg',
      confirmButtonText: `Add ${
        newLanguageIds.length ? newLanguageIds.length : ''
      } additional ${getPlural(
        newLanguageIds.length,
        'language',
        'languages'
      )}`,
      disableConfirmButton:
        selectedLanguageIds.length ===
          contentEntry?.localizedContentEntries.length ||
        selectedLanguageIds.length === 0,
    },
  });

  return { modal, openModal };
};

export default useAddLanguagesToContentEntry;
