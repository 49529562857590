import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';
interface ResourcePathVariables {
  clientId: number;
  id?: number;
}
class ClientLocale extends OzmoApiBase<
  ClientLocaleModel,
  void,
  void,
  ResourcePathVariables
>() {
  protected static resourcePath = 'config/clients/:clientId/locales/:id';
  protected static embedOptions = [];
  protected static defaultReactQueryConfig = {
    staleTime: 300000, // 5 minutes
  };

  // Make this model read only- don't allow mutating or creating anything on the server
  // This will disable the update/create/delete methods
  protected static readonly = true;

  get localeChain(): string[] {
    if (!this.data?.localeChain) {
      return [];
    }
    return this.data.localeChain.split(',');
  }
}

export default ClientLocale;
