import { useCallback, useMemo, MouseEventHandler } from 'react';
import { useAppToast } from 'contexts/app-toast-context';
import usePreviousPage from 'services/utils/use-previous-page';
import { ContentTypes } from 'types/enums';
import { generateCollectionPath } from 'scenes/nuevo-collection/util';
import { useLocation } from 'react-router-dom';

type ReturnType = [MouseEventHandler<HTMLButtonElement>, string];

export const pathToken = (linkTargetType?: string) => {
  switch (linkTargetType?.toLocaleLowerCase()) {
    case ContentTypes.COLLECTION.toLocaleLowerCase():
      return window.ozmoAuthoringFlags?.useCollections2
        ? 'legacy-collection'
        : 'collection';
    default:
      return 'edit';
  }
};

export const generateRootLink = (
  linkTargetType: string | undefined,
  targetId: number,
  language?: string
) => {
  const { origin } = window.location;
  const lang = language ? `/${language}` : '';

  if (linkTargetType === 'collection2') {
    // no splitting / here because generateCollectionPath includes it
    return `${origin}${generateCollectionPath(targetId)}${lang}`;
  }

  return `${origin}/${pathToken(linkTargetType)}/${targetId}${lang}`;
};

export const generateTooltipText = (
  linkTargetType?: string,
  stepNumber?: number
) => {
  let linkType = 'answer';
  if (stepNumber) linkType = 'step';
  if (
    linkTargetType?.toLocaleLowerCase() ===
      ContentTypes.COLLECTION.toLocaleLowerCase() ||
    linkTargetType?.toLocaleLowerCase() === 'collection2'
  ) {
    linkType = 'collection';
  }
  return `Copy link to ${linkType}`;
};

export const useCopyLink = (
  linkTargetType: string | undefined,
  targetId: number,
  stepNumber?: number,
  includePreviousPage = false,
  language?: string
): ReturnType => {
  const dispatchToast = useAppToast();
  const { generateUrlWithPreviousPage } = usePreviousPage();
  const { search } = useLocation();
  const [link, tooltipText] = useMemo(() => {
    const rootLink = generateRootLink(linkTargetType, targetId, language!);
    const rootLinkWithSearch = `${rootLink}${search}`;
    const stepHash = `step-${stepNumber}`;
    const link = includePreviousPage
      ? generateUrlWithPreviousPage(
          rootLinkWithSearch,
          undefined,
          undefined,
          stepNumber ? stepHash : undefined
        )
      : `${rootLinkWithSearch}${stepNumber ? `#${stepHash}` : ''}`;

    const text = generateTooltipText(linkTargetType, stepNumber);

    return [link, text];
  }, [
    linkTargetType,
    targetId,
    language,
    search,
    stepNumber,
    includePreviousPage,
    generateUrlWithPreviousPage,
  ]);

  const onClick = useCallback(() => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        dispatchToast({
          level: 'success',
          message: 'Link copied to clipboard',
        });
      })
      .catch(() => {
        dispatchToast({
          level: 'error',
          message: 'Unable to copy link to clipboard',
        });
      });
  }, [link, dispatchToast]);

  return [onClick, tooltipText];
};
