import { useLocation } from 'react-router-dom';
import { extractQueryAttributes } from 'components/attribute-selector';

export const useCollectionFilters = () => {
  const location = useLocation();
  const startingAttributes = extractQueryAttributes(location);

  return {
    startingAttributes,
  };
};
