import { Skeleton } from '@mui/material';
import { FunctionComponent } from 'react';
import { ReferenceWithCategoryId } from 'scenes/nuevo-collection/hooks/use-content-tab';

import Category, { useCurrentCategory } from '../category';
import useAnswerFiltering from '../category/use-answer-filter/use-answer-filter';

import AllAnswers from './all-answers';
import NoAnswers from './no-answers';

type Props = {
  onInfoClick: (id: number) => void;
  selectedReferences: ReferenceWithCategoryId[];
  onSelectReference: (id: number, catId: number) => void;
};

const AnswersList: FunctionComponent<Props> = ({
  onInfoClick,
  selectedReferences,
  onSelectReference,
}) => {
  const {
    attributeIds,
    category,
    collectionId,
    collectionName,
    isLoading,
    categoryId,
    languageId,
    topicGroupedReferences,
    uniqueReferences,
  } = useCurrentCategory();
  const { filterCount } = useAnswerFiltering();

  if (isLoading || !collectionId) {
    return (
      <Skeleton
        data-testid="answers-list:loading"
        variant="rectangular"
        height="100px"
        width="100%"
      />
    );
  }

  if (categoryId === undefined) {
    return (
      <AllAnswers
        key={languageId}
        attributeIds={attributeIds}
        collectionId={collectionId}
        collectionName={collectionName!}
        languageId={languageId}
        onInfoClick={onInfoClick}
        onSelectReference={onSelectReference}
        selectedReferences={selectedReferences}
      />
    );
  }

  if (!category) {
    return <>{'Invalid category'}</>;
  }

  if (category.contentEntries.length === 0 && filterCount > 0) {
    return <NoAnswers />;
  }

  return (
    <Category
      key={category.updatedAt}
      id={category.id}
      name={category.name}
      topicGroupedReferences={topicGroupedReferences}
      uniqueReferences={uniqueReferences}
      attributeIds={attributeIds}
      collectionId={collectionId}
      collectionName={collectionName!}
      languageId={languageId}
      onInfoClick={onInfoClick}
      onSelectReference={onSelectReference}
      selectedReferences={selectedReferences}
    />
  );
};

export default AnswersList;
