import { FunctionComponent, useCallback } from 'react';
import SplitButton from 'components/split-button';
import { SvgIcon } from '@mui/material';

const INDICATOR_OPTIONS = ['Tap', 'Pinch In', 'Pinch Out', 'Moving', 'Hotspot'];

type Props = {
  onAdd?: (indicator: Indicator) => void;
  disabled?: boolean;
  defaultValue?: Indicator;
};

const AddIndicatorButton: FunctionComponent<Props> = ({
  onAdd,
  defaultValue,
  disabled,
}) => {
  const indicatorIcon = (
    <SvgIcon fontSize="large" viewBox="0 0 20 20">
      <svg height="20" width="20" viewBox="0 0 20 20">
        <defs>
          <mask id="hole">
            <circle cx="10" cy="10" r="10" fill="white" />
            <circle cx="10" cy="10" r="4" fill="black" />
          </mask>
        </defs>
        <circle
          cx="10"
          cy="10"
          r="9"
          color="var(--color-neutral-four)"
          mask="url(#hole)"
        />
        <circle
          cx="10"
          cy="10"
          r="6"
          color="var(--color-neutral-seven)"
          mask="url(#hole)"
        />
      </svg>
    </SvgIcon>
  );

  const handleAddIndicator = useCallback(
    (selectedIndex: number) => {
      if (selectedIndex === 0 && defaultValue) {
        return onAdd?.(defaultValue);
      }
      const selectedIndicatorType = INDICATOR_OPTIONS[selectedIndex];
      const newIndicator: Indicator = {
        id: selectedIndicatorType.toUpperCase(),
        type: selectedIndicatorType.toUpperCase(),
        x: 50,
        y: 50,
      };

      if (selectedIndicatorType.includes('Pinch')) {
        newIndicator.id = selectedIndicatorType.toUpperCase().replace(' ', '_');
        newIndicator.type = selectedIndicatorType
          .toUpperCase()
          .replace(' ', '_');
        newIndicator.length = 30;
        newIndicator.spread = 30;
      }

      if (selectedIndicatorType === 'Moving') {
        newIndicator.id = 'WAYPOINT';
        newIndicator.type = 'WAYPOINT';
        newIndicator.y = 85;
        newIndicator.waypoints = [
          {
            x: 50,
            y: 85,
            duration: 1000,
          },
          {
            x: 50,
            y: 15,
            duration: 500,
          },
        ];
      }

      onAdd?.(newIndicator);
    },
    [defaultValue, onAdd]
  );

  return (
    <SplitButton
      options={INDICATOR_OPTIONS}
      color="neutralSix"
      onClick={handleAddIndicator}
      text={'Add Indicator'}
      icon={indicatorIcon}
      buttonTooltip={'Add default indicator'}
      arrowTooltip={'Indicator type'}
      isDisabled={disabled}
    />
  );
};

export default AddIndicatorButton;
