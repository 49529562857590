const fuzzyMatch = (source: string, test: string) =>
  source
    .toLowerCase()
    .replace(/-/g, ' ')
    .includes(test.toLowerCase().replace(/-/g, ' '));

const filterOperatingSystemVersions = (
  versions: OperatingSystemVersionModel[],
  filterText: string
) =>
  versions.filter(
    (version) =>
      fuzzyMatch(version.name, filterText) ||
      fuzzyMatch(version.operatingSystem, filterText) ||
      fuzzyMatch(version.operatingSystemRelease, filterText)
  );

const filterDevices = (devices: DeviceModel[], filterText: string) =>
  devices.filter(
    (device) =>
      fuzzyMatch(device.trackingName, filterText) ||
      fuzzyMatch(device.manufacturer, filterText)
  );

export { filterOperatingSystemVersions, filterDevices };
