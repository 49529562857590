import { ChangeEvent, ReactNode } from 'react';
import { FCWithChildren } from 'types/fc-with-children';
import { Checkbox, Box, styled, css, Divider } from '@mui/material';
import ContentTypeWithIcon from 'components/content-type-with-icon';
import { HiddenUnder } from 'components/hidden';
import DragHandle from 'components/drag-handle';
import { CompletenessFlag } from 'components/completeness-indicators';
import { PermissionRequired } from 'components/permission-required';
import { useOzmoApiService } from 'contexts/ozmo-api-service-context';

const StyledBoxWithChildPadding = styled(Box)`
  // Padding on the middle children, but not the first or last
  & > div:not(:first-child):not(:last-child) {
    padding: 0 8px;
  }
`;

const StyledPermissionRequired = styled(PermissionRequired)`
  margin-right: ${({ theme }) => theme.spacing(1)} !important;
  display: flex;
  align-items: center;
`;

const StyledBox = styled(Box)<{ $asCard: boolean }>`
  background-color: var(--color-neutral-one);
  ${({ $asCard }) =>
    $asCard &&
    (css`
      transition: box-shadow, height 0.1s ease-in-out;
      &:hover {
        box-shadow: ${({ theme }) => theme.shadows[4]};
      }
    ` as any)};
`;

const StyledCompletenessFlag = styled(CompletenessFlag)`
  border-top-right-radius: 6px;

  :hover {
    cursor: default;
  }
`;

export const StyledChildrenWithSpaceBox = styled(Box)`
  // Margin for divs, p, etc
  & > *:not(svg) {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
  // SVGs are special because they have some built-in spacing
  & > svg {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const StyledHiddenIfCategoriesOpen = styled(HiddenUnder)`
  overflow: hidden;
  /* For screen sizes smaller than large */
  ${({ theme }) => theme.breakpoints.down('lg')} {
    /* If any parent has the class "categories-panel-open" apply to this component */
    .categories-panel-open & {
      display: none !important;
    }
  }
`;

const StyledContentTypeWithIcon = styled(ContentTypeWithIcon)`
  #content-type-name {
    min-width: 3.5rem;
  }
  /* For screen sizes smaller than medium */
  ${({ theme }) => theme.breakpoints.down('md')} {
    /* Hide the content type name if MD or smaller */
    & > #content-type-name {
      display: none !important;
    }
  }
  /* If the screen size is smaller than extra large */
  ${({ theme }) => theme.breakpoints.down('xl')} {
    /* Also hide the name if the categories panel is open */
    .categories-panel-open & > #content-type-name {
      display: none;
    }
  }
`;

export type Props = {
  className?: string;
  contentType: string;
  actions?: ReactNode[] | ReactNode;
  asCard?: boolean;
  onSelectChange: (e: ChangeEvent<HTMLInputElement>) => void;
  selected: boolean;
  variants?: ReactNode[];
  isIndeterminateSelection?: boolean;
  flagIncomplete?: boolean;
};

const Reference: FCWithChildren<Props> = ({
  className,
  actions,
  children,
  contentType,
  selected,
  onSelectChange,
  variants,
  asCard = true,
  isIndeterminateSelection = false,
  flagIncomplete = false,
}) => {
  const api = useOzmoApiService();

  const { getByName: getContentTypeByName } = api.ContentType.getAll();
  const contentTypeData = getContentTypeByName(contentType);
  const contentTypeLabel = contentTypeData?.displayName;

  return (
    <StyledBox
      $asCard={asCard}
      zIndex={2}
      position="relative"
      borderRadius="6px"
      boxShadow={asCard ? 1 : 0}
      className={className}
      data-testid="base-reference-card"
    >
      {flagIncomplete && <StyledCompletenessFlag />}
      <StyledBoxWithChildPadding
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
      >
        <StyledChildrenWithSpaceBox display="flex" alignItems="center">
          {asCard && (
            <StyledPermissionRequired
              permission="edit_collection"
              disableElement
            >
              <DragHandle />
            </StyledPermissionRequired>
          )}
          <PermissionRequired permission="edit_collection" disableElement>
            <Checkbox
              checked={selected}
              indeterminate={isIndeterminateSelection}
              onChange={onSelectChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </PermissionRequired>
          <StyledContentTypeWithIcon
            contentType={contentType}
            label={contentTypeLabel}
          />
        </StyledChildrenWithSpaceBox>
        {children}
        <Box>{actions}</Box>
      </StyledBoxWithChildPadding>
      {variants && (
        <Box paddingLeft={4}>
          <Divider />
          {variants.map((v, index) => (
            <>
              {v}
              {index < variants.length - 1 && <Divider />}
            </>
          ))}
        </Box>
      )}
    </StyledBox>
  );
};

export default Reference;
