import { FC, useMemo } from 'react';
import { Typography, Button, ButtonProps } from '@mui/material';

import BaseModal, { Props as BaseModalProps } from './base';

export type Props = Omit<
  BaseModalProps,
  'actionChildren' | 'confirmInProgressText'
> & {
  message?: string;
  hideCloseButton?: boolean;
  closeButtonText?: string;
  closeButtonColor?: ButtonProps['color'];
};

export const InfoModal: FC<Props> = (props) => {
  const {
    onClose,
    closeButtonColor = 'primary',
    closeButtonText = 'Close',
    hideCloseButton,
    message,
    children,
    ...baseProps
  } = props;
  const actionButton = useMemo(() => {
    if (hideCloseButton) {
      return null;
    }
    return (
      <Button onClick={onClose} color={closeButtonColor} variant="contained">
        {closeButtonText}
      </Button>
    );
  }, [closeButtonColor, closeButtonText, hideCloseButton, onClose]);
  return (
    <BaseModal actionChildren={actionButton} onClose={onClose} {...baseProps}>
      {message ? <Typography>{message}</Typography> : children}
    </BaseModal>
  );
};
