import { Operation } from '../use-attribute-editor';

export const isPending = (
  action: 'add' | 'remove',
  attributeName: string,
  attribute: any,
  pendingOps: Operation[] = []
) => {
  return pendingOps.some(
    (operation) =>
      operation.op === action &&
      operation.type === attributeName &&
      operation.attribute.id === attribute.id
  );
};

export const getPendingState = (
  attributeName: string,
  attribute: any,
  pendingOps: Operation[] = []
) => {
  const isPendingAdd = isPending('add', attributeName, attribute, pendingOps);
  const isPendingRemove = isPending(
    'remove',
    attributeName,
    attribute,
    pendingOps
  );

  return { isPendingAdd, isPendingRemove };
};

export const shouldBeChecked = (
  isSelected: boolean,
  isPendingRemove: boolean,
  isPendingAdd: boolean
) => {
  return (isSelected || isPendingAdd) && !isPendingRemove;
};
