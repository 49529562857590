import { useCallback, SyntheticEvent } from 'react';
import { SelectChangeEvent } from '@mui/material';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { useTopicDispatchContext } from 'contexts/topic-context';
import { PUSH_TO_UNDO_STACK } from 'contexts/topic-context/topic-actions';
import { useContributors } from 'services/hooks/api-hooks';
import { useCatchStaleWriteError } from 'services/ozmo-api/utils/use-catch-stale-write-error';
import { generateQueryKey } from 'services/ozmo-api/use-query-cache';

type HookArgs = {
  contentEntryId: number;
};

export const useDetailsPage = ({ contentEntryId }: HookArgs) => {
  const dispatchTopic = useTopicDispatchContext();
  const { catchStaleWrite } = useCatchStaleWriteError();
  const api = useOzmoApiService();
  const {
    data: contentEntry,
    isLoading,
    isUpdating: contentEntryUpdating,
    update: updateContentEntry,
  } = api.ContentEntry.get({
    id: contentEntryId,
  });

  const {
    data: contentType,
    isLoading: contentTypeLoading,
  } = api.ContentType.get({ id: contentEntry?.contentTypeId });
  const contentTypeLabel = contentType?.displayName;

  const contentEntryLoading = isLoading || contentTypeLoading;

  const { contributors } = useContributors(contentEntryId);

  const handleAudienceSelect = useCallback(
    (event: SelectChangeEvent<string>) => {
      const audience = event.target.value;
      updateContentEntry({ audience });
      dispatchTopic({
        type: PUSH_TO_UNDO_STACK,
        present: {
          contentEntry,
        },
      });
    },
    [contentEntry, dispatchTopic, updateContentEntry]
  );

  const handleProficiencySelect = useCallback(
    (event: SelectChangeEvent<string>) => {
      const proficiencyLevel = event.target.value;
      updateContentEntry({ proficiencyLevel });
      dispatchTopic({
        type: PUSH_TO_UNDO_STACK,
        present: {
          contentEntry,
        },
      });
    },
    [contentEntry, dispatchTopic, updateContentEntry]
  );

  const handleTopicSelect = useCallback(
    (_: SyntheticEvent<Element>, topic: TopicModel | null) => {
      if (topic) {
        updateContentEntry({ topicId: topic.id })
          .then(() => {
            // Invalidate the content entry query so the topic will update in the header
            api.queryClient.invalidateQueries(
              generateQueryKey(contentEntry.id, 'authoring/content_entries')
            );
          })
          .catch(catchStaleWrite('We were unable to update this answer'));
      }
    },
    [catchStaleWrite, updateContentEntry, api.queryClient, contentEntry?.id]
  );

  return {
    contentEntryLoading,
    contentEntryUpdating,
    contentEntry,
    contentTypeLabel,
    contributors,
    handleAudienceSelect,
    handleTopicSelect,
    handleProficiencySelect,
  };
};
