import { useCallback, useState, useEffect } from 'react';
import keysToSnake from 'services/utils/convert-object-keys-camel-to-snake';
import { useAppToast } from 'contexts/app-toast-context';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import getPlural from 'services/utils/get-plural';

import { downloadFile } from './translation-export-utils';

export const useTranslationExport = (
  collectionId: number,
  language: string
) => {
  const api = useOzmoApiService();
  const { isLoading, data: collection } = api.ContentEntry.get({
    id: collectionId,
  });
  const { getFromLanguage: getLocaleFromLanguage } = api.Locale.getAll();
  const { getAllReferences } = api.LocalizedCollection;
  const { title } = collection ?? {};
  const dispatchToast = useAppToast();
  const [targets, setTargets] = useState<number[]>([]);
  const [source, setSource] = useState(language);
  const [shouldTranslateMenu, setShouldTranslateMenu] = useState(false);

  // Source is fed from a prop and can change after instantiation if the user
  // selects a new language from the dropdown, so reset if it changes
  useEffect(() => setSource(language), [language]);

  const onExportRefuse = useCallback(() => {
    setTargets([]);
    setShouldTranslateMenu(false);
  }, []);
  const handleChangeSource = (e: any) => {
    const {
      target: { value },
    } = e;
    setSource(value);
  };
  const handleSelectTarget = (languageIds: number[]) => {
    setTargets(languageIds);
  };

  const handleChangeMenuTranslate = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShouldTranslateMenu(e.target.checked);
  };

  const languages = collection?.localizedContentEntries?.map(
    ({ languageId, languageShortCode, languageDisplayName }) => ({
      value: languageId,
      chipLabel: languageShortCode,
      label: `${languageDisplayName} (${languageShortCode})`,
    })
  );

  // If there are 0 targets, just read "Export files for translations",
  // otherwise do the plural for "Export x file(s) for translations"
  const exportButtonText = `Export ${
    targets.length === 0
      ? 'files'
      : `${targets.length} ${getPlural(targets.length, 'file', 'files')}`
  } for translation`;

  const handleExportSubmit = async () => {
    const localizedContentEntry = collection.localizedContentEntries.find(
      (lce) => lce.languageShortCode === source
    );

    if (!localizedContentEntry) {
      onExportRefuse();
      dispatchToast({
        level: 'error',
        message: 'The source language selected does not exist.',
      });
      return;
    }

    const uniqueContentEntryIds = getAllReferences(
      // Expects LocalizedCollection, but a LocalizedContentEntry will work too
      localizedContentEntry as any,
      true
    ).map((ref) => ref.id);

    const sourceLocaleId = getLocaleFromLanguage(
      localizedContentEntry.languageId
    )?.id;
    const targetLocaleIds = targets.map((t) => getLocaleFromLanguage(t)?.id);

    if (!sourceLocaleId || targetLocaleIds.some((t) => t === undefined)) {
      dispatchToast({
        level: 'error',
        message:
          'The source or target languages could not be mapped to locales',
      });
      return;
    }

    const idsToTranslate = shouldTranslateMenu
      ? [...uniqueContentEntryIds, collectionId]
      : uniqueContentEntryIds;

    try {
      const response = await api.ContentEntry.exportTranslationsAsync(
        idsToTranslate,
        sourceLocaleId,
        targetLocaleIds as number[] // TS can't seem to infer that we've already verified this array is only numbers above
      );

      const snakeyResponse = keysToSnake(response);
      Object.entries(snakeyResponse).forEach(([key, value]) => {
        downloadFile(key, value);
      });
    } catch (error) {
      dispatchToast({
        level: 'error',
        message:
          'An error occured while generating your translated files. Please try your export again.',
      });
    } finally {
      onExportRefuse();
    }
  };

  return {
    handleSelectTarget,
    handleExportSubmit,
    onExportRefuse,
    handleChangeSource,
    handleChangeMenuTranslate,
    languages,
    targets,
    source,
    exportButtonText,
    isLoading,
    title,
  };
};
