import { useCallback, useMemo } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import {
  getSuccessCount,
  recursiveJobRequest,
} from 'services/ozmo-api/utils/recursive-job-request';
import { assembleBulkOperation } from 'services/utils/assemble-bulk-operation';
import { useAppToast } from 'contexts/app-toast-context';
import getPlural from 'services/utils/get-plural';
import { ReferenceWithCategoryId } from 'scenes/nuevo-collection/hooks/use-content-tab';

export const useCategoryHeader = (
  categoryId: number,
  collectionId: number,
  existingReferences: number[],
  onSelectReference: (id: number, catId: number) => void,
  selectedReferences: ReferenceWithCategoryId[]
) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();

  const handleRemoveReferenceFromCategory = useCallback(
    (id: number) =>
      api.Collection.deleteReferenceFromCategoryAsync(
        collectionId,
        categoryId,
        id
      ),
    [api.Collection, categoryId, collectionId]
  );

  const handleAddReferenceToCategory = useCallback(
    (id: number) =>
      api.Collection.addReferenceToCategoryAsync(collectionId, categoryId, id),
    [api.Collection, categoryId, collectionId]
  );

  const handleAddReferencesToCategory = useCallback(
    async (contentEntryIds: number[]) => {
      const operations = [
        assembleBulkOperation(
          categoryId,
          'add',
          'content_entry_ids',
          contentEntryIds
        ),
      ];
      const { job } = await api.Collection.bulkAsync(collectionId, operations);
      const response = await api.Collection.refetchedOperationAsync(
        () => recursiveJobRequest<BulkOperationJobResponse>(job),
        collectionId
      );
      const successCount = getSuccessCount(response);

      if (successCount === operations.length) {
        dispatchToast({
          level: 'success',
          message: `${contentEntryIds.length} ${getPlural(
            contentEntryIds.length,
            'answer has',
            'answers have'
          )} been successfully added to your category.`,
        });
        return true;
      }
      dispatchToast({
        level: 'error',
        message: 'We were unable to add the answers to the collection',
      });
      console.error(response);
      return false;
    },
    [api.Collection, categoryId, collectionId, dispatchToast]
  );

  const handleSelectAllReferences = useCallback(() => {
    const alreadySelected = existingReferences.filter((refId) =>
      selectedReferences.some(
        (ref) => ref.id === refId && ref.categoryId === categoryId
      )
    );

    if (
      alreadySelected.length === existingReferences.length ||
      alreadySelected.length === 0
    ) {
      existingReferences.forEach((refId) =>
        onSelectReference(refId, categoryId)
      );
    } else {
      existingReferences.forEach((refId) => {
        const isSelected = selectedReferences.some(
          (ref) => ref.id === refId && ref.categoryId === categoryId
        );
        if (!isSelected) {
          onSelectReference(refId, categoryId);
        }
      });
    }
  }, [categoryId, existingReferences, onSelectReference, selectedReferences]);

  const categoryIsSelected = useMemo(() => {
    return (
      existingReferences?.every((refId) =>
        selectedReferences?.some(
          (ref) => ref.id === refId && ref.categoryId === categoryId
        )
      ) ?? false
    );
  }, [categoryId, existingReferences, selectedReferences]);

  return {
    handleAddReferenceToCategory,
    handleAddReferencesToCategory,
    handleRemoveReferenceFromCategory,
    handleSelectAllReferences,
    categoryIsSelected,
  };
};
