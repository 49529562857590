import { FC, memo } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import LoadingIndicator from 'components/loading-indicator';
import Error from 'components/error';
import { Box, Typography } from '@mui/material';
import { CompletenessChip } from 'components/completeness-indicators';

import ReadOnlyContent from './read-only-content';
import { useCurrentLocalizedContentEntry } from './hooks/use-current-localized-content-entry';
import SchemaComponent from './components';
import { useScrollToAnchor } from './hooks/use-scroll-to-anchor';

const UniversalContentEditor: FC = () => {
  const {
    localizedContentEntry,
    contentEntry,
    isLoading,
    isError,
    error,
  } = useCurrentLocalizedContentEntry();
  const api = useOzmoApiService();
  const contentType = api.ContentType.get({
    id: contentEntry?.contentTypeId,
  });

  const { onContentLoaded } = useScrollToAnchor();

  if (isLoading || contentType.isLoading) {
    return <LoadingIndicator message="Loading..." />;
  }
  if (isError || contentType.isError) {
    return (
      <Error>
        <Typography>{'Unable to load answer'}</Typography>
        <pre>{JSON.stringify(error) || JSON.stringify(contentType.error)}</pre>
      </Error>
    );
  }

  onContentLoaded();

  const { complete, properties } = localizedContentEntry;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      position="relative"
      margin={2}
      marginLeft={12}
      marginRight={12}
    >
      <Box
        component={CompletenessChip}
        complete={complete}
        boxShadow={3}
        position="fixed"
        bottom="1rem"
        right="1rem"
        zIndex={1}
      />
      <ReadOnlyContent />
      <SchemaComponent
        schema={contentType.data.contentSchema as ContentTypeObjectField}
        value={properties}
        type="Object"
        name=""
        description=""
        displayName=""
        displayOrder={0}
        path=""
        level={0}
      />
    </Box>
  );
};

export default memo(UniversalContentEditor, () => true);
