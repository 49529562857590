import { FC } from 'react';
import ExpansionPanel from 'components/expansion-panel';

import { useReferencedCollectionsList } from './use-referenced-collections-list';

type Props = {
  contentEntryId: number;
  collapsedHeight?: string;
};

const ReferencedCollectionsList: FC<Props> = ({
  contentEntryId,
  collapsedHeight,
}) => {
  const { referencedCollections, links } = useReferencedCollectionsList(
    contentEntryId
  );
  return (
    <>
      {referencedCollections?.length > 5 ? (
        <ExpansionPanel
          expandText="Show More"
          collapseText="Show Less"
          collapsedHeight={collapsedHeight}
          startExpanded={false}
        >
          {links}
        </ExpansionPanel>
      ) : (
        <>{links}</>
      )}
    </>
  );
};

export default ReferencedCollectionsList;
