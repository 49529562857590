import { FC } from 'react';
import { Box } from '@mui/material';
import VerticalReorderableList, {
  DropResult,
} from 'components/vertical-reorderable-list';
import { usePermissions } from 'components/permission-required';

import Category, { AllAnswers } from '../category';

import { useCategories } from './hooks';

type Props = {
  localizedCollection: LocalizedCollectionModel;
  selectedCategoryIndex: number;
  onReorderCategory: (result: DropResult) => void;
  onRemoveCategory: (atIndex: number) => Promise<boolean>;
  onEditCategory: (
    category: Omit<LocalizedCollectionCategory, 'items'>,
    atIndex: number
  ) => Promise<boolean>;
};

const CategoriesList: FC<Props> = ({
  localizedCollection,
  selectedCategoryIndex,
  onReorderCategory,
  onRemoveCategory,
  onEditCategory,
}) => {
  const {
    categories,
    answerCount,
    collectionContainsIncompleteContent,
    handleSelectCategory,
    handleSelectAllAnswers,
    handleRemoveCategory,
    handleEditCategory,
  } = useCategories(
    localizedCollection,
    selectedCategoryIndex,
    onRemoveCategory,
    onEditCategory
  );
  const { checkPermission } = usePermissions();

  return (
    <Box
      overflow="auto"
      flex="1"
      padding={2}
      paddingLeft={4}
      paddingTop={0}
      marginTop={2}
    >
      <AllAnswers
        answerCount={answerCount}
        isSelected={selectedCategoryIndex === -1}
        flagIncomplete={collectionContainsIncompleteContent}
        onClick={handleSelectAllAnswers}
      />
      <VerticalReorderableList
        listId="categories-drag-drop-list"
        onDragEnd={onReorderCategory}
        itemCursor="pointer"
        draggableProps={{ isDragDisabled: !checkPermission('edit_collection') }}
        items={categories.map((category, index) => ({
          id: category.title,
          component: (
            <Category
              key={category.title}
              category={category}
              collectionTitle={localizedCollection?.properties?.title}
              isSelected={selectedCategoryIndex === index}
              onSelect={() => handleSelectCategory(index)}
              onRemove={() => handleRemoveCategory(index)}
              onEdit={handleEditCategory(index)}
            />
          ),
        }))}
      />
    </Box>
  );
};

export default CategoriesList;
