import { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import { Checkbox, Typography, IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { limitedStringList } from 'services/utils/limited-string-list';
import { ContentTypes } from 'types/enums';
import StatusIndicator from 'components/status-indicator';
import ContentType from 'components/content-type-with-icon';
import TagCloud from 'components/tag-cloud';
import { CompletenessFlag } from 'components/completeness-indicators';
import LinkBox from 'components/link-box';
import usePreviousPage from 'services/utils/use-previous-page';
import {
  ContentTableCell,
  ContentTableRow,
} from 'components/content-table-row';
import { useFlag } from 'services/flags';
import { useEventTracking } from 'services/analytics';
import { useOzmoApiService } from 'contexts/ozmo-api-service-context';

import RowActions from './row-actions';

type Props = {
  data: ContentTableData;
  isSelected: boolean;
  enableContentActions?: boolean;
  openEditInNewTab?: boolean;
  handleSelectItem: (itemId: number) => void;
  handleInfoClick: (itemId: number) => void;
  handleSetItemId: (itemId: number) => void;
  openDuplicateModel: () => void;
};

const StyledTypographyFeatureId = styled(Typography)`
  word-break: break-word;
  color: var(--color-neutral-six);
`;

const StyledCenterStatus = styled.div`
  display: flex;
  justify-content: center;
  // offsets the icons some to better align with the column header.
  // the default centering takes the "hidden" sort icon
  // into account, which we don't want
  margin-right: 1.25rem;
`;

const StyledCenterAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const ContentEntryTableRow: FC<Props> = ({
  data,
  isSelected,
  enableContentActions,
  openEditInNewTab,
  handleSelectItem,
  handleInfoClick,
  handleSetItemId,
  openDuplicateModel,
}) => {
  const {
    id,
    contentType,
    title,
    complete,
    updatedAt,
    space,
    languages,
    attributes,
    status,
    topic,
  } = data;

  const api = useOzmoApiService();

  const { getByName: getContentTypeByName } = api.ContentType.getAll();
  const contentTypeData = getContentTypeByName(contentType);
  const contentTypeLabel = contentTypeData?.displayName;

  const useCollections2 = useFlag('useCollections2');

  const stableHandleSelect = useCallback(() => handleSelectItem(data.id), [
    data,
    handleSelectItem,
  ]);

  const stableHandleInfoClick = useCallback(() => handleInfoClick(data.id), [
    data,
    handleInfoClick,
  ]);

  const isCollectionType = contentType === ContentTypes.COLLECTION;

  // Collections do not have a completeness check, and complete defaults to "false".
  // So ignore Collections when flagging content.
  const flagIncomplete = !isCollectionType && !complete;

  const { generateUrlWithPreviousPage } = usePreviousPage();
  const trackEvent = useEventTracking();

  const getCollectionPath = () =>
    `${useCollections2 ? 'legacy-' : ''}collection`;
  const contentTitleLink = generateUrlWithPreviousPage(
    `/${isCollectionType ? getCollectionPath() : 'edit'}/${data.id}`
  );

  return (
    <ContentTableRow
      $rowId={id}
      data-testid="content-table-row"
      selected={isSelected}
      hover
      tabIndex={-1}
      aria-checked={isSelected}
      className={`content-entry-${id}`}
    >
      {/* checkbox */}
      <ContentTableCell padding="checkbox">
        <Checkbox
          data-testid="content-table-row-checkbox"
          color="primary"
          checked={isSelected}
          onChange={stableHandleSelect}
        />
      </ContentTableCell>
      {/* type */}
      <ContentTableCell align="left">
        {<ContentType contentType={contentType} label={contentTypeLabel} />}
      </ContentTableCell>
      {/* title */}
      <ContentTableCell align="left">
        <LinkBox
          url={contentTitleLink}
          onClick={() => {
            trackEvent('search-result-selected', { id, type: 'answer' });
          }}
          // @ts-ignore it appears since the Box is wrapped within a styled component, it can't infer
          // that is has access to the "to" prop, but this does work.
          target={openEditInNewTab && '_blank'}
        >
          <Typography fontWeight="bold">
            {title}
            <Typography
              color="var(--color-neutral-six)"
              fontSize="0.81rem"
              paddingLeft="3px"
              component="span"
            >{`(#${id})`}</Typography>
          </Typography>
          <StyledTypographyFeatureId>{topic || ''}</StyledTypographyFeatureId>
        </LinkBox>
      </ContentTableCell>
      {/* last updated */}
      <ContentTableCell align="left">
        {new Date(updatedAt).toLocaleDateString()}
      </ContentTableCell>
      {/* space */}
      <ContentTableCell align="left">{space}</ContentTableCell>
      {/* languages */}
      <ContentTableCell align="left">
        {limitedStringList(
          languages.map((l) => l.shortCode),
          3,
          true
        )}
      </ContentTableCell>
      {/* attributes */}
      <ContentTableCell align="left" hideOverflow>
        <TagCloud tags={attributes} limitTags={3} />
      </ContentTableCell>
      {/* status */}
      <ContentTableCell align="center">
        <StyledCenterStatus>
          <StatusIndicator status={status} />
        </StyledCenterStatus>
      </ContentTableCell>
      {/* Info Panel Icon */}
      <ContentTableCell align="center" style={{ position: 'relative' }}>
        {/* Incompleteness Flag */}
        {/* The flag has to be attached to the last cell in a row. */}
        {flagIncomplete && <CompletenessFlag />}
        <StyledCenterAction>
          <IconButton onClick={stableHandleInfoClick} aria-label="Info">
            <InfoOutlined />
          </IconButton>
          {enableContentActions && (
            <RowActions
              rowId={data.id}
              contentType={contentType}
              openDuplicateModal={openDuplicateModel}
              handleSetItemId={handleSetItemId}
            />
          )}
        </StyledCenterAction>
      </ContentTableCell>
    </ContentTableRow>
  );
};

const memoizedContentTableRow = memo(
  ContentEntryTableRow,
  (prevProps, nextProps) =>
    prevProps.data === nextProps.data &&
    prevProps.isSelected === nextProps.isSelected
);

export default memoizedContentTableRow;
