import { createContext, useContext } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import ozmoApi, { queryClient, OzmoApiContext } from 'services/ozmo-api';

const OzmoApiServiceContext = createContext<OzmoApiContext>(
  {} as OzmoApiContext
);
const useOzmoApiService = () => useContext(OzmoApiServiceContext);

const OzmoApiServiceProvider = ({ children }: any) => {
  return (
    <QueryClientProvider client={queryClient}>
      <OzmoApiServiceContext.Provider value={ozmoApi}>
        {children}
      </OzmoApiServiceContext.Provider>
    </QueryClientProvider>
  );
};

export default useOzmoApiService;
export {
  OzmoApiServiceProvider,
  useOzmoApiService,
  OzmoApiServiceContext,
  queryClient,
};
