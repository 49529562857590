import { FC, useCallback, useMemo } from 'react';
import { Box, Typography, styled, Tooltip } from '@mui/material';
import { SelectedAttributes } from 'components/attribute-selector';
import AddAttributeSelector from 'components/add-attribute-selector/add-attribute-selector';

import { useCurrentCollection } from '../hooks';

const toId = (item: BaseModel) => item.id;

const DetailsHeader = styled(Typography)`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const Details: FC = () => {
  const { collection, update, refetch } = useCurrentCollection();

  const collectionAttributes = useMemo(() => {
    return {
      Device: collection?.devices?.map(toId),
      DeviceType: collection?.deviceTypes?.map(toId),
      Manufacturer: collection?.manufacturers?.map(toId),
      OperatingSystem: collection?.operatingSystems?.map(toId),
      OperatingSystemRelease: collection?.operatingSystemReleases?.map(toId),
      OperatingSystemVersion: collection?.operatingSystemVersions?.map(toId),
    };
  }, [collection]);

  const onAttributesChanged = useCallback(async (attrs: SelectedAttributes) => {
    await update(attrs as Partial<CollectionUpdateModel>);
    // refetch collection after changes, because we update ex: `deviceIds` and read from `devices`
    refetch();
    // disable the changing of this function, regardless.
    // if it updates, the AttributeSelector component will endlessly
    // spit updates and create an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Tooltip
        arrow
        placement="bottom-start"
        title="Attributes will be saved automatically as they are changed"
      >
        <DetailsHeader as="span">{'Collection attributes'}</DetailsHeader>
      </Tooltip>
      <AddAttributeSelector
        startingAttributes={collectionAttributes}
        onSelectedAttrChange={onAttributesChanged}
        prohibitedAttributes={['Topic', 'Space', 'Language']}
        inputPlaceholder="Add attributes to this collection"
        showTitle={false}
      />
    </Box>
  );
};

export default Details;
