import { FC, ChangeEvent } from 'react';
import { Box, TextField } from '@mui/material';

type Props = {
  name: string;
  setFormItem: (title: string) => (e: ChangeEvent<HTMLInputElement>) => void;
};

const CategoryDetailsModal: FC<Props> = ({ name, setFormItem }) => {
  return (
    <>
      <Box padding={2}>
        <TextField
          fullWidth
          label="Name"
          value={name}
          onChange={setFormItem('name')}
        />
      </Box>
    </>
  );
};

export default CategoryDetailsModal;
