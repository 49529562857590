import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';
interface ResourcePathVariables {
  id?: number;
  deviceId: number;
}
class DeviceShell extends OzmoApiBase<
  DeviceShellModel,
  null,
  null,
  ResourcePathVariables
>() {
  protected static readonly = true;

  protected static resourcePath = 'config/devices/:deviceId/shells/:id';
  protected static embedOptions = [];

  // If you want override config values for react-query for all models
  // add them here- note that options passed to the instances will override these
  // For a full list of config options see the react-query docs: https://react-query.tanstack.com/reference/useQuery
  protected static defaultReactQueryConfig = {
    staleTime: 300000, // 5 minutes
  };
}

// Make sure to update this to match you class name
// And delete all these extra commentsre
export default DeviceShell;
