import { useEffect } from 'react';

import { toPixels } from '../draggable-utils';

const useForcedReposition = (
  setPosition: Function,
  initialX: number,
  initialY: number,
  parentWidth: number,
  parentHeight: number
) => {
  // If the initialX or initialY change, reposition the indicator.
  // This makes it a weird semi-controlled component, but so be it.
  // We need to be able to programmatically reset the position
  // in the case of undo/redo
  useEffect(() => {
    setPosition({
      x: toPixels(initialX, parentWidth),
      y: toPixels(initialY, parentHeight),
    });
  }, [setPosition, initialX, initialY, parentWidth, parentHeight]);
};

export default useForcedReposition;
