import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Alert, Grid, Link, Skeleton } from '@mui/material';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

import { usePreviewContent } from './hooks';
import { AnswerExistsError } from './error';

type Props = {
  error: AnswerExistsError;
};

const AnswerExistsErrorDetail: FC<Props> = ({ error }) => {
  const { previewUrl } = usePreviewContent(error.existingAnswerId);
  return (
    <>
      <Alert
        severity="error"
        data-testid="answer-exists-error"
        sx={{ paddingBottom: 0 }}
      >
        {error.formattedMessage()}

        <Grid container marginTop={1}>
          <Grid item>
            {previewUrl ? (
              <Link
                display="flex"
                alignContent="center"
                gap="0.25rem"
                underline="hover"
                component={RouterLink}
                to={previewUrl}
                target="_blank"
              >
                {'View existing answer'}
                <OpenInBrowserIcon fontSize="small" />
              </Link>
            ) : (
              <Skeleton variant="text" width="150px" />
            )}
          </Grid>
        </Grid>
      </Alert>
    </>
  );
};

export default AnswerExistsErrorDetail;
