import { string } from 'services/utils/prop-types';
type Props = {
  direction: string;
};

const SelectedIndicatorCircles: React.FunctionComponent<Props> = ({
  direction,
}) => {
  const arrows =
    direction === 'in' ? (
      <>
        <circle
          className="arrow"
          cx="90"
          cy="60"
          r="10"
          fill="#007EE6"
          stroke="#fff"
          strokeWidth="1"
        />
        <path
          strokeWidth="1"
          transform="translate(75, 46)"
          d="m16.577 19.588.016-1.38-4.486-.054 7.998-7.822-.968-.989-7.997 7.822.05-4.49-1.385-.011-.076 6.848 6.848.076z"
          fill="#fff"
          stroke="#fff"
        />
        <circle
          cx="60"
          cy="90"
          r="10"
          fill="#007EE6"
          stroke="#fff"
          strokeWidth="1"
        />
        <path
          className="arrow"
          strokeWidth="1"
          transform="translate(75, 104) rotate(180)"
          d="m16.577 19.588.016-1.38-4.486-.054 7.998-7.822-.968-.989-7.997 7.822.05-4.49-1.385-.011-.076 6.848 6.848.076z"
          fill="#fff"
          stroke="#fff"
        />
      </>
    ) : (
      <>
        <circle
          className="arrow"
          cx="139"
          cy="11"
          r="10"
          fill="#007EE6"
          stroke="#fff"
          strokeWidth="1"
        />
        <path
          strokeWidth="1"
          transform="translate(153, 26) rotate(180)"
          d="m16.577 19.588.016-1.38-4.486-.054 7.998-7.822-.968-.989-7.997 7.822.05-4.49-1.385-.011-.076 6.848 6.848.076z"
          fill="#fff"
          stroke="#fff"
        />
        <circle
          cx="11"
          cy="139"
          r="10"
          fill="#007EE6"
          stroke="#fff"
          strokeWidth="1"
        />
        <path
          className="arrow"
          strokeWidth="1"
          transform="translate(-4, 125) "
          d="m16.577 19.588.016-1.38-4.486-.054 7.998-7.822-.968-.989-7.997 7.822.05-4.49-1.385-.011-.076 6.848 6.848.076z"
          fill="#fff"
          stroke="#fff"
        />
      </>
    );
  return arrows;
};

SelectedIndicatorCircles.propTypes = {
  direction: string.isRequired,
};

export default SelectedIndicatorCircles;
