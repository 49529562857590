import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { parseJWT, getTokenExpiry } from 'services/utils/parse-jwt';

export const OZMO_AUTH_APPLICATION = 'Studio';
export const LS_GOOGLE_AUTH_RESPONSE = 'ozmo-authoring-google-auth';
export const LS_OZMO_TOKEN = 'ozmo-authoring-ozmo-token';
export const LS_OZMO_USER = 'ozmo-authoring-ozmo-user';

export const useOzmoApiToken = () => localStorage.getItem(LS_OZMO_TOKEN);
export const useOzmoAuthUser = (): OzmoApiAuthUser | undefined =>
  JSON.parse(localStorage.getItem(LS_OZMO_USER) ?? '{}');

const isJWTValid = (jwt: string) => {
  const { exp } = parseJWT(jwt);
  // expiration is in seconds, not milliseconds
  return (exp ?? 0) * 1000 > new Date().getTime();
};

const parseStoredGoogleAuthResponse = () => {
  try {
    const storedResponse = localStorage.getItem(LS_GOOGLE_AUTH_RESPONSE);
    if (storedResponse) {
      const googleAuthResponse = JSON.parse(
        storedResponse
      ) as GoogleAuthResponse;
      const isValid = isJWTValid(googleAuthResponse.credential);

      return isValid ? googleAuthResponse : null;
    }
    return null;
  } catch (err) {
    return null;
  }
};

const isOzmoTokenValid = () => {
  try {
    const token = localStorage.getItem(LS_OZMO_TOKEN);
    if (token) {
      return isJWTValid(token);
    }
    return false;
  } catch (err) {
    return false;
  }
};

const initializeAndPromptGoogleAuth = (
  callback: (authResponse: GoogleAuthResponse) => any
) => {
  (window as any).google.accounts.id.initialize({
    callback,
    /* eslint-disable camelcase */
    client_id: import.meta.env.VITE_APP_GOOGLE_CLIENT_ID,
    // If the user has previously granted permission from only one Google account
    // then automatically reuse that one, without requiring them to click anything
    auto_select: true,
    ux_mode: 'popup',
    cancel_on_tap_outside: false,
    /* eslint-enable camelcase */
  });
  (window as any).google.accounts.id.prompt();
};

export const useGoogleAuth = () => {
  const user = useOzmoAuthUser();
  const permissions = useMemo(
    () =>
      user?.roles?.flatMap((role) =>
        role.role_permissions.map(
          /* eslint-disable camelcase */
          ({ permission_name, application_name }) =>
            `${application_name}:${permission_name}`
          /* eslint-enable camelcase */
        )
      ) ?? [],
    [user]
  );

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [googleToken, setGoogleToken] = useState(
    () => parseStoredGoogleAuthResponse()?.credential ?? null
  );

  const [ozmoToken, setOzmoToken] = useState(() =>
    localStorage.getItem(LS_OZMO_TOKEN)
  );

  const logout = useCallback(() => {
    // Disable the auto login, since the user has manually logged out
    // Otherwise you'll just *log right back in*
    (window as any).google.accounts.id.disableAutoSelect();
    try {
      localStorage.removeItem(LS_OZMO_TOKEN);
      localStorage.removeItem(LS_GOOGLE_AUTH_RESPONSE);
      setIsLoggedIn(false);
    } catch (e) {
      console.log(`Error accessing localStorage: ${e}`);
    }
  }, []);

  const handleLogin = useCallback(async (authResponse: GoogleAuthResponse) => {
    const res = await fetch(
      `${import.meta.env.VITE_APP_OZMO_API_URL}/auth/tokens/loginGoogle`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'X-Api-Key': `${import.meta.env.VITE_APP_OZMO_X_API_KEY}`,
        },
        body: JSON.stringify({
          embed: 'role,role_permission',
          // eslint-disable-next-line camelcase
          google_token: authResponse.credential,
          // eslint-disable-next-line camelcase
          google_client_id: import.meta.env.VITE_APP_GOOGLE_CLIENT_ID,
          application: OZMO_AUTH_APPLICATION,
        }),
      }
    );
    if (res.ok) {
      const json = await res.json();
      try {
        localStorage.setItem(LS_OZMO_TOKEN, json.token);
        localStorage.setItem(LS_OZMO_USER, JSON.stringify(json.user));
        localStorage.setItem(
          LS_GOOGLE_AUTH_RESPONSE,
          JSON.stringify(authResponse)
        );
        setIsLoggedIn(true);
        setGoogleToken(authResponse.credential);
        setOzmoToken(json.token);
      } catch (e) {
        console.log(`Error accessing localStorage: ${e}`);
      }
    }
  }, []);

  const refreshOzmoToken = useCallback(() => {
    const googleAuthResponse = parseStoredGoogleAuthResponse();
    if (googleAuthResponse) {
      handleLogin(googleAuthResponse);
    }
  }, [handleLogin]);

  const refreshGoogleToken = useCallback(() => {
    initializeAndPromptGoogleAuth(handleLogin);
  }, [handleLogin]);

  usePeriodicRefresh(
    ozmoToken,
    googleToken,
    refreshOzmoToken,
    refreshGoogleToken
  );

  useEffect(() => {
    if (isLoggedIn) {
      // If we're already logged in, do nothing
      console.log('Already logged in, doing nothing');
      return;
    }

    if (isOzmoTokenValid()) {
      console.log('Ozmo token is still valid, doing nothing');
      setIsLoggedIn(true);
      return;
    }
    // If we're NOT logged in (say if the user closed the tab), BUT we still have
    // a valid Google auth token, then use that
    const googleAuthResponse = parseStoredGoogleAuthResponse();
    if (googleAuthResponse) {
      handleLogin(googleAuthResponse);
      console.log('Google auth still valid, relogging-in to Ozmo');
      return;
    }

    initializeAndPromptGoogleAuth(handleLogin);
  }, [handleLogin, isLoggedIn]);

  return {
    logout,
    permissions,
    isLoggedIn,
  };
};

// Check the tokens for expiration every ten minutes
const TOKEN_REFRESH_INTERVAL_MS = 10 * 60 * 1000;
// If the token will expire within 2 check intervals, refresh it
const REFRESH_THRESHOLD_MS = TOKEN_REFRESH_INTERVAL_MS * 2;

const usePeriodicRefresh = (
  ozmoToken: Maybe<string>,
  googleToken: Maybe<string>,
  refreshOzmoToken: () => void,
  refreshGoogleAuth: () => void
) => {
  const ozmoTokenExpiry = useMemo(() => getTokenExpiry(ozmoToken), [ozmoToken]);

  const googleAuthExpiry = useMemo(() => getTokenExpiry(googleToken), [
    googleToken,
  ]);

  const checkExpirationAndRefreshIfNeeded = (
    expiry: number,
    refresh: () => void,
    name: string
  ) => {
    console.log(`Checking ${name} token expiry`);
    const delta = expiry * 1000 - new Date().getTime();
    if (delta <= REFRESH_THRESHOLD_MS) {
      console.log(
        `${name} token expires in ${delta / 1000 / 60} minutes, refreshing`
      );
      refresh();
    } else {
      console.log(`${name} token expires in ${delta / 1000 / 60} minutes`);
    }
  };

  useEffect(() => {
    checkExpirationAndRefreshIfNeeded(
      googleAuthExpiry,
      refreshGoogleAuth,
      'google'
    );

    checkExpirationAndRefreshIfNeeded(
      ozmoTokenExpiry,
      refreshOzmoToken,
      'ozmo'
    );
    // Only run this once at mount, periodic checking is enabled in another effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const googleRefreshTimer = window.setInterval(() => {
      checkExpirationAndRefreshIfNeeded(
        googleAuthExpiry,
        refreshGoogleAuth,
        'google'
      );
    }, TOKEN_REFRESH_INTERVAL_MS);

    const ozmoRefreshTimer = window.setInterval(() => {
      checkExpirationAndRefreshIfNeeded(
        ozmoTokenExpiry,
        refreshOzmoToken,
        'ozmo'
      );
    }, TOKEN_REFRESH_INTERVAL_MS);

    return () => {
      window.clearInterval(googleRefreshTimer);
      window.clearInterval(ozmoRefreshTimer);
    };
  }, [ozmoTokenExpiry, googleAuthExpiry, refreshOzmoToken, refreshGoogleAuth]);
};

export const useLoginButton = () => {
  const buttonRef = useRef();

  useEffect(() => {
    (window as any).google.accounts.id.renderButton(buttonRef.current, {
      theme: 'outline',
      size: 'large',
    });
  }, []);

  return { buttonRef };
};
