import { FC } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

type Props = {
  onClick?: () => void;
};

const AddContentDefaultButton: FC<Props> = ({ onClick }) => (
  <Button startIcon={<AddIcon />} variant="contained" onClick={onClick}>
    {'Create content'}
  </Button>
);

export default AddContentDefaultButton;
