import {
  ChangeEvent,
  EventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useAppToast } from 'contexts/app-toast-context';
import { useOzmoApiService } from 'contexts/ozmo-api-service-context';
import { ChangeFunction } from 'scenes/universal-content-editor';
import {
  validateDroppedFile,
  isHandledRejection,
  validateSelectedFile,
  isDropEvent,
} from 'services/utils/image-upload';
import useIsBeingDraggedOver from 'services/utils/use-is-being-dragged-over';

type UploadError = {
  details: JSX.Element | string;
  filename: string;
};

export const useImageUpload = (onChange: ChangeFunction) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();
  const [pendingImageSrc, setPendingImageSrc] = useState<string | null>(null);
  const [uploadError, setUploadError] = useState<UploadError | null>(null);
  const dragAndDropRef = useRef<HTMLDivElement | null>(null);

  const handleRemoveImage = () => {
    onChange(undefined);
  };

  const handleClearError = useCallback(() => setUploadError(null), []);

  const handleUploadError = useCallback(
    (filename: string, details: JSX.Element | string) => {
      // dispatch a toast
      dispatchToast({
        level: 'error',
        message: `Could not upload ${filename}.  Please check your file and try again.`,
      });
      setUploadError({ details, filename });
    },
    [dispatchToast]
  );

  const handleFileEvent = useCallback<EventHandler<any>>(
    async (e: DragEvent | ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      try {
        const result = isDropEvent(e)
          ? await validateDroppedFile(e)
          : await validateSelectedFile(e);

        const { file, src } = result;
        setPendingImageSrc(src);
        // Wait for the upload to finish because the finally action will clear
        // the ghosted pending image we just set after this line
        const mediaEntry = await api.MediaEntry.createOrGetDuplicateAsync({
          file,
          mediaTypeId: 1,
        });
        await onChange({ id: mediaEntry.id, referenceType: 'MediaEntry' });
      } catch (error) {
        if (isHandledRejection(error)) {
          const { filename, message } = error;
          handleUploadError(filename, message);
        } else {
          handleUploadError('Unknown file', (error as Error).toString());
        }
      } finally {
        // Clear the ghosted image
        setPendingImageSrc(null);
      }
    },
    [api.MediaEntry, handleUploadError, onChange]
  );

  const { isBeingDraggedOver, isBulkTransfer } = useIsBeingDraggedOver(
    dragAndDropRef,
    handleFileEvent
  );

  const dragAndDropError = isBulkTransfer ? (
    <>
      {'Bulk uploading is not possible at this time. '}
      <br />
      {'Please upload one image at a time to each step.'}
    </>
  ) : undefined;

  return {
    dragAndDropError,
    dragAndDropRef,
    isBeingDraggedOver,
    isBulkTransfer,
    pendingImageSrc,
    uploadError,
    handleClearError,
    handleFileEvent,
    handleRemoveImage,
  };
};
