import { FunctionComponent, useCallback, ChangeEvent, useContext } from 'react';
import { IsReferenceSelectedContext } from 'scenes/nuevo-collection/content-tab';
import { isMissingCollectionReference } from 'services/utils/type-guards/localized-collection';

import LocalizedReference from './localized-reference';
import MissingReference from './missing-reference';

type Props = (
  | LocalizedCollectionReference
  | MissingLocalizedCollectionReference
) & {
  onInfoClick: (contentEntryId: number) => void;
  onSelectChange: (referenceId: number, categoryId: number) => void;
  categoryId: number;
  collectionId: number;
  asCard?: boolean;
};

const CollectionReference: FunctionComponent<Props> = ({
  onInfoClick,
  onSelectChange,
  categoryId,
  collectionId,
  asCard,
  ...reference
}) => {
  const isReferenceSelected = useContext(IsReferenceSelectedContext);
  const selected = isReferenceSelected(reference.id, categoryId);
  const handleSelectChange = useCallback(
    (e: ChangeEvent) => {
      onSelectChange(reference.id, categoryId);
    },
    [reference.id, categoryId, onSelectChange]
  );
  if (isMissingCollectionReference(reference)) {
    return (
      <MissingReference
        {...reference}
        asCard={asCard}
        selected={selected}
        onSelectChange={handleSelectChange}
      />
    );
  }

  return (
    <LocalizedReference
      {...reference}
      asCard={asCard}
      collectionId={collectionId}
      categoryId={categoryId}
      selected={selected}
      onInfoClick={onInfoClick}
      onSelectChange={handleSelectChange}
    />
  );
};

export default CollectionReference;
