/**
 *
 * @param listSource string[] - The source for the list. Should be an array of strings.
 * @param maxItems number - The maximum number of items to be listed, inclusive.
 * @param withEllipses boolean - When true, and the total list of items exceeds maxItems, an '…' will be appended to the end of the list.
 * @param separator string - The character used to separator the list items, defaults to ','.
 * @returns string - A string composing of item from the listSource. Limited by `maxItems`, seperated by `separator`, and appended with an ellipse if defined to do so.
 */
export const limitedStringList = (
  listSource: string[],
  maxItems?: number,
  withEllipses?: boolean,
  separator = ', '
) => {
  if (!listSource) return '';

  const list = listSource.slice(0, maxItems).join(separator);

  if (withEllipses && maxItems && maxItems < listSource.length) {
    return `${list}…`;
  }
  return list;
};
