import { generatePath } from 'react-router-dom';

// TODO: Revert to rootPath as a const variable after collection2 migration
// export const rootPath = '/legacy-collection/:id';
export const getRootPath = () =>
  `/${
    window.ozmoAuthoringFlags?.useCollections2 ? 'legacy-' : ''
  }collection/:id`;
// TODO Revert to const variable after collection2 migration
// const languagePath = `${rootPath}/:language`;
const getLanguagePath = () => `${getRootPath()}/:language`;
// TODO Revert to const variable after collection2 migration
// const categoryPath = `${languagePath}/:categoryIndex`;
const getCategoryPath = () => `${getLanguagePath()}/:categoryIndex`;

export const generateCollectionPath = (
  collectionId: number | string,
  language?: string,
  categoryIndex?: number
) => {
  if (!language) {
    return generatePath(getRootPath(), { id: collectionId.toString() });
  }

  if (categoryIndex === undefined) {
    return generatePath(getLanguagePath(), {
      id: collectionId.toString(),
      language,
    });
  }

  return generatePath(getCategoryPath(), {
    id: collectionId.toString(),
    language,
    categoryIndex: categoryIndex.toString(),
  });
};
