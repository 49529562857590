import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { FunctionComponent } from 'react';

type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type Props = BoxProps & {
  breakpoint: Breakpoints;
};

type StyledProps = Pick<Props, 'breakpoint' | 'display'>;
const StyledBox = styled(Box)<StyledProps>`
  /* breakpoints.up is INCLUSIVE of the value */
  ${({ breakpoint, theme }) => theme.breakpoints.up(breakpoint)} {
    display: ${({ display }) => `${display} !important`};
  }
  /* breakpoints.down is EXCLUSIVE of the value */
  ${({ breakpoint, theme }) => theme.breakpoints.down(breakpoint)} {
    display: none !important;
  }
`;

const HiddenUnder: FunctionComponent<Props> = ({
  breakpoint,
  children,
  display = 'block',
  ...boxProps
}) => (
  <StyledBox {...boxProps} breakpoint={breakpoint} display={display}>
    {children}
  </StyledBox>
);

export default HiddenUnder;
