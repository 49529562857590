import { isFileList } from '../type-guards/files';

export const getFileArray = (fileOrFiles: File | FileList) => {
  return isFileList(fileOrFiles) ? Array.from(fileOrFiles) : [fileOrFiles];
};

export const truncateFilename = (filename: string, length = 40) =>
  filename.length > length
    ? `${filename.slice(0, length - 13)}...${filename.slice(
        filename.length - 10,
        filename.length
      )}`
    : filename;

export const appendToFilename = (
  filename: string,
  adornment: string,
  joinChar = '_'
) => {
  const parts = filename.split('.');
  const [rest, extension] = [parts.slice(0, -1), parts.slice(-1)];
  const newFilename = `${rest.join('.')}${joinChar}${adornment}`;
  return `${newFilename}.${extension}`;
};

export const changeFileExtension = (filename: string, newExtension: string) => {
  const parts = filename.split('.');
  const filenameWithoutExtension = parts.slice(0, -1).join('.');
  return `${filenameWithoutExtension}.${newExtension}`;
};
