import { FC } from 'react';
import TranslateIcon from '@mui/icons-material/Translate';
import { useTranslationExport } from 'scenes/translations/collection-translation-export/hooks';
import { useActionModal } from 'components/modals';
import ExportModalContent from 'scenes/translations/collection-translation-export/export-modal-content';
import { useCurrentCollection } from 'scenes/nuevo-collection/hooks';

import { SelectedReference } from '../../../hooks/use-content-tab';
import ActionButton from '../action-button';

type Props = {
  selectedReferences: SelectedReference[];
};

const ExportTranslations: FC<Props> = ({ selectedReferences }) => {
  const { collectionId, languageId } = useCurrentCollection();

  const {
    exportButtonText,
    onExportRefuse,
    handleExportSubmit,
    isLoading,
    name,
    source,
    handleChangeSource,
    handleSelectTarget,
    languages,
    targets,
  } = useTranslationExport(
    collectionId,
    languageId,
    selectedReferences.map(({ id }) => id)
  );

  const { modal: exportModal, openModal: openExportModal } = useActionModal({
    onRefuse: onExportRefuse,
    onConfirm: handleExportSubmit,
    modalContent: (
      <ExportModalContent
        isLoading={isLoading}
        handleChangeSource={handleChangeSource as any}
        sourceId={source}
        languages={languages as any}
        handleSelectTarget={handleSelectTarget}
        name={name}
        contentEntryIds={selectedReferences.map(({ id }) => id)}
      />
    ),
    modalProps: {
      title: 'Export text translations',
      disableConfirmButton: targets.length === 0,
      confirmButtonText: exportButtonText,
      maxWidth: 'md',
      fullWidth: true,
    },
  });

  return (
    <>
      <ActionButton startIcon={<TranslateIcon />} onClick={openExportModal}>
        {'Export Translations'}
      </ActionButton>
      {exportModal}
    </>
  );
};

export default ExportTranslations;
