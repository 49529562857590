import { styled, Button } from '@mui/material';

const ActionButton = styled(Button)`
  color: var(--color-neutral-one);

  :hover {
    color: var(--color-primary-dark-contrast);
  }
`;

export default ActionButton;
