import { FC } from 'react';
import { useDeviceScale } from 'scenes/universal-content-editor/components/schema-media/device-scale-context';
import { useIsPaused } from 'contexts/pausable-by-mouseover-context';
import LoadingIndicator from 'components/loading-indicator';

import DraggableTapIndicator from './draggable-tap-indicator';
import DraggablePinchIndicator from './draggable-pinch-indicator';
import DraggableMovingIndicator from './draggable-moving-indicator';
import DraggablePointOfInterest from './draggable-point-of-interest';
import UnsupportedIndicator from './unsupported-indicator';

export type OnSave = (index: number, indicator: Indicator) => void;

type Props = {
  indicators: Indicator[];
  onSave: OnSave;
  onDelete: (index: number) => void;
};

const DraggableIndicators: FC<Props> = ({ indicators, onSave, onDelete }) => {
  const { isPaused } = useIsPaused();
  const { height: parentHeight, width: parentWidth } = useDeviceScale();

  if (parentHeight === 0 || parentHeight === 0) {
    return <LoadingIndicator message="Loading indicators..." />;
  }

  return (
    <>
      {indicators.map((indicator, i) => {
        const indicatorProps = {
          key: `${i}-${indicator.id}`,
          index: i,
          initialX: indicator.x,
          initialY: indicator.y,
          isPaused,
          parentHeight,
          parentWidth,
          onSave,
          onDelete,
        };

        switch (indicator.id) {
          case 'TAP':
            return <DraggableTapIndicator {...indicatorProps} />;
          case 'PINCH_IN':
            return (
              <DraggablePinchIndicator {...indicatorProps} direction="in" />
            );
          case 'PINCH_OUT':
            return (
              <DraggablePinchIndicator {...indicatorProps} direction="out" />
            );
          case 'WAYPOINT':
            return (
              <DraggableMovingIndicator
                {...indicatorProps}
                indicator={indicator as MovingIndicator}
              />
            );
          case 'HOTSPOT':
            return <DraggablePointOfInterest {...indicatorProps} />;
          default:
            return (
              <UnsupportedIndicator {...indicatorProps} type={indicator.id} />
            );
        }
      })}
    </>
  );
};

export default DraggableIndicators;
