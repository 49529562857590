export const downloadFile = (key: string, value: any) => {
  const json = JSON.stringify(value, null, 2);
  const blob = new Blob([json], { type: 'text/json' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${key}.json`);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};
