import { AppToastProvider } from 'contexts/app-toast-context';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { OzmoApiServiceProvider } from 'contexts/ozmo-api-service-context';
import { FlagsProvider } from 'services/flags';
import GoogleAuth from 'scenes/google-auth';

const GlobalContexts = ({ children }) => (
  <OzmoApiServiceProvider>
    <AppToastProvider>
      <FlagsProvider>
        <GoogleAuth>{children}</GoogleAuth>
      </FlagsProvider>
    </AppToastProvider>
    <ReactQueryDevtools />
  </OzmoApiServiceProvider>
);

export default GlobalContexts;
