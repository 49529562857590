import { FC } from 'react';

/**
 * A circled arrow indicator SVG. Rotates around a central point.
 * @returns Arrow SVG
 */
const ArrowSvg: FC = () => (
  <svg width="120" height="120" viewBox="0 0 120 120">
    <circle
      className="arrow"
      cx="60"
      cy="11"
      r="10"
      fill="#007EE6"
      stroke="#fff"
      strokeWidth="1"
    />
    <circle
      cx="60"
      cy="11"
      r="10"
      fill="#007EE6"
      stroke="#fff"
      strokeWidth="1"
    />
    <path
      className="arrow"
      strokeWidth="1"
      style={{ transform: 'translate(50px, 0)' }}
      d="m5.534 8.302.976.975L9.685 6.11v11.186h1.384V6.11l3.175 3.175.976-.982-4.843-4.843-4.843 4.843z"
      fill="#fff"
      stroke="#fff"
    />
  </svg>
);

export default ArrowSvg;
