import OzmoApiBase from 'services/ozmo-api/ozmo-api-base';
interface ResourcePathVariables {
  id?: number;
}
class OperatingSystem extends OzmoApiBase<
  OperatingSystemModel,
  void,
  void,
  ResourcePathVariables
>() {
  protected static resourcePath = 'config/operating_systems/:id';
  protected static embedOptions = [];
  protected static defaultReactQueryConfig = {
    staleTime: 300000, // 5 minutes
  };

  // Make this model read only- don't allow mutating or creating anything on the server
  // This will disable the update/create/delete methods
  protected static readonly = true;
}

export default OperatingSystem;
