import { FCWithChildren } from 'types/fc-with-children';
import { useState, useCallback, MouseEvent } from 'react';
import { IconButton, Menu } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

type Props = {
  onMenuOpen?: Function;
};

const RowActions: FCWithChildren<Props> = ({ children, onMenuOpen }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onMenuOpen && onMenuOpen();
      setAnchorEl(event.currentTarget);
    },
    [onMenuOpen]
  );

  const handleMenuClose = useCallback(() => setAnchorEl(null), []);

  return (
    <>
      <IconButton
        aria-label="Content Actions"
        aria-controls={open ? 'content-table-row-actions' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="content-table-row-actions"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default RowActions;
