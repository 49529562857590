import { MouseEvent } from 'react';
import { TableCell, TableSortLabel } from '@mui/material';

type Props<T extends {}> = ContentHeadCell<T> & {
  orderBy?: string;
  order: TableOrder;
  handleSort?: (event: MouseEvent, property: keyof T) => void;
};

const ContentTableHeaderCell = <T extends {}>(props: Props<T>) => {
  const {
    id,
    label,
    align,
    isSortable,
    disablePadding,
    orderBy,
    order,
    handleSort,
  } = props;

  return (
    <TableCell
      align={align}
      padding={disablePadding ? 'none' : 'normal'}
      sortDirection={orderBy === id ? order : false}
    >
      {isSortable && handleSort ? (
        <TableSortLabel
          active={orderBy === id}
          direction={orderBy === id ? order : 'asc'}
          onClick={(e) => handleSort(e, id)}
        >
          {label}
        </TableSortLabel>
      ) : (
        label
      )}
    </TableCell>
  );
};

export default ContentTableHeaderCell;
