import styled, { css } from 'styled-components';

import { Crosshairs } from './crosshairs';

// Constant sizes for CircleIndicator
const CI_SIZE = 100;
export const CI_CENTER = Math.round(CI_SIZE / 2);
export const CI_R1 = 19;
export const CI_STROKE = 11;
const CI_R2 = 29;

const CI_R1_SEL = 25;
const CI_R2_SEL = 38;

export const CircularIndicatorSVG = styled.svg<IndicatorVariant>`
  position: absolute;
  z-index: 1;
  opacity: ${({ selected }) => (selected ? 0.6 : 1.0)};

  .outer {
    stroke: var(--color-indicator-outer);
    stroke-width: ${CI_STROKE}px;
    fill: none;
  }

  .inner {
    stroke: var(--color-indicator-inner);
    stroke-width: ${CI_STROKE}px;
    fill: none;

    ${({ editMode, selected, global }) =>
      editMode &&
      !selected &&
      !global &&
      css`
        &:hover {
          stroke: #00bfff;
        }
      `};
  }

  ${({ selected }) =>
    selected &&
    css`
      & > line {
        stroke: #000000;
        stroke-width: 3px;
        fill: none;
      }
    `}

  @media print {
    opacity: 0;
  }
` as any;

export const CircularIndicator = (props: IndicatorVariant) => {
  const { scale = 1, selected, children } = props;
  const r1 = selected ? CI_R1_SEL : CI_R1;
  const r2 = selected ? CI_R2_SEL : CI_R2;
  return (
    <CircularIndicatorSVG
      height={`${CI_SIZE * scale}`}
      width={`${CI_SIZE * scale}`}
      viewBox={`0 0 ${CI_SIZE} ${CI_SIZE}`}
      {...props}
    >
      <circle className="outer" cx={CI_CENTER} cy={CI_CENTER} r={r2} />
      <circle className="inner" cx={CI_CENTER} cy={CI_CENTER} r={r1} />
      {children}
      {selected && <Crosshairs />}
    </CircularIndicatorSVG>
  );
};
