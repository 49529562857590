import { useState, MouseEventHandler, ChangeEventHandler } from 'react';
import usePreviousPage from 'services/utils/use-previous-page';

export const useImageButton = (
  onRemoveImage: VoidFunction,
  onUpdateImage: ChangeEventHandler<HTMLInputElement>
) => {
  const [
    imageMenuAnchorEl,
    setImageMenuAnchorEl,
  ] = useState<null | HTMLElement>(null);
  const { generateUrlWithPreviousPage } = usePreviousPage();
  const compilerLink = generateUrlWithPreviousPage('/shell-compiler');

  const imageMenuOpen = Boolean(imageMenuAnchorEl);
  const handleImageMenuOpen: MouseEventHandler<HTMLButtonElement> = (event) => {
    setImageMenuAnchorEl(event.currentTarget);
  };
  const handleImageMenuClose = () => {
    setImageMenuAnchorEl(null);
  };
  const handleUploadImageClick: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    onUpdateImage(event);
    handleImageMenuClose();
  };

  const handleRemoveImageClick = () => {
    onRemoveImage();
    handleImageMenuClose();
  };

  return {
    compilerLink,
    imageMenuOpen,
    imageMenuAnchorEl,
    handleImageMenuOpen,
    handleImageMenuClose,
    handleRemoveImageClick,
    handleUploadImageClick,
  };
};
