import { useAppToast } from 'contexts/app-toast-context';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import usePreviousPage from 'services/utils/use-previous-page';

/**
 * Provides a function that copies a link to the provided path
 * to the user's clipboard.  Supports the previous page pattern.
 *
 * @param path The path of the element to link to
 * @returns ({ handleCopyLinkClick }) A function that copies the formatted link to the user's clipboard
 */
export const useCopyLinkToPath = (path: string) => {
  const dispatchToast = useAppToast();
  const { generateUrlWithPreviousPage } = usePreviousPage();
  const { search, pathname } = useLocation();
  const { href, origin } = window.location;

  const target = `${origin}${pathname}${search ? `?${search}` : ''}`;

  const handleCopyLinkClick = useCallback(() => {
    const url = generateUrlWithPreviousPage(target, href, 'Answer', path);
    navigator.clipboard
      .writeText(url)
      .then(() => {
        dispatchToast({
          level: 'success',
          message: 'Link copied to clipboard',
        });
      })
      .catch(() => {
        dispatchToast({
          level: 'error',
          message: 'Unable to copy link to clipboard',
        });
      });
  }, [dispatchToast, generateUrlWithPreviousPage, href, path, target]);

  return { handleCopyLinkClick };
};
