import useOzmoApiService from 'contexts/ozmo-api-service-context';
import usePreviousPage from 'services/utils/use-previous-page';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { generateCollectionPath } from 'scenes/nuevo-collection/util';

export const useReferencedCollectionsList = (contentEntryId: number | null) => {
  const api = useOzmoApiService();
  const {
    all: referencedCollections,
  } = api.Collection.getCollectionsByReferencedContentEntryId(contentEntryId, {
    enabled: contentEntryId !== null,
  });

  const { generateUrlWithPreviousPage } = usePreviousPage();

  const links = referencedCollections.map((c) => (
    <Typography key={c.id} noWrap={true}>
      <Link to={generateUrlWithPreviousPage(generateCollectionPath(c.id))}>
        {c.name}
      </Link>
    </Typography>
  ));
  return {
    referencedCollections,
    links,
  };
};
