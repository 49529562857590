import { useConfirmationModal } from 'components/modals';

/**
 * Pop up a confirmation modal when removing an item from an array
 * @param displayName The name of the item being removed
 * @param onRemove THe function to call when the delete action is confirmed
 * @returns The modal and openModal functions
 */
export const useRemoveArrayItem = (
  displayName: string,
  onRemove?: VoidFunction
) => {
  const { modal, openModal } = useConfirmationModal({
    onConfirm: onRemove,
    modalProps: {
      confirmButtonColor: 'danger',
      confirmButtonText: 'Delete',
      title: 'Confirm Delete',
      'aria-label': `Delete ${displayName} confirmation`,
      message: `Are you sure you want to delete this ${displayName}?`,
      maxWidth: 'md',
      fullWidth: true,
    },
  });

  return {
    removeConfirmationModal: modal,
    openRemoveConfirmationModal: openModal,
  };
};
