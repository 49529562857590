import { Typography } from '@mui/material';
import { Component } from 'react';

import { logError } from './logErrorService';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {},
      errorInfo: {},
    };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (error) {
      this.setState({
        error: {
          name: error.name,
          message: error.message,
        },
        errorInfo,
      });
      logError(error, errorInfo);
    }
  }

  render() {
    const { error, errorInfo } = this.state;

    if (this.state.hasError) {
      return (
        <div>
          <Typography variant="h4">
            {'An unhandled error was caught'}
          </Typography>
          <Typography variant="h5">
            {
              'Please screenshot this and send it to Engineering along with your bug report'
            }
          </Typography>
          <Typography variant="body1">
            <b>{error?.name}</b>
            {`: ${error?.message}`}
          </Typography>
          <Typography variant="body2">
            <pre>{errorInfo?.componentStack}</pre>
          </Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
