import { Editor, Element, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { LinkElement } from 'types/slate';

import { isLinkElement } from '../types';
import { isUrl } from '../utils';

export const withInlines = (editor: ReactEditor) => {
  const { insertData, insertText, isInline } = editor;

  // The only way these plugins work is by mutating the
  // editor in place- you cannot do a spread.  I tried.
  /* eslint-disable no-param-reassign */
  editor.isInline = (element) => isLinkElement(element) || isInline(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };
  /* eslint-enable no-param-reassign */

  return editor;
};
export const isLinkActive = (editor: Editor) => {
  // @ts-ignore
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && isLinkElement(n),
  });
  return !!link;
};
export const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && isLinkElement(n),
  });
};

export const wrapLink = (editor: Editor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const insertLink = (editor: Editor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};
