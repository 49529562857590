import { FC, useMemo } from 'react';
import { Typography, styled } from '@mui/material';
import { SelectedAttributes } from 'components/attribute-selector';
import getPlural from 'services/utils/get-plural';
import AddAttributeSelector from 'components/add-attribute-selector';

type Props = {
  collection: CollectionModel;
  startingAttributes: Record<string, any>;
  setAttributes: (attributes: SelectedAttributes) => void;
};

const StyledAddAttributeSelector = styled(AddAttributeSelector)`
  /* style the attribute results panel */
  #attribute-selector-results-panel {
    max-height: 225px;
    bottom: 2.5rem;
  }
`;

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  position: relative;
`;

const DuplicateCollectionModal: FC<Props> = ({
  collection,
  startingAttributes,
  setAttributes,
}) => {
  const categoryCount = useMemo(() => collection?.categories?.length || 0, [
    collection,
  ]);

  const answerCount = useMemo(
    () =>
      collection?.categories?.reduce(
        (acc, category) => acc + (category?.contentEntryIds?.length ?? 0),
        0
      ),
    [collection]
  );

  return (
    <StyledDiv>
      <Typography>
        {'The most recently edited version of '}
        <b>{collection?.name}</b>
        {"'s attributes must be modified prior to duplication."}
      </Typography>
      <Typography>
        {'Duplicating a collection '}
        <b>{'does not duplicate the answers'}</b>
        {' it contains. '}
        <b>{`${categoryCount} ${getPlural(
          categoryCount,
          'category',
          'categories'
        )} will be duplicated`}</b>
        {' and the new collection will still '}
        <b>{`reference ${answerCount} ${getPlural(
          answerCount,
          'answer',
          'answers'
        )}`}</b>
        {'.'}
      </Typography>
      <div>
        <StyledAddAttributeSelector
          startingAttributes={startingAttributes}
          onSelectedAttrChange={setAttributes}
          prohibitedAttributes={[
            'Topic',
            'Space',
            'Language',
            'ContentTitle',
            'ContentType',
          ]}
          inputPlaceholder="Modify new collection attributes"
        />
      </div>
    </StyledDiv>
  );
};

export default DuplicateCollectionModal;
