import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Chip, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const StyledDeleteIcon = styled(ClearIcon)`
  color: var(--color-neutral-one) !important;
`;

const StyledDisabledTag = styled(Chip)`
  margin-right: 0.5em !important;
  margin-bottom: 0.5em !important;
  color: var(--color-neutral-seven) !important;
  background-color: var(--color-neutral-three) !important;
`;

const StyledTag = styled(Chip)`
  background-color: var(--color-primary-light) !important;
  border-color: var(--color-primary-light) !important;
  color: var(--color-neutral-one) !important;
  margin-right: 0.5em !important;
  margin-bottom: 0.5em !important;
`;

const StyledTypography = styled(Typography)`
  margin-top: 4px;
`;

interface Tag {
  name: string;
  id: number;
  disabled?: boolean;
}

type TagCloudProps = {
  tags: Tag[];
  limitTags?: number;
  showRemovedCount?: boolean;
  onDelete?: (tag: any) => void;
};

const TagCloud: FunctionComponent<TagCloudProps> = ({
  tags,
  limitTags,
  showRemovedCount = true,
  onDelete,
}) => {
  const limitedTags = [...tags];
  const removedTagCount =
    limitTags && limitTags > 0 ? limitedTags.splice(limitTags).length : 0;

  return (
    <>
      {limitedTags.map((tag) =>
        tag.disabled === true ? (
          <StyledDisabledTag
            key={`${tag.name}-${tag.id}`}
            label={tag.name}
            data-testid="tag-cloud:disabled-tag"
          />
        ) : (
          <StyledTag
            key={`${tag.name}-${tag.id}`}
            data-testid="tag-cloud:enabled-tag"
            label={tag.name}
            disabled={tag.disabled ?? false}
            deleteIcon={
              onDelete && (
                <StyledDeleteIcon
                  fontSize="small"
                  data-testid="tag-cloud:delete-icon"
                />
              )
            }
            onDelete={onDelete && (() => onDelete(tag))}
          />
        )
      )}
      {removedTagCount > 0 && showRemovedCount && (
        <StyledTypography data-testid="tag-cloud:hidden-tag-count">{`& ${removedTagCount} more`}</StyledTypography>
      )}
    </>
  );
};

export default TagCloud;
