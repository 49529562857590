import { FC } from 'react';
import { styled } from '@mui/material';
import AttributeSelector, {
  AllowedAttributeKeys,
} from 'components/attribute-selector';

import { useContentSearch } from './hooks';
import SearchButton from './search-button';

type Props = {
  prohibitedContentTypes?: ContentEntryContentType[];
  prohibitedAttributes?: AllowedAttributeKeys[];
  onSearch: (
    searchParams: ContentEntrySearchParams,
    searchResultLimit?: number
  ) => void;
  onAttributeResultsToggle?: (isOpen: boolean) => void;
  syncWithQueryParams?: boolean;
};

const StyledFlex = styled('div')`
  display: flex;
  align-items: center;

  #attribute-selector-container {
    flex: 1;
    margin-right: 1rem;
  }
`;

const ContentSearch: FC<Props> = ({
  prohibitedContentTypes,
  prohibitedAttributes,
  onSearch,
  onAttributeResultsToggle,
  syncWithQueryParams = false,
}) => {
  const {
    isSearchDisabled,
    filtersChanged,
    handleSearch,
    handleSelectedAttrChange,
    startingAttributes,
  } = useContentSearch(onSearch);

  return (
    <StyledFlex>
      <AttributeSelector
        startingAttributes={startingAttributes}
        showStaticAttributes={true}
        prohibitedContentTypes={prohibitedContentTypes}
        prohibitedAttributes={prohibitedAttributes}
        onSelectedAttrChange={handleSelectedAttrChange}
        onResultsPanelToggle={onAttributeResultsToggle}
        onShiftEnterPressed={handleSearch}
        syncWithQueryParams={syncWithQueryParams}
      />
      <SearchButton
        disabled={isSearchDisabled}
        indicateAttrsChanged={filtersChanged}
        onClick={handleSearch}
      />
    </StyledFlex>
  );
};

export default ContentSearch;
