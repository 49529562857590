import { useCallback } from 'react';
import { Typography } from '@mui/material';
import { useAppToast } from 'contexts/app-toast-context';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { generateQueryKey } from 'services/ozmo-api/use-query-cache';

const useRestoreAnswer = (
  contentEntryId: number,
  localizedContentEntryId: number
) => {
  const api = useOzmoApiService();
  const dispatchToast = useAppToast();

  const { data: lce, isLoading } = api.LocalizedContentEntry.get({
    id: localizedContentEntryId,
    contentEntryId,
  });

  const handleRestoreAnswer = useCallback(async () => {
    const title = lce?.properties?.title;
    try {
      const restoredLce = await api.LocalizedContentEntry.restoreAsync({
        id: localizedContentEntryId,
        contentEntryId,
      });

      if (restoredLce?.deletedAt) {
        throw new Error('Localized content entry is still deleted');
      }
      // invalidate the query to refetch the now restored LCE
      api.queryClient.invalidateQueries(
        generateQueryKey(
          localizedContentEntryId,
          `authoring/content_entries/${contentEntryId}/localized_content_entries`
        )
      );

      dispatchToast({
        level: 'success',
        message: (
          <Typography>
            <Typography fontWeight="bold" component="span">
              {title}
            </Typography>
            {' has been successfully restored.'}
          </Typography>
        ),
      });
    } catch (error) {
      dispatchToast({
        level: 'error',
        message: (
          <Typography>
            {'Unable to restore '}
            <Typography fontWeight="bold" component="span">
              {title}
            </Typography>
            {'. Please try again'}
          </Typography>
        ),
      });
    }
  }, [
    localizedContentEntryId,
    contentEntryId,
    lce?.properties?.title,
    api.LocalizedContentEntry,
    api.queryClient,
    dispatchToast,
  ]);

  return { lce, isLoading, handleRestoreAnswer };
};

export default useRestoreAnswer;
