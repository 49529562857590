import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Skeleton, Box } from '@mui/material';
import Contributor from 'components/contributor';
import { useContributors } from 'services/hooks/api-hooks';

type Props = {
  contentEntryId: number;
};

const StyledContributors = styled.div`
  & > div {
    margin-bottom: 8px;
  }
`;

const Contributors: FunctionComponent<Props> = ({ contentEntryId }) => {
  const { isLoading, contributors } = useContributors(contentEntryId);

  if (isLoading) {
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          marginBottom={1}
          data-testid="content-sidepanel-contributors:skeleton"
        >
          <Skeleton variant="circular" width={24} height={24} />
          <Skeleton sx={{ marginLeft: 1 }} variant="rectangular" width="40%" />
        </Box>
      </>
    );
  }
  return (
    <StyledContributors>
      {contributors.map((c) => (
        <Contributor key={c.email} {...c} />
      ))}
    </StyledContributors>
  );
};

export default Contributors;
