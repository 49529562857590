// Adapted from https://matthiashager.com/converting-snake-case-to-camel-case-object-keys-with-javascript

type UnknownObject = Record<string, unknown>;

const toCamel = (s: string) => {
  return s.replace(/([_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('_', '');
  });
};

const isArray = function (a: unknown): a is unknown[] {
  return Array.isArray(a);
};

const isObject = function (o: unknown): o is UnknownObject {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const keysToCamel = function (o: any): any {
  if (isObject(o)) {
    const n = {} as UnknownObject;

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i: any) => {
      return keysToCamel(i);
    });
  }

  return o;
};

export { toCamel };
export default keysToCamel;
