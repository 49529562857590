import { memo, FC, ChangeEvent, MouseEvent } from 'react';
import { TableCell, TableHead, TableRow, Checkbox } from '@mui/material';
import ContentTableHeaderCell from 'components/content-table-header-cell';

import { headCells } from './head-cells';

type Props = {
  selectCount: number;
  rowCount: number;
  orderBy: string;
  order: TableOrder;
  handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSort: (event: MouseEvent, property: keyof ContentTableData) => void;
};

const ContentTableHeader: FC<Props> = ({
  selectCount,
  rowCount,
  orderBy,
  order,
  handleSelectAll,
  handleSort,
}) => {
  return (
    <TableHead data-testid="content-table-header">
      <TableRow>
        {/* checkbox */}
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={selectCount > 0 && selectCount < rowCount}
            checked={rowCount > 0 && selectCount === rowCount}
            onChange={handleSelectAll}
            inputProps={{
              'aria-label': 'select all content',
            }}
          />
        </TableCell>
        {/* data columns */}
        {headCells.map((headCell) => (
          <ContentTableHeaderCell
            key={headCell.id}
            order={order}
            orderBy={orderBy}
            handleSort={handleSort}
            {...headCell}
          />
        ))}
        {/* Empty space for info panel icon */}
        <TableCell
          data-testid="content-table-panel-icon-header"
          align="center"
          padding="none"
        />
      </TableRow>
    </TableHead>
  );
};
const memoizedContentTableHeader = memo(
  ContentTableHeader,
  (prevProps, nextProps) =>
    prevProps.selectCount === nextProps.selectCount &&
    prevProps.rowCount === nextProps.rowCount &&
    prevProps.order === nextProps.order &&
    prevProps.orderBy === nextProps.orderBy
);
export default memoizedContentTableHeader;
