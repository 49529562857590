import { FC } from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Create, Visibility } from '@mui/icons-material';
import { ContentTypes } from 'types/enums';
import { isLegacyContent } from 'services/utils/is-legacy-content';
import { useEventTracking } from 'services/analytics';
import usePreviousPage from 'services/utils/use-previous-page';

type Props = {
  contentType: string;
  rowId: number;
};

const EditMenuItem: FC<Props> = ({ contentType, rowId }) => {
  const isCollectionType = contentType === ContentTypes.COLLECTION;
  const isLegacy = isLegacyContent(contentType);

  const Icon = isLegacy ? Visibility : Create;
  const text = isLegacy ? 'View' : 'Edit';

  const { generateUrlWithPreviousPage } = usePreviousPage();
  const trackEvent = useEventTracking();

  const editLink = generateUrlWithPreviousPage(
    `/${isCollectionType ? 'collection' : 'edit'}/${rowId}`
  );

  return (
    <MenuItem
      component={Link}
      to={editLink}
      onClick={() => {
        trackEvent('search-result-selected', { id: rowId, type: 'answer' });
      }}
    >
      <ListItemIcon>
        <Icon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </MenuItem>
  );
};

export default EditMenuItem;
