import {
  Card,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  styled,
  Typography,
} from '@mui/material';
import { FCWithChildren } from 'types/fc-with-children';
import { Box } from '@mui/system';
import { Delete, Link, MoreVert } from '@mui/icons-material';
import IconMenuButton from 'components/icon-menu-button';
import { useRemoveArrayItem } from 'scenes/universal-content-editor/hooks/use-remove-array-item';
import { useCopyLinkToPath } from 'scenes/universal-content-editor/hooks/use-copy-link-to-path';

import AddNewButton from '../add-new-button';
import HeaderOffsetAnchor from '../header-offset-anchor';

const StyledCard = styled(Card)<{ $level: number }>`
  --background: ${({ $level }) =>
    $level % 2
      ? 'var(--color-neutral-one)'
      : 'var(--color-primary-background)'};

  &:hover,
  &:focus-within {
    border-left: 3px solid var(--color-primary-base);
  }

  border-left: 3px solid var(--background);
  background-color: var(--background);
`;

type Props = {
  displayName: string;
  displayOrder: number;
  name: string;
  level: number;
  path: string;
  count?: number;
  siblings?: number;
  onAddNew?: VoidFunction;
  onRemove?: VoidFunction;
};

const SchemaObject: FCWithChildren<Props> = ({
  children,
  count,
  name,
  level,
  displayName,
  displayOrder,
  path,
  siblings,
  onAddNew,
  onRemove,
}) => {
  const {
    removeConfirmationModal,
    openRemoveConfirmationModal,
  } = useRemoveArrayItem(displayName, onRemove);

  const { handleCopyLinkClick } = useCopyLinkToPath(path);

  if (level === 0) {
    return <>{children}</>;
  }

  return (
    <Box component="div" order={displayOrder} tabIndex={displayOrder}>
      <HeaderOffsetAnchor path={path} />
      <StyledCard $level={level}>
        <Box
          marginLeft={4}
          marginRight={4}
          marginTop={1}
          marginBottom={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography textTransform={'uppercase'} variant="subtitle2">
            {displayName || name}
            {siblings && ` ${count}/${siblings}`}
          </Typography>
          <IconMenuButton icon={<MoreVert />} label="More actions">
            <MenuItem onClick={handleCopyLinkClick}>
              <ListItemIcon>
                <Link />
              </ListItemIcon>
              <Typography>{'Copy link'}</Typography>
            </MenuItem>
            {onRemove && removeConfirmationModal}
            {onRemove && (
              <MenuItem onClick={openRemoveConfirmationModal}>
                <ListItemIcon>
                  <Delete color="error" />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="error">{'Delete'}</Typography>
                </ListItemText>
              </MenuItem>
            )}
          </IconMenuButton>
        </Box>
        <Divider />
        <Box
          margin={4}
          className="children"
          display="flex"
          flexDirection="column"
          gap={3}
        >
          {children}
        </Box>
      </StyledCard>
      {onAddNew && (
        <AddNewButton
          level={level}
          onAddNew={onAddNew}
          displayName={displayName}
        />
      )}
    </Box>
  );
};

export default SchemaObject;
