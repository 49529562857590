import { createContext, useContext } from 'react';
import { FCWithChildren } from 'types/fc-with-children';

type MediaData = {
  id: number;
  path: string;
  displayOrder: number;
};

export const MediaContext = createContext<MediaData | null>(null);
export const useMediaContext = () => useContext(MediaContext);

export const SchemaMediaProvider: FCWithChildren<MediaData> = ({
  children,
  id,
  displayOrder,
  path,
}) => (
  <MediaContext.Provider value={{ id, displayOrder, path }}>
    {children}
  </MediaContext.Provider>
);
