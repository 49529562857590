import styled from 'styled-components';
import { useCallback, useState, useMemo } from 'react';
import { TapIndicator, WaypointIndicator } from 'components/indicators';
import { func, number, bool, string } from 'services/utils/prop-types';

import SelectedIndicator from '../selected-indicator';
import DraggableBase, { BaseProps } from '../draggable-base';
import DirectionalArrow from '../directional-arrow';
import { createConnections } from '../draggable-utils';

const StyledDragWrapper = styled.div`
  height: 0px;
  width: 0px;
  pointer-events: initial;
  cursor: pointer;
`;

const StyledIndicatorContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`;

export type Props = BaseProps & {
  indicator: MovingIndicator;
};

const DraggableMovingIndicator: React.FC<Props> = ({
  indicator,
  onSave,
  initialSelected,
  ...props
}) => {
  const [indicatorState, setIndicatorState] = useState<MovingIndicator>(
    indicator
  );

  // because timing issues can occur between selection/deselection from the
  // draggables, keep selection state as a set instead of a single piece
  const [selectedIndices, setSelectedIndices] = useState<Set<number>>(() => {
    return new Set(initialSelected ? [0] : []);
  });

  const [dragPosition, setDragPosition] = useState({ index: -1, x: 0, y: 0 });

  const onSelect = useCallback(
    (waypointIdx: number) => (selected: boolean) => {
      setSelectedIndices((set) => {
        const newSet = new Set(set);
        if (selected) {
          newSet.add(waypointIdx);
        } else {
          newSet.delete(waypointIdx);
        }

        return newSet;
      });
    },
    [setSelectedIndices]
  );

  const handleWaypointSave = useCallback(
    (waypointIdx: number) => (index: number, wp: Indicator) => {
      if (!indicatorState.waypoints) return;

      const waypoint = indicatorState.waypoints[waypointIdx];
      const updated = { ...waypoint, x: wp.x, y: wp.y };

      const updatedWaypoints = [
        ...indicatorState.waypoints.slice(0, waypointIdx),
        updated,
        ...indicatorState.waypoints.slice(waypointIdx + 1),
      ];

      const basePositionUpdate = waypointIdx === 0 ? { x: wp.x, y: wp.y } : {};

      const newWaypoint: MovingIndicator = {
        ...indicatorState,
        ...basePositionUpdate,
        waypoints: updatedWaypoints,
      };

      onSelect(waypointIdx)(false);
      setIndicatorState(newWaypoint);

      // if we've unselected all indicators, save
      // place in a timeout so the state update completes first
      // we're checking for a size of 1 here because the removal above
      // will not have taken effect yet
      if (selectedIndices.size === 1) {
        setTimeout(() => onSave(props.index, newWaypoint), 0);
      }
    },
    [
      onSelect,
      indicatorState,
      setIndicatorState,
      selectedIndices,
      props.index,
      onSave,
    ]
  );

  const onMove = useCallback(
    (index: number) => (x: number, y: number) => {
      setDragPosition({ index, x, y });
    },
    [setDragPosition]
  );

  const connections = useMemo(() => {
    return createConnections(
      indicatorState.waypoints,
      props.parentWidth,
      props.parentHeight,
      dragPosition
    );
  }, [
    indicatorState.waypoints,
    props.parentWidth,
    props.parentHeight,
    dragPosition,
  ]);

  if (selectedIndices.size === 0) {
    return (
      <StyledIndicatorContainer
        style={{ width: props.parentWidth, height: props.parentHeight }}
      >
        <StyledDragWrapper>
          <WaypointIndicator
            x={indicator.x}
            y={indicator.y}
            waypoints={indicator.waypoints}
            onClick={onSelect(0)}
            isPaused={props.isPaused}
            data-testid="draggable-moving-indicator:wrapper"
          />
        </StyledDragWrapper>
      </StyledIndicatorContainer>
    );
  }

  return (
    <>
      {indicator.waypoints?.map((wp, ix) => (
        <DraggableBase
          key={ix}
          {...props}
          indicatorType={'tap'}
          initialX={wp.x}
          initialY={wp.y}
          initialSelected={selectedIndices.has(ix)}
          onSave={handleWaypointSave(ix)}
          onSelected={onSelect(ix)}
          onMove={onMove(ix)}
          selectedComponent={SelectedIndicator}
          unselectedComponent={TapIndicator}
        />
      ))}
      <StyledIndicatorContainer
        style={{ width: props.parentWidth, height: props.parentHeight }}
      >
        {connections.map((conn, ix) => (
          <DirectionalArrow key={ix} angle={conn.angle} x={conn.x} y={conn.y} />
        ))}
      </StyledIndicatorContainer>
    </>
  );
};

DraggableMovingIndicator.propTypes = {
  index: number.isRequired,
  parentHeight: number.isRequired,
  parentWidth: number.isRequired,
  initialSelected: bool,
  isPaused: bool,
  boundingParentSelector: string,
  onSave: func.isRequired,
  onDelete: func.isRequired,
};

export default DraggableMovingIndicator;
