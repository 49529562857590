import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import NoResultsIcon from 'icons/no-results-found-laptop-magnifying-glass.svg?react';

const QuickAddNoResults: FunctionComponent = () => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <NoResultsIcon />
    <Typography variant="body1">{'No results found'}</Typography>
    <Typography variant="body2">{'Please try your search again'}</Typography>
  </Box>
);

export default QuickAddNoResults;
