import { useCallback, FC, SyntheticEvent } from 'react';
import { Tabs, Tab } from '@mui/material';

export type TabLabelProps = {
  currentTab: number;
  setCurrentTab: (tab: number) => void;
  tabLabels: string[];
};

const TabLabels: FC<TabLabelProps> = ({
  currentTab,
  setCurrentTab,
  tabLabels,
}) => {
  const handleChange = useCallback(
    (_e: SyntheticEvent, newTab: number) => setCurrentTab(newTab),
    [setCurrentTab]
  );

  return (
    <Tabs value={currentTab} onChange={handleChange}>
      {tabLabels.map((label) => (
        <Tab key={label} label={label} />
      ))}
    </Tabs>
  );
};

export default TabLabels;
