import { useOzmoAuthUser } from 'scenes/google-auth';
export const PER_USER_FLAG_PREFIX = 'ozmo-authoring-user-flag';

export const usePerUserFlag = () => {
  const user = useOzmoAuthUser();

  const formatFlagName = (flag: string, userId: number) =>
    `${PER_USER_FLAG_PREFIX}-${flag}-${userId}`;

  const getFlag = (flag: string) => {
    if (!user) {
      console.error('User not set, cannot save user flag');
      return null;
    }

    try {
      const flagName = formatFlagName(flag, user.id);
      const item = localStorage.getItem(flagName);
      return item ? JSON.parse(item) : item;
    } catch (e) {
      console.error(e);
    }
  };

  const setFlag = (flag: string, value: any) => {
    if (!user) {
      console.error('User not set, cannot save user flag');
      return null;
    }

    try {
      const flagName = formatFlagName(flag, user.id);
      localStorage.setItem(flagName, JSON.stringify(value));
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  return { getFlag, setFlag };
};
