import { useEffect, useState, useRef, FunctionComponent, memo } from 'react';
import { IconButton, Typography, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import LoadingIndicator from 'components/loading-indicator';
import { truncateFilename } from 'services/utils/file-utils';

import { createCompositeCanvasLink } from './canvas-composite-image-utils';

const StyledCanvas = styled('canvas')`
  max-height: 100%;
  max-width: 100%;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: -6px;
  right: 0px;
`;

const StyledErrorContainer = styled('div')`
  border: 2px dashed var(--color-messaging-error);
  background-color: #fff;
  border-radius: 6px;
  height: 100%;
  width: 200px;
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 8px 0px;
  }
  p {
    word-break: break-word;
    text-align: center;
  }
`;

type Props = {
  overlaySource?: string;
  shellSource: string;
  screenCoordinates: {
    x: number;
    y: number;
    height: number;
    width: number;
  };
  onCompositeComplete: (link: HTMLAnchorElement) => void;
  onRemoveFile: () => void;
  onError?: (error: Error) => void;
  file?: File;
  shellName: string;
};

const CanvasCompositeImage: FunctionComponent<Props> = ({
  overlaySource,
  screenCoordinates,
  shellSource,
  onCompositeComplete,
  onError,
  onRemoveFile,
  file,
  shellName,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>();

  // this ref is mostly here to prevent createCompositeCanvasLink from
  // executing twice when StrictMode is enabled (which causes
  // all useEffects to trigger twice on mount.)
  const isLinkProcessing = useRef(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!file) return;

    if (canvas && !isLinkProcessing.current) {
      setIsLoading(true);
      isLinkProcessing.current = true;
      createCompositeCanvasLink(
        canvas,
        file,
        shellSource,
        screenCoordinates,
        overlaySource,
        shellName
      )
        .then((link) => onCompositeComplete(link))
        .catch((error) => {
          setError(error);
          onError && onError(error);
        })
        .finally(() => {
          setIsLoading(false);
          isLinkProcessing.current = false;
        });
    }
  }, [
    shellSource,
    overlaySource,
    screenCoordinates,
    file,
    onCompositeComplete,
    onError,
    shellName,
  ]);

  if (!file) {
    return null;
  }

  if (error) {
    return (
      <StyledErrorContainer>
        <StyledIconButton
          onClick={onRemoveFile}
          size="small"
          aria-label="Remove File"
        >
          <DeleteIcon color="error" />
        </StyledIconButton>
        <ErrorIcon color="error" />
        <Typography color="error">
          <b>{truncateFilename(file.name)}</b>
        </Typography>
        <Typography color="error">
          {'Unable to generate composite image'}
        </Typography>
      </StyledErrorContainer>
    );
  }

  return (
    <>
      {isLoading && <LoadingIndicator message="Loading..." />}
      {!isLoading && (
        <StyledIconButton
          onClick={onRemoveFile}
          size="small"
          aria-label="Remove File"
        >
          <DeleteIcon color="error" />
        </StyledIconButton>
      )}
      <StyledCanvas ref={canvasRef} />
    </>
  );
};

export default memo(CanvasCompositeImage);
