import { FC, useState, useCallback, MouseEvent } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { MoreVert, Create, Delete } from '@mui/icons-material';

import UpdateCategory from '../categories/category-details/add-edit-category';

import { useRemoveCategoryModal } from './hooks';

type Props = {
  category: LocalizedCollectionCategory;
  collectionTitle: string;
  onEdit: (
    category: Omit<LocalizedCollectionCategory, 'items'>
  ) => Promise<boolean>;
  onRemove: VoidFunction;
};

const CategoryActions: FC<Props> = ({
  category,
  collectionTitle,
  onEdit,
  onRemove,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { removeModal, openRemoveModal } = useRemoveCategoryModal(
    category.title,
    collectionTitle,
    onRemove,
    setAnchorEl
  );
  const open = Boolean(anchorEl);

  const handleMenuOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // prevent "selecting" the category
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback((event: any) => {
    event.stopPropagation(); // prevent "selecting" the category
    setAnchorEl(null);
  }, []);

  const handleUpdate = useCallback(
    (category: Omit<LocalizedCollectionCategory, 'items'>) => {
      setAnchorEl(null);
      return onEdit(category);
    },
    [onEdit]
  );

  return (
    <>
      <IconButton
        aria-controls={open ? 'category-card-actions' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="category-card-actions"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        {/* Edit */}
        <UpdateCategory
          onSubmit={handleUpdate}
          onRefuse={() => setAnchorEl(null)}
          existingCategory={category}
        >
          <MenuItem>
            <ListItemIcon>{<Create fontSize="small" />}</ListItemIcon>
            <ListItemText>{'Edit'}</ListItemText>
          </MenuItem>
        </UpdateCategory>

        {/* Remove */}
        <MenuItem onClick={openRemoveModal}>
          <ListItemIcon>
            {<Delete fontSize="small" color="error" />}
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'error' }}>
            {'Remove'}
          </ListItemText>
        </MenuItem>
      </Menu>
      {removeModal}
    </>
  );
};

export default CategoryActions;
