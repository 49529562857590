import { FC, useCallback, useRef } from 'react';
import { Box, Skeleton } from '@mui/material';
import { SIGNAL_CLEAR_ATTRIBUTES, useConsumeSignal } from 'components/signal';

import {
  useAttributeSearch,
  useStaticAttributes,
  useKeyboardNavigation,
} from './hooks';
import {
  SelectedAttributes,
  AllowedAttributeKeys,
  AttributesWithValueType,
} from './types';
import SearchInput from './search-input';
import SearchResults from './search-results';

export type AttributeSelectorProps = {
  startingAttributes?: AttributesWithValueType | SelectedAttributes;
  prohibitedAttributes?: AllowedAttributeKeys[];
  inputPlaceholder?: string;
  prohibitedContentTypes?: ContentEntryContentType[];
  showStaticAttributes?: boolean;
  className?: string;
  onSelectedAttrChange?: (attrs: SelectedAttributes) => void;
  onShiftEnterPressed?: VoidFunction;
  onResultsPanelToggle?: (isOpen: boolean) => void;
  syncWithQueryParams?: boolean;
};

const AttributeSelector: FC<AttributeSelectorProps> = ({
  startingAttributes,
  prohibitedAttributes,
  inputPlaceholder,
  prohibitedContentTypes,
  showStaticAttributes = false,
  className,
  onSelectedAttrChange,
  onShiftEnterPressed,
  onResultsPanelToggle,
  syncWithQueryParams = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    searchTerm,
    isLoading,
    selectedAttributes,
    attributes,
    keywordAttribute,
    resultsPanelOpen,
    showResults,
    handleAttributeSelect,
    handleAttributeRemove,
    isAttributeSelected,
    handleSearchInput,
    handleShowResults,
    handleHideResults,
    clearSelectedAttributes,
  } = useAttributeSearch(
    onSelectedAttrChange,
    onResultsPanelToggle,
    startingAttributes,
    prohibitedAttributes,
    containerRef,
    syncWithQueryParams
  );
  const { staticAttributes } = useStaticAttributes(
    prohibitedContentTypes,
    prohibitedAttributes
  );

  const handleShiftEntryPressed = useCallback(() => {
    if (onShiftEnterPressed) {
      onShiftEnterPressed();
      handleHideResults();
    }
  }, [onShiftEnterPressed, handleHideResults]);

  const { handleKeyDown } = useKeyboardNavigation(
    attributes,
    staticAttributes,
    keywordAttribute,
    containerRef,
    handleHideResults,
    handleShiftEntryPressed
  );

  useConsumeSignal(SIGNAL_CLEAR_ATTRIBUTES, clearSelectedAttributes);

  return (
    <Box
      ref={containerRef}
      component="div"
      id="attribute-selector-container"
      onKeyDown={handleKeyDown}
      className={className}
    >
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          height="40px"
          data-testid="attribute-selector:loading"
        />
      ) : (
        <>
          <SearchInput
            searchTerm={searchTerm}
            placeholder={inputPlaceholder}
            keywordAttribute={keywordAttribute}
            selectedAttributes={selectedAttributes}
            onAttrSelect={handleAttributeSelect}
            onAttrRemove={handleAttributeRemove}
            onChange={handleSearchInput}
            onFocus={handleShowResults}
            onClearAll={clearSelectedAttributes}
          />
          <Box position="relative">
            <SearchResults
              isOpen={resultsPanelOpen}
              showResults={showResults}
              showStaticAttributes={showStaticAttributes}
              keywordAttribute={keywordAttribute}
              attributes={attributes}
              staticAttributes={staticAttributes}
              onAttrSelect={handleAttributeSelect}
              onAttrRemove={handleAttributeRemove}
              isAttrSelected={isAttributeSelected}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AttributeSelector;
