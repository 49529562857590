import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDropZone = styled.div<{ isDraggingOver: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--color-neutral-one);
  border: ${({ isDraggingOver }) =>
    isDraggingOver ? '2px dashed #e4e4e4' : '2px dashed #979797'};
`;

const StyledDropZoneOverlay = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

type Props = {
  isDraggingOver: boolean;
  children: any;
};

const DragAndDropArea = forwardRef<HTMLDivElement, Props>(
  ({ isDraggingOver, children }, ref) => (
    <StyledDropZone
      data-testid="drag-and-drop:styled-drop-zone"
      ref={ref}
      isDraggingOver={isDraggingOver}
    >
      {isDraggingOver ? (
        <StyledDropZoneOverlay data-testid="drag-and-drop:overlay">
          <div>{'Drop to select'}</div>
        </StyledDropZoneOverlay>
      ) : null}
      {children}
    </StyledDropZone>
  )
);

// Hack required to add displayName to component when using forwardRef
DragAndDropArea.displayName = 'DragAndDropArea';
DragAndDropArea.propTypes = {
  isDraggingOver: PropTypes.bool.isRequired,
};

export default DragAndDropArea;
