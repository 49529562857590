/* eslint-disable no-underscore-dangle */
import api from 'services/ozmo-api';
import useQueryCache, {
  generateQueryKey,
  updateData,
  updateCacheOptimistically,
  onUpdateSuccess,
  deleteData,
  removeFromCacheOptimistically,
  onDeleteSuccess,
  createData,
  onMutationError,
  getData,
  UseQueryCacheResponse,
  UseQueryOptionsWithPrefetch,
  UrlParamOptions,
  Pagination,
} from 'services/ozmo-api/use-query-cache';
import camelCaseToSnakeCase from 'services/utils/convert-object-keys-camel-to-snake';

import { StaleWriteError } from '../errors';

function OzmoApiBase<
  DataModel extends BaseModel,
  DataUpdateModel,
  DataCreateModel,
  ResourcePathVariables
>() {
  type QueryResponseWithData = {
    data: DataModel;
    response: Response;
  };
  type QueryResponseWithDataArray = {
    data: DataModel[];
    response: Response;
  };
  abstract class OzmoApiBase {
    protected static readonly = false;
    protected static resourcePath: string = 'default';
    protected static embedOptions: Array<string> = [];
    protected static urlParamOptions?: {
      [option: string]: boolean | string | number;
    };
    protected static defaultReactQueryConfig: UseQueryOptionsWithPrefetch = {};

    protected _readonly = false;
    protected query: any;
    protected _data: DataModel | DataModel[];

    public get data() {
      if (Array.isArray(this._data)) {
        console.log(
          '%c[OzmoAPI Warning]' +
            '%c You accessed .data on a model, but the data is an array.  If you fetched this data using .getAll() you should access it using .all instead.',
          'background: #daff62; color: #ff0000',
          'background: #fff, color: #000'
        );
      }
      return this._data as DataModel;
    }
    public get all(): DataModel[] {
      if (Array.isArray(this._data)) {
        return this._data;
      }
      if (this._data && Object.keys(this._data).length > 0) {
        console.log(
          '%c[OzmoAPI Warning]' +
            '%c You accessed .all on a model, but the data is not an array.  If you fetched this data using .get() you should access it using .data instead.',
          'background: #bada55; color: #ff0000',
          'background: #fff, color: #000'
        );
        return [this._data];
      }
      return [];
    }

    private _update: Function;
    private _updateCacheOnly: Function;
    private _delete: Function;

    public refetch: Function;
    public reset: Function;
    public isLoading: boolean;
    public isSuccess: boolean;
    public isError: boolean;
    public isUpdating: boolean;
    public isUpdateSuccess: boolean;
    public isUpdateError: boolean;
    public isFetching: boolean;
    public id: number;
    public error: any;
    public pagination: Pagination;

    constructor(queryResponse: UseQueryCacheResponse, readonly: boolean) {
      this.query = queryResponse.query;
      this._update = queryResponse.update;
      this._updateCacheOnly = queryResponse.updateCacheOnly;
      this._delete = queryResponse.delete;
      this.refetch = queryResponse.refetch;
      this.error = queryResponse.error;
      this.isLoading = queryResponse.isLoading;
      this.isError = queryResponse.isError;
      this.isSuccess = queryResponse.isSuccess;
      this.isUpdating = queryResponse.isUpdating;
      this.isUpdateSuccess = queryResponse.isUpdateSuccess;
      this.isUpdateError = queryResponse.isUpdateError;
      this.isFetching = queryResponse.isFetching;
      this.id = queryResponse.data?.id;
      this.reset = queryResponse.reset;
      this._data = queryResponse.data;
      this._readonly = readonly;
      this.pagination = queryResponse.pagination;
    }

    protected static parseResourcePathString = (
      resourcePath: string,
      options?: ResourcePathVariables
    ): [string, number?] => {
      try {
        let parsed = Object.keys(options || {}).reduce(
          (substitutedPath, optionKey) => {
            if (!(options as any)[optionKey]) return substitutedPath;
            return substitutedPath.replace(
              `:${optionKey}`,
              (options as any)[optionKey].toString()
            );
          },
          resourcePath
        );

        const idRemovalRegex = new RegExp(`/${(options as any)?.id || ''}$`);
        parsed = parsed
          .replace(/(:.*\/)/g, '') // Remove any variables that didn't get substituted
          .replace(/(:.*$)/g, '') // Remove the final :id if it didn't get sub'd
          .replace(/\/$/, '') // Remove any trailing slashes
          .replace(idRemovalRegex, ''); // Remove the ID

        return [parsed, (options as any)?.id as number];
      } catch (error) {
        console.log(error);
        return ['', undefined];
      }
    };

    static getQueryKey(
      options?: ResourcePathVariables,
      urlParamOptions?: UrlParamOptions,
      embedOptions?: string[]
    ) {
      const [resourcePath, id] = this.parseResourcePathString(
        (this as any).resourcePath,
        options
      );
      return generateQueryKey(
        id ?? '/',
        resourcePath,
        urlParamOptions,
        embedOptions ?? (this as any).embedOptions
      );
    }

    /**
     * @param options object - The required variables for the operation, (usually { id })
     * @param config object (optional) - react-query options (see: https://react-query.tanstack.com/reference/useQuery)
     * @param embedOptions string[] (optional) - Array of strings to replace the default embed options for a model for this call
     */
    static get<DerivedClass extends OzmoApiBase>(
      this: new (...args: any[]) => DerivedClass,
      options: ResourcePathVariables,
      config?: UseQueryOptionsWithPrefetch,
      embedOptions?: string[],
      urlParamOptions: UrlParamOptions = {}
    ) {
      const [resourcePath, id] = (this as any).parseResourcePathString(
        (this as any).resourcePath,
        options
      );
      const queryResponse = (this as any).getQueryCacheResponse(
        id,
        resourcePath,
        embedOptions ?? (this as any).embedOptions,
        { ...(this as any).defaultReactQueryConfig, ...config },
        { ...(this as any).urlParamOptions, ...urlParamOptions }
      );
      return new this(queryResponse, (this as any).readonly);
    }

    /**
     * @param options object - The required variables for the operation, (usually { id })
     * @param config object (optional) - react-query options (see: https://react-query.tanstack.com/reference/useQuery)
     * @param urlParamOptions object (optional) - URL query params (e.g.: perPage, limit, etc)
     * @param embedOptions string[] (optional) - Array of strings to replace the default embed options for a model for this call
     */
    static getAll<DerivedClass extends OzmoApiBase>(
      this: new (...args: any[]) => DerivedClass,
      options?: ResourcePathVariables,
      config?: UseQueryOptionsWithPrefetch,
      urlParamOptions?: UrlParamOptions,
      embedOptions?: string[]
    ) {
      const [resourcePath] = (this as any).parseResourcePathString(
        (this as any).resourcePath,
        options
      );
      const queryResponse = (this as any).getQueryCacheResponse(
        '/',
        resourcePath,
        embedOptions ?? (this as any).embedOptions,
        { ...(this as any).defaultReactQueryConfig, ...config },
        urlParamOptions
      );
      return new this(queryResponse, (this as any).readonly);
    }

    static getQueryCacheResponse(
      id: number,
      resourcePath: string,
      embedOptions: string[],
      config: UseQueryOptionsWithPrefetch,
      options: UrlParamOptions
    ): UseQueryCacheResponse {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useQueryCache(id, resourcePath, embedOptions, config, options);
    }

    /**
     * @param options object - The required variables for the operation, (usually { id })
     * @param config object (optional) - react-query options (see: https://react-query.tanstack.com/reference/useQuery)
     * @param urlOptions object (optional) - URL query params (e.g.: perPage, limit, etc)
     * @param embedOptions string[] (optional) - Array of strings to replace the default embed options for a model for this call
     */
    static getAsync(
      options: ResourcePathVariables,
      config?: UseQueryOptionsWithPrefetch,
      urlOptions?: UrlParamOptions,
      embedOptions?: string[]
    ): Promise<DataModel> {
      const [resourcePath] = this.parseResourcePathString(
        this.resourcePath,
        options
      );
      return new Promise(async (resolve, reject) => {
        const urlParamOptions = urlOptions ?? this.urlParamOptions;
        const snakeyUrlOptions = camelCaseToSnakeCase(urlParamOptions);
        const queryKey = this.getQueryKey(options, urlParamOptions);
        api.queryClient
          .fetchQuery(
            queryKey,
            () =>
              getData(
                resourcePath,
                embedOptions ?? this.embedOptions,
                {
                  queryKey,
                },
                snakeyUrlOptions
              ),
            { ...this.defaultReactQueryConfig, ...config }
          )
          .then((data: any) => resolve(data))
          .catch((error: any) => reject(error));
      });
    }

    /**
     * @param options object - The required variables for the operation, (usually { id })
     * @param config object (optional) - react-query options (see: https://react-query.tanstack.com/reference/useQuery)
     * @param urlOptions object (optional) - URL query params (e.g.: perPage, limit, etc)
     * @param embedOptions string[] (optional) - Array of strings to replace the default embed options for a model for this call
     */
    static getWithResponseAsync(
      options: ResourcePathVariables,
      config?: UseQueryOptionsWithPrefetch,
      urlOptions?: UrlParamOptions,
      embedOptions?: string[]
    ): Promise<QueryResponseWithData> {
      const [resourcePath] = this.parseResourcePathString(
        this.resourcePath,
        options
      );
      return new Promise(async (resolve, reject) => {
        const queryKey = this.getQueryKey(options);
        api.queryClient
          .fetchQuery(
            queryKey,
            () =>
              getData(resourcePath, embedOptions ?? this.embedOptions, {
                queryKey,
              }),
            { ...this.defaultReactQueryConfig, ...config }
          )
          // @ts-ignore
          .then((res: QueryResponseWithData) => resolve(res))
          .catch((error: any) => reject(error));
      });
    }

    /**
     * @param options object - The required variables for the operation, (usually { id })
     * @param config object (optional) - react-query options (see: https://react-query.tanstack.com/reference/useQuery)
     * @param urlOptions object (optional) - URL query params (e.g.: perPage, limit, etc)
     * @param embedOptions string[] (optional) - Array of strings to replace the default embed options for a model for this call
     */
    static getAllAsync(
      options?: ResourcePathVariables,
      config?: UseQueryOptionsWithPrefetch,
      urlOptions?: UrlParamOptions,
      embedOptions?: string[]
    ): Promise<DataModel[]> {
      const [resourcePath] = this.parseResourcePathString(
        this.resourcePath,
        options
      );
      return new Promise(async (resolve, reject) => {
        const queryKey = this.getQueryKey(options);
        api.queryClient
          .fetchQuery(
            queryKey,
            () =>
              getData(
                resourcePath,
                embedOptions ?? this.embedOptions,
                {
                  queryKey,
                },
                urlOptions
              ),
            { ...this.defaultReactQueryConfig, ...config }
          )
          .then((data: any) => resolve(data))
          .catch((error: any) => reject(error));
      });
    }

    /**
     * @param options object - The required variables for the operation, (usually { id })
     * @param config object (optional) - react-query options (see: https://react-query.tanstack.com/reference/useQuery)
     * @param urlOptions object (optional) - URL query params (e.g.: perPage, limit, etc)
     * @param embedOptions string[] (optional) - Array of strings to replace the default embed options for a model for this call
     */
    static getAllWithResponseAsync(
      options?: ResourcePathVariables,
      config?: UseQueryOptionsWithPrefetch,
      urlOptions?: UrlParamOptions,
      embedOptions?: string[]
    ): Promise<{ data: DataModel[]; response: Response }> {
      const [resourcePath] = this.parseResourcePathString(
        this.resourcePath,
        options
      );
      return new Promise(async (resolve, reject) => {
        const queryKey = this.getQueryKey(options);
        api.queryClient
          .fetchQuery(
            queryKey,
            () =>
              getData(resourcePath, embedOptions ?? this.embedOptions, {
                queryKey,
              }),
            { ...this.defaultReactQueryConfig, ...config }
          )
          // @ts-ignore
          .then((res: QueryResponseWithDataArray) => resolve(res))
          .catch((error: any) => reject(error));
      });
    }

    static createAsync(
      data: DataCreateModel,
      options?: ResourcePathVariables
    ): Promise<DataModel> {
      const [resourcePath] = this.parseResourcePathString(
        this.resourcePath,
        options
      );
      return new Promise((resolve, reject) => {
        if (this.readonly) {
          return reject('Mutation is not allowed for this model');
        }
        const snakeyData = camelCaseToSnakeCase(data);

        createData(resourcePath, snakeyData)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => reject(error));
      });
    }

    protected static async createAsyncWithCache<T, Q>(
      data: T,
      resource?: string,
      options?: ResourcePathVariables,
      urlOptions?: UrlParamOptions,
      config?: UseQueryOptionsWithPrefetch
    ): Promise<Q> {
      const [resourcePath, id] = this.parseResourcePathString(
        resource ?? this.resourcePath,
        options
      );

      return new Promise((resolve, reject) => {
        const urlParamOptions = urlOptions ?? this.urlParamOptions;
        if (this.readonly) {
          return reject('Mutation is not allowed for this model');
        }
        const snakeyData = camelCaseToSnakeCase(data);
        const queryKey = [
          ...generateQueryKey(id ?? '/', resourcePath, urlParamOptions),
          data,
        ];

        api.queryClient
          .fetchQuery(
            queryKey,
            () => createData(resourcePath, snakeyData, undefined, urlOptions),
            { ...this.defaultReactQueryConfig, ...config }
          )
          .then((result: any) => {
            resolve(result);
          })
          .catch((error) => reject(error));
      });
    }

    static updateAsync(
      options: ResourcePathVariables,
      data: DataUpdateModel
    ): Promise<DataModel> {
      const [resourcePath, id] = this.parseResourcePathString(
        this.resourcePath,
        options
      );
      return new Promise((resolve, reject) => {
        if (this.readonly) {
          return reject('Mutation is not allowed for this model');
        }
        const queryKey = this.getQueryKey(options);
        const { queryClient } = api;
        // Get a copy of the current value so we can rollback to it if the server mutation fails
        const previousVersion = queryClient.getQueryData<DataModel>(queryKey);

        // Optimistically update the cache
        updateCacheOptimistically(queryClient, queryKey, data);

        // Do the update
        const snakeyData = camelCaseToSnakeCase(data);
        updateData(
          resourcePath,
          { id, ...snakeyData },
          // if the data isn't in the cache for some reason, send now as the value for
          // If-Unmodified-Since to be as safe as possible
          previousVersion?.updatedAt ?? new Date().toISOString()
        )
          .then((result) => {
            onUpdateSuccess(
              queryClient,
              queryKey,
              result,
              data,
              previousVersion
            );
            resolve(result);
          })
          .catch((error) => {
            onMutationError(
              queryClient,
              queryKey,
              error,
              data,
              previousVersion
            );
            if (error.code === 412) {
              return reject(new StaleWriteError(error.message, queryKey));
            }
            reject(error);
          });
      });
    }

    static deleteAsync(options: ResourcePathVariables): Promise<boolean> {
      const [resourcePath, id] = this.parseResourcePathString(
        this.resourcePath,
        options
      );
      return new Promise((resolve, reject) => {
        if (this.readonly) {
          return reject('Mutation is not allowed for this model');
        }
        const queryKey = this.getQueryKey(options);
        const { queryClient } = api;
        // Get a copy of the current value so we can rollback to it if the server mutation fails
        const previousVersion = queryClient.getQueryData(queryKey);

        // Optimistically update the cache
        removeFromCacheOptimistically(queryClient, queryKey);

        // Do the update
        deleteData(resourcePath, id as number)
          .then(() => {
            onDeleteSuccess(queryClient, queryKey);
            resolve(true);
          })
          .catch((error) => {
            onMutationError(
              queryClient,
              queryKey,
              error,
              undefined,
              previousVersion
            );
            reject(error);
          });
      });
    }

    static refetchAsync(
      options: ResourcePathVariables,
      filters?: Record<string, any>
    ): Promise<void> {
      const queryKey = this.getQueryKey(options);
      const { queryClient } = api;
      return queryClient.refetchQueries(queryKey, filters);
    }

    static refetchAllAsync(
      options: ResourcePathVariables,
      filters?: Record<string, any>
    ): Promise<void> {
      const [resourcePath] = this.parseResourcePathString(
        this.resourcePath,
        options
      );
      const queryKey = [resourcePath];
      const { queryClient } = api;
      return queryClient.refetchQueries(queryKey, filters);
    }

    // Public instance methods, you shouldn't need to change these
    public update = (data: DataUpdateModel): Promise<DataModel> => {
      if (this._readonly) {
        throw new Error('Mutation is not allowed for this model');
      }
      const snakeyData = camelCaseToSnakeCase(data);
      return this._update({ id: this.id, ...snakeyData });
    };

    public updateCache = (data: Partial<DataModel>): DataModel => {
      if (this._readonly) {
        throw new Error('Mutation is not allowed for this model');
      }
      const snakeyData = camelCaseToSnakeCase(data);
      return this._updateCacheOnly(snakeyData);
    };

    public delete = (): void => {
      if (this._readonly) {
        throw new Error('Mutation is not allowed for this model');
      }
      this._delete(this.id);
    };
  }

  return OzmoApiBase;
}

export default OzmoApiBase;
