import {
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  MouseEvent,
} from 'react';
import { Typography } from '@mui/material';
import { useActionModal } from 'components/modals';

export const useRemoveCategoryModal = (
  categoryTitle: string,
  collectionTitle: string,
  onRemove: VoidFunction,
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
) => {
  const removeModalContent = useMemo(
    () => (
      <Typography>
        {'You are about to remove this category, '}
        <strong>{categoryTitle}</strong>
        {', from the collection, '}
        <strong>{collectionTitle}</strong>
        {' in all locales. This action will remove the category'}
        <em>{' and '}</em>
        {'answers from this collection.'}
      </Typography>
    ),
    [categoryTitle, collectionTitle]
  );

  const { openModal, modal } = useActionModal({
    modalContent: removeModalContent,
    onConfirm: onRemove,
    disableConfirmButtonOnClick: true,
    stopPropagation: true,
    modalProps: {
      title: 'Remove category from collection?',
      confirmButtonText: 'Yes, remove category',
      closeButtonText: 'No, keep category',
      confirmButtonColor: 'danger',
    },
  });

  const openRemoveModal = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setAnchorEl(null);
      openModal();
    },
    [openModal, setAnchorEl]
  );

  return {
    removeModal: modal,
    openRemoveModal,
  };
};
