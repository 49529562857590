import { FC } from 'react';
import { OverridableComponent } from '@mui/types';
import { Typography, Stack, SvgIconTypeMap } from '@mui/material';

export type StyleOverrides = {
  color?: string;
  fontSize?: string;
  fontWeight?: string | number;
  fontStyle?: string;
  iconSize?: string;
};

type Props = StyleOverrides & {
  className?: string;
  label?: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
};

const ContentTypeBase: FC<Props> = ({
  className,
  label,
  color,
  Icon,
  fontSize,
  fontWeight = 'bold',
  fontStyle = 'normal',
  iconSize,
}) => (
  <Stack
    direction="row"
    alignItems="center"
    gap="0.25rem"
    className={className}
  >
    <Icon fontSize="small" sx={{ color, fontSize: iconSize }} />
    {label && (
      <Typography
        id="content-type-name"
        fontWeight={fontWeight}
        color="p"
        sx={{ color, fontSize, fontStyle }}
      >
        {label}
      </Typography>
    )}
  </Stack>
);

export default ContentTypeBase;
