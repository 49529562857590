/**
 * isNotNull: A typeguard to validate that a provided value is anything
 * other than null.  Useful when combined with an array filter to get
 * a correctly typed results array.
 * @example
 * const values = [true, false, null];
 * // filtered1 will be incorrectly typed as (boolean | null)[]
 * const filtered1 = values.filter(v => v !== null);
 * // filtered2 will be typed correctly now as boolean[]
 * const filtered2 = values.filter(isNotNull);
 *
 * @param t The variable to check
 * @returns true if the variable is not null
 */
export const isNotNull = <T>(t: T | null): t is NonNullable<T> => t !== null;

/**
 * isDefined: A typeguard to validate that a provided value is defined
 * @param t The variable to check
 * @returns true if the variable is not null
 */
export const isDefined = <T>(t: T | undefined): t is T => t !== undefined;
