import { Fragment, FunctionComponent } from 'react';
import {
  Skeleton,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Box,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import StatusIndicator from 'components/status-indicator';
import { CompletenessChip } from 'components/completeness-indicators';
import TrashChip from 'components/trash-chip/trash-chip';
import usePreviousPage from 'services/utils/use-previous-page';

import { useLanguages } from './hooks';

type Props = {
  contentEntryId: number;
  hasActionableLanguages: boolean;
};

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  '&:hover': {
    boxShadow: theme.shadows[5],
  },
  '&.deleted': {
    backgroundColor: 'var(--color-neutral-two)',
    fontStyle: 'italic',
    '& .MuiTypography-body1': {
      color: 'var(--color-neutral-six) !important',
    },
  },
}));

const StyledCardContent = styled(CardContent)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    padding-bottom: 16px;
  }

  svg.flip {
    transform: rotate(180deg);
  }
`;

const StyledLanguageRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 7rem;
  /* No padding under the language name's typography wrapper */
  & > p {
    padding-bottom: 0 !important;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 0.5rem 0;
`;

const Languages: FunctionComponent<Props> = ({
  contentEntryId,
  hasActionableLanguages,
}) => {
  const { isLoading, contentEntry, deleteModal, flagIncomplete } = useLanguages(
    contentEntryId
  );

  const { generateUrlWithPreviousPage } = usePreviousPage();

  if (isLoading) {
    return (
      <Skeleton
        data-testid="content-sidepanel-languages:skeleton"
        variant="rectangular"
      />
    );
  }

  if (!hasActionableLanguages) {
    return (
      <>
        {contentEntry.localizedContentEntries.map((lce, index) => (
          <Fragment key={lce.id}>
            <Box
              display="flex"
              justifyContent="space-between"
              data-testid="content-sidepanel-languages:nonactionable"
            >
              <StyledLanguageRow>
                <Typography>{`${lce.languageDisplayName} (${lce.languageShortCode})`}</Typography>
                <StatusIndicator status={lce.status} size={10} />
              </StyledLanguageRow>
              {/* Only show the chip when incomplete - ignore collections */}
              {!!lce.deletedAt && <TrashChip />}
              {flagIncomplete(lce) && (
                <CompletenessChip complete={lce.complete} />
              )}
            </Box>
            {/* Render a divider until the last item */}
            {index + 1 < contentEntry.localizedContentEntries.length && (
              <StyledDivider data-testid="content-sidepanel-languages:nonactionable-divider" />
            )}
          </Fragment>
        ))}
      </>
    );
  }

  const baseEditUrl =
    contentEntry.contentType.toLowerCase() === 'collection'
      ? 'collection'
      : 'edit';

  return (
    <>
      {contentEntry.localizedContentEntries.map((lce) => (
        <StyledCard
          key={lce.id}
          data-testid="content-sidepanel-languages:actionable"
          className={lce.deletedAt ? 'deleted' : ''}
        >
          {/* @ts-expect-error: MUI has a bug in their types */}
          <CardActionArea
            LinkComponent={Link}
            to={generateUrlWithPreviousPage(
              `/${baseEditUrl}/${contentEntryId}/${lce.languageShortCode}`
            )}
          >
            <StyledCardContent>
              <StyledLanguageRow>
                <Typography>{`${lce.languageDisplayName} (${lce.languageShortCode})`}</Typography>
                <StatusIndicator status={lce.status} size={10} />
              </StyledLanguageRow>
              {/* Only show the chip when incomplete - ignore collections */}
              {!!lce.deletedAt && <TrashChip />}
              {flagIncomplete(lce) && (
                <CompletenessChip complete={lce.complete} />
              )}
            </StyledCardContent>
          </CardActionArea>
        </StyledCard>
      ))}
      {deleteModal}
    </>
  );
};

export default Languages;
