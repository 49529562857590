import { Add } from '@mui/icons-material';
import { Button, Box } from '@mui/material';
import { FunctionComponent } from 'react';

type Props = {
  level: number;
  displayName: string;
  onAddNew?: VoidFunction;
};

const AddNewButton: FunctionComponent<Props> = ({
  displayName,
  onAddNew,
  level,
}) =>
  onAddNew ? (
    <Box
      component={Button}
      variant={level % 2 ? 'contained' : 'text'}
      startIcon={<Add />}
      marginTop={1}
      // Discard the event argument, we don't need it
      onClick={(_) => onAddNew()}
    >{`Add ${displayName}`}</Box>
  ) : null;

export default AddNewButton;
