import { Box, BoxProps } from '@mui/material';
import LoadingIndicator from 'components/loading-indicator';
import { FunctionComponent, ReactNode } from 'react';

import { useStaggeredRenderedList } from './use-staggered-rendered-list';

type Props = BoxProps & {
  perPage?: number;
  children: ReactNode[];
};

const StaggeredRenderedList: FunctionComponent<Props> = ({
  perPage = 50,
  children,
  ...boxProps
}) => {
  const { renderedItems, isLoading } = useStaggeredRenderedList(
    children,
    perPage
  );
  return (
    <Box {...boxProps}>
      {renderedItems}
      {isLoading && <LoadingIndicator message="Loading more answers..." />}
    </Box>
  );
};

export default StaggeredRenderedList;
