import { FC } from 'react';
import styled from 'styled-components';
import { Tooltip, Stack, Typography } from '@mui/material';

import { getStatusDisplayInfo } from './utils';

type Props = {
  status: LocalizedContentEntryStatus | undefined;
  withTooltip?: boolean;
  withText?: boolean;
  size?: number | string;
  colorOverride?: string;
};

const $StyledIndicator = styled.div<{ size: string; color?: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
  border-color: ${({ color }) => color};

  &.published {
    /* to create a "filled" circle */
    border-width: calc(${({ size }) => size} / 2);
  }

  &.published-with-draft {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 50%,
      ${({ color }) => color} 50%
    );
  }
`;

const StatusIndicator: FC<Props> = ({
  status,
  withTooltip = true,
  withText = false,
  size = 14,
  colorOverride,
}) => {
  if (!status) return null;
  const { text, className, color } = getStatusDisplayInfo(status);
  const formattedSize = typeof size === 'number' ? `${size}px` : size;

  return (
    <Tooltip
      arrow
      placement="bottom"
      title={text}
      leaveDelay={100}
      disableHoverListener={!withTooltip}
    >
      <Stack direction="row" alignItems="center" gap="0.25rem">
        <$StyledIndicator
          data-testid="status-indicator"
          className={className}
          size={formattedSize}
          color={colorOverride || color}
        />
        {withText && (
          <Typography color={colorOverride} fontSize={formattedSize}>
            {text}
          </Typography>
        )}
      </Stack>
    </Tooltip>
  );
};

export default StatusIndicator;
