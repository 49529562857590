import { useCallback, useMemo } from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import omit from 'lodash/omit';
import { SIGNAL_CLEAR_ATTRIBUTES, useProduceSignal } from 'components/signal';

import extractQueryAttributes from './extract-query-attributes';
import applyCategoryFilter from './apply-category-filter';

const doNavigate = (navigate: NavigateFunction, location: Location) => (
  params: URLSearchParams
) => {
  navigate(
    {
      pathname: location.pathname,
      search: params.toString(),
      hash: location.hash,
    },
    { replace: true }
  );
};

const useAnswerFiltering = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const produceSignal = useProduceSignal();

  const filterState = useMemo(() => extractQueryAttributes(location), [
    location,
  ]);

  const filterCount = useMemo(() => {
    return Object.values(omit(filterState, ['sortField', 'sortDirection']))
      .flat()
      .filter(Boolean).length;
  }, [filterState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applyNavigation = useCallback(doNavigate(navigate, location), [
    navigate,
    location,
  ]);

  const clearFilters = useCallback(() => {
    const currentParams = new URLSearchParams(location.search);
    const newParams = new URLSearchParams();
    if (currentParams.has('prev')) {
      newParams.append('prev', currentParams.get('prev')!);
    }
    produceSignal(SIGNAL_CLEAR_ATTRIBUTES);

    applyNavigation(newParams);
  }, [applyNavigation, produceSignal, location.search]);

  const setSortBy = useCallback(
    (
      field: keyof LocalizedCollectionReference,
      sortDirection: 'asc' | 'desc'
    ) => {
      const params = new URLSearchParams(location.search);
      const newSort = (sortDirection === 'desc' ? '-' : '') + field;
      params.set('sortBy', newSort);
      applyNavigation(params);
    },
    [location, applyNavigation]
  );

  const setOnlyIncomplete = useCallback(
    (onlyIncomplete: boolean) => {
      const params = new URLSearchParams(location.search);
      if (onlyIncomplete) {
        params.set('onlyIncomplete', 'true');
      } else {
        params.delete('onlyIncomplete');
      }
      applyNavigation(params);
    },
    [location, applyNavigation]
  );

  const setPublishStates = useCallback(
    (desiredStatuses?: LocalizedContentEntryStatus[]) => {
      const params = new URLSearchParams(location.search);
      params.delete('Status[]'); // clear it regardless of whether we add more back
      if (desiredStatuses) {
        desiredStatuses.forEach((status) => params.append('Status[]', status));
      }
      applyNavigation(params);
    },
    [location, applyNavigation]
  );

  const applyFilters = useCallback(
    (category: CategoryModel | CategoryModel[] | undefined) => {
      if (category === undefined) return undefined;

      if (category instanceof Array) {
        return category.map(applyCategoryFilter(filterState));
      }

      return applyCategoryFilter(filterState)(category);
    },
    [filterState]
  );

  return {
    clearFilters,
    applyFilters,
    setSortBy,
    setOnlyIncomplete,
    setPublishStates,
    filterCount,
    ...filterState,
  };
};

export default useAnswerFiltering;
