import { FunctionComponent } from 'react';
import EditOff from '@mui/icons-material/EditOff';

import BaseStaticToast from './base-static-toast';

const PermissionStaticToast: FunctionComponent = () => (
  <BaseStaticToast
    title="You have read-only permissions for this content."
    message="You can view details like attributes and languages, but cannot edit step text, images, or indicators."
    icon={<EditOff />}
  />
);

export default PermissionStaticToast;
