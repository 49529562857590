import { FC, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import DragHandle from 'components/drag-handle';
import { PermissionRequired } from 'components/permission-required';
import { containsIncompleteContent } from 'services/collections/utils';

import BaseCategory from './base-category';
import CategoryActions from './category-actions';

type Props = {
  category: LocalizedCollectionCategory;
  collectionTitle: string;
  isSelected: boolean;
  onSelect: VoidFunction;
  onRemove: VoidFunction;
  onEdit: (
    category: Omit<LocalizedCollectionCategory, 'items'>
  ) => Promise<boolean>;
};

const Category: FC<Props> = ({
  category,
  collectionTitle,
  isSelected,
  onSelect,
  onRemove,
  onEdit,
}) => {
  const flagIncomplete = useMemo(
    () => containsIncompleteContent(category.items),
    [category.items]
  );

  return (
    <BaseCategory
      isSelected={isSelected}
      onClick={onSelect}
      flagIncomplete={flagIncomplete}
    >
      <Box display="flex" alignItems="center" marginRight={2}>
        <PermissionRequired permission="edit_collection" disableElement>
          <DragHandle />
        </PermissionRequired>
      </Box>
      <Box flex={1}>
        <Typography fontSize="1rem" whiteSpace="break-spaces" lineHeight={1.25}>
          {category.title}
        </Typography>
      </Box>
      <Box justifySelf="right">
        <PermissionRequired permission="edit_collection">
          <CategoryActions
            category={category}
            collectionTitle={collectionTitle}
            onRemove={onRemove}
            onEdit={onEdit}
          />
        </PermissionRequired>
      </Box>
    </BaseCategory>
  );
};

export default Category;
