import { ozmoApiRequest } from '../ozmo-api';

export const waitForIt = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const recursiveJobRequest = async <T>(jobId: number): Promise<T> => {
  const result = await ozmoApiRequest(`jobs/status/${jobId}`, undefined, 'GET');

  if (result.status === 'working' || result.status === 'queued') {
    await waitForIt(3000);
    return recursiveJobRequest(jobId);
  }

  return result;
};

export const getSuccessCount = (response: BulkOperationJobResponse) =>
  (response?.results ?? []).reduce(
    (count, result) => (result.status === 200 ? count + 1 : count),
    0
  );
