import { FC, useState, useCallback, MouseEvent } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { MoreVert, Create, Delete } from '@mui/icons-material';
import { useAppToast } from 'contexts/app-toast-context';
import { useNavigate } from 'react-router-dom';
import { generateCollectionPath } from 'scenes/nuevo-collection/util';

import AddOrEditCategory from '../edit';
import { useCategories } from '../hooks';

import { useRemoveCategoryModal } from './hooks';

type Props = {
  category: CategoryModel;
};

const CategoryActions: FC<Props> = ({ category }) => {
  const dispatchToast = useAppToast();
  const navigate = useNavigate();
  const {
    collection,
    selectedCategoryId,
    addOrEditCategory,
    deleteCategory,
  } = useCategories();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // prevent "selecting" the category
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback((event: any) => {
    event.stopPropagation(); // prevent "selecting" the category
    setAnchorEl(null);
  }, []);

  const handleUpdate = useCallback(
    async (editedCategory: Pick<CategoryModel, 'name'>) => {
      setAnchorEl(null);
      return addOrEditCategory(editedCategory, category.id);
    },
    [addOrEditCategory, category.id]
  );

  const onDeleteCategory = useCallback(async () => {
    const success = await deleteCategory(category.id);
    if (success) {
      dispatchToast({
        level: 'success',
        message: 'Your category was successfully removed.',
      });

      if (category.id === selectedCategoryId) {
        navigate(generateCollectionPath(collection.id));
      }
    }
    return success;
  }, [
    deleteCategory,
    dispatchToast,
    navigate,
    category?.id,
    selectedCategoryId,
    collection?.id,
  ]);

  const { removeModal, openRemoveModal } = useRemoveCategoryModal(
    category.name,
    collection?.name,
    onDeleteCategory,
    setAnchorEl
  );

  return (
    <>
      <IconButton
        aria-controls={open ? 'category-card-actions' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuOpen}
        aria-label="Category actions"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="category-card-actions"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        {/* Edit */}
        <AddOrEditCategory
          onSubmit={handleUpdate}
          onRefuse={() => setAnchorEl(null)}
          existingCategory={category}
        >
          <MenuItem>
            <ListItemIcon>{<Create fontSize="small" />}</ListItemIcon>
            <ListItemText>{'Edit'}</ListItemText>
          </MenuItem>
        </AddOrEditCategory>

        {/* Remove */}
        <MenuItem onClick={openRemoveModal}>
          <ListItemIcon>
            {<Delete fontSize="small" color="error" />}
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'error' }}>
            {'Remove'}
          </ListItemText>
        </MenuItem>
      </Menu>
      {removeModal}
    </>
  );
};

export default CategoryActions;
