import { createContext, useContext } from 'react';
import { FCWithChildren } from 'types/fc-with-children';

import { useGoogleAuth } from './hooks';

const LogoutContext = createContext(() => {});
export const useAuthLogout = () => useContext(LogoutContext);

const IsLoggedInContext = createContext(false);
export const useIsLoggedIn = () => useContext(IsLoggedInContext);

export const PermissionsContext = createContext<string[]>([]);
export const useRawPermissions = () => useContext(PermissionsContext);

const GoogleAuth: FCWithChildren = ({ children }) => {
  const { isLoggedIn, logout, permissions } = useGoogleAuth();

  return (
    <LogoutContext.Provider value={logout}>
      <IsLoggedInContext.Provider value={isLoggedIn}>
        <PermissionsContext.Provider value={permissions}>
          {children}
        </PermissionsContext.Provider>
      </IsLoggedInContext.Provider>
    </LogoutContext.Provider>
  );
};

export default GoogleAuth;
