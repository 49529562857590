import { extractQueryAttributes as extractAttributes } from 'components/attribute-selector';

import type { Location } from 'react-router-dom';
import type { FilterState } from './filter-state';

const extractQueryAttributes = (location: Location): FilterState => {
  const params = new URLSearchParams(location.search);
  const sort = params.get('sortBy') ?? 'addedAt';
  const sortField = (sort[0] === '-'
    ? sort?.slice(1)
    : sort) as keyof CategoryContentEntry;
  const sortDirection = sort[0] === '-' ? 'desc' : 'asc';
  const onlyIncomplete = params.get('onlyIncomplete') === 'true';

  // Statuses
  const status = params.getAll('Status[]') as LocalizedContentEntryStatus[];

  const {
    Space,
    ContentType,
    Topic,
    searchQuery,
    DeviceType,
    Device,
    Manufacturer,
    OperatingSystem,
    OperatingSystemRelease,
    OperatingSystemVersion,
  } = extractAttributes(location);

  return {
    sortField,
    sortDirection,
    onlyIncomplete,
    status,
    spaces: Space,
    contentTypes: ContentType,
    topics: Topic,
    contentTitle: searchQuery,
    deviceTypeIds: DeviceType,
    manufacturerIds: Manufacturer,
    deviceIds: Device,
    osIds: OperatingSystem,
    osReleaseIds: OperatingSystemRelease,
    osVersionIds: OperatingSystemVersion,
  };
};

export default extractQueryAttributes;
