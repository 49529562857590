import { FunctionComponent, PropsWithChildren } from 'react';
import styled, { keyframes } from 'styled-components';

import { CircularIndicator } from '../../base';

type LofiPinchIndicator = WithLofiMode<PinchIndicator> & { className?: string };

// Percentage of height of the image the pinch indicator will travel. This should always be less
// than 50%, probably much smaller, as it is the distance from the origin, not the distance from the
// other pinch half. (Total distance between pinch haves will be 2x this.)
const PINCH_DISTANCE = 15;

// The vars in this section are set by the parent component using the vars. This animation works
// for both pinch in and pinch out -- it just moves the indicator from a start position to a
// stop position.
//
// Lofi mode removes the opacity transiton but not the motion animation
const pinchAnimation = keyframes`
  0%    { opacity: calc((var(--lofi) * .9) + .1); } // 1 in lofi mode, .1 otherwise
  16%   { opacity: 1; }
  72%   { top: var(--endTop); opacity: 1; }
  100%  { top: var(--endTop); opacity: calc((var(--lofi) * 1)); } // 1 in lofi mode, 0 otherwise
`;

// Inline styles managed here through attrs to keep typechecking happy, but I
// would rather set these with a style attribute
const PinchContainer = styled.div`
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
  pointer-events: none;
  position: relative;
  z-index: 5;
`;

/*
  This is a single pinch element; there are two for each gesture. The "direction" changes motion
  towards or away from the center based on whether this is a top indicator or a bottom indicator.
 */
const Pinch = styled(CircularIndicator)<LofiPinchIndicator>`
  // Initial top and left values for browsers that !grok(var()) are are set using the style
  // attribute

  // pause the animation and be sure our opacity is 1.
  &.paused {
    opacity: 1 !important;
    animation-play-state: paused !important;
  }

  @supports (--direction: 1) {
    // These are just some default values, and can be overridden from outside of the component.
    --start: 50px;
    --end: 10px;
    --y: 0;
    --x: 0;
    --direction: 1;
    --lofi: 1; // not used here, but used in the animation keyframes.

    // These are calculated values that should probably not be overridden from outside the component.
    // Although endTop is not used immediately, both properties are used in the animation keyframes.
    --startTop: calc(var(--y) - (var(--start) * var(--direction)));
    --endTop: calc(var(--y) - (var(--end) * var(--direction)));

    // Set our initial positions.
    transform: translate(-50%, -50%) translateY(calc(-35% * var(--direction)));
    top: var(--startTop);
    left: var(--x);
    animation: ${pinchAnimation} 1.8s infinite;

    // Allow pointer events
    pointer-events: all;
  }
`;

/*
  This component represents the pinch group. It has been generalized enough that it can serve for
  either pinch in or pinch out, although these are exported as two separate components below to keep
  the indicator API intact.

  Because the pinch indicator styles makes heavy use of CSS varibles, we expose the variables
  properties as inline styles instead of passing props to Styled Components. This reduces the amount
  of CSS we have to generate as well as the amount of logic we are doing with JS inside of the CSS.

  Overriding var properties with inline styles is a modern accepted use of inline styles and is the
  encouraged mechanism for passing specific data from markup into CSS without resorting to unique
  CSS generation for each component.
 */
const InternalPinchIndicator: FunctionComponent<
  PropsWithChildren<LofiPinchIndicator>
> = (props: LofiPinchIndicator) => {
  const { type, x, y } = props;

  const startPos = type === 'PINCH_OUT' ? 0 : PINCH_DISTANCE;
  const endPos = type === 'PINCH_OUT' ? PINCH_DISTANCE : 0;
  const paused = props.isPaused ? 'paused' : '';
  // Merge passed in classNames with paused class
  const className = `${props.className ?? ''} ${paused}`;

  const topStyle = {
    top: `${y - startPos}%`,
    left: `${x}%`,
    '--start': `${startPos}%`,
    '--end': `${endPos}%`,
    '--lofi': props.lofi ? 1 : 0,
    '--x': `${x}%`,
    '--y': `${y}%`,
  };

  // All of these values are the same as the top style, but direction changes.
  const bottomStyle = {
    ...topStyle,
    top: `${y + startPos}%`,
    '--direction': -1,
  };

  return (
    <PinchContainer style={{ transformOrigin: `${x}% ${y}%` }}>
      <Pinch style={topStyle} {...{ ...props, className }} />
      <Pinch style={bottomStyle} {...{ ...props, className }} />
    </PinchContainer>
  );
};

// Maintaining these components to keep the old indicator interface intact.
export const PinchInIndicator: FunctionComponent<PinchIndicator> = (props) => {
  const augmentedProps: LofiPinchIndicator = { ...props, type: 'PINCH_IN' };
  return <InternalPinchIndicator {...augmentedProps} />;
};

export const PinchOutIndicator: FunctionComponent<PinchIndicator> = (props) => {
  const augmentedProps: LofiPinchIndicator = { ...props, type: 'PINCH_OUT' };
  return <InternalPinchIndicator {...augmentedProps} />;
};
