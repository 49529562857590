import { FunctionComponent } from 'react';
import { Typography, Tooltip } from '@mui/material';

type Props = {
  children: string;
};

const CollectionReferenceTitle: FunctionComponent<Props> = ({ children }) => (
  <Tooltip arrow placement="top-start" leaveDelay={100} title={children}>
    <Typography
      fontWeight={700}
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
    >
      {children}
    </Typography>
  </Tooltip>
);

export default CollectionReferenceTitle;
