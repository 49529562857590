import { ChangeEventHandler } from 'react';
import { Button, Menu, MenuItem, styled } from '@mui/material';
import { CropOriginal, ArrowDropDown } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import { useImageButton } from './use-image-button';

const StyledImageMenuDangerItem = styled(MenuItem)`
  color: ${({ disabled }) =>
    disabled ? 'var(--color-neutral-six)' : 'var(--color-messaging-error)'};
`;

const StyledUploadLabel = styled('label')`
  text-decoration: none;
  cursor: pointer;
  & > input[type='file'] {
    display: none;
  }
`;

type ImageButtonProps = {
  hasImage: boolean;
  onRemoveImage: VoidFunction;
  onUpdateImage: ChangeEventHandler<HTMLInputElement>;
};

const ImageButton: React.FunctionComponent<ImageButtonProps> = ({
  hasImage,
  onRemoveImage,
  onUpdateImage,
}) => {
  const {
    compilerLink,
    imageMenuOpen,
    imageMenuAnchorEl,
    handleImageMenuOpen,
    handleImageMenuClose,
    handleRemoveImageClick,
    handleUploadImageClick,
  } = useImageButton(onRemoveImage, onUpdateImage);

  return (
    <>
      <Button
        variant="outlined"
        color="neutralSix"
        startIcon={<CropOriginal />}
        onClick={handleImageMenuOpen}
        endIcon={<ArrowDropDown />}
      >
        {'Image'}
      </Button>
      <Menu
        id="step-header:image-menu"
        anchorEl={imageMenuAnchorEl}
        open={imageMenuOpen}
        onClose={handleImageMenuClose}
        elevation={1}
      >
        <MenuItem>
          <StyledUploadLabel>
            <input
              id="fileSelect"
              data-testid="imagebutton:update-image-button"
              accept="image/*"
              type="file"
              onChange={handleUploadImageClick}
            />
            {hasImage ? 'Replace' : 'Upload'}
          </StyledUploadLabel>
        </MenuItem>
        <MenuItem component={Link} to={compilerLink}>
          {'Shell compiler'}
        </MenuItem>
        <StyledImageMenuDangerItem
          disabled={!hasImage}
          onClick={handleRemoveImageClick}
        >
          {'Remove'}
        </StyledImageMenuDangerItem>
      </Menu>
    </>
  );
};

export default ImageButton;
