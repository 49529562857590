import { useCallback, useRef, useState } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { useAppToast } from 'contexts/app-toast-context';
import useResizeObserver from 'services/utils/use-resize-observer';

export const useResizableImage = (id: number | undefined, path: string) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [isImageLoadError, setIsImageLoadError] = useState(false);
  const [imageSize, setImageSize] = useState({ height: 0, width: 0 });
  const dispatchToast = useAppToast();
  const ozmoApi = useOzmoApiService();

  const onResize = useCallback((resizeObserverEntry: ResizeObserverEntry[]) => {
    const [
      {
        contentRect: { height, width },
      },
    ] = resizeObserverEntry;
    setImageSize({ height, width });
  }, []);

  useResizeObserver(imageRef, onResize);

  const { isLoading, isError, data: mediaEntry } = ozmoApi.MediaEntry.get({
    id,
  });

  const handleMediaLoadError = useCallback(() => {
    setIsImageLoadError(true);
    dispatchToast({
      level: 'error',
      message: `Unable to load image for ${path}.  Check VPN or replace image.`,
    });
  }, [dispatchToast, path]);

  // After the image loads initially, calculate the height/width
  // After the initial load, resizing we be handled by the resize observer abvove
  const handleInitialMediaLoad = useCallback(() => {
    if (imageRef.current) {
      const { height, width } = imageRef.current.getBoundingClientRect();
      setImageSize({ height, width });
    }
  }, [setImageSize]);

  return {
    isLoading,
    isError,
    mediaEntry,
    imageRef,
    isImageLoadError,
    imageSize,
    setImageSize,
    handleMediaLoadError,
    handleInitialMediaLoad,
  };
};
