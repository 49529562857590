import {
  useState,
  useEffect,
  useCallback,
  useRef,
  FocusEvent,
  ChangeEvent,
} from 'react';
import { FCWithChildren } from 'types/fc-with-children';
import PropTypes from 'prop-types';
import {
  TextField,
  IconButton,
  Button,
  TextFieldProps,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import styled from 'styled-components';
const StyledTextField = styled(TextField)`
  border: none;
`;
const StyledTextAndButtonContainer = styled.div``;

const StyledPlaceholderText = styled(Typography)`
  font-style: italic;
  color: var(--color-neutral-seven) !important;
`;

const StyledButtonsContainer = styled.div`
  float: right;
`;

const StyledButton = styled(Button)`
  height: 45px !important;
  min-width: unset !important;
  width: 45px;
  margin: 4px !important;
  &:not(:hover) {
    box-shadow: 0px 1px 2px rgba(26, 36, 45, 0.2),
      0px 1px 3px rgba(26, 36, 45, 0.1) !important;
  }
`;

const StyledTextContainer = styled.div`
  width: fit-content;
  border-radius: 6px;
  line-height: 42px;
  &:hover {
    transition: all 150ms ease-in-out;
    background-color: #dcdcdd;
    cursor: text;
  }
  & > * {
    display: inline;
  }
  & > button {
    vertical-align: sub;
  }
`;

const StyledEditButton = styled(IconButton)`
  display: none;
  ${StyledTextContainer}:hover & {
    display: inline-block;
  }
`;

type Props = {
  value: string;
  onChange: (newValue: string) => void;
  textfieldProps?: TextFieldProps;
  placeholder?: string;
  isEmptyValid?: boolean;
};

const SimpleEditableTextfield: FCWithChildren<Props> = ({
  value,
  onChange,
  children,
  textfieldProps,
  placeholder,
  isEmptyValid = true,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [_value, setValue] = useState<string>(value);
  const saveButtonRef = useRef(null);
  const discardButtonRef = useRef(null);

  const handleDiscard = useCallback(() => {
    setValue(value);
    setIsEditing(false);
  }, [value]);

  useEffect(() => {
    const onKeydown = (event: KeyboardEvent) => {
      const { key } = event;
      // If the user presses escape key exit the editor
      if (key === 'Escape') {
        handleDiscard();
      }
    };
    // Listen for keydown events
    window.addEventListener('keydown', onKeydown);
    // Cleanup: Unsubscribe from events on unmount
    return () => window.removeEventListener('keydown', onKeydown);
  }, [handleDiscard]);

  const handleSave = () => {
    onChange(_value);
    setIsEditing(false);
  };

  const handleBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (
      event.relatedTarget === saveButtonRef.current ||
      event.relatedTarget === discardButtonRef.current ||
      isSaveDisabled
    ) {
      return;
    }

    handleSave();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleEditStart = () => {
    setIsEditing(true);
    setValue(value);
  };

  const isSaveDisabled = !isEmptyValid && _value === '';
  const showPlaceholder = _value === '' && placeholder;

  if (!isEditing) {
    return (
      <StyledTextContainer onClick={handleEditStart}>
        {children}
        {showPlaceholder && (
          <StyledPlaceholderText>{placeholder}</StyledPlaceholderText>
        )}
        <StyledEditButton color="primary" onClick={handleEditStart}>
          <EditIcon />
        </StyledEditButton>
      </StyledTextContainer>
    );
  }

  return (
    <StyledTextAndButtonContainer>
      <StyledTextField
        {...textfieldProps}
        variant="outlined"
        value={_value}
        onChange={handleChange}
        onBlur={handleBlur}
        data-testid="simple-editable-textfield:text-field"
        focused
        autoFocus
        fullWidth
      />
      <StyledButtonsContainer>
        <StyledButton
          ref={discardButtonRef}
          variant="contained"
          data-testid="simple-editable-textfield:close-button"
          onClick={handleDiscard}
        >
          <CloseIcon color="error" />
        </StyledButton>
        <StyledButton
          ref={saveButtonRef}
          disabled={isSaveDisabled}
          variant="contained"
          color="primary"
          data-testid="simple-editable-textfield:save-button"
          onClick={handleSave}
        >
          <CheckIcon />
        </StyledButton>
      </StyledButtonsContainer>
    </StyledTextAndButtonContainer>
  );
};

SimpleEditableTextfield.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SimpleEditableTextfield;
