import { FC } from 'react';
import { Box } from '@mui/material';

import Details from './details';

const DetailsTab: FC = () => (
  <Box padding={2}>
    <Details />
  </Box>
);

export default DetailsTab;
