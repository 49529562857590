import { ReactEditor } from 'slate-react';

import { convertOzmoFormatToHTML, hasOzmoFormattedText } from '../utils';

/**
 * This plugin supports pasting text containing Ozmo's weird custom text
 * formatting markup used in Content Productions step text google sheets
 *
 * Currently this plugin only supports replacing the Ozmo <*bold text*>
 * format with the standard markdown **bold text** format.
 */
export const withOzmoFormatting = (editor: ReactEditor) => {
  const { insertData } = editor;
  // The only way these plugins work is by mutating the
  // editor in place- you cannot do a spread.  I tried.
  /* eslint-disable no-param-reassign */
  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (hasOzmoFormattedText(text)) {
      // We need to call the parent/super version of insertData so that
      // this will now be properly parsed as HTML
      const dt = new DataTransfer();
      dt.setData('text/html', convertOzmoFormatToHTML(text));
      insertData(dt);
      return;
    }

    insertData(data);
  };
  /* eslint-enable no-param-reassign */

  return editor;
};
