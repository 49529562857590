import { FC } from 'react';
import { Typography } from '@mui/material';

type Props = {
  contentEntry: ContentEntryModel;
};

export const ModalInstructions: FC<Props> = ({ contentEntry }) => (
  <Typography>
    {'The most recently edited version of '}
    <b>{contentEntry.title}</b>
    {' is in the '}
    <b>{`${contentEntry.space?.name} space. `}</b>
    {
      'You can duplicate this answer and update the space, categories, or attributes in the fields below.'
    }
  </Typography>
);
