import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Scrolls to a hash anchor after the content is loaded
 * @returns {onContentLoaded} A function that should be called when the content is loaded
 */
export const useScrollToAnchor = () => {
  const haveScrolledToAnchor = useRef<Maybe<boolean>>(null);
  const { hash } = useLocation();

  // After every render
  useEffect(() => {
    // If there is a hash value AND the content is loaded
    // AND we haven't already done this
    if (hash && haveScrolledToAnchor.current === false) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        haveScrolledToAnchor.current = true;
      }
    }
  });

  const onContentLoaded = () => {
    if (haveScrolledToAnchor.current === null) {
      haveScrolledToAnchor.current = false;
    }
  };

  return { onContentLoaded };
};
