import { Box } from '@mui/material';
import { useIsLoggedIn, useLoginButton } from 'scenes/google-auth';

const PrivateRoute = ({ children }) => {
  const isLoggedIn = useIsLoggedIn();
  const { buttonRef } = useLoginButton();

  if (isLoggedIn) {
    return <>{children}</>;
  }

  return <Box maxWidth={400} minWidth={200} ref={buttonRef} />;
};

export default PrivateRoute;
