import { FC } from 'react';
import { Box, Typography, styled } from '@mui/material';
import OzmoIcon from 'icons/ozmo-icon.svg?react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

type Props = {
  environment?: string;
  className?: string;
};

const StyledTitle = styled(Typography)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

const StyledOzmoIcon = styled(OzmoIcon)`
  width: 28px;
  margin-right: 16px;
`;

const StudioLogo: FC<Props> = ({ environment, className }) => (
  <Box display="flex" className={className}>
    <Button component={Link} to="/">
      <StyledOzmoIcon data-testid="studio-logo:ozmo-icon" />
      <StyledTitle noWrap color="primary">
        {'Ozmo Studio'}
        {/* if environment isn't undefined and isn't prod, render the environment */}
        {environment && environment !== 'Prod' && (
          <Typography display="inline" color="error" component="span">
            <b>&nbsp;&nbsp;{environment}</b>
          </Typography>
        )}
      </StyledTitle>
    </Button>
  </Box>
);

export default StudioLogo;
