export function objectDescendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  // check that only primitives are being passed; if not, do not sort (return 0)
  if (
    typeof a[orderBy] === 'object' ||
    typeof b[orderBy] === 'object' ||
    Array.isArray(a[orderBy]) ||
    Array.isArray(b[orderBy])
  ) {
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => objectDescendingComparator(a, b, orderBy)
    : (a, b) => -objectDescendingComparator(a, b, orderBy);
}

export function sortByCompartor<T>(
  array: T[],
  comparator: (a: T, b: T) => number
) {
  const sortArray = array.map((el, idx) => [el, idx] as [T, number]);
  sortArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return sortArray.map((el) => el[0]);
}
