/* eslint-disable react/jsx-key */
import { useState, useMemo } from 'react';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useContentEntry } from 'services/hooks/api-hooks';

export const useCollapsibleAttributeList = (
  contentEntryId: number,
  startExpanded: boolean
) => {
  const [open, setOpen] = useState(startExpanded);
  const { contentEntry, isLoading } = useContentEntry(contentEntryId);

  const handleOpen = () => setOpen(!open);

  const attributes = {
    deviceTypes: contentEntry?.deviceTypes,
    devices: contentEntry?.devices,
    manufacturers: contentEntry?.manufacturers,
    operatingSystems: contentEntry?.operatingSystems,
    operatingSystemReleases: contentEntry?.operatingSystemReleases,
    operatingSystemVersions: contentEntry?.operatingSystemVersions,
  };

  // sum up the count of all attributes.
  const attributeCount = Object.entries(attributes).reduce(
    // eslint-disable-next-line no-param-reassign
    (count, [, value]) => (count += value?.length || 0),
    0
  );

  const [buttonIcon, buttonText] = useMemo(() => {
    if (open) {
      return [<ExpandLess />, `Hide attributes (${attributeCount})`];
    }

    return [<ExpandMore />, `Show attributes (${attributeCount})`];
  }, [open, attributeCount]);

  return {
    open,
    isLoading,
    buttonIcon,
    buttonText,
    attributes,
    handleOpen,
  };
};
