import { OZMO_AUTH_APPLICATION, useRawPermissions } from 'scenes/google-auth';

/**
 * usePermissions: Returns the current users permissions and a function
 * to see if the user has a specific permission
 * @returns
 *  permissions: string[] - An array of permissions the user has
 *
 *  checkPermission: (string) => boolean - A function to check if the user has a specific permission
 */
const usePermissions = () => {
  const permissions = useRawPermissions();

  /**
   * Check if the current user has a specific permission
   *
   * @param permission (string) The permission name to check for (e.g.: "publish")
   * @param authApplicationName (string) The name of the auth application the permission belongs to.  Defaults to the OZMO_AUTH_APPLICATION value from `scenes/google-auth`
   * @returns (boolean) Whether the current user has the permission
   */
  const checkPermission = (
    permission: string,
    authApplicationName = OZMO_AUTH_APPLICATION
  ) => permissions.includes(`${authApplicationName}:${permission}`);

  return {
    permissions,
    checkPermission,
  };
};

export default usePermissions;
