import { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import { IconButton, Tooltip } from '@mui/material';

type Props = {
  label: string;
  tooltip: string;
  children: ReactNode;
  backgroundColor?: string;
  color?: string;
  onClick: MouseEventHandler;
};

const BaseButton: FunctionComponent<Props> = ({
  color,
  backgroundColor,
  tooltip,
  label,
  onClick,
  children,
}) => {
  return (
    <Tooltip title={tooltip} arrow>
      <IconButton
        style={{
          backgroundColor,
          color,
        }}
        onClick={onClick}
        aria-label={label}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default BaseButton;
