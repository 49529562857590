import { FCWithChildren } from 'types/fc-with-children';
import styled from 'styled-components';

export const StyledCollectionWrapper = styled.div`
  display: flex;
  height: 100%;
`;

type Props = {
  className?: string;
};

const CollectionWrapper: FCWithChildren<Props> = ({ children, className }) => {
  return (
    <StyledCollectionWrapper className={className}>
      {children}
    </StyledCollectionWrapper>
  );
};

export default CollectionWrapper;
