import { FunctionComponent } from 'react';
import { Attribute } from 'components/attribute-editor/attribute-editor';
import { Typography } from '@mui/material';
import styled from 'styled-components';

import { Operation } from '../use-attribute-editor';

import AttributeTagGroup from './attribute-tag-group';

const StyledTypography = styled(Typography)`
  margin-bottom: 24px !important;
`;

type Props = {
  devices: DeviceModel[];
  deviceTypes: DeviceTypeModel[];
  manufacturers: ManufacturerModel[];
  operatingSystems: OperatingSystemModel[];
  operatingSystemReleases: OperatingSystemReleaseModel[];
  operatingSystemVersions: OperatingSystemVersionModel[];
  pendingOperations?: Operation[];
  onClear?: (
    attributeName: string,
    attribute: Attribute,
    action: 'remove'
  ) => void;
};

const AttributeTagDisplay: FunctionComponent<Props> = ({
  devices,
  deviceTypes,
  manufacturers,
  operatingSystems,
  operatingSystemReleases,
  operatingSystemVersions,
  pendingOperations = [],
  onClear,
}) => {
  return (
    <>
      <StyledTypography>
        <b>{'Attributes Summary'}</b>
      </StyledTypography>
      <AttributeTagGroup
        label="Manufacturer"
        attributeName="manufacturer"
        attributes={manufacturers}
        pendingOperations={pendingOperations}
        onDelete={onClear}
      />
      <AttributeTagGroup
        label="Device Type"
        attributeName="deviceType"
        attributes={deviceTypes}
        pendingOperations={pendingOperations}
        onDelete={onClear}
      />
      <AttributeTagGroup
        label="Device"
        attributeName="device"
        attributes={devices}
        pendingOperations={pendingOperations}
        onDelete={onClear}
      />
      <AttributeTagGroup
        label="Operating System"
        attributeName="operatingSystem"
        attributes={operatingSystems}
        pendingOperations={pendingOperations}
        onDelete={onClear}
      />
      <AttributeTagGroup
        label="Operating System Release"
        attributeName="operatingSystemRelease"
        attributes={operatingSystemReleases}
        pendingOperations={pendingOperations}
        onDelete={onClear}
      />
      <AttributeTagGroup
        label="Operating System Version"
        attributeName="operatingSystemVersion"
        attributes={operatingSystemVersions}
        pendingOperations={pendingOperations}
        onDelete={onClear}
      />
    </>
  );
};

AttributeTagDisplay.propTypes = {};

export default AttributeTagDisplay;
