import { MouseEventHandler, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import styled from 'styled-components';

import { useQuery } from '../use-query';

const StyledButton = styled(Button)`
  z-index: 10;
` as any;

/**
 *
 * @param {function} confirmationCallback An optional callback that can be used to confirm navigation
 * @returns {object} with keys previousPageUrl, previousPageTitle, previousPageButton, generateUrlWithPreviousPage
 *
 * confirmationCallback should return a promise that resolves to true if we should navigate to the previous page
 * or false if we should not.  If confirmationCallback is not provided (or is undefined) navigation will happen immediately
 *
 */

const usePreviousPage = (confirmationCallback?: () => Promise<boolean>) => {
  const params = useQuery();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const generateUrlWithPreviousPage = useCallback(
    /**
     * generateUrlWithPreviousPage - Create a link that contains the special sauce needed to support
     * the previous page button mechanism.
     *
     * If a backUrl is not provided, it will create the link
     * using the current path as the backUrl and the title will be the first part of the path with some
     *  string substitutions- e.g: /somewhere-special/123/en-ca -> "somewhere special"
     * @param url - The destination to link to
     * @param backUrl - Where the previous button should take you back to
     * @param backTitle - The title to use on the previous button (back to ____ )
     * @returns string - a formatted URL ready to be used in an \<a\> or \<Link\>
     */
    (url: string, backUrl?: string, backTitle?: string, urlHash?: string) => {
      // Create a base64 encoded version of a dictionary converted to a string
      let prevParam = btoa(
        unescape(
          encodeURIComponent(JSON.stringify({ u: backUrl, t: backTitle }))
        )
      );
      // If a backUrl isn't provided, create one and the title from the current path
      if (!backUrl) {
        let title = backTitle ?? pathname.split('/')[1].replace('-', ' ');
        switch (title) {
          case 'edit':
            title = 'content entry';
            break;
          case '':
            title = 'home';
            break;
          default:
            break;
        }
        prevParam = btoa(
          unescape(
            encodeURIComponent(
              JSON.stringify({ u: `${pathname}${search}`, t: title })
            )
          )
        );
      }
      // If the url already has a query string append to the end
      if (url.includes('?')) {
        return `${url}&prev=${prevParam}${urlHash ? `#${urlHash}` : ''}`;
      }
      // Otherwise start a query string
      return `${url}?prev=${prevParam}${urlHash ? `#${urlHash}` : ''}`;
    },
    [pathname, search]
  );

  let previousPageUrl: string;
  let previousPageTitle: string;

  try {
    const prevParam = params.get('prev');
    if (!prevParam) throw new Error('no url "prev" url param available');

    const previous = JSON.parse(decodeURIComponent(escape(atob(prevParam))));
    previousPageUrl = previous.u;
    previousPageTitle = previous.t;
  } catch (err) {
    // default to home page
    previousPageUrl = '/';
    previousPageTitle = 'home';
  }

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (confirmationCallback === undefined) return;

    e.preventDefault();
    confirmationCallback().then((proceed) => {
      if (proceed) {
        navigate(previousPageUrl || '/');
      }
    });
  };

  const previousPageButton = (
    <StyledButton
      color="primary"
      startIcon={<ArrowBackIcon />}
      component={Link}
      onClick={handleClick}
      to={previousPageUrl || '/'}
    >
      {`Back to ${previousPageTitle}`}
    </StyledButton>
  );

  return {
    previousPageUrl,
    previousPageTitle,
    previousPageButton,
    generateUrlWithPreviousPage,
  };
};

usePreviousPage.propTypes = {
  confirmationCallback: PropTypes.func,
};

export default usePreviousPage;
