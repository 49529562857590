import { FC } from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import { Link } from 'react-router-dom';

type Props = BoxProps & {
  url: string;
};

const StyledLinkBox = styled(Box)`
  text-decoration: underline;
  text-decoration-color: transparent !important;
  transition: text-decoration-color 0.3s ease-in-out;
  :hover {
    text-decoration-color: var(--color-neutral-eight) !important;
  }
`;

const LinkBox: FC<Props> = ({ url, children, ...boxProps }) => {
  return (
    <StyledLinkBox
      {...boxProps}
      component={Link}
      // @ts-ignore it appears since the Box is wrapped within a styled component, it can't infer
      // that is has access to the "to" prop, but this does work.
      to={url}
    >
      {children}
    </StyledLinkBox>
  );
};
export default LinkBox;
