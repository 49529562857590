import { MouseEventHandler, useCallback } from 'react';
import { AddNewFunction } from 'scenes/universal-content-editor';

type Params = {
  imageSize: { height: number; width: number };
  handleAddNew?: AddNewFunction;
  defaultIndicator?: Indicator;
};

export const useIndicatorDoubleClick = ({
  defaultIndicator,
  imageSize,
  handleAddNew,
}: Params) => {
  const handleDoubleClick = useCallback<MouseEventHandler>(
    (event) => {
      const { offsetX, offsetY } = event.nativeEvent;
      const { height, width } = imageSize;

      const x = (offsetX / width) * 100;
      const y = (offsetY / height) * 100;
      const indicator = defaultIndicator
        ? { ...defaultIndicator, x, y }
        : { x, y, id: 'TAP', type: 'TAP' };

      handleAddNew?.(indicator);
    },
    [defaultIndicator, handleAddNew, imageSize]
  );

  return {
    handleDoubleClick,
  };
};
