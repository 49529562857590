import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { CssBaseline, Divider } from '@mui/material';
import HelpMenu from 'components/topic-header/help-menu';
import { styled } from '@mui/material/styles';
import FeedbackIcon from '@mui/icons-material/Feedback';
import VerticalFlag from 'components/vertical-flag';
import StudioLogo from 'components/studio-logo/';
import usePreviousPage from 'services/utils/use-previous-page';
import { useAuthLogout } from 'scenes/google-auth';

const StyledToolbarDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: '48px !important',
  marginBottom: '2rem',
}));

const StyledMain = styled('main')`
  background-color: #f2f2f2;
  flex-grow: 1;
  padding: 2em;
  padding-top: 0px;
  min-height: 100vh;
  position: relative;
`;

const StyledRootDiv = styled('div')`
  display: flex;
`;

const StyledToolbar = styled(Toolbar)`
  background-color: #ffffff;
  border-bottom: ${({ environment }) =>
    environment === 'Prod' ? '2px solid white' : '2px solid red'};
  & > hr,
  a {
    margin: 0px 8px;
  }
`;

const StyledStudioLogo = styled(StudioLogo)`
  flex-grow: 1;
`;

const ApplicationShell = ({ children }) => {
  const logout = useAuthLogout();

  const environment = import.meta.env.VITE_APP_ENVIRONMENT;

  const { generateUrlWithPreviousPage } = usePreviousPage();

  const compilerLink = generateUrlWithPreviousPage('/shell-compiler');

  return (
    <StyledRootDiv>
      <CssBaseline />
      <AppBar elevation={0} position="fixed">
        <StyledToolbar environment={environment}>
          <StyledStudioLogo environment={environment} />

          <Button color="primary" variant="text" component={Link} to="/">
            {'Home'}
          </Button>
          <Button
            color="primary"
            variant="text"
            component={Link}
            to={compilerLink}
          >
            {'Shell Compiler'}
          </Button>
          <Divider orientation="vertical" flexItem />
          <Button color="primary" onClick={logout}>
            {'Logout'}
          </Button>
          <Divider orientation="vertical" flexItem />
          <HelpMenu />
        </StyledToolbar>
      </AppBar>
      <StyledMain>
        <StyledToolbarDiv />
        {children}
      </StyledMain>
      <VerticalFlag
        text="Report  Issue"
        url="https://modeacorp.atlassian.net/secure/CreateIssue.jspa?issuetype=1&pid=12527"
        icon={<FeedbackIcon />}
      />
    </StyledRootDiv>
  );
};
export default ApplicationShell;
