import { FunctionComponent } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateIcon from '@mui/icons-material/Create';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Link } from 'react-router-dom';
import { PermissionRequired } from 'components/permission-required';
import { isLegacyContent } from 'services/utils/is-legacy-content';

import {
  useAddToCategoryAction,
  useDuplicateAction,
  useLocalizedReferenceActions,
  useRemoveItemAction,
} from './hooks';

type Props = LocalizedCollectionReference & {
  editUrl: string;
  categoryIndex: number;
  collectionId: number;
  localizedCollectionId: number;
};

const LocalizedReferenceActions: FunctionComponent<Props> = ({
  editUrl,
  collectionId,
  localizedCollectionId,
  categoryIndex,
  ...reference
}) => {
  const { contentType } = reference;
  const {
    anchorEl,
    isOpen,
    handleOpen,
    handleClose,
  } = useLocalizedReferenceActions();

  const isLegacyContentType = isLegacyContent(contentType);

  const { removeItemModal, handleRemoveItemClick } = useRemoveItemAction({
    collectionId,
    itemId: reference.id,
    categoryIndex,
    answerTitle: reference.title,
  });

  const { handleAddToCategoryClick, categoriesModal } = useAddToCategoryAction({
    reference,
    collectionId,
    localizedCollectionId,
  });

  const { duplicateModal, handleDuplicateClick } = useDuplicateAction(
    reference.id,
    categoryIndex,
    collectionId,
    localizedCollectionId
  );

  return (
    <>
      {categoriesModal}
      {duplicateModal}
      {removeItemModal}

      <IconButton key={0} aria-label="More options" onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem component={Link} to={editUrl}>
          <ListItemIcon>
            <CreateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{'Edit'}</ListItemText>
        </MenuItem>
        <PermissionRequired permission="edit_collection" disableElement>
          <MenuItem onClick={handleAddToCategoryClick}>
            <ListItemIcon>
              <PlaylistAddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{'Categorize'}</ListItemText>
          </MenuItem>
        </PermissionRequired>
        <PermissionRequired permission="edit_collection" disableElement>
          <MenuItem
            onClick={handleDuplicateClick}
            disabled={isLegacyContentType}
          >
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{'Duplicate'}</ListItemText>
          </MenuItem>
        </PermissionRequired>
        <PermissionRequired permission="edit_collection" disableElement>
          <MenuItem onClick={handleRemoveItemClick}>
            <ListItemIcon>
              <RemoveCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{'Remove'}</ListItemText>
          </MenuItem>
        </PermissionRequired>
      </Menu>
    </>
  );
};

export default LocalizedReferenceActions;
