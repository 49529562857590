import { FunctionComponent } from 'react';

import { useLinkButton } from './hooks';
import BaseButton from './base-button';

const AddLinkButton: FunctionComponent = () => {
  const { backgroundColor, color, handleClick, icon, label } = useLinkButton();
  return (
    <BaseButton
      color={color}
      backgroundColor={backgroundColor}
      label={label}
      tooltip={label}
      onClick={handleClick}
    >
      {icon}
    </BaseButton>
  );
};

export default AddLinkButton;
