export const headCells: ContentHeadCell<CollectionTableData>[] = [
  {
    id: 'title',
    disablePadding: false,
    isSortable: false,
    label: 'Title',
    align: 'left',
  },
  {
    id: 'updatedAt',
    disablePadding: false,
    isSortable: false,
    label: 'Updated',
    align: 'left',
  },
  {
    id: 'attributes',
    disablePadding: false,
    isSortable: false,
    label: 'Attributes',
    align: 'left',
  },
];
