import { FC } from 'react';
import { Box, styled, Tooltip } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

type Props = {
  className?: string;
  tooltipText?: string;
};

const StyledBox = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 0.25rem;
  width: 2rem;
  height: 2rem;
  top: 0;
  right: 0;
  background-color: var(--color-messaging-error-light);
  clip-path: polygon(0 0, 100% 0, 100% 100%);

  .MuiSvgIcon-root {
    font-size: 0.75rem;
    color: var(--color-messaging-error);
  }
`;

export const CompletenessFlag: FC<Props> = ({
  className,
  tooltipText = 'Content incomplete',
}) => (
  <StyledBox data-testid="content-completeness-flag" className={className}>
    <Tooltip title={tooltipText} placement="left" arrow>
      {<ErrorOutline />}
    </Tooltip>
  </StyledBox>
);
