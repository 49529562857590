import { Fragment, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextField, CircularProgress } from '@mui/material';
import useOzmoApi from 'services/utils/use-ozmo-api';
import { formatAttributeName } from 'services/ozmo-api/utils/format-attribute-name';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import styled, { css } from 'styled-components';

const StyledAutocomplete = styled(Autocomplete)`
  width: 300px;
  margin: auto auto;
`;

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    ${({ updated }) =>
      updated === 'true' &&
      css`
        border: 2px solid var(--color-primary-base);
      `}
  }
`;

const SelectDevice = ({
  device,
  setDevice,
  operatingSystemRelease,
  hasHighlight,
  isDisabled = false,
  isClearDisabled = false,
}) => {
  const { isLoading, result: devices, get } = useOzmoApi();
  // eslint-disable-next-line camelcase
  const [searchTerm, setSearchTerm] = useState(null);
  const operatingSystemId =
    // eslint-disable-next-line camelcase
    operatingSystemRelease?.operating_system_id ||
    operatingSystemRelease?.operatingSystemId;
  // eslint-disable-next-line camelcase
  const operatingSystemName =
    // eslint-disable-next-line camelcase
    operatingSystemRelease?.operating_system_name ||
    operatingSystemRelease?.operatingSystemName;
  const operatingSystemReleaseName = operatingSystemRelease?.name;

  const operatingSystemFilteredDevices = useMemo(() => {
    if (devices && operatingSystemId) {
      return devices
        .filter((device) => device.operating_system_id === operatingSystemId)
        .map((device) => ({
          ...device,
          filter: `Showing only options for ${operatingSystemName} ${operatingSystemReleaseName}`,
        }));
    }

    if (devices) {
      return devices;
    }

    return [];
  }, [
    devices,
    operatingSystemId,
    operatingSystemName,
    operatingSystemReleaseName,
  ]);

  useEffect(() => {
    if (operatingSystemId) {
      get(
        `config/devices?sort=tracking_name&find_operating_system_id=${operatingSystemId}&per_page=1000`
      );
      setSearchTerm(null);
    }
  }, [get, operatingSystemId]);

  useEffect(() => {
    if (searchTerm && searchTerm.length > 2) {
      // don't search until user stops typing
      const delaySearch = setTimeout(() => {
        // Split the search term on spaces and add each word as a search term
        const findBy = searchTerm
          .split(' ')
          .reduce((acc, term) => `${acc}&find_tracking_name=${term}`, '');
        get(`config/devices?sort=tracking_name${findBy}&per_page=1000`);
      }, 250);

      return () => clearTimeout(delaySearch);
    }
  }, [get, searchTerm]);

  const clearSearchTerm = () => {
    if (operatingSystemId) {
      get(
        `config/devices?sort=tracking_name&find_operating_system_id=${operatingSystemId}&per_page=1000`
      );
    }
    setSearchTerm(null);
  };

  const handleInputChange = (event, value, reason) => {
    if (reason === 'clear') {
      clearSearchTerm();
      setDevice(null);
    }
  };

  const handleSearchTermChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value === '') {
      clearSearchTerm();
      return;
    }
    setSearchTerm(value);
  };

  const handleChange = (event, value) => {
    if (value) {
      const { tracking_name: trackingName } = value;
      setDevice({
        ...value,
        fancyName: formatAttributeName(trackingName),
      });
      setSearchTerm(formatAttributeName(trackingName));
    }
  };

  return (
    <StyledAutocomplete
      fullWidth
      disableClearable={isClearDisabled}
      disabled={isDisabled}
      options={operatingSystemFilteredDevices}
      value={device || null}
      loading={isLoading}
      groupBy={(device) => device.filter}
      noOptionsText={
        operatingSystemName
          ? `No options available for ${operatingSystemName}`
          : 'Type to search'
      }
      onKeyUp={(e) => setSearchTerm(e.target.value)}
      onChange={handleChange}
      onInputChange={handleInputChange}
      // eslint-disable-next-line camelcase
      getOptionLabel={({ tracking_name, trackingName, manufacturer }) =>
        `${manufacturer} ${formatAttributeName(
          // eslint-disable-next-line camelcase
          tracking_name || trackingName
        )}` || ''
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={createFilterOptions({
        // https://material-ui.com/components/autocomplete/#custom-filter
        // eslint-disable-next-line camelcase
        stringify: ({ tracking_name, trackingName, manufacturer }) =>
          `${manufacturer} ${formatAttributeName(
            // eslint-disable-next-line camelcase
            tracking_name || trackingName
          )}`,
      })}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label="Device"
          value={searchTerm}
          onChange={handleSearchTermChange}
          placeholder={'All'}
          variant="outlined"
          updated={hasHighlight ? 'true' : 'false'}
          InputProps={{
            ...params.InputProps,
            'data-testid': 'select-device:text-input',
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

SelectDevice.propTypes = {
  operatingSystem: PropTypes.shape({}),
  device: PropTypes.shape({}),
  setDevice: PropTypes.func.isRequired,
};

export default SelectDevice;
