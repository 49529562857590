import { FC, memo } from 'react';
import { Typography, Box } from '@mui/material';
import { convertCamelOrTitleCaseToTitle } from 'services/utils/convert-camel-to-title-case';

import {
  SearchableAttribute,
  AttributeOptionWithKey,
  OnAttrSelect,
  OnAttrRemove,
  IsAttrSelected,
} from '../types';
import AttributeChip from '../attribute-chip';

type Props = {
  attribute: SearchableAttribute | undefined;
  className?: string;
  onAttrSelect?: OnAttrSelect;
  onAttrRemove?: OnAttrRemove;
  isAttrUsed?: IsAttrSelected;
};

const AttributeResultItem: FC<Props> = ({
  attribute,
  className,
  onAttrSelect,
  onAttrRemove,
  isAttrUsed,
}) => {
  if (!attribute) return null;

  const {
    key,
    selectedReturnKey,
    options,
    additionalOptionCount = 0,
  } = attribute;

  if (options.length === 0) return null;

  return (
    <Box
      data-testid="attr-result-item-wrapper"
      className={className}
      marginBottom={3}
      marginTop={3}
    >
      <Typography
        textTransform="uppercase"
        fontWeight="bold"
        marginBottom={0.5}
      >
        {convertCamelOrTitleCaseToTitle(key)}
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={0.75}>
        {options.map((option) => {
          const attrOption: AttributeOptionWithKey = {
            attributeKey: key,
            selectedReturnKey,
            ...option,
          };

          // assume false if the isFilterUsed function is not provided
          const attrOptionUsed = isAttrUsed?.(attrOption) || false;
          return (
            <AttributeChip
              key={`${option.name}-${option.id}`}
              grayOutWhenUsed
              attribute={attrOption}
              isAttrUsed={attrOptionUsed}
              onAttrSelect={onAttrSelect}
              onAttrRemove={onAttrRemove}
            />
          );
        })}
        {additionalOptionCount > 0 && (
          <Typography>{`& ${additionalOptionCount} More`}</Typography>
        )}
      </Box>
    </Box>
  );
};

const memoizedAttrResultItem = memo(
  AttributeResultItem,
  (prevProps, nextProps) =>
    prevProps.attribute === nextProps.attribute &&
    prevProps.className === nextProps.className &&
    prevProps.isAttrUsed === nextProps.isAttrUsed
);

export default memoizedAttrResultItem;
