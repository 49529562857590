import { toSnake } from 'services/utils/convert-object-keys-camel-to-snake';

/**
 *
 * @param id The id of the model the operation is being made on. (Ex. a ContentEntry)
 * @param op The operation being done. (Ex. Add, replace, remove_value)
 * @param path The field or association on the model to be updated.
 * @param value The value(s) that will be applied to the path provided.
 */
export const assembleBulkOperation = (
  id: number,
  op: BulkOperationActions,
  path: string,
  value: number | number[] | string | string[]
): BulkOperation => {
  let formattedPath = toSnake(path) as string;
  // if path doesn't start with a forward slash, provide it.
  if (!formattedPath.startsWith('/')) {
    formattedPath = `/${formattedPath}`;
  }

  // if the add operation is being done, and path doesn't end with "/-", add it
  if (op === 'add' && !formattedPath.endsWith('/-')) {
    formattedPath += '/-';
  }

  // if not the add operation, and path ends with "/-", remove it
  if (op !== 'add' && formattedPath.endsWith('/-')) {
    formattedPath = formattedPath.replace('/-', '');
  }
  return { id, operation: { op, path: formattedPath, value } };
};
