/**
 * Converts camelCase or TitleCase text into more readable "Title Text" format.
 * @param text The text that will be converted.
 * @returns The converted text, or the original text if it was not camelCase or titleCase.
 */
export const convertCamelOrTitleCaseToTitle = (text: string) => {
  const cameCaseRegex = /^([a-zA-Z]+)(([A-Z]([a-z]+))+)$/;
  if (!text.match(cameCaseRegex)) return text;

  return text
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .trimStart(); // TitleCase will have an extra space at the start, so remove it.
};
