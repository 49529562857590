import { FC } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import CollectionReference from 'scenes/nuevo-collection/answers/answers-list/reference';
import LoadingIndicator from 'components/loading-indicator';

type Props = {
  displayName?: string;
  description?: string;
  name: string;
  referenceType: string;
  id: number;
};

const Reference: FC<Props> = ({
  displayName,
  description,
  name,
  referenceType,
  id,
}) => {
  const api = useOzmoApiService();
  const { data, isLoading } = api.ContentEntry.get({ id });

  if (isLoading) {
    return <LoadingIndicator message="Loading reference..." />;
  }

  const [lce] = data.localizedContentEntries;

  return (
    <CollectionReference
      {...lce}
      {...data}
      localizedContentEntryId={lce.id}
      space={data.space?.name ?? 'Unknown space'}
      topic={data.topic?.title}
      topicSlug={data.topic?.slug}
      deviceIds={[]}
      deviceTypeIds={[]}
      manufacturerIds={[]}
      osIds={[]}
      osReleaseIds={[]}
      osVersionIds={[]}
      collectionId={0}
      categoryId={0}
      onInfoClick={() => {}}
      onSelectChange={() => {}}
    />
  );
};

export default Reference;
