import { styled, Typography } from '@mui/material';

// Have to do this in a styled component because Typography doesn't
// support the -prefixed props as far as I could figure out
const MultiLineOverflowTypography = styled(Typography)<{ lines: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines};
  -webkit-box-orient: vertical;
`;

export default MultiLineOverflowTypography;
