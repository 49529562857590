import { FC } from 'react';
import { Collapse, styled } from '@mui/material';

import {
  SearchableAttribute,
  KeywordAttribute as KeywordAttributeType,
  OnAttrSelect,
  OnAttrRemove,
  IsAttrSelected,
} from '../types';

import StaticAttributes from './static-attributes';
import KeywordAttribute from './keyword-attribute';
import SearchInstructions from './search-instructions';
import ExampleAttributes from './example-attributes';
import AttributeResultItem from './attribute-result-item';

type Props = {
  isOpen: boolean;
  showResults: boolean;
  showStaticAttributes: boolean;
  keywordAttribute: KeywordAttributeType | undefined;
  attributes: SearchableAttribute[];
  staticAttributes: (SearchableAttribute | undefined)[];
  onAttrSelect: OnAttrSelect;
  onAttrRemove: OnAttrRemove;
  isAttrSelected: IsAttrSelected;
};

const Wrapper = styled('div')`
  position: absolute;
  margin-top: 0.25rem;
  background-color: var(--color-neutral-one);
  width: 100%;
  padding: 0.25rem 1.75rem;
  border-radius: 6px;
  box-shadow: 0 10px 20px 0 rgba(26, 36, 45, 0.19),
    0 6px 6px 0 rgba(26, 36, 45, 0.26);
  z-index: 6; // needed to be over top of the table header and table overlay
  max-height: 400px;
  overflow-y: auto;
`;

const SearchResults: FC<Props> = ({
  isOpen,
  showResults,
  showStaticAttributes,
  keywordAttribute,
  attributes,
  staticAttributes,
  onAttrSelect,
  onAttrRemove,
  isAttrSelected,
}) => {
  return (
    <Wrapper
      data-testid="attribute-result-wrapper"
      id="attribute-selector-results-panel"
      sx={{
        visibility: isOpen ? 'visible' : 'hidden',
      }}
    >
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {!showResults && <SearchInstructions />}
        {showStaticAttributes && (
          <StaticAttributes
            attributes={staticAttributes}
            onAttrSelect={onAttrSelect}
            onAttrRemove={onAttrRemove}
            isAttrSelected={isAttrSelected}
          />
        )}
        {!showResults && <ExampleAttributes />}
        {showResults && (
          <KeywordAttribute
            keywordAttribute={keywordAttribute}
            onSelect={onAttrSelect}
          />
        )}
        {showResults &&
          attributes.map((attr) => (
            <AttributeResultItem
              key={attr.key}
              attribute={attr}
              onAttrSelect={onAttrSelect}
              onAttrRemove={onAttrRemove}
              isAttrUsed={isAttrSelected}
            />
          ))}
      </Collapse>
    </Wrapper>
  );
};

export default SearchResults;
