import { FC } from 'react';
import { Grid, Box, Typography } from '@mui/material';

import Category from './category';
import AddOrEditCategory from './edit';
import { useCategories } from './hooks';

const Categories: FC = () => {
  const {
    categories,
    allAnswersCategory,
    selectedCategoryId,
    collectionisLoading,
    onSelectCategory,
    addOrEditCategory,
  } = useCategories();

  return (
    <>
      <Grid container justifyContent="space-between" paddingX={4}>
        <Typography
          variant="button"
          flex="auto"
          alignSelf="center"
          textTransform="uppercase"
        >
          {'Categories'}
        </Typography>
        {/* Only show add category once the collection has loaded */}
        {!collectionisLoading && (
          <AddOrEditCategory onSubmit={addOrEditCategory} />
        )}
      </Grid>
      <Box
        overflow="auto"
        flex="1"
        padding={2}
        paddingLeft={4}
        paddingTop={0}
        marginTop={2}
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <Category
          hideActions
          category={allAnswersCategory}
          isSelected={selectedCategoryId === 0}
          onSelect={() => onSelectCategory(0)}
        />
        {categories.map((category) => (
          <Category
            key={category.id}
            category={category}
            isSelected={selectedCategoryId === category.id}
            onSelect={onSelectCategory}
          />
        ))}
      </Box>
    </>
  );
};

export default Categories;
