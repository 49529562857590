import { FunctionComponent } from 'react';
import {
  Box,
  Divider,
  MenuItem,
  ListItemIcon,
  Typography,
  BoxProps,
  ListSubheader,
  Checkbox,
  Stack,
} from '@mui/material';
import { Add, Search, TextSnippet } from '@mui/icons-material';
import { ContentTypes } from 'types/enums';
import AddContent from 'scenes/add-content';
import { PermissionRequired } from 'components/permission-required';
import MenuButton from 'components/menu-button';
import { useModal } from 'components/modals';
import AddExistingContentModal from 'scenes/collection/localized-collection/add-existing-content';
import StopMouseEventPropagation from 'components/stop-mouse-event-propagation/stop-mouse-event-propagation';
import StopKeyboardEventPropagation from 'components/stop-keyboard-event-propagation';
import { ReferenceWithCategoryId } from 'scenes/nuevo-collection/hooks/use-content-tab';

import { AttributeIds } from '../use-current-category';

import { useCategoryHeader } from './use-category-header';
import QuickAddSearch from './quick-add-search';

type Props = Omit<BoxProps, 'id'> & {
  attributeIds: AttributeIds;
  collectionName: string;
  collectionId: number;
  count: number;
  id: number;
  name: string;
  languageId: number;
  existingReferences: number[];
  onSelectReference: (id: number, catId: number) => void;
  selectedReferences: ReferenceWithCategoryId[];
};

const CategoryHeader: FunctionComponent<Props> = ({
  attributeIds,
  collectionName,
  collectionId,
  count,
  id,
  name,
  languageId,
  existingReferences,
  onSelectReference,
  selectedReferences,
  ...boxProps
}) => {
  const {
    handleAddReferenceToCategory,
    handleAddReferencesToCategory,
    handleRemoveReferenceFromCategory,
    handleSelectAllReferences,
    categoryIsSelected,
  } = useCategoryHeader(
    id,
    collectionId,
    existingReferences,
    onSelectReference,
    selectedReferences
  );
  const {
    open: openExistingContent,
    openModal: handleOpenExistingContent,
    handleClose: handleCloseExistingContent,
  } = useModal();
  const {
    open: openAddContent,
    openModal: handleOpenAddContent,
    handleClose: handleCloseAddContent,
  } = useModal();

  return (
    <Box
      marginBottom={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      {...boxProps}
    >
      <Stack direction="row" alignItems="center">
        <Checkbox
          onClick={handleSelectAllReferences}
          checked={categoryIsSelected}
        />
        <Typography fontWeight="500">{`${name} (${count})`}</Typography>
      </Stack>
      <PermissionRequired permission="edit_collection" disableElement>
        <MenuButton
          keepMounted={false}
          variant="text"
          startIcon={<Add />}
          label="Answer"
        >
          <StopMouseEventPropagation>
            <QuickAddSearch
              categoryName={name}
              languageId={languageId}
              existingReferences={existingReferences}
              onSelectResult={handleAddReferenceToCategory}
              onRemoveResult={handleRemoveReferenceFromCategory}
            />
          </StopMouseEventPropagation>
          <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
          {/* Override lineHeight on the ListSubheader component otherwise it is too tall */}
          <ListSubheader sx={{ lineHeight: '32px' }}>
            {'OTHER ACTIONS'}
          </ListSubheader>

          <MenuItem onClick={handleOpenExistingContent}>
            <ListItemIcon>
              <Search fontSize="small" />
            </ListItemIcon>
            {'Search existing answers'}
          </MenuItem>
          <StopKeyboardEventPropagation>
            <MenuItem onClick={handleOpenAddContent}>
              <ListItemIcon>
                <TextSnippet fontSize="small" />
              </ListItemIcon>
              {'Create a new answer'}
            </MenuItem>
          </StopKeyboardEventPropagation>
        </MenuButton>
      </PermissionRequired>
      <AddContent
        open={openAddContent}
        onClose={handleCloseAddContent}
        sourceName="collection"
        contentTypeBlocklist={[
          ContentTypes.COLLECTION,
          ContentTypes.LEGACY_TUTORIAL,
          ContentTypes.LEGACY_TOUR,
        ]}
        attributes={attributeIds}
        onSave={handleAddReferenceToCategory}
      />
      <AddExistingContentModal
        open={openExistingContent}
        handleClose={handleCloseExistingContent}
        onAdd={handleAddReferencesToCategory}
        collectionId={collectionId}
        collectionName={collectionName}
      />
    </Box>
  );
};

export default CategoryHeader;
