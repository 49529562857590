import { ChangeEvent } from 'react';
import { oxfordize } from 'services/utils/oxfordize';

/**
 * getImageData takes in File object from an <input type="file"> and attempt
 * to mount an image in memory to verify the file actually /is/ an image
 *
 * This require the FileReader and File APIs so it checks for both
 * at the top and calls the error callback if either is missing
 *
 * @param {File} file - The File object from select or drag and drop
 * @param {func} setImageCallback - The callback to set the image if it loads successfully
 * @param {func} onImageErrorCallback - A callback to receive errors
 * @param {string[]} validMimeTypes - Array of MIME types to consider "valid"
 *
 */
const getImageData = (
  file: File,
  setImageCallback: (image: any) => void,
  onImageErrorCallback: (error: any, filename: string) => void,
  validMimeTypes?: string[]
) => {
  const hasFileApiSupport = window.File && window.FileReader;
  if (!hasFileApiSupport) {
    return onImageErrorCallback(
      'Browser does not support FileReader API',
      'Unsupported file'
    );
  }
  if (file === null || file === undefined) {
    return onImageErrorCallback('Invalid file provided', 'Invalid file');
  }

  // Get some metadata from the selected file
  const { lastModified, name, size, type } = file;

  if (validMimeTypes && !validMimeTypes.includes(type)) {
    const types = validMimeTypes.map((t) =>
      t.split('/').slice(-1)[0].toUpperCase()
    );
    return onImageErrorCallback(
      `This file type is currently not supported.  Please upload ${oxfordize(
        types,
        'or'
      )}`,
      file?.name ?? 'Unknown file'
    );
  }

  const reader = new FileReader();

  // When the reader loads a file successfully
  reader.onload = (event) => {
    const { result } = event.target as any;

    // Create an image object, even though we don't
    // intend to display it, we need it to get the width/height
    const image = new Image();
    image.src = result;

    // When the image loads
    image.onload = function () {
      const { height, width } = this as any;
      // Set the image metadata.  Hooray for closures!
      setImageCallback({
        src: result,
        height,
        width,
        name,
        type,
        sizeBytes: size,
        lastModified,
        tags: [],
        file,
      });
    };

    image.onerror = () =>
      onImageErrorCallback('File provided was not a valid image', name);
  };
  reader.readAsDataURL(file);
};

/**
 * getImageDataFromUrl takes in URL string and attempts
 * to mount an image in memory to get the image base64 data, width, and height
 *
 * @param {String} url - The URL to the image
 * @param {func} setImageCallback - The callback to set the image if it loads successfully
 * @param {func} onImageErrorCallback - A callback to receive errors
 *
 */
const getImageDataFromUrl = (
  url: string,
  setImageCallback: (image: any) => void,
  onImageErrorCallback: (error: any) => void,
  outputFormat: string
) => {
  const image = new Image();
  image.crossOrigin = 'Anonymous';
  image.onload = function () {
    const canvas = document.createElement('CANVAS') as any;
    const ctx = canvas.getContext('2d');
    canvas.height = (this as any).naturalHeight;
    canvas.width = (this as any).naturalWidth!;
    ctx.drawImage(this, 0, 0);
    const result = canvas.toDataURL(outputFormat);
    const { height, width } = this as any;
    // Set the image metadata.  Hooray for closures!
    setImageCallback({
      src: result,
      height,
      width,
      type: outputFormat,
    });
  };
  image.onerror = (err) => onImageErrorCallback(err);
  image.src = url;
};

/**
 * Get the extension on an image from the url
 *
 * @param {string} url: The URL of the image
 */
const getImageType = (url: string) => {
  // eslint-disable-next-line prefer-destructuring
  const extension = url.substring(url.length - 5).split('.')[1];
  if (extension === 'jpg') {
    return 'image/jpeg';
  }
  return `image/${extension}`;
};

/**
 * Returns the file type of the file
 *
 * @param {string} filename: The name of the file
 */
const getImageTypeFromFile = (filename: string) => {
  // const extension = #TODO: get file extension from the file
  const extension = filename.split('.').pop();
  if (extension === 'jpg') {
    return 'image/jpeg';
  }
  return `image/${extension}`;
};

/**
 * changeImageNameExtension: Change the extension in the image name
 *
 * @param {String} name : The current file name
 * @param {String} newExtension : The new file extension (jpeg, png, etc)
 */
const changeImageNameExtension = (name: string, newExtension: string) => {
  const { length } = name;
  let lowerExtension = newExtension.toLowerCase();
  if (lowerExtension === 'jpeg') {
    lowerExtension = 'jpg';
  }
  // three char extension (jpg, png)
  if (name[length - 3] === '.') {
    return `${name.substring(0, length - 3)}.${lowerExtension}`;
  }
  // four char extension (jpeg)
  if (name[length - 4] === '.') {
    return `${name.substring(0, length - 4)}.${lowerExtension}`;
  }
};

/**
 * convertImage: Convert an image between image formats using canvas
 *
 * @param {File} image : The image object as uploaded (as a File)
 * @param {String} newType : The new type ('image/png' or 'image/jpeg')
 *
 * @returns {Promise} : A promise that resolves to the converted File object (or rejects with an error string)
 */
const convertImage = (image: File, newType: string) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas') as any;
    const { width, height, src } = image as any;
    const tempImage = new Image();
    tempImage.src = src;

    // Draw the image in the canvas
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(tempImage, 0, 0);

    // Convert to desired format
    const convertedImage = new Image();
    const newSrc = canvas.toDataURL(newType);
    // When the image loads (have to use function here instead of a fat arrow
    // because arrow functions don't get "this")
    convertedImage.onload = function () {
      const { height, width } = this as any;
      // eslint-disable-next-line prefer-destructuring
      const newExtension = newType.split('/')[1];
      const newName = changeImageNameExtension(image.name, newExtension);
      // eslint-disable-next-line prefer-destructuring
      const imageSrc = newSrc.split(',')[1];
      const newSizeBytes = window.atob(imageSrc).length;

      resolve({
        ...image,
        height,
        width,
        name: newName,
        type: newType,
        src: newSrc,
        sizeBytes: newSizeBytes,
      });
    };
    convertedImage.onerror = (error) => reject(error);
    convertedImage.src = newSrc;
  });
};

const getFormattedSize = (bytes: number) => {
  if (bytes === undefined) return '';
  if (bytes > 1000000) {
    return `${(bytes / 1000000).toFixed(2)} MB`;
  }
  if (bytes > 1000) {
    return `${(bytes / 1000).toFixed(2)} KB`;
  }

  return `${bytes.toFixed(2)} Bytes`;
};

const ALLOWABLE_TRANSFER_TYPES = ['Files', 'mediaentryid', 'text/uri-list'];
const VALID_MIME_TYPES = ['image/png', 'image/jpeg'];

// Types for validateDroppedFile
type ValidFile = { file: File; src: string };
type ValidationRejection = { filename: string; message: string };

// Type guards for drag-and-drop validation
export const isDropEvent = (e: DragEvent | ChangeEvent): e is DragEvent =>
  e.type === 'drop';

export const isHandledRejection = (
  reason: any
): reason is ValidationRejection =>
  (reason as ValidationRejection).filename !== undefined;
export const isValidTransferType = (e: DragEvent) => {
  if (e.dataTransfer) {
    return ALLOWABLE_TRANSFER_TYPES.includes(e.dataTransfer.types[0]);
  }
  return false;
};
/**
 * Validate a file, mediaEntry, or external URL of an image that has been
 * drag-and-dropped onto an element
 *
 * @param e (DragEvent): The event from a browser ondrop handler
 * @returns A promise that resolves to a validated file and the string src
 *          or rejects with a filename and error message
 */
export const validateDroppedFile = (e: DragEvent) =>
  new Promise<ValidFile>(async (resolve, reject) => {
    const onImageValid = ({ file, src }: { file: File; src: string }) => {
      resolve({ file, src });
    };

    const onImageInvalid = (error: Error, filename: string) => {
      const message = error.toString() || 'An unknown error occurred.';
      reject({ filename, message });
    };

    if (e.dataTransfer === null) {
      return reject({
        filename: '',
        message: 'Invalid drop event (missing e.dataTransfer)',
      });
    }
    const [transferType] = e.dataTransfer.types;

    if (!isValidTransferType(e)) {
      return reject({ filename: '', message: 'Invalid file type' });
    }

    // If this is a file being dragged/dropped
    if (transferType === 'Files') {
      if (e.dataTransfer.items.length === 1) {
        const [file] = Array.from(e.dataTransfer.files);
        getImageData(file, onImageValid, onImageInvalid, VALID_MIME_TYPES);
        return;
      }
    }
    // if this is an image being dragged in from another website
    else if (transferType === 'text/uri-list') {
      const src = e.dataTransfer.getData('URL');
      try {
        const result = await fetch(src);
        const blob = await result.blob();
        if (blob.type.match(/image\//)) {
          const [, extension] = blob.type.split('image/');
          const file = new File([blob], `upload.${extension}`, {
            type: blob.type,
          });
          getImageData(file, onImageValid, onImageInvalid, VALID_MIME_TYPES);
        } else {
          const [filename] = src.split('/').slice(-1);
          const message = `${blob.type} is is currently not supported.  Please upload PNG or JPG.`;
          return reject({ filename, message });
        }
      } catch (error) {
        if ((error as Error).toString() === 'TypeError: Failed to fetch') {
          reject({
            filename: 'image',
            message:
              'Image cannot be uploaded due to an issue with the server hosting the image.  ' +
              'Save the image to your computer and upload it to Studio.',
          });
        } else {
          const [filename] = src.split('/').slice(-1);
          const message =
            (error as Error).toString() || 'An unknown error occurred.';
          return reject({ filename, message });
        }
      }
    }
  });

/**
 * Validate a file that has been selected via the system file picker UI
 *
 * @param e (ChangeEvent): The event from a browser onchange handler
 * @returns A promise that resolves to a validated file and the string src
 *          or rejects with a filename and error message
 */
export const validateSelectedFile = (e: ChangeEvent<HTMLInputElement>) =>
  new Promise<ValidFile>((resolve, reject) => {
    const onImageValid = ({ file, src }: { file: File; src: string }) => {
      resolve({ file, src });
    };

    const onImageInvalid = (error: Error, filename: string) => {
      const message = error.toString() || 'An unknown error occurred.';
      reject({ filename, message });
    };

    const [file] = Array.from(e.target.files ?? []);
    if (file) {
      getImageData(file, onImageValid, onImageInvalid, VALID_MIME_TYPES);
    } else {
      reject({ filename: 'Invalid file', message: 'No file provided' });
    }
  });

export {
  getImageData,
  getImageDataFromUrl,
  getImageType,
  getImageTypeFromFile,
  changeImageNameExtension,
  convertImage,
  getFormattedSize,
};
