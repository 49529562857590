import { isMissingCollectionReference } from 'services/utils/type-guards/localized-collection';

/**
 * This will take the bulk operation job response, separate it by successful and failed
 * results, and further filter out duplicate answers within both successful and failed results.
 * @param job the bulk operation job response
 * @returns an object containing the arrays of successful and failed results
 */
export const parseBulkAddAttributesJob = (job: BulkOperationJobResponse) => {
  let successResults: BulkOperationJobResultSuccess[] = [];
  let failedResults: BulkOperationJobResultFail[] = [];

  // Collect jobs by success or failure
  job.results?.forEach((j) => {
    if (j.status === 200) {
      successResults.push(j);
    } else {
      failedResults.push(j as BulkOperationJobResultFail);
    }
  });

  // Filter out duplicate answers in each. This is done because the job results
  // are separated by attibute type. So you could end up with multiple jobs
  // of the same id, which would give an incorrect count of answers updated.
  successResults = successResults.filter(
    (j, index, self) => index === self.findIndex((s) => s.id === j.id)
  );
  failedResults = failedResults.filter(
    (j, index, self) => index === self.findIndex((s) => s.id === j.id)
  );

  return {
    successResults,
    failedResults,
  };
};

export const containsIncompleteContent = (
  items: (LocalizedCollectionReference | MissingLocalizedCollectionReference)[]
) =>
  items.some(
    (item) => item && !isMissingCollectionReference(item) && !item.complete
  );
