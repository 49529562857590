import { useMemo } from 'react';
import { FCWithChildren } from 'types/fc-with-children';
import {
  createClient,
  createFlagOverridesFromMap,
  OverrideBehaviour,
} from 'configcat-js';

import { FlagsContext, useAddFlagsToWindow } from './hooks';

const CONFIG_TTL = 300;

export const FlagsProvider: FCWithChildren = ({ children }) => {
  const flagsClient = useMemo(() => {
    return createClient(import.meta.env.VITE_APP_CONFIGCAT_KEY!, {
      pollIntervalSeconds: CONFIG_TTL,
      flagOverrides: createFlagOverridesFromMap(
        (window as any).Cypress?.flagOverrides ?? {},
        OverrideBehaviour.LocalOverRemote
      ),
    });
  }, []);

  useAddFlagsToWindow(flagsClient);

  return (
    <FlagsContext.Provider value={flagsClient}>
      {children}
    </FlagsContext.Provider>
  );
};
