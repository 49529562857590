import { FC, ChangeEvent } from 'react';
import { Box, TextField } from '@mui/material';

type Props = {
  title: string;
  description: string;
  setFormItem: (title: string) => (e: ChangeEvent<HTMLInputElement>) => void;
};

const CategoryDetailsModal: FC<Props> = ({
  title,
  description,
  setFormItem,
}) => {
  return (
    <>
      <Box padding={2}>
        <TextField
          fullWidth
          label="Title"
          value={title}
          onChange={setFormItem('title')}
        />
      </Box>
      <Box padding={2}>
        <TextField
          fullWidth
          multiline
          label="Description (optional)"
          minRows={2}
          value={description}
          onChange={setFormItem('description')}
        />
      </Box>
    </>
  );
};

export default CategoryDetailsModal;
