import { useEffect } from 'react';

import { calculateNextPosition } from '../draggable-utils';

export type Position = { x: number; y: number };
type SetPositionFn = (
  pos: Position
) => ReturnType<typeof calculateNextPosition>;
type SetPosition = (fn: SetPositionFn) => void;

const useKeyboardNudge = (
  index: number,
  parentWidth: number,
  parentHeight: number,
  isSelected: boolean,
  setPosition: SetPosition,
  handleSave: Function,
  onDelete: Function
) => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      // Don't let the keyboard even scroll the page
      e.preventDefault();
      const { shiftKey, key } = e;
      // How much should x and y move
      let [xMove, yMove] = [0, 0];
      // Move 10 pixels with shift, 1 without
      const offset = shiftKey ? 10 : 1;

      switch (key) {
        case 'ArrowUp':
          yMove = -offset;
          break;
        case 'ArrowDown':
          yMove = offset;
          break;
        case 'ArrowLeft':
          xMove = -offset;
          break;
        case 'ArrowRight':
          xMove = offset;
          break;
        case 'Escape':
        case 'Enter':
          handleSave();
          return;
        case 'Delete':
          onDelete(index);
          return;
        default:
          break;
      }

      setPosition(({ x, y }) =>
        calculateNextPosition(
          x,
          y,
          x + xMove,
          y + yMove,
          parentHeight,
          parentWidth
        )
      );
    };

    if (isSelected) {
      // Must use keydown because keypress does not capture arrow key events
      window.addEventListener('keydown', onKeyDown);
      return () => window.removeEventListener('keydown', onKeyDown);
    }
  }, [
    parentHeight,
    parentWidth,
    isSelected,
    handleSave,
    onDelete,
    setPosition,
    index,
  ]);
};

export default useKeyboardNudge;
