import { RefObject, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * useResizeObserver - A custom hook to listen for changes to DOM elements
 *  size, using the ResizeObserver browser API.
 *
 * Important note: This has no IE support, but a polyfill is available if needed in the future
 *
 * @param ref - A react ref object to listen to for resizing events (e.g.: the return value from useRef)
 * @param callback - The callback to execute on resize
 * @returns null
 */
const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  callback: (entries: ResizeObserverEntry[]) => void
) => {
  const element = ref && ref.current;
  const resizeObserver = useRef<ResizeObserver>();

  useEffect(() => {
    if (element) {
      // No IE11 support here- there exists a polyfill for IE 9+
      // if we need it in the future: https://www.npmjs.com/package/resize-observer-polyfill
      resizeObserver.current = new ResizeObserver(callback);
      resizeObserver.current.observe(element);

      const currentObserver = resizeObserver.current;
      // Cleanup crew: Unsubscribe when the component is unmounted
      return () => {
        if (currentObserver) {
          currentObserver.disconnect();
        }
      };
    }
  }, [callback, element]);
};

useResizeObserver.propTypes = {
  callback: PropTypes.func.isRequired,
  ref: PropTypes.shape({
    current: PropTypes.instanceOf(HTMLElement).isRequired,
  }).isRequired,
};

export default useResizeObserver;
