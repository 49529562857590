import { FC } from 'react';
import { styled } from '@mui/material';
import { SearchResultsStatusProvider } from 'contexts/search-results-status-context';
import {
  ContentEntryTableSidebar,
  CollectionTableSidebar,
} from 'scenes/content-filter-table-sidebar';
import TabbedContent from 'scenes/tabbed-content';

import HomeTabbedHeader from './header';

type Props = {
  defaultTab?: number;
};

const StyledTabbedContent = styled(TabbedContent)`
  /* 100vh - <main> top/bottom padding - <header> height */
  height: calc(100vh - 4rem - 11rem);
  & .filter-wrapper {
  }
  & .table-wrapper {
    background-color: var(--color-neutral-one);

    height: auto;
    /* 100% of the parent container minus (roughly) the height of the filters div, results text, and header */
    max-height: calc(100% - 2rem);
  }
`;

const Home: FC<Props> = ({ defaultTab = 0 }) => {
  return (
    <SearchResultsStatusProvider>
      <StyledTabbedContent
        header={<HomeTabbedHeader />}
        tabLabels={['Collections', 'Answers']}
        defaultTab={defaultTab}
      >
        <CollectionTableSidebar />
        <ContentEntryTableSidebar
          hasActionableLanguages={true}
          hasContentActions={true}
          syncSearchAttributesWithQueryParams={true}
        />
      </StyledTabbedContent>
    </SearchResultsStatusProvider>
  );
};

export default Home;
