import { ChangeEvent } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Box,
  Checkbox,
  TextField,
} from '@mui/material';

import ListboxComponent from './virtualized-listbox';

interface MinimumAutocompleteOption {
  id: number;
  name: string;
}

type Props<AutocompleteOption = MinimumAutocompleteOption> = {
  options: MinimumAutocompleteOption[];
  initialSelectedOptions?: MinimumAutocompleteOption[];
  onChange: (
    event: ChangeEvent<{}>,
    selectedOptions: AutocompleteOption[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<MinimumAutocompleteOption>
  ) => void;
  label: string;
  placeholder?: string;
  className?: string;
  isLoading?: boolean;
};

const AutocompleteMultiselect = <AutocompleteOption,>({
  options,
  onChange,
  initialSelectedOptions,
  label,
  placeholder,
  className,
  isLoading = false,
}: Props<AutocompleteOption>) => {
  const getTruncatedSelectedOptions = (
    selected: MinimumAutocompleteOption[]
  ) => (
    <Box display="flex" maxWidth="70%" paddingLeft={1}>
      <Box
        component="span"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {selected[0].name}
      </Box>
      {selected.length > 1 && (
        <Box minWidth="4.5rem">&nbsp;{`& ${selected.length - 1} more`}</Box>
      )}
    </Box>
  );

  return (
    <Autocomplete
      multiple
      fullWidth
      // Padding here is needed to account for the label on the textfield, otherwise
      // the "edge" of the component is the outlined box and the label will be cut off
      sx={{ paddingTop: 1 }}
      disabled={isLoading}
      className={className}
      options={options}
      defaultValue={initialSelectedOptions}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      ListboxComponent={options.length > 200 ? ListboxComponent : undefined}
      // @ts-expect-error
      onChange={onChange}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={`${option.name}-${option.id}`}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          label={label}
        />
      )}
      renderTags={(selected) =>
        isLoading ? 'Loading...' : getTruncatedSelectedOptions(selected)
      }
    />
  );
};

export default AutocompleteMultiselect;
