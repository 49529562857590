/* 
  The intent of this file is to provide commonly used hooks that access data from the api.
*/
import { useMemo } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

/**
 * Returns a content entry.
 * @param {number} contentEntryId
 * @param {string[]} embedOptions
 */
export const useContentEntry = (
  contentEntryId: number | null,
  embedOptions?: string[]
) => {
  const api = useOzmoApiService();
  const { data: contentEntry, isLoading } = api.ContentEntry.get(
    {
      id: contentEntryId ?? undefined,
    },
    undefined,
    embedOptions
  );
  return { contentEntry, isLoading };
};

export const useContributors = (contentEntryId: number) => {
  const api = useOzmoApiService();
  const { data: contentEntry, isLoading } = api.ContentEntry.get({
    id: contentEntryId,
  });

  const contributors = useMemo(() => {
    if (isLoading || !contentEntry) {
      return [];
    }

    const contributorsByEmail: { [key: string]: Contributor } = {};
    contentEntry.localizedContentEntries.forEach((lce) => {
      lce.contributors.forEach((c) => {
        contributorsByEmail[c.email] = c;
      });
    });

    return Object.values(contributorsByEmail);
  }, [contentEntry, isLoading]);

  return { isLoading, contributors };
};
