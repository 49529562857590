import { CSSProperties } from 'react';
import styled, { keyframes } from 'styled-components';

import { CircularIndicator } from '../../base';

const FADE_IN_OUT_DURATION = 400;
const getTotalDuration = (waypoints: Waypoint[]) =>
  waypoints.reduce((acc, wp) => acc + wp.duration, 0) +
  FADE_IN_OUT_DURATION * 2;

const buildWaypoint = (
  { duration, x, y }: Waypoint,
  totalDuration: number
) => ({
  percentage: duration / totalDuration,
  x,
  y,
});

const waypointAnimation = ({ x, y, lofi, waypoints }: WaypointIndicator) => {
  const totalDuration = getTotalDuration(waypoints);
  const fadePercentage = FADE_IN_OUT_DURATION / totalDuration;
  const lastWaypoint = waypoints[waypoints.length - 1];

  return keyframes`
    0% { opacity: ${lofi ? 1.0 : 0}; top: ${y}%; left: ${x}%; }
    ${fadePercentage.toFixed(1)}% { opacity: 1; }
    ${waypoints
      .map((wp: Waypoint) => buildWaypoint(wp, totalDuration))
      .reduce(
        (tuples: any, wp: any) => {
          const newPercent =
            (tuples[tuples.length - 1][0] as number) + wp.percentage;
          return tuples.concat([
            [
              newPercent,
              `${(newPercent * 100).toFixed(1)}% { top: ${wp.y}%; left: ${
                wp.x
              }%; opacity: 1; }`,
            ],
          ]);
        },
        [[fadePercentage, '']]
      )
      .map(([, declaration]: string[]) => declaration)}
    100% { opacity: ${lofi ? 1.0 : 0}; top: ${lastWaypoint.y}%; left: ${
    lastWaypoint.x
  }%; }
  `;
};

export const WaypointIndicatorSVG = styled(CircularIndicator)`
  transform: translate(-50%, -50%);
  animation: ${waypointAnimation} ${(p) => getTotalDuration(p.waypoints)}ms
    infinite;
`;

const WaypointIndicator = (props: WaypointIndicator) => {
  const style: CSSProperties & { top: string; left: string } = {
    top: `${props.y}%`,
    left: `${props.x}%`,
    animationPlayState: props.isPaused ? 'paused' : 'running',
  };

  if (props.isPaused) {
    style.opacity = 1;
  }

  return <WaypointIndicatorSVG style={style} {...props} />;
};

export default WaypointIndicator;
