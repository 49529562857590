import { useState } from 'react';
import { FCWithChildren } from 'types/fc-with-children';
import { TableBody } from '@mui/material';
import LoadingIndicator from 'components/loading-indicator';
import Error from 'components/error';

import PlaceholderBody from './placeholder-body';
import EmptyTable from './empty-table';

type Props = {
  isPopulating: boolean;
  hasError: boolean;
  rowCount: number;
  colCount: number;
};

const ContentTableBody: FCWithChildren<Props> = ({
  isPopulating,
  hasError,
  rowCount,
  colCount,
  children,
}) => {
  const [isLoading, setIsLoading] = useState(isPopulating);

  // reset isLoading to true if isPopulating is true
  if (isPopulating && !isLoading) {
    setIsLoading(isPopulating);
  }

  // This is to give a little time between render states; so it isn't so abrupt and avoids sitations
  // where it briefly shows an empty results error prior to the data being fully processed.
  if (!isPopulating) {
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  }
  if (hasError) {
    return (
      <PlaceholderBody colSpan={colCount}>
        <Error>{'An error occurred while searching.  Please try again.'}</Error>
      </PlaceholderBody>
    );
  }
  if (isLoading) {
    return (
      <PlaceholderBody colSpan={colCount}>
        <LoadingIndicator
          message="Gathering content…"
          alignment="column"
          indicatorOptions={{ size: '5rem', thickness: 5 }}
        />
      </PlaceholderBody>
    );
  }

  if (rowCount <= 0) {
    return (
      <PlaceholderBody colSpan={colCount}>
        <EmptyTable />
      </PlaceholderBody>
    );
  }

  return <TableBody data-testid="content-table-body">{children}</TableBody>;
};

export default ContentTableBody;
