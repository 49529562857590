import { FunctionComponent, ReactNode } from 'react';

import { MarkOptions } from '../types';

import { useFormatButton } from './hooks';
import BaseButton from './base-button';

type Props = {
  format: MarkOptions;
  tooltip: string;
  children: ReactNode;
};

const FormatButton: FunctionComponent<Props> = ({
  format,
  tooltip,
  children,
}) => {
  const { color, backgroundColor, handleClick } = useFormatButton(format);

  return (
    <BaseButton
      color={color}
      backgroundColor={backgroundColor}
      label={format}
      tooltip={tooltip}
      onClick={handleClick}
    >
      {children}
    </BaseButton>
  );
};

export default FormatButton;
