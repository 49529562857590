/**
 * ozfordize: Turns an array of joinable things into a comma-separated string
 *  with a customizable conjunction joining the last item
 * @example oxfordize(["foo", "bar", 3, "baz"], "and"]) => "foo, bar, 3, and baz"
 * @param a any[] - The array of items to "oxfordize"
 * @param conjunction string - The conjunction to use before the final item
 * @returns string - The "oxfordized" string of all items
 */

export const oxfordize = (a: any[], conjunction: string): string => {
  if (!Array.isArray(a)) {
    return a === undefined || a === null ? '' : `${a}`;
  }

  if (a.length === 0) {
    return '';
  }
  if (a.length === 1) {
    return a[0];
  }
  if (a.length === 2) {
    return `${a[0]} ${conjunction} ${a[1]}`;
  }

  return `${a.slice(0, -1).join(', ')}, ${conjunction} ${a.slice(-1)}`;
};
