import { FunctionComponent } from 'react';

import ContentEntryTranslationButton from './content-entry-translation-button';
import CollectionTranslationButton from './collection-translation-button';

type Props = {
  collectionId: number;
  currentLanguage: string | number;
  isContentEntryBased?: boolean;
};

const TranslationButton: FunctionComponent<Props> = ({
  isContentEntryBased = true,
  currentLanguage,
  ...props
}) =>
  isContentEntryBased ? (
    <ContentEntryTranslationButton
      {...props}
      currentLanguage={currentLanguage as string}
    />
  ) : (
    <CollectionTranslationButton
      {...props}
      languageId={currentLanguage as number}
    />
  );

export default TranslationButton;
