import { useState, useMemo } from 'react';
import { containsIncompleteContent } from 'services/collections/utils';

export const useTopicGroup = (
  references: (
    | LocalizedCollectionReference
    | MissingLocalizedCollectionReference
  )[],
  categoryIndex: number,
  onSelectChange: (
    referenceId: number | number[],
    categoryIndex: number
  ) => void,
  isReferenceSelected: (id: number, categoryIndex: number) => boolean
) => {
  const selectedCount = references.reduce(
    (acc, ref) => acc + Number(isReferenceSelected(ref.id, categoryIndex)),
    0
  );
  const allSelected = selectedCount === references.length;
  const [showVariants, setShowVariants] = useState(false);
  const flagIncomplete = useMemo(() => containsIncompleteContent(references), [
    references,
  ]);

  const toggleShowVariants = () => setShowVariants((cur) => !cur);
  const handleGroupSelectChange = () => {
    const allIds = references.map(({ id }) => id);
    if (allSelected) {
      onSelectChange(allIds, categoryIndex);
    } else {
      const nonSelectedIds = references.reduce<number[]>((acc, ref) => {
        if (isReferenceSelected(ref.id, categoryIndex)) {
          return acc;
        }
        return [...acc, ref.id];
      }, []);
      onSelectChange(nonSelectedIds, categoryIndex);
    }
  };

  return {
    showVariants,
    selectedCount,
    allSelected,
    flagIncomplete,
    handleGroupSelectChange,
    toggleShowVariants,
  };
};
