import { MouseEventHandler } from 'react';
import { FCWithChildren } from 'types/fc-with-children';
import { styled, Box } from '@mui/material';
import { CompletenessFlag } from 'components/completeness-indicators';

type Props = {
  isSelected: boolean;
  className?: string;
  flagIncomplete?: boolean;
  onClick: MouseEventHandler;
};

const StyledCompletenessFlag = styled(CompletenessFlag)`
  border-top-right-radius: 6px;
`;

const StyledContainer = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: 6px;
  background-color: var(--color-neutral-two);
  min-height: 3.375rem;
  cursor: pointer;

  transition: box-shadow 0.1s ease-in-out;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows[3]};
  }

  &.is-selected {
    background-color: var(--color-primary-background);

    p {
      font-weight: bold;
      color: var(--color-primary-base);
    }
  }
`;

const BaseCategory: FCWithChildren<Props> = ({
  onClick,
  className = '',
  isSelected,
  flagIncomplete,
  children,
}) => {
  return (
    <StyledContainer
      data-testid="base-category-container"
      className={`${className} ${isSelected ? 'is-selected' : ''}`}
      onClick={onClick}
    >
      {flagIncomplete && (
        <StyledCompletenessFlag tooltipText="Contains incomplete content" />
      )}
      {children}
    </StyledContainer>
  );
};

export default BaseCategory;
