import { FC } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Link from '@mui/icons-material/Link';
import { useCopyLink } from 'services/hooks/use-copy-link';

type Props = {
  contentType: string;
  contentEntryId: number;
  language?: string;
};

const CopyContentEntryLink: FC<Props> = ({
  contentType,
  contentEntryId,
  language,
}) => {
  const [onClick, tooltipText] = useCopyLink(
    contentType,
    contentEntryId,
    undefined,
    true,
    language
  );

  return (
    <Tooltip arrow title={tooltipText}>
      <IconButton onClick={onClick} aria-label={tooltipText}>
        <Link />
      </IconButton>
    </Tooltip>
  );
};

export default CopyContentEntryLink;
