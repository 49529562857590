import { FunctionComponent, useCallback, ChangeEvent } from 'react';
import { isMissingCollectionReference } from 'services/utils/type-guards/localized-collection';

import LocalizedReference from './localized-reference';
import MissingReference from './missing-reference';

type Props = (
  | LocalizedCollectionReference
  | MissingLocalizedCollectionReference
) & {
  onInfoClick: (contentEntryId: number) => void;
  onSelectChange: (referenceId: number, categoryIndex: number) => void;
  isReferenceSelected: (referenceId: number, categoryIndex: number) => boolean;
  categoryIndex: number;
  collectionId: number;
  localizedCollectionId: number;
  asCard?: boolean;
};

const CollectionReference: FunctionComponent<Props> = ({
  onInfoClick,
  onSelectChange,
  isReferenceSelected,
  categoryIndex,
  collectionId,
  localizedCollectionId,
  asCard,
  ...reference
}) => {
  const isSelected = isReferenceSelected(reference.id, categoryIndex);
  const handleSelectChange = useCallback(
    (e: ChangeEvent) => {
      onSelectChange(reference.id, categoryIndex);
    },
    [reference.id, categoryIndex, onSelectChange]
  );
  if (isMissingCollectionReference(reference)) {
    return (
      <MissingReference
        {...reference}
        asCard={asCard}
        collectionId={collectionId}
        localizedCollectionId={localizedCollectionId}
        selected={isSelected}
        onSelectChange={handleSelectChange}
      />
    );
  }

  return (
    <LocalizedReference
      {...reference}
      asCard={asCard}
      collectionId={collectionId}
      localizedCollectionId={localizedCollectionId}
      categoryIndex={categoryIndex}
      selected={isSelected}
      onInfoClick={onInfoClick}
      onSelectChange={handleSelectChange}
    />
  );
};

export default CollectionReference;
