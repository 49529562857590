import { FunctionComponent } from 'react';
import { Box, Typography, Skeleton, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import usePreviousPage from 'services/utils/use-previous-page';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import ExpansionPanel from 'components/expansion-panel';
import ContentTypeWithIcon from 'components/content-type-with-icon';
import Section from 'components/content-entry-side-panel/section';
import { generateCollectionPath } from 'scenes/nuevo-collection/util';
import AttributesTagCloud from 'components/tag-cloud/attributes-tag-cloud/attributes-tag-cloud';

type Props = {
  id: number;
};

const StyledSection = styled(Section)`
  span {
    margin-bottom: 1rem;
  }
`;

const CollectionSidePanel: FunctionComponent<Props> = ({ id }) => {
  const api = useOzmoApiService();
  const { generateUrlWithPreviousPage } = usePreviousPage();
  const { data: collection } = api.Collection.get({
    id: id ?? undefined,
  });

  const {
    deviceTypes,
    devices,
    manufacturers,
    operatingSystems,
    operatingSystemReleases,
    operatingSystemVersions,
    categories = [],
  } = collection || {};
  const answers = categories.map((cat) => cat.contentEntryIds).flat();
  const numAttributes =
    (deviceTypes?.length ?? 0) +
    (devices?.length ?? 0) +
    (manufacturers?.length ?? 0) +
    (operatingSystems?.length ?? 0) +
    (operatingSystemReleases?.length ?? 0) +
    (operatingSystemVersions?.length ?? 0);

  if (!collection) {
    return (
      <StyledSection padding="12px 24px 0px 24px">
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
      </StyledSection>
    );
  }
  const links = categories.map((cat, index) => (
    <div key={index}>
      <Link
        to={generateUrlWithPreviousPage(
          `${generateCollectionPath(id, 'en', cat.id)}`
        )}
      >
        {cat.name}
      </Link>
    </div>
  ));

  return (
    <>
      <Section padding="12px 24px 0px 24px">
        <Box display="flex" gap="0.45rem" alignItems="start">
          <Box marginTop="0.25rem">
            <ContentTypeWithIcon contentType="Collection" />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography fontSize="1.15rem !important" fontWeight={500}>
              {collection?.name}
              <Typography
                component="span"
                color="var(--color-neutral-six) !important"
                paddingLeft={0.5}
                fontSize="0.938rem !important"
              >
                {`(#${id})`}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Section>
      <Section title="ANSWER COUNT">
        <Typography>{answers.length || 0}</Typography>
      </Section>
      <Section title="LAST UPDATED">
        <Typography>
          {new Date(collection?.updatedAt).toLocaleDateString()}
        </Typography>
      </Section>
      <Section
        title={`CATEGORIES (${categories.length})`}
        data-testid="collection-details-categories-section"
      >
        {categories.length > 5 ? (
          <ExpansionPanel
            expandText="Show More"
            collapseText="Show Less"
            collapsedHeight={'7.5rem'}
            startExpanded={false}
          >
            {links}
          </ExpansionPanel>
        ) : (
          <>{links}</>
        )}
      </Section>
      <Section
        title={`ATTRIBUTES (${numAttributes})`}
        data-testid="collection-details-attributes-section"
      >
        <AttributesTagCloud
          disabled={false}
          deviceTypes={collection?.deviceTypes ?? []}
          devices={collection?.devices ?? []}
          manufacturers={collection?.manufacturers ?? []}
          operatingSystems={collection?.operatingSystems ?? []}
          operatingSystemReleases={collection?.operatingSystemReleases ?? []}
          operatingSystemVersions={collection?.operatingSystemVersions ?? []}
        />
      </Section>
    </>
  );
};

export default CollectionSidePanel;
