import { FCWithChildren } from 'types/fc-with-children';
import styled from 'styled-components';

export const StyledTableWrapper = styled.div`
  display: flex;
  height: 100%;
`;

type Props = {
  className?: string;
  isPanelOpen: boolean;
  contentEntryId: number | null;
};

const TableWrapper: FCWithChildren<Props> = ({
  children,
  className,
  isPanelOpen,
  contentEntryId,
}) => {
  const classes = isPanelOpen
    ? `${className ?? ''} panel-open selected-content-entry-${contentEntryId}`
    : className;
  return (
    <StyledTableWrapper className={classes}>{children}</StyledTableWrapper>
  );
};

export default TableWrapper;
