import { FC } from 'react';
import { Typography } from '@mui/material';
import Delete from '@mui/icons-material/Delete';

type Props = {
  iconSize?: 'small' | 'medium' | 'large';
};

const TrashChip: FC<Props> = ({ iconSize = 'small' }) => (
  <Typography
    display="flex"
    justifyContent="center"
    fontStyle="italic"
    color="var(--color-neutral-six) !important"
    fontSize="inherit"
  >
    <Delete fontSize={iconSize} />
    {'In trash'}
  </Typography>
);

export default TrashChip;
