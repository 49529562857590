import React from 'react';
import PropTypes from 'prop-types';
import PreviewContent from 'components/preview-content';
import LoadingIndicator from 'components/loading-indicator';
import Error from 'components/error';
import { Typography } from '@mui/material';

type Props = {
  contentEntryId?: number;
  locale?: string;
  isLoading: boolean;
  isError: boolean;
  error?: string;
  localizedContentEntryId?: number;
};

const PreviewContentEntryBody: React.FunctionComponent<Props> = ({
  contentEntryId,
  locale,
  isLoading,
  isError,
  error,
  localizedContentEntryId,
}) => {
  if (!contentEntryId || !locale) {
    return (
      <Error>
        <Typography>{'Locale and Content Entry ID are required.'}</Typography>
      </Error>
    );
  }

  if (isLoading) {
    return <LoadingIndicator message="Loading preview..." />;
  }

  if (isError) {
    return (
      <Error>
        <div>
          <Typography>{`Unable to load content entry #${contentEntryId}`}</Typography>
          <pre>{error}</pre>
        </div>
      </Error>
    );
  }

  if (!localizedContentEntryId) {
    return (
      <Error>
        <Typography>{`No localized version found for ${locale} in ContentEntry #${contentEntryId}`}</Typography>
      </Error>
    );
  }
  return <PreviewContent locale={locale} />;
};

PreviewContentEntryBody.propTypes = {
  contentEntryId: PropTypes.number,
  locale: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.string,
  localizedContentEntryId: PropTypes.number,
};

export default PreviewContentEntryBody;
