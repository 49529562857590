import { useCallback, FC } from 'react';
import { ToggleButton } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

import useAnswerFiltering from '../category/use-answer-filter';

const OnlyIncompleteButton: FC = () => {
  const { setOnlyIncomplete, onlyIncomplete } = useAnswerFiltering();

  const toggleOnlyIncomplete = useCallback(() => {
    setOnlyIncomplete(!onlyIncomplete);
  }, [onlyIncomplete, setOnlyIncomplete]);

  return (
    <ToggleButton
      value="only-incomplete"
      size="small"
      color="error"
      selected={Boolean(onlyIncomplete)}
      onChange={toggleOnlyIncomplete}
      sx={{ textTransform: 'none' }}
    >
      <InfoOutlined />
      {'Incomplete'}
    </ToggleButton>
  );
};

export default OnlyIncompleteButton;
