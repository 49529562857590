import { FunctionComponent, useState } from 'react';
import { Button } from '@mui/material';
import LoadingButton from 'components/loading-button';

type Props = {
  completeReferencesCount: number;
  onPublish: () => Promise<void>;
  onClose: VoidFunction;
};

const ModalActions: FunctionComponent<Props> = ({
  completeReferencesCount,
  onPublish,
  onClose,
}) => {
  const [isPublishInProgress, setIsPublishInProgress] = useState(false);
  const handlePublishClick = async () => {
    setIsPublishInProgress(true);
    await onPublish();
    onClose();
  };

  return (
    <>
      <Button onClick={onClose}>{'Cancel'}</Button>
      {completeReferencesCount ? (
        <LoadingButton
          onClick={handlePublishClick}
          variant="contained"
          isLoading={isPublishInProgress}
          disabled={isPublishInProgress}
        >
          {isPublishInProgress
            ? 'Publishing answers'
            : `Publish ${completeReferencesCount} answers`}
        </LoadingButton>
      ) : (
        <Button variant="contained" onClick={onClose}>
          {'Got it'}
        </Button>
      )}
    </>
  );
};

export default ModalActions;
