import { FC } from 'react';
import { Typography } from '@mui/material';

import BaseCategory from './base-category';

type Props = {
  answerCount: number;
  isSelected: boolean;
  flagIncomplete?: boolean;
  onClick: VoidFunction;
};

const AllAnswers: FC<Props> = ({
  answerCount,
  isSelected,
  flagIncomplete,
  onClick,
}) => {
  return (
    <BaseCategory
      isSelected={isSelected}
      onClick={onClick}
      flagIncomplete={flagIncomplete}
    >
      <Typography
        fontSize="1rem"
        whiteSpace="break-spaces"
        lineHeight={1.25}
        marginLeft={2}
      >{`All answers (${answerCount})`}</Typography>
    </BaseCategory>
  );
};

export default AllAnswers;
