import { FC } from 'react';
import { SelectedAttributes } from 'components/attribute-selector';

import AddAttributesContent from './add-attribute-content';
import JobResultsContent from './job-results-content';

type Props = {
  answerCount: number;
  bulkJob: BulkOperationJobResponse | undefined;
  activeReferences: (
    | LocalizedCollectionReference
    | MissingLocalizedCollectionReference
  )[];
  onSelectedAttributeChange: (selectedAttributes: SelectedAttributes) => void;
};

const AddAttributeModalContent: FC<Props> = ({
  answerCount,
  bulkJob,
  activeReferences,
  onSelectedAttributeChange,
}) => {
  if (bulkJob) {
    return (
      <JobResultsContent
        bulkJob={bulkJob}
        activeReferences={activeReferences}
      />
    );
  }

  return (
    <AddAttributesContent
      answerCount={answerCount}
      onSelectedAttributeChange={onSelectedAttributeChange}
    />
  );
};

export default AddAttributeModalContent;
