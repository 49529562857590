import { FunctionComponent } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { stringToColor } from 'services/utils/string-to-color';

type Props = {
  email: string;
  user: string;
  picture: Maybe<string>;
};

const Contributor: FunctionComponent<Props> = ({ user, picture }) => {
  const initials = user
    .split(' ')
    .map((part) => part[0])
    .join('');

  return (
    <Box display="flex" alignItems="center">
      <Avatar
        data-testid="contributor:avatar"
        sx={{
          width: 24,
          height: 24,
          fontSize: '0.875rem',
          backgroundColor: stringToColor(user),
        }}
        src={picture ?? undefined}
        alt={user}
        imgProps={{
          // Required for content loaded from googleusercontent.com
          // See: https://stackoverflow.com/questions/40570117/http403-forbidden-error-when-trying-to-load-img-src-with-google-profile-pic
          referrerPolicy: 'no-referrer',
        }}
      >
        {initials}
      </Avatar>
      <Typography marginLeft={1}>{user}</Typography>
    </Box>
  );
};

export default Contributor;
