import { FCWithChildren } from 'types/fc-with-children';
import PropTypes from 'prop-types';
import ErrorIcon from 'icons/update-error.svg?react';
import { Box, Grid, Typography } from '@mui/material';

const Error: FCWithChildren = ({ children }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      spacing={2}
    >
      <Grid item>
        <ErrorIcon />
      </Grid>
      <Grid item>
        <Typography variant="h2">{'Something went wrong.'}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h6">
          <Box fontWeight={300}>{children}</Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

Error.propTypes = {
  children: PropTypes.node,
};

export default Error;
