import { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';

type Props = {
  index: number;
  type: string;
  onDelete: (index: number) => void;
};

const StyledOverlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1000; /* over everything plz */

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
`;

const UnsupportedIndicator: FC<Props> = ({ index, onDelete, type }) => (
  <StyledOverlay>
    <p>{`This step contains an imported legacy ${type}, which can not be edited or displayed in Studio.`}</p>
    <p>
      {
        'If this step needs to be edited, the unsupported indicator must first be deleted.'
      }
    </p>
    <Button onClick={() => onDelete(index)} variant="text">
      {`Delete ${type}`}
    </Button>
  </StyledOverlay>
);

export default UnsupportedIndicator;
