import { useState, useCallback, Dispatch, SetStateAction } from 'react';

type UseSelectionState = [
  boolean,
  Dispatch<SetStateAction<boolean>>,
  (e: MouseEvent) => void
];

type OnSelected = (selected: boolean) => void;

const useSelectionState = (
  initialSelected: boolean,
  onSelected?: OnSelected
): UseSelectionState => {
  const [isSelected, setIsSelected] = useState(initialSelected);

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      // only for left click
      if (e.button === 0) {
        setIsSelected(true);
        onSelected?.(true);
      }
    },
    [onSelected]
  );

  return [isSelected, setIsSelected, handleMouseDown];
};

export default useSelectionState;
