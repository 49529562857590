import { FC, ChangeEvent } from 'react';
import { TableCell, TableHead, TableRow, Checkbox } from '@mui/material';
import ContentTableHeaderCell from 'components/content-table-header-cell';

import { headCells } from './head-cells';

type Props = {
  selectedCount: number;
  rowCount: number;
  handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
};

const CollectionTableHeader: FC<Props> = ({
  selectedCount,
  rowCount,
  handleSelectAll,
}) => (
  <TableHead data-testid="collection-table-header">
    <TableRow>
      {/* checkbox */}
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          indeterminate={selectedCount > 0 && selectedCount < rowCount}
          checked={rowCount > 0 && selectedCount === rowCount}
          onChange={handleSelectAll}
          inputProps={{
            'aria-label': 'select all content',
          }}
        />
      </TableCell>
      {/* Content type (always collection, so not really a data column) */}
      <TableCell align="left" padding="normal">
        {'Type'}
      </TableCell>
      {/* data columns */}
      {headCells.map((headCell) => (
        // TODO - hardcoding in order for now until we add sorting
        <ContentTableHeaderCell key={headCell.id} order="asc" {...headCell} />
      ))}
      {/* Empty space for info panel icon */}
      <TableCell
        data-testid="collection-table-panel-icon-header"
        align="right"
        padding="none"
      />
    </TableRow>
  </TableHead>
);

export default CollectionTableHeader;
