import { useState, useCallback, useEffect, useRef } from 'react';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { ContentTypes } from 'types/enums';

const MAX_SEARCH_RESULTS = 200;

export const useContentEntrySearch = (
  prohibitedContentTypes?: ContentEntryContentType[]
) => {
  const api = useOzmoApiService();
  const [isSearching, setIsSearching] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [searchResults, setSearchResults] = useState<ContentSearchResult[]>([]);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleSearch = useCallback(
    async (
      searchParams: ContentEntrySearchParams,
      searchResultLimit?: number
    ) => {
      setHasError(false);
      setIsSearching(true);
      try {
        // add available content types if none were included in the searchParam
        if (
          !searchParams.contentTypeIds ||
          searchParams.contentTypeIds.length === 0
        ) {
          const contentTypes = await api.ContentType.getAllAsync(
            undefined,
            undefined,
            {
              perPage: 1000,
            }
          );
          const availableTypes = contentTypes.filter(
            (ct) =>
              // we never want to search for legacy content by default.
              // those types must be selected
              ![
                ContentTypes.LEGACY_TUTORIAL,
                ContentTypes.LEGACY_TOUR,
                ...(prohibitedContentTypes || []),
              ].includes(ct.name)
          );
          // eslint-disable-next-line no-param-reassign
          searchParams.contentTypeIds = availableTypes.map((ct) => ct.id);
        }

        const contentEntries = await api.ContentEntry.searchAsync(
          searchParams,
          {
            perPage: searchResultLimit || MAX_SEARCH_RESULTS,
            limit: searchResultLimit || MAX_SEARCH_RESULTS,
          }
        );
        mounted.current && setSearchResults(contentEntries);
      } catch (error: any) {
        mounted.current && setHasError(true);
      } finally {
        mounted.current && setIsSearching(false);
      }
    },
    [api.ContentEntry, api.ContentType, prohibitedContentTypes]
  );

  return {
    isSearching,
    hasError,
    searchResults,
    handleSearch,
  };
};

export const useCollectionSearch = () => {
  const api = useOzmoApiService();
  const [isSearching, setIsSearching] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [searchResults, setSearchResults] = useState<CollectionSearchResult[]>(
    []
  );
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const handleSearch = useCallback(
    async (
      searchParams: CollectionSearchParams,
      searchResultLimit?: number
    ) => {
      setHasError(false);
      setIsSearching(true);
      try {
        const collections = await api.Collection.searchAsync(searchParams, {
          perPage: searchResultLimit || MAX_SEARCH_RESULTS,
          limit: searchResultLimit || MAX_SEARCH_RESULTS,
        });
        mounted.current && setSearchResults(collections);
      } catch {
        mounted.current && setHasError(true);
      } finally {
        mounted.current && setIsSearching(false);
      }
    },
    [api.Collection]
  );

  return {
    searchResults,
    isSearching,
    hasError,
    handleSearch,
  };
};

export const useFiltersAndTable = (
  onSelectedContentChanged?: (selection: number[]) => void
) => {
  const [filterResultsOpen, setFilterResultsOpen] = useState(false);

  const handleFilterResultsToggle = useCallback((resultsOpen: boolean) => {
    setFilterResultsOpen(resultsOpen);
  }, []);

  const handleSelectedContentChange = useCallback(
    (selectedContent: number[]) => {
      onSelectedContentChanged?.(selectedContent);
    },
    [onSelectedContentChanged]
  );

  return {
    filterResultsOpen,
    handleSelectedContentChange,
    handleFilterResultsToggle,
  };
};
