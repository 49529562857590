import { FCWithChildren } from 'types/fc-with-children';
import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const MissingTitle: FCWithChildren = ({ children }) => (
  <Box display="flex" alignItems="center">
    <ErrorIcon
      className="keep-color-if-missing"
      color="error"
      fontSize="small"
    />
    <Typography
      className="keep-color-if-missing"
      color="error"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      marginLeft={1}
    >
      {children}
    </Typography>
  </Box>
);

export default MissingTitle;
