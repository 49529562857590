import { FunctionComponent } from 'react';
import {
  capitalize,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import useAnswerFilter from '../category/use-answer-filter';

type Option = {
  field: keyof LocalizedCollectionReference;
  direction: 'asc' | 'desc';
  displayName: string;
};

const SORT_OPTIONS: { [key: string]: Option } = {
  addedAtAsc: {
    field: 'addedAt',
    direction: 'asc',
    displayName: 'First Added',
  },
  addedAtDesc: {
    field: 'addedAt',
    direction: 'desc',
    displayName: 'Last Added',
  },
  titleAsc: {
    field: 'title',
    direction: 'asc',
    displayName: 'Answer Title Alphabetical (A-Z)',
  },
  titleDesc: {
    field: 'title',
    direction: 'desc',
    displayName: 'Answer Title Alphabetical (Z-A)',
  },
  topicSlugAsc: {
    field: 'topicSlug',
    direction: 'asc',
    displayName: 'Topic Slugs Alphabetical (A-Z)',
  },
  topicSlugDesc: {
    field: 'topicSlug',
    direction: 'desc',
    displayName: 'Topic Slugs Alphabetical (Z-A)',
  },
};

const SortSelector: FunctionComponent = () => {
  const { setSortBy, sortField, sortDirection } = useAnswerFilter();
  const selectValue = `${sortField}${capitalize(sortDirection)}`;

  const handleChange = (e: SelectChangeEvent<keyof typeof SORT_OPTIONS>) => {
    const { value } = e.target;
    const { field, direction } = SORT_OPTIONS[value];
    setSortBy(field, direction);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="collection-answer-sort-label">{'Sort by'}</InputLabel>
      <Select<keyof typeof SORT_OPTIONS>
        labelId="collection-answer-sort-label"
        id="collection-answer-sort"
        label={'Sort by'}
        value={selectValue}
        onChange={handleChange}
      >
        {Object.entries(SORT_OPTIONS).map(([key, value]) => (
          <MenuItem value={key} key={key}>
            {value.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortSelector;
