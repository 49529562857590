import { useInfoModal } from 'components/modals';
import { OpenInBrowser } from '@mui/icons-material';
import { Link, Typography } from '@mui/material';

const useOperatingSystemSelectorWarningModal = () => {
  const { modal, openModal } = useInfoModal({
    modalProps: {
      title: 'Warning: You are about to add an OS Version attribute',
      message: '',
      maxWidth: 'md',
      closeButtonText: 'Got it!',
      children: (
        <>
          <Typography>
            <>
              {
                'Adding an OS Version attribute will cause this content to only be visible for listed OS Versions. '
              }
            </>
          </Typography>
          <Typography>
            <strong>
              {
                'OS Versions should typically only be used for Apps and Apple release versions.'
              }
            </strong>
          </Typography>
          <Link
            href="https://modeacorp.atlassian.net/wiki/spaces/CP/pages/3491922023/Studio+Attribution#When-to-use-Operating-System-Version-Attribute"
            target="_blank"
            display="flex"
            alignItems="center"
            marginTop={1}
            underline="none"
          >
            {'When to apply OS Version attributes'}&nbsp;
            <OpenInBrowser fontSize="small" />
          </Link>
        </>
      ),
    },
  });
  return {
    modal,
    openModal,
  };
};

export { useOperatingSystemSelectorWarningModal };
