import { FunctionComponent } from 'react';
import Delete from '@mui/icons-material/Delete';

import BaseStaticToast from './base-static-toast';

const SoftDeletedStaticToast: FunctionComponent = () => (
  <BaseStaticToast
    color="neutralSix"
    variant="filled"
    title="This answer has been deleted"
    message={
      <>
        {'This answer is '}
        <b>{'view-only'}</b>
        {'. Contact an admin if you need to restore this answer.'}
      </>
    }
    icon={<Delete />}
  />
);

export default SoftDeletedStaticToast;
