import { FunctionComponent } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

type Props = BoxProps & {
  title?: string;
};

const Section: FunctionComponent<Props> = ({
  title,
  children,
  ...boxProps
}) => {
  return (
    <Box padding="24px 24px 0px 24px" {...boxProps}>
      {title && (
        <Typography
          textTransform="uppercase"
          fontWeight={500}
          paddingBottom={1}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default Section;
