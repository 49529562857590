import { FC } from 'react';
import { Chip } from '@mui/material';

import StatusIndicator from './status-indicator';
import { getStatusDisplayInfo } from './utils';

type Props = {
  status: LocalizedContentEntryStatus | undefined;
};

const StatusChip: FC<Props> = ({ status }) => {
  const { text, color } = getStatusDisplayInfo(status);

  return (
    <Chip
      sx={{
        backgroundColor: color,
        borderColor: 'transparent',
        color: 'white',
        fontSize: '0.875rem',
        paddingLeft: 0.75,
      }}
      label={text}
      icon={<StatusIndicator status={status} colorOverride="white" />}
    />
  );
};

export default StatusChip;
