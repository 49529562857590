export const headCells: ContentHeadCell<ContentTableData>[] = [
  {
    id: 'contentType',
    disablePadding: false,
    isSortable: true,
    label: 'Type',
    align: 'left',
  },
  {
    id: 'title',
    disablePadding: false,
    isSortable: true,
    label: 'Title',
    align: 'left',
  },
  {
    id: 'updatedAt',
    disablePadding: false,
    isSortable: true,
    label: 'Updated',
    align: 'left',
  },
  {
    id: 'space',
    disablePadding: false,
    isSortable: true,
    label: 'Space',
    align: 'left',
  },
  {
    id: 'languages',
    disablePadding: false,
    isSortable: false,
    label: 'Languages',
    align: 'left',
  },
  {
    id: 'attributes',
    disablePadding: false,
    isSortable: false,
    label: 'Attributes',
    align: 'left',
  },
  {
    id: 'status',
    disablePadding: false,
    isSortable: true,
    label: 'Status',
    align: 'center',
  },
];
