import { FC } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { SelectedAttributes } from 'components/attribute-selector/';
import getPlural from 'services/utils/get-plural';
import AddAttributeSelector from 'components/add-attribute-selector';

type Props = {
  answerCount: number;
  onSelectedAttributeChange: (selectedAttributes: SelectedAttributes) => void;
};

const StyledAddAttributeSelector = styled(AddAttributeSelector)`
  /* style the attribute results panel */
  #attribute-selector-results-panel {
    position: static;
    width: auto;
  }
`;

const AddAttributeContent: FC<Props> = ({
  answerCount,
  onSelectedAttributeChange,
}) => (
  <Box>
    <Typography marginBottom={3}>
      {'You are about to add attributes to '}
      <Typography
        component="span"
        fontWeight="bold"
      >{`${answerCount} ${getPlural(
        answerCount,
        'answer',
        'answers'
      )}. `}</Typography>
      {
        'This action will add these attributes to all instances where these answers exist in Studio.'
      }
    </Typography>
    <StyledAddAttributeSelector
      prohibitedAttributes={[
        'ContentTitle',
        'Space',
        'ContentType',
        'Language',
        'Topic',
      ]}
      onSelectedAttrChange={onSelectedAttributeChange}
    />
  </Box>
);

export default AddAttributeContent;
