import { FCWithChildren } from 'types/fc-with-children';
import { TableContainer, styled } from '@mui/material';
import { StyledTableWrapper } from 'components/content-entry-side-panel';

type Props = {
  showOverlay?: boolean;
};

const FlexWrapper = styled('div')`
  flex: 3;
  ${StyledTableWrapper}.panel-open & {
    overflow-x: clip;
  }
`;

const StyledTableContainer = styled(TableContainer)`
  /* Note: The placeholder loading and empty states for the table */
  /* are roughly centered based on this height. */
  position: relative;
  max-height: 100%;

  ${StyledTableWrapper}.panel-open & {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .MuiTableRow-root:last-child > td {
    border-bottom: none;
  }

  .MuiTableCell-footer {
    z-index: 2;
    left: 0;
    bottom: 0;
    position: sticky;
    border-top: 1px solid var(--color-neutral-four);
    background-color: var(--color-neutral-one);
  }
`;

const Overlay = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 6px; // matches table's border radius
  background-color: var(--color-neutral-seven);
  opacity: 0.4;
  z-index: 5;
`;

const ContentTableContainer: FCWithChildren<Props> = ({
  showOverlay,
  children,
}) => (
  <FlexWrapper>
    <StyledTableContainer>
      {showOverlay && <Overlay />}
      {children}
    </StyledTableContainer>
  </FlexWrapper>
);

export default ContentTableContainer;
