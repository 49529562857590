import {
  useState,
  useCallback,
  MouseEvent,
  FC,
  ReactElement,
  ReactNode,
} from 'react';
import { Menu, IconButtonProps, MenuProps, IconButton } from '@mui/material';

type Props = Omit<IconButtonProps, 'onClick'> & {
  label: string;
  icon: ReactElement;
  keepMounted?: MenuProps['keepMounted'];
  children?: ReactNode;
};

const IconMenuButton: FC<Props> = ({
  label,
  icon,
  children,
  keepMounted = true,
  ...buttonProps
}) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      setAnchorElement(e.currentTarget);
    },
    [setAnchorElement]
  );

  const handleMenuClose = useCallback(() => {
    setAnchorElement(null);
  }, [setAnchorElement]);

  return (
    <>
      <IconButton
        {...buttonProps}
        onClick={handleButtonClick}
        aria-label={label}
      >
        {icon}
      </IconButton>
      <Menu
        keepMounted={keepMounted}
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
      >
        {children}
      </Menu>
    </>
  );
};

export default IconMenuButton;
