// Adapted from https://matthiashager.com/converting-snake-case-to-camel-case-object-keys-with-javascript

export const toSnake = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const arrayToKeyObject = function (arr) {
  return arr.reduce(
    (obj, key) => ({
      ...obj,
      [key]: true,
    }),
    {}
  );
};

const keysToSnake = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toSnake(k)] = keysToSnake(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i) => {
      return keysToSnake(i);
    });
  }

  return o;
};

export default keysToSnake;
