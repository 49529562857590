import { FC, useEffect } from 'react';
import ContentTable from 'scenes/content-table';
import ContentEntrySidePanel, {
  useContentEntrySidePanel,
} from 'components/content-entry-side-panel';
import SidePanelContainer from 'components/search-side-panel/side-panel-container';
import { useOzmoApiService } from 'contexts/ozmo-api-service-context';
import { useSearchResultsStatus } from 'contexts/search-results-status-context';

import ContentTableSidebarContainer from './content-filter-table-sidebar';
import { useContentEntrySearch, useFiltersAndTable } from './hooks';

type Props = {
  hasActionableLanguages?: boolean;
  hasContentActions?: boolean;
  prohibitedFilterContentTypes?: ContentEntryContentType[];
  syncSearchAttributesWithQueryParams?: boolean;
  openEditInNewTab?: boolean;
  onSelectedContentChanged?: (selection: number[]) => void;
};

export const ContentEntryTableSidebar: FC<Props> = ({
  hasActionableLanguages,
  hasContentActions,
  prohibitedFilterContentTypes,
  syncSearchAttributesWithQueryParams,
  openEditInNewTab,
  onSelectedContentChanged,
}) => {
  const {
    showPanel,
    hidePanel,
    panelItemId,
    isPanelOpen,
  } = useContentEntrySidePanel();
  const {
    searchResults,
    isSearching,
    hasError,
    handleSearch,
  } = useContentEntrySearch(prohibitedFilterContentTypes);
  const { setSearchResultsStatus } = useSearchResultsStatus() ?? {};
  const {
    filterResultsOpen,
    handleFilterResultsToggle,
    handleSelectedContentChange,
  } = useFiltersAndTable(onSelectedContentChanged);
  const api = useOzmoApiService();
  const { data: contentEntry } = api.ContentEntry.get({
    id: panelItemId ?? undefined,
  });

  // Share search results status with the context so that the export button can be enabled/disabled
  useEffect(() => {
    if (setSearchResultsStatus) {
      setSearchResultsStatus({
        isSearching,
        hasError,
        searchResults,
      });
    }
  }, [isSearching, hasError, searchResults, setSearchResultsStatus]);

  return (
    <ContentTableSidebarContainer
      isPanelOpen={isPanelOpen}
      panelItemId={panelItemId}
      prohibitedFilterContentTypes={prohibitedFilterContentTypes}
      syncSearchAttributesWithQueryParams={syncSearchAttributesWithQueryParams}
      handleFilterResultsToggle={handleFilterResultsToggle}
      handleSearch={handleSearch}
    >
      <ContentTable
        content={searchResults}
        isPopulating={isSearching}
        hasError={hasError}
        hideTable={filterResultsOpen}
        onSelectedChange={handleSelectedContentChange}
        onInfoClick={showPanel}
        enableContentActions={hasContentActions}
        openEditInNewTab={openEditInNewTab}
      />
      {panelItemId && (
        <SidePanelContainer
          onClose={hidePanel}
          contentType={contentEntry?.contentType ?? ''}
          id={panelItemId}
        >
          <ContentEntrySidePanel
            hasActionableLanguages={!!hasActionableLanguages}
            contentEntryId={panelItemId}
          />
        </SidePanelContainer>
      )}
    </ContentTableSidebarContainer>
  );
};
