import { Box, Typography } from '@mui/material';
import StatusIndicator from 'components/status-indicator';
import { FC } from 'react';
import SimpleEditableTextfield from 'components/simple-editable-textfield';

type Props = {
  isCollection: boolean;
  contentId: number | undefined;
  title: string;
  onTitleUpdate: (newTitle: string) => void;
  status?: LocalizedContentEntryStatus;
};

const Title: FC<Props> = ({
  isCollection,
  contentId,
  title,
  onTitleUpdate,
  status,
}) => (
  <Box display="flex" gap={1} alignItems="center">
    <Typography
      variant="button"
      fontSize="1rem"
      color={
        isCollection
          ? 'var(--color-primary-base)'
          : 'var(--color-content-tutorial)'
      }
    >
      {isCollection ? 'Collection' : 'Answer'}
    </Typography>
    <Typography
      component="span"
      variant="body1"
      fontSize="1.2rem"
      fontWeight="bold"
    >
      {'/'}
    </Typography>
    <SimpleEditableTextfield value={title} onChange={onTitleUpdate}>
      <Typography
        component="span"
        variant="body1"
        fontSize="1.2rem"
        fontWeight="bold"
      >
        {contentId ? `${title} (#${contentId})` : title}
      </Typography>
    </SimpleEditableTextfield>
    {status && <StatusIndicator status={status} />}
  </Box>
);

export default Title;
