import { FC, useMemo, MouseEventHandler } from 'react';
import { Button, ButtonProps } from '@mui/material';
import LoadingButton from 'components/loading-button';

import BaseModal, { Props as BaseModalProps } from './base';

export type Props = Omit<BaseModalProps, 'actionChildren'> & {
  closeButtonText?: string;
  confirmButtonText?: string;
  confirmButtonColor?: ButtonProps['color'];
  disableConfirmButton?: boolean;
  confirmInProgress?: boolean;
  onConfirm: MouseEventHandler<HTMLButtonElement>;
};

export const ActionModal: FC<Props> = (props) => {
  const {
    children,
    confirmButtonText = 'Confirm',
    confirmInProgressText = confirmButtonText,
    confirmButtonColor = 'primary',
    disableConfirmButton,
    confirmInProgress,
    closeButtonText = 'Cancel',
    onConfirm,
    onClose,
    ...baseProps
  } = props;

  const actionButtons = useMemo(() => {
    return (
      <>
        <Button onClick={onClose} color="primary">
          {closeButtonText}
        </Button>
        <LoadingButton
          isLoading={confirmInProgress}
          color={confirmButtonColor}
          onClick={onConfirm}
          disabled={disableConfirmButton}
          variant="contained"
        >
          {confirmInProgress ? confirmInProgressText : confirmButtonText}
        </LoadingButton>
      </>
    );
  }, [
    confirmInProgress,
    closeButtonText,
    confirmButtonText,
    confirmButtonColor,
    disableConfirmButton,
    confirmInProgressText,
    onConfirm,
    onClose,
  ]);

  return (
    <BaseModal actionChildren={actionButtons} onClose={onClose} {...baseProps}>
      {children}
    </BaseModal>
  );
};
