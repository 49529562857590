import { FC, memo } from 'react';
import { Typography, Stack } from '@mui/material';
import { convertCamelOrTitleCaseToTitle } from 'services/utils/convert-camel-to-title-case';

import { AttributeOptionWithKey, OnAttrRemove } from '../types';
import AttributeChip from '../attribute-chip';

type Props = {
  selectedAttributes: AttributeOptionWithKey[];
  onAttrRemove: OnAttrRemove;
};

const SelectedAttributeChips: FC<Props> = ({
  selectedAttributes,
  onAttrRemove,
}) => {
  if (selectedAttributes.length === 0) return null;

  return (
    <>
      {selectedAttributes.map((attr) => (
        <AttributeChip
          key={`${attr.name}-${attr.id}`}
          attribute={attr}
          isAttrUsed
          label={
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              fontSize="1em"
              lineHeight={1.5} // mui default; set here to keep all these aligned
            >
              <Typography
                textTransform="capitalize"
                component="span"
                fontWeight="bold"
                color="var(--color-neutral-one)"
                fontSize="0.8125rem"
              >
                {`${convertCamelOrTitleCaseToTitle(attr.attributeKey)}: `}
              </Typography>
              {/* attr.name could be a string or ReactNode. If just a string, wrap in <span/> */}
              {typeof attr.name === 'string' ? (
                <span>{attr.name}</span>
              ) : (
                attr.name
              )}
            </Stack>
          }
          onAttrRemove={onAttrRemove}
        />
      ))}
    </>
  );
};

const memoizedSelectedAttributes = memo(
  SelectedAttributeChips,
  (prevProps, nextProps) =>
    prevProps.selectedAttributes === nextProps.selectedAttributes
);

export default memoizedSelectedAttributes;
